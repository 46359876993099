import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleBoltNutsEditPath } from '../../../paths';
import { selectCurrentBoltNutFamily } from '../../../entities/Piping/BoltNutFamilies/selectors';

function BendEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModuleBoltNutsEditPath.url });
  const boltNutId = (match && match.params.id) || null;
  const boltNutFamily = useSelector(selectCurrentBoltNutFamily(boltNutId));

  return <BreadcrumbTemplate title={(boltNutFamily && boltNutFamily.name) || PipingModuleBoltNutsEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);
