import { useCallback, useEffect, useRef, useState } from 'react';

import useLatest from './useLatest';

const useToggle = (initialState = false, handler) => {
  const didMount = useRef(false);
  // saving the latest ref of the handler here
  // which means we only ever run the latest one
  // after all renders are complete
  const handlerRef = useLatest(handler);

  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);

  useEffect(() => {
    if (didMount.current) {
      if (!handler) return;

      handlerRef.current(state);
    } else {
      didMount.current = true;
    }

    // make the dependency for this handler a boolean
    // based on the presence of the handler - the handlerRef
    // inside the function will always be the correct one
  }, [state, !handler]);

  return [state, toggle];
};

export default useToggle;
