import { takeLatest, put, fork } from 'redux-saga/effects';

import { LoginPath } from '../../paths';

import { processResetPasswordTypes } from '../../modules/auth/constants';

import { push } from 'connected-react-router';

import { handleLogout } from '../../modules/auth/actions';

export function* handleResetPasswordReset() {
  yield put(handleLogout());
  yield put(push(LoginPath.generate({ passwordReset: true })));
}

export function* watchResetPasswordSaga() {
  yield takeLatest(processResetPasswordTypes.SUCCESS, handleResetPasswordReset);
}

// final output saga
export default function* main() {
  yield fork(watchResetPasswordSaga);
}
