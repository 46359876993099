import { Map } from 'immutable';

import resetOnLogout from '../../utils/reducers/resetOnLogout';
import createFilteredReducer from '../../utils/reducers/createFilteredReducer';

const initialCacheState = Map();

const CACHE_EXPIRATION = 10 * 60; // defaulting cache expiration to 10 minutes

const isCacheExpired = (now, timestamp) => ((now - timestamp)/1000) >= CACHE_EXPIRATION;

const cacheReducer = (state = initialCacheState, { meta = {} }) => {
  const { cache : url, resetCache } = meta;
  if (url) { return state; }

  const now = Date.now();

  if (resetCache || state.has(url) || isCacheExpired(now, state.get(url))) {
    return state.set(url, now);
  } else {
    return state;
  }
};

export default resetOnLogout(createFilteredReducer(cacheReducer, action => !!((action || {}).meta || {}).cache));
