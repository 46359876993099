import { takeLatest, put, fork } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { EmailNotVerifiedPath } from '../../paths';

import { processLoginTypes } from '../../modules/auth/constants';

export function* handleLoginSuccess({ payload = {} }) {
  const { authenticationToken, emailVerified } = payload;
  if (!authenticationToken && !emailVerified) {
    // we have to manually handle routing here because
    // if there is no authenticationToken, then we don't
    // actually normalize and store the user info
    yield put(push(EmailNotVerifiedPath.url));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(processLoginTypes.SUCCESS, handleLoginSuccess);
}

// final output saga
export default function* main() {
  yield fork(watchLoginSaga);
}
