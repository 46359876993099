import Loadable from 'react-loadable';

// import SupportRedirect from '../../SupportRedirect';
import Login from '../../Login';
import Register from '../../Register';
import PasswordResetRequest from '../../PasswordResetRequest';
import PasswordReset from '../../PasswordReset';
import AccountDeactivated from '../../AccountDeactivated';
import NoLicense from '../../NoLicense';
import NotVerified from '../../NotVerified';
import EmailVerification from '../../EmailVerification';
import AccountSetup from '../../AccountSetup';
import InternalErrorC from '../../InternalError';
import NotFound from '../../NotFound';
import Dashboard from '../../Dashboard';

// export const SUPPORT_REDIRECT_ROUTE_CONTAINER = SupportRedirect;
export const LOGIN_ROUTE_CONTAINER = Login;
export const REGISTER_ROUTE_CONTAINER = Register;
export const FORGOT_PASSWORD_REQUEST_ROUTE_CONTAINER = PasswordResetRequest;
export const RESET_PASSWORD_ROUTE_CONTAINER = PasswordReset;
export const ACCOUNT_DEACTIVATED_ROUTE_CONTAINER = AccountDeactivated;
export const NO_LICENSE_ROUTE_CONTAINER = NoLicense;
export const EMAIL_NOT_VERIFIED_ROUTE_CONTAINER = NotVerified;
export const VERIFY_EMAIL_ROUTE_CONTAINER = EmailVerification;
export const ACCOUNT_SETUP_ROUTE_CONTAINER = AccountSetup;
export const SERVER_ERROR_ROUTE_CONTAINER = InternalErrorC;
export const PAGE_NOT_FOUND_ROUTE_CONTAINER = NotFound;
export const DASHBOARD_ROUTE_CONTAINER = Dashboard;

// export const SUPPORT_REDIRECT_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/SupportRedirect'),
//   loading: LoadingImport,
// });

// export const LOGIN_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/Login'),
//   loading: LoadingImport,
// });

// export const REGISTER_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/Register'),
//   loading: LoadingImport,
// });

// export const FORGOT_PASSWORD_REQUEST_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/PasswordResetRequest'),
//   loading: LoadingImport,
// });

// export const RESET_PASSWORD_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/PasswordReset'),
//   loading: LoadingImport,
// });

// export const ACCOUNT_DEACTIVATED_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/AccountDeactivated'),
//   loading: LoadingImport,
// });

// export const NO_LICENSE_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/NoLicense'),
//   loading: LoadingImport,
// });

// export const EMAIL_NOT_VERIFIED_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/NotVerified'),
//   loading: LoadingImport,
// });

// export const VERIFY_EMAIL_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/EmailVerification'),
//   loading: LoadingImport,
// });

// export const ACCOUNT_SETUP_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/AccountSetup'),
//   loading: LoadingImport,
// });

// export const SERVER_ERROR_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/InternalError'),
//   loading: LoadingImport,
// });

// export const PAGE_NOT_FOUND_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/NotFound'),
//   loading: LoadingImport,
// });

// export const DASHBOARD_ROUTE_CONTAINER = Loadable({
//   loader: () => import('routes/Dashboard'),
//   loading: LoadingImport,
// });
