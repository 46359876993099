import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';

export const convertToBendMachineInstanceType = ({
  id,
  sizeProperty,
  startClampLength,
  midClampLength,
  endClampLength,
  isEdited=false,
  isCreated=false,
  isDeleted=false,
}) => ({
  id,
  sizeProperty,
  startClampLength,
  midClampLength,
  endClampLength,
  isEdited,
  isCreated,
  isDeleted
});

export const saveCurrentBendMachineInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty?.id,
        startClampLength: getUnitLength(item.startClampLength, currentUnitSystem),
        midClampLength: getUnitLength(item.midClampLength, currentUnitSystem),
        endClampLength: getUnitLength(item.endClampLength, currentUnitSystem),
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty?.id,
        startClampLength: getUnitLength(item.startClampLength, currentUnitSystem),
        midClampLength: getUnitLength(item.midClampLength, currentUnitSystem),
        endClampLength: getUnitLength(item.endClampLength, currentUnitSystem),
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};