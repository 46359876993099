import React from 'react';

import Flex from '../../common/Flex';
import FadeOverflow from '../../common/FadeOverflow';
import { Chip } from 'primereact/chip';
import CountCircle from '../../icons/custom/CountCircle';

const ensureArrayFromList = (values) => {
  let _values = [];
  try {
    _values = values && values.toArray();
  } catch (error) {
    _values = values;
  }
  return _values;
};

export default function ({values, field}) {
  const arrayValues = ensureArrayFromList(values);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FadeOverflow content={
        <Flex flexDirection='row' style={{ width: 'calc( 100% - 5px)', overflow: 'hidden' }} >
          {arrayValues.map(item => <Chip label={field && item[field] || item.primary} key={field && item[field] || item.primary} style={{ whiteSpace: 'nowrap' }} />)}
        </Flex>
      } maxWidth={'calc(100% - 30px)'} />
      {arrayValues.length > 0 && <CountCircle size='25' content={arrayValues.length} style={{ float: 'right' }} />}
    </div>
  );
}