import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

import { PRODUCT_TIERS } from '../Organizations/model';

import CurrentUserSchema from './schema';

export const selectNormalizedCurrentUser = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CURRENT_USER.state).first()
);
export const selectIsPendingForcedPasswordReset = () => createSelector(
  selectNormalizedCurrentUser(),
  currentUser => currentUser && currentUser.passwordResetRequired
);
export const selectCurrentUser = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.CURRENT_USER.state), [CurrentUserSchema], entities).first()
);
export const selectCurrentOrganization = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.organization
);
export const selectCurrentOrganizationId = () => createSelector(
  selectCurrentOrganization(),
  organization => organization.id
);
export const selectCurrentUserHasPipingModule = () => createSelector(
  selectCurrentUser(),
  selectCurrentOrganization(),
  (currentUser, organization) => organization.hasModulePipingPrivileges && currentUser.hasModulePiping
);
export const selectCurrentUserHasSynchronizeModule = () =>  createSelector(
  selectCurrentOrganization(),
  organization => organization.hasProductTierSynchronizePrivileges
);
export const selectCurrentUserHasStandardizeModule = () => createSelector(
  selectCurrentOrganization(),
  organization => organization.hasProductTierStandardizePrivileges
);
export const selectCurrentUserAllowedRoleAssignments = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.allowedRoleAssignments
);
