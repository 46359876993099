import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Box from '../../../../components/common/Box';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import CreateOrganizationUserForm from '../../../form/templates/CreateOrganizationUserForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { 
  selectCurrentOrganizationId,
  selectCurrentUserAllowedRoleAssignments, 
} from '../../../../entities/CurrentUser/selectors';

import { 
  processAdminCreateUser, 
} from '../../../../entities/Users/actions';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const CreateOrganizationUserModal = ({ organizationId, roles, isPerforming, onCreate }) => {
  const onSave = useCallback(data => onCreate(organizationId, data), [organizationId, onCreate]);

  return (
    <Box>
      <ModalTitle><strong>Create new</strong> User</ModalTitle>

      <CreateOrganizationUserForm roles={roles} isPerforming={isPerforming} onRDXSubmit={onSave} />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  organizationId: selectCurrentOrganizationId(),
  roles: selectCurrentUserAllowedRoleAssignments(),
});

const enhance = compose(
  injectSaga({ key: 'createOrganizationUserModal', saga }),
  connect(
    mapStateToProps, 
    { onCreate: processAdminCreateUser }
  ),
);

export default enhance(CreateOrganizationUserModal);
