import { toast } from 'react-toastify';

/**
 * Creates a Redux middleware that looks for actions that create a non-timed
 * notification (a.k.a, a notice), and then it programmatically assigns
 * it a toast id that will be later used to remove it
 *
 * @returns {Function} A Redux middleware.
 */
export default function toastifyNoticeMiddleware() {
  return (store) =>
    (next) =>
      (action) => {
        const { notice } = (action || {}).meta || {};

        if (notice && notice.message && action.payload.id) {
          const id = action.payload.id;

          const {
            message,
            type = toast.TYPE.DEFAULT,
            position = toast.POSITION.BOTTOM_CENTER,
            ...rest
          } = notice;

          let toastId = store.getState().getIn(['notices', id]);

          if (toastId) {
            toast.update(toastId, {
              render: message,
              type,
              position,
              autoClose: false,
              ...rest,
            });
          } else {
            toastId = toast(message, {
              type,
              position,
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
              draggable: false,
              ...rest,
            });
          }

          next({
            ...action,
            payload: {
              id,
              toastId,
            },
          });
        }

        next(action);
      };
}
