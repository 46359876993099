import React from 'react';
import Button from '../../../components/common/Button';

export const ConstructedOptions = ({
  isPerforming,
  manageBends,
  manageBendMachines,
  manageBendSpringbacks,
  manageMiters,
}) => (
  <React.Fragment>
    <Button secondary disabled={isPerforming} onClick={manageBends} mr={2}>
      Bends
    </Button>
    <Button
      secondary
      disabled={isPerforming}
      onClick={manageBendMachines}
      mr={2}
    >
      Bend Machines
    </Button>
    <Button
      secondary
      disabled={isPerforming}
      onClick={manageBendSpringbacks}
      mr={2}
    >
      Bend Springbacks
    </Button>
    <Button secondary disabled={isPerforming} onClick={manageMiters} mr={2}>
      Miters
    </Button>
  </React.Fragment>
);