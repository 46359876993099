import { Map } from 'immutable';

import { WATCH_BUTTON_ACTION, SET_BUTTON_ACTION_STATUS, UNWATCH_BUTTON_ACTION } from './constants';

const initialButtonState = Map({});

const buttonReducer = (state = initialButtonState, { type, payload }) => {
  if (type === WATCH_BUTTON_ACTION) {
    const { id } = payload;

    return id ? state.set(id, false) : state;
  } else if (type === SET_BUTTON_ACTION_STATUS) {
    const { id, status } = SET_BUTTON_ACTION_STATUS;
    
    return id ? state.set(id, status) : state;
  } else if (type === UNWATCH_BUTTON_ACTION) {
    const { id } = payload;

    return id ? state.delete(id) : state;
  } else {
    return state;
  }
};

export default buttonReducer;