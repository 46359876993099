import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.SPECS.state;

export const fetchAllSpecsTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualSpecTypes = generateAPHTypes(resource, 'FETCH');

export const createSpecTypes = generateAPHTypes(resource, 'CREATE');

export const copySpecTypes = generateAPHTypes(resource, 'COPY');

export const editSpecTypes = generateAPHTypes(resource, 'EDIT');

export const deleteSpecTypes = generateAPHTypes(resource, 'DELETE');

export const archiveSpecsTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveSpecsTypes = generateAPHTypes(resource, 'UNARCHIVE');