import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import MultiSelectCellRenderer from '../../../components/grid/MultiSelectCellRenderer';
import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';
import Button from '../../../components/common/Button';

//miscellaneous
import MultiTypeCellRenderer from '../../../components/grid/MultiTypeCellRenderer';
import { Dialog } from 'primereact/dialog';

// Header
import Flex from '../../../components/common/Flex';
import {
  ParameterProfilesEditPath
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// dropdown constants and validation
import {
  BlankPipe,
  PIPE_SHAPES,
  VALIDATION_FIELDS,
} from '../../../entities/Piping/PipeFamilies/model';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { areFieldsValid, doRequiredFieldsExist } from '../ParameterProfiles/components';

// Actions
import { convertToParameterProfileInstancesType } from './actions';
import { processFetchIndividualParameterProfile } from '../../../entities/Standardize/ParameterProfiles/actions';
import { processFetchAllParameters } from '../../../entities/Standardize/Parameters/actions';
import { processSaveParameterProfileParameters } from '../../../entities/Standardize/ParameterProfileParameters/actions';

// selectors
import {
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { selectParametersOptions } from '../../../entities/Standardize/Parameters/selectors';
import { selectCurrentParameterProfile } from '../../../entities/Standardize/ParameterProfiles/selectors';
import { selectCurrentFilteredParameterProfileParameters, selectParameterProfileCounts } from '../../../entities/Standardize/ParameterProfileParameters/selectors';

const mapStateToProps = (PipeId) => createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentParameterProfile(PipeId),
  selectCurrentFilteredParameterProfileParameters(PipeId),
  selectParameterProfileCounts(PipeId),
  selectParametersOptions(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    parameterProfile,
    data,
    instanceCount,
    parameters,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      parameterProfile,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && parameterProfile && !parameterProfile.archivedFlag,
      canCollaborate,
      hasValidLicense,
      instanceCount,
      parameters: parameters?.toList().toArray() || []
    };
  },
);

function PipesEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedParameterProfile, setEditedParameterProfile] = useState(BlankPipe);

  //Dialog controls
  const openDialog = () => {
    setEditedParameterProfile({
      id: reduxProps.parameterProfile.id,
      name: reduxProps.parameterProfile.id
    });
    setValidation(VALIDATION_FIELDS);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToParameterProfileInstancesType, reduxProps.editable, true);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    setEditedParameterProfile((old) => {
      let _editedRow = { ...old };
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  const savePipeAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedParameterProfile));
    if (!doRequiredFieldsExist(editedParameterProfile)) {
      return;
    }
    const editedRow = {
      id: editedParameterProfile.id,
      name: editedParameterProfile.name,
      minLength: getUnitLength(editedParameterProfile.minLength, currentUnitSystem),
      maxLength: getUnitLength(editedParameterProfile.maxLength, currentUnitSystem),
      maxOverallLength: getUnitLength(editedParameterProfile.maxOverallLength, currentUnitSystem),
      minBends: editedParameterProfile.minBends,
      maxBends: editedParameterProfile.maxBends,
      crossSectionShape: editedParameterProfile.crossSectionShape,
      lineType: editedParameterProfile.lineType,
      unitSystemId: editedParameterProfile.unitSystem && editedParameterProfile.unitSystem.id || reduxProps.defaultUnitSystem.id,
      materialId: editedParameterProfile.material.id,
    };

    dispatch(processSaveParameterProfileParameters(editedParameterProfile.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToParameterProfileInstancesType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const pppHasDefValue = (rowdata) => {
    switch (rowdata.parameter.valueType) {
    case 'bool':
      return typeof(rowdata.defaultValue) == 'string' ? !!rowdata.defaultValue : rowdata.defaultValue == true || rowdata.defaultValue == false;
    default:
      return !!rowdata.defaultValue;
    }
  };

  const dvBody = (rowdata, field, placeholder) => {
    // MultiTypeCellRenderer
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToParameterProfileInstancesType(rowdata);

    if (pppHasDefValue(curRow) && curRow.parameter) {
      return MultiTypeCellRenderer({defaultValue: curRow.defaultValue, valueType: curRow.parameter.valueType, unit: curRow.parameter.unit});
    } else if (curRow.parameter.defaultValue) {
      return MultiTypeCellRenderer(curRow.parameter);
    }
    return <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  // Cell Editors
  const getDVEditor = () => {
    switch (currentEditingRow.parameter.valueType) {
    case 'integer':
      return (
        <div className='field' >
          <InputNumber id='defaultValue' value={currentEditingRow.defaultValue || currentEditingRow.parameter.defaultValue} onChange={(e) => onCellChange(e.value, 'defaultValue')} maxFractionDigits={0} />
        </div>
      );
    case 'bool':
      return (
        <div className='field' >
          <Dropdown
            value={currentEditingRow.defaultValue === true || currentEditingRow.defaultValue === false ? currentEditingRow.defaultValue : currentEditingRow.parameter.defaultValue}
            options={[{label: 'true', value: true},{label: 'false', value: false}, {label: 'undefined', value: null}]}
            filter={true}

            optionLabel='label'
            optionValue='value'

            onChange={(e) =>{onDropdownComplete.current(e.value, 'defaultValue', currentEditingRow);}}
            style={{ width: '100%' }}
          />
        </div>
      );
    case 'string':
      return (
        <div className='field' >
          <InputText id='defaultValue' value={currentEditingRow.defaultValue || currentEditingRow.parameter.defaultValue} onChange={(e) => onCellChange(e.target.value, 'defaultValue')} />
        </div>
      );
    case 'double':
      return (
        <div className='field' >
          <InputNumber
            id='defaultValue'
            value={currentEditingRow.defaultValue || currentEditingRow.parameter.defaultValue}
            onChange={(e) => onCellChange(e.value, 'defaultValue')}
            onBlur={() => {onCellEditComplete.current({ rowData: currentEditingRow });}}
            minFractionDigits={2} maxFractionDigits={5} style={{ width: '90%', float: 'left' }}
          />
          <Text style={{float: 'right'}} >{currentEditingRow.unit?.abbreviation || '--'}</Text>
        </div>
      );
    case 'list':
      return (
        <div className='field' >
          <Dropdown
            value={currentEditingRow.defaultValue || currentEditingRow.parameter.defaultValue}
            options={currentEditingRow.parameter?.listValues?.toArray() || []}
            optionLabel='label'
            optionValue='value'
            filter={true}

            onChange={(e) => onDropdownComplete.current(e.value, 'defaultValue', currentEditingRow)}
            style={{ width: '100%' }}
          />
        </div>
      );
    default:
      return (
        <div className='field' >
          <Text $ellipsis fontSize={2} color='gray.7'>Default Value</Text>
          <Text fontSize={1} color='gray.6'>Choose Parameter First</Text>
        </div>
      );
    }
  };

  const shouldRunOcec = useMemo(() => {
    return currentEditingRow?.parameter?.valueType && currentEditingRow.parameter.valueType != 'list' && currentEditingRow.parameter.valueType != 'bool';
  }, [currentEditingRow]);

  const checkbox = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToParameterProfileInstancesType(rowdata);
    if (!curRow.archivedFlag) {
      return <Button icon={curRow[field] ? 'checkbox' : 'checkbox-outline'} iconFillColor={curRow[field] ? 'primary.4' : 'gray.6'} onClick={() => !rowdata.archivedFlag && onDropdownComplete.current(!curRow[field], field, curRow)} />;
    }
  };

  const parameterDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.parameter}
      options={reduxProps.parameters}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'parameter', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  //saveActions

  // create params:
  //    :parameter_id,
  //    :default_value,
  //    :param_is_locked,
  //    :param_is_designated
  // update params:
  //    :id
  //    :default_value,
  //    :param_is_locked,
  //    :param_is_designated
  const saveCurrentChanges = () => {
    let data = [];
    editedRows.forEach((row) => {
      if(row.isDeleted) {
        data.push({
          id: row.id,
          _destroy: true
        });
      }else if (row.isCreated) {
        data.push({
          parameterId: row.parameter.id,
          defaultValue: pppHasDefValue(row) ? `${row.defaultValue}` : null,
          paramIsLocked: row.paramIsLocked,
          paramIsDesignated: row.paramIsDesignated
        });
      }else {
        data.push({
          id: row.id,
          defaultValue: pppHasDefValue(row) ? `${row.defaultValue}` : null,
          paramIsLocked: row.paramIsLocked,
          paramIsDesignated: row.paramIsDesignated
        });
      }
    });
    dispatch(processSaveParameterProfileParameters(match.params.id, data));
    resetEditedRows();
  };

  useEffect(() => {
    dispatch(processFetchIndividualParameterProfile(match.params.id));
    dispatch(processFetchAllParameters());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.parameterProfile && reduxProps.parameterProfile.name) || ParameterProfilesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Pipe Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Pipe Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentChanges()}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          isArchived={reduxProps.parameterProfile && reduxProps.parameterProfile.archivedFlag}
          editEntity={openDialog}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='parameter' header='Parameter' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => parameterDropdown()} body={(rowdata) => sBody(rowdata, 'parameter', 'Select Parameter')} style={{ width: '46%' }} ></Column>
          <Column field='defaultValue' header='Default Value' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => getDVEditor()} body={(rowdata) => dvBody(rowdata, 'defaultValue', 'Default Value')} onCellEditComplete={(e) => shouldRunOcec && onCellEditComplete.current(e)} style={{ width: '46%' }} ></Column>
          <Column field='paramIsLocked' header='Locked' body={(rowdata) => checkbox(rowdata, 'paramIsLocked')} style={{ width: '2%' }} ></Column>
          <Column field='paramIsDesignated' header='Designated' body={(rowdata) => checkbox(rowdata, 'paramIsDesignated')} style={{ width: '2%' }} ></Column>
        </DataTable>
      </Flex>
      <Dialog></Dialog>
    </>
  );
}


export default withRouter(PipesEditGrid);