import { List, Map, Record, Set } from 'immutable';

import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import values from 'lodash/values';
import Color from 'color';

import { ENTITY_STATUS, FIELD_STATUS } from './constants';

const DEFAULT_STATUSES = {
  IS_SELECTED: true,
  IS_SELECTED_FOR_ID: false,
  IS_EDITED: false,
  IS_LOCAL_DRAFT: false,
  IS_VALID: true,
};

/**
 * Return the CSS class for the status of the current Entity based on the values of its validity and selection
 * @return {string} The CSS class to apply based on the Entity's current status
 * @public
 */
const analysis = ({ isSelected, isEdited, isLocalDraft, isValid }) => {
  if (isSelected) {
    if (!isEdited) {
      if (isLocalDraft) {
        return ENTITY_STATUS.LOCAL_DRAFT;
      } else {
        return ENTITY_STATUS.SAVED;
      }
    } else {
      if (isValid) {
        if (isLocalDraft) {
          return ENTITY_STATUS.PENDING_VALID_SAVE;
        } else {
          return ENTITY_STATUS.PENDING_VALID_CHANGES;
        }
      } else {
        if (isLocalDraft) {
          return ENTITY_STATUS.PENDING_INVALID_SAVE;
        } else {
          return ENTITY_STATUS.PENDING_INVALID_CHANGES;
        }
      }
    }
  } else {
    if (isLocalDraft) {
      return ENTITY_STATUS.UNSELECTED;
    } else {
      return ENTITY_STATUS.PENDING_DELETE;
    }
  }
};

/**
 * The primary class used to define every single record in the app
 * @param {Object} base - All of the properties/schema for the internal handling of the entity itself (where the key is the field name)
 * @param {*} value - The default value for this field
 * @param {boolean} isEntity - Whether or not this field is an entity in our architecture
 * @param {(boolean|function)} handleCopy - A boolean indicating whether this field should be included when an entity is being copied (can also be a function to handle the copying itself)
 * @param {boolean} handleDeepCopy - A boolean indicating whether this field should call its own copy function when being copied
 * @param {(boolean|function)} handleCompare - A boolean indicating whether this field should be included when comparing entities of this type (can also be a function to alter value before spitting it out)
 * @param {boolean} handleDeepCompare - A boolean indicating whether this field should call its own compare function when being compared
 * @param {boolean} isUnit - A boolean indicating whether or not the field is an array of converted units that needs to be handled differently
 * @param {string} unitType - The type of unit to compare against when looking through unit systems (must be one of 'unitLength', 'unitWeight', or 'unitDensity')
 * @param {(boolean|function)} handleSerialize - A boolean indicating whether or not this field should be included when serializing an entity (can also be a function for controlling what the serialization should be like)
 * @param {boolean} handleDeepSerialize - A boolean indicating whether or not this field should call its own serialize function when being serialized
 * @param {string} serializeOutputField - The new name of the field for when a field is being serialized (e.g., used for collections)
 */
const EntityRecord = (base, opts = {}) => {
  const defaults = reduce(
    base,
    (values, params, field) => ({ ...values, [field]: params.value }),
    {},
  );

  const validationFieldsSet = opts.multiFieldValidations
    ? reduce(
      base,
      (fields, params, field) => params.validate ? fields.add(field) : fields,
      Set(),
    ).concat(
      reduce(
        opts.multiFieldValidations,
        (fields, params, field) => params.validate ? fields.add(field) : fields,
        Set(),
      ),
    )
    : reduce(
      base,
      (fields, params, field) => params.validate ? fields.add(field) : fields,
      Set(),
    );

  const validationSchema = opts.multiFieldValidations
    ? {
      ...reduce(
        base,
        (fields, params, field) => ({
          ...fields,
          ...(params.validate ? { [field]: params.validate } : {}),
        }),
        {},
      ),
      ...reduce(
        opts.multiFieldValidations,
        (fields, params, field) => ({
          ...fields,
          ...(params.validate ? { [field]: params.validate } : {}),
        }),
        {},
      ),
    }
    : reduce(
      base,
      (fields, params, field) => ({
        ...fields,
        ...(params.validate ? { [field]: params.validate } : {}),
      }),
      {},
    );

  const validationErrors = opts.multiFieldValidations
    ? {
      ...reduce(
        base,
        (fields, params, field) => ({
          ...fields,
          ...(params.validate ? { [field]: params.validateError } : {}),
        }),
        {},
      ),
      ...reduce(
        opts.multiFieldValidations,
        (fields, params, field) => ({
          ...fields,
          ...(params.validate ? { [field]: params.validateError } : {}),
        }),
        {},
      ),
    }
    : reduce(
      base,
      (fields, params, field) => ({
        ...fields,
        ...(params.validate ? { [field]: params.validateError } : {}),
      }),
      {},
    );

  const filterFields = opts.multiFieldFilters
    ? {
      ...reduce(
        base,
        (fields, params, field) => ({
          ...fields,
          ...(params.filter ? { [field]: params.filter } : {}),
        }),
        {},
      ),
      ...reduce(
        opts.multiFieldFilters,
        (fields, params, field) => ({
          ...fields,
          ...(params.filter ? { [field]: params.filter } : {}),
        }),
        {},
      ),
    }
    : reduce(
      base,
      (fields, params, field) => ({
        ...fields,
        ...(params.filter ? { [field]: params.filter } : {}),
      }),
      {},
    );

  const compareFields = reduce(
    base,
    (fields, params, field) => ({
      ...fields,
      ...(params.handleCompare ? { [field]: params } : {}),
    }),
    {},
  );
  const copyFields = reduce(
    base,
    (fields, params, field) => ({
      ...fields,
      ...(params.handleCopy ? { [field]: params } : {}),
    }),
    {},
  );
  const serializeFields = reduce(
    base,
    (fields, params, field) => ({
      ...fields,
      ...(params.handleSerialize ? { [field]: params } : {}),
    }),
    {},
  );
  const unitFields = reduce(
    base,
    (fields, params, field) => ({
      ...fields,
      ...(params.isUnit ? { [field]: params } : {}),
    }),
    {},
  );

  /**
   * The base Entity class that is based off of ImmutableJS Record and is used to enforce properties for all Entities in the app
   * @class
   * @constructor
   * @public
   */
  return class Entity extends Record({
    /**
     * The ID of the Entity
     * @property {boolean} id
     * @public
     */
    id: undefined,

    /**
     * Used to indicate if a draft/entity is selected (mostly used for tables)
     * @property {boolean} isSelected
     * @public
     */
    isSelected: DEFAULT_STATUSES.IS_SELECTED,

    /**
     * Used to indicate if a draft/entity is selected purely for its ID (used for import/export or other group actions)
     * @property {boolean} isSelectedForId
     * @public
     */
    isSelectedForId: DEFAULT_STATUSES.IS_SELECTED_FOR_ID,

    /**
     * Used to keep track of new drafts - ONLY ever true when a draft is created and given a generated uuid
     * @property {boolean} isLocalDraft
     * @public
     */
    isLocalDraft: DEFAULT_STATUSES.IS_LOCAL_DRAFT,

    /**
     * Return the CSS class for the status of the current Entity based on the values of its validity and selection
     * @property {string} status
     * @public
     */
    status: ENTITY_STATUS.SAVED,

    /**
     * Global property for all Entities, but only used when specifically deleting entities via a collection endpoint.
     * Should be `undefined` by default, any set specifically to `1` if the Entity is going to be deleted
     * @property {string} _destroy
     * @public
     */
    _destroy: undefined,

    /**
     * Set of fields that were undefined during object creation
     * @property {Set} defaulted
     * @public
     */
    defaulted: Set(),

    /**
     * Set of fields that are currently valid
     * Only triggers after edits, not on initialization
     * @property {Set} validated
     */
    validated: Set(),

    /**
     * Set of fields that are currently errored
     * Only triggers after edits, not on initialization
     * @property {Set} errored
     */
    errored: Set(),

    /**
     * Validations errors keyed by field
     * Only triggers after edits, not an initialization
     * @property {Map} errors
     */
    errors: Map(),

    /**
     * Set of fields that are currently edited
     * Only triggers after edits, not on initialization
     * @property {Set} edited
     */
    edited: Set(),

    /**
     * Set of fields that are currently empty (which is defined as equal to the default value)
     * Only triggers after edits, not on initialization
     * @property {Set} empty
     */
    empty: Set(),

    /**
     * Set of fields that are currently nonempty (which is defined as anything other than the default value)
     * Only triggers after edits, not on initialization
     * @property {Set} nonempty
     */
    nonempty: Set(),

    /**
     * Used to indicate where the Entity is used optionally - even if there are counts here, the Entity can still be deleted,
     * but the user should be warned that the values will be reset to null
     * @property {object} warnDeleteCounts
     * @public
     */
    warnDeleteCounts: {},

    /**
     * Used to indicate where the Entity is used mandatorily - if there are counts here, the Entity CANNOT be deleted
     * and the user needs to be informed of why exactly that is the case
     * @property {object} restrictDeleteCounts
     * @public
     */
    restrictDeleteCounts: {},

    ...defaults,
  }) {
    constructor(properties = {}) {
      const {
        id,
        isSelected = DEFAULT_STATUSES.IS_SELECTED,
        isEdited = DEFAULT_STATUSES.IS_EDITED,
        isLocalDraft = DEFAULT_STATUSES.IS_LOCAL_DRAFT,
        isValid = DEFAULT_STATUSES.IS_VALID,
        ...other
      } = properties;
      super({
        id: isNil(id) ? undefined : `${id}`,
        isSelected,
        isLocalDraft,
        status: analysis({ isSelected, isEdited, isLocalDraft, isValid }),
        ...reduce(
          other,
          (post, v, k) => ({
            ...post,
            // if the defaultValue for the key is a List(), then make sure
            // we convert any arrays to a List
            [k]: defaults.hasOwnProperty(k) && List.isList(defaults[k])
              ? isArray(v) || List.isList(v) ? List(v) : List()
              : v,
          }),
          {},
        ),
        defaulted: Set(keys(defaults)).subtract(keys(other)),
      });
    }

    /**
     * Used to keep track of whether or not the current record will actually be saved
     * Based on the status field
     * @property {boolean} canSave
     * @public
     */
    get canSave() {
      return (
        this.status === ENTITY_STATUS.PENDING_VALID_SAVE ||
        this.status === ENTITY_STATUS.PENDING_VALID_CHANGES ||
        this.status === ENTITY_STATUS.PENDING_DELETE
      );
    }

    /**
     * Used to keep track of whether or not a draft has been edited, calculated during updates outside of reducer
     * @property {boolean} isEdited Boolean indicating whether this record is edited or not
     * @public
     */
    get isEdited() {
      return this.edited.size > 0;
    }

    /**
     * Used to keep track of whether or not current state of draft is valid, calculated during updates outside of reducer
     * @property {boolean} isValid Booean indicating whether this record is valid or not
     * @public
     */
    get isValid() {
      return this.errored.size === 0;
    }

    /**
     * Return this entity with its updated status based on its current status
     * @property {Entity} analyze Entity with its status updated based on its current properties
     * @public
     */
    get analyze() {
      return this.set('status', analysis(this));
    }

    /**
     * Returns the current state of the field status given a field(s)
     * Used only for ag-grid cell renderer params
     * @return {string} the current state of the field(s) for the given Entity
     * @param {string|array} field the key of the field(s) we we want to identify
     * @public
     */
    getFieldStatus(field) {
      let anyFieldsEdited, allFieldsValid, anyFieldsError;

      if (isArray(field)) {
        anyFieldsEdited = false;
        allFieldsValid = true;
        anyFieldsError = false;

        for (const f of field) {
          anyFieldsEdited = anyFieldsEdited || this.edited.has(f);
          allFieldsValid = allFieldsValid && this.validated.has(f);
          anyFieldsError = anyFieldsError || this.errored.has(f);
        }
      } else {
        anyFieldsEdited = this.edited.has(field);
        allFieldsValid = this.validated.has(field);
        anyFieldsError = this.errored.has(field);
      }

      if (anyFieldsEdited) {
        if (allFieldsValid) {
          return FIELD_STATUS.VALID_EDITED;
        } else if (anyFieldsError) {
          return FIELD_STATUS.INVALID_EDITED;
        } else {
          return FIELD_STATUS.EDITED;
        }
      } else {
        if (allFieldsValid) {
          return FIELD_STATUS.VALID_UNEDITED;
        } else if (anyFieldsError) {
          return FIELD_STATUS.INVALID_UNEDITED;
        } else {
          return FIELD_STATUS.UNEDITED;
        }
      }
    }

    /**
     * Returns true if this record matches the fields passed in or false otherwise
     * @return {boolean} A boolean dictating whether or not this record matches the query
     * @param {object} query an object with the fields being queried
     * @public
     */
    doesMatchQuery(query, { normalized = true, searchAll = false } = {}) {
      return (
        (searchAll || this.status !== ENTITY_STATUS.LOCAL_DRAFT) &&
        reduce(
          filterFields,
          (isMatch, filter, field) => {
            if (!isMatch) {
              return isMatch;
            }

            return (
              filter &&
              filter(this.get(field), query[field], query, {
                normalized,
                searchAll,
              })
            );
          },
          true,
        )
      );
    }

    /**
     * Returns the renormalized version of this Entity
     * @property {Entity} renormalized normalized Entity
     * @public
     */
    get renormalized() {
      return reduce(
        base,
        (entity, params, field) => {
          if (params.isEntity) {
            if (List.isList(entity[field])) {
              return entity.set(
                field,
                entity[field].map((item) => {
                  return (item && item.id) || (isString(item) ? item : null);
                }),
              );
            } else {
              return entity.set(
                field,
                (entity[field] && entity[field].id) ||
                  (isString(entity[field]) ? entity[field] : null),
              );
            }
          } else {
            return entity;
          }
        },
        this,
      );
    }

    /**
     * Returns a mapping of all of the unit-necessitated fields with their respective unit types
     * @property {object} unitFields mapping of unit fields to their unit type
     * @public
     */
    get unitFields() {
      return reduce(
        unitFields,
        (output, { unitType }, field) => ({ ...output, [field]: unitType }),
        {},
      );
    }

    /**
     * Default summary of the entity to be used in displaying information
     * @property {object} summary The summary of the entity
     * @public
     */
    get summary() {
      return {
        primary: this.primary || this.primaryPlaceholder,
        secondary: this.secondary || this.secondaryPlaceholder,
      };
    }

    /**
     * Default template for an entity to be converted to an option for a select
     * @return {object} The entity transformed into an option
     * @public
     */
    toOption() {
      return {
        id: this.id,
        value: this.id,
        label: this.primary || this.primaryPlaceholder,
        primary: this.primary || this.primaryPlaceholder,
        secondary: this.secondary || this.secondaryPlaceholder,
        tertiary: this.tertiary,
      };
    }

    /**
     * Returns tuples of all of the warn deletion counts that are present
     * @property {array} Array of tuples of warned deletion counts with printable string names
     * @public
     */
    get printableWarnDeleteCounts() {
      if (this.warnDeleteCounts) {
        return reduce(
          this.warnDeleteCounts,
          (tuples, count, key) => {
            return [
              ...tuples,
              [
                count,
                // insert a space before all caps
                key.replace(/([A-Z])/g, ' $1').toLowerCase(),
              ],
            ];
          },
          [],
        );
      } else {
        return [];
      }
    }

    /**
     * Returns tuples of all of the restrict deletion counts that are present
     * @property {array} Array of tuples of restricted deletion counts with printable string names
     * @public
     */
    get printableRestrictDeleteCounts() {
      if (this.restrictDeleteCounts) {
        return reduce(
          this.restrictDeleteCounts,
          (tuples, count, key) => {
            return [
              ...tuples,
              [
                count,
                // insert a space before all caps
                key.replace(/([A-Z])/g, ' $1').toLowerCase(),
              ],
            ];
          },
          [],
        );
      } else {
        return [];
      }
    }

    /**
     * Informs the user if the Entity can be safely deleted based on the `restrictDeleteCounts` AND `warnDeleteCounts` properties.
     * If either property is not present, we assume true for that property. The difference between `canSafelyDelete` and
     * `canForcefullyDelete` is that `canForcefullyDelete` is a hard check whether an Entity can be deleted, but `canSafelyDelete` checks if
     * the entity is being used anywhere based on the `warnDeleteCounts` property.
     * @return {boolean} Whether or not the Entity should be deleted based on its current usage
     * @public
     */
    get canSafelyDelete() {
      if (this.warnDeleteCounts) {
        return (
          values(this.warnDeleteCounts).every((count) => count === 0) &&
          this.canForcefullyDelete
        );
      } else {
        return this.canForcefullyDelete;
      }
    }

    /**
     * Informs the user if the Entity can be safely deleted based on the `restrictDeleteCounts` property.
     * If the `restrictDeleteCounts` property is not present, then we assume the Entity can be deleted
     * @property {boolean} Whether or not the Entity can be deleted based on its current usage
     * @public
     */
    get canForcefullyDelete() {
      if (this.restrictDeleteCounts) {
        return values(this.restrictDeleteCounts).every((count) => count === 0);
      } else {
        return true;
      }
    }

    /**
     * Returns a copy of the raw object data (which can be modified with a function before being copied)
     * of the base properties of the object. Primarily used for table rows
     * @property {object} copy Copy of raw object of Entity data
     * @public
     */
    get copy() {
      const current = this;
      return reduce(
        copyFields,
        (
          output,
          { handleCopy, handleDeepCopy, value, isEntity, isUnit },
          field,
        ) => {
          let fieldOutput;

          if (isFunction(handleCopy)) {
            fieldOutput = handleCopy(current);
          } else {
            if (isEntity) {
              // if this is an entity
              if (List.isList(value)) {
                // if this is a list of entities
                if (handleDeepCopy) {
                  // if we want to recursively compare the entities
                  if (current[field] && current[field].size > 0) {
                    fieldOutput = current[field]
                      .map(
                        (fieldItem) =>
                          (fieldItem && fieldItem.copy) || fieldItem,
                      )
                      .toArray();
                  } else {
                    fieldOutput = current[field];
                  }
                } else {
                  // if we just want to compare the basic lists
                  if (current[field] && current[field].size > 0) {
                    fieldOutput = current[field]
                      .map(
                        (fieldItem) => (fieldItem && fieldItem.id) || fieldItem,
                      )
                      .toArray();
                  } else {
                    fieldOutput = current[field];
                  }
                }
              } else {
                // if this is a single entity
                if (handleDeepCopy) {
                  // if we want to recursively compare this entity
                  fieldOutput = (current[field] && current[field].copy) ||
                    current[field];
                } else {
                  fieldOutput = (current[field] && current[field].id) ||
                    current[field];
                }
              }
            } else if (isUnit) {
              fieldOutput = (List.isList(value) &&
                current[field] &&
                current[field].toArray()) ||
                [];
            } else {
              fieldOutput = current[field];
            }
          }

          return { ...output, [field]: fieldOutput };
        },
        {},
      );
    }

    /**
     * Validates the Entity against the fields' yup validations and updates the `validated`/`errored`/`empty` Sets
     * @property {Entity} validated Entity with the updated `validated`/`errored`/`empty` field
     * @public
     */
    get validate() {
      return validationFieldsSet.reduce(
        (entity, field) => {
          const validation = validationSchema[field];
          if (validation && validation(entity)) {
            return entity.set('validated', entity.validated.add(field));
          } else {
            return entity
              .set('errored', entity.errored.add(field))
              .set(
                'errors',
                entity.errors.set(
                  field,
                  typeof validationErrors[field] === 'function'
                    ? validationErrors[field](entity)
                    : validationErrors[field],
                ),
              );
          }
        },
        this.set('validated', Set()).set('errored', Set()).set('errors', Map()),
      );
    }

    /**
     * Validates the Entity against the fields' compareFields and updates the `empty`/`nonempty` Sets
     * @property {Entity} tally Entity with the updated `empty`/`nonempty` field
     * @public
     */
    get tally() {
      return reduce(
        compareFields,
        (entity, _, field) => {
          if (List.isList(defaults[field])) {
            // if the current Lists are the same sizes
            if (defaults[field].size === 0 && entity.get(field).size === 0) {
              return entity.set('empty', entity.empty.add(field));
            } else {
              // we need to compare the contents of the Lists to see if they're the same
              // TODO: this shouldn't ever trigger? all default List()s are empty
              return entity.set('nonempty', entity.nonempty.add(field));
            }
          } else {
            // if the current value is equal to the defaulted value
            // TODO: need to add handling for objects here, not just simple values
            if (entity.get(field) === defaults[field]) {
              return entity.set('empty', entity.empty.add(field));
            } else {
              return entity.set('nonempty', entity.nonempty.add(field));
            }
          }
        },
        this.set('empty', Set()).set('nonempty', Set()),
      );
    }

    /**
     * Force set whether or not the current entry is edited
     * @param {boolean} isEdited defaults to true, which meacns that we will force the current record to be marked as edited
     * @return {Entity} the Entity updated with its edited field
     * @public
     */
    forceSetIsEdited(isEdited = true) {
      if (isEdited) {
        const current = this;
        return reduce(
          compareFields,
          (entity, _, field) => {
            return entity.set('edited', entity.edited.add(field));
          },
          current.set('edited', Set()),
        );
      } else {
        return this.set('edited', Set());
      }
    }

    /**
     * Compares if the provided entity is the same as this object and returns Entity with updated `edited` field
     * @param {Entity} incoming - The Entity to compare against
     * @return {Entity} the Entity with the updated `edited` field
     * @public
     */
    comparison(incoming) {
      const current = this;
      return !incoming ? current.set('edited', current.nonempty) : reduce(
        compareFields,
        (
          entity,
          { handleCompare, handleDeepCompare, value, isEntity, isUnit },
          field,
        ) => {
          let isIdentical = false;

          if (isFunction(handleCompare)) {
            isIdentical = handleCompare(current, incoming);
          } else {
            if (isEntity) {
              // if this is an entity
              if (List.isList(value)) {
                // if this is a list of entities
                if (
                  current[field] &&
                  current[field].size > 0 &&
                  incoming[field] &&
                  incoming[field].size > 0 &&
                  current[field].size === incoming[field].size
                ) {
                  // run checks of individual items in list recursively
                  if (handleDeepCompare) {
                    isIdentical = current[field].every((fieldItem) => {
                      const foundFieldItem = incoming[field].find(
                        (incomingFieldItem) =>
                          fieldItem.id === incomingFieldItem.id,
                      );
                      return (
                        !!foundFieldItem &&
                        fieldItem.compareIfSame(foundFieldItem)
                      );
                    });
                  } else {
                    // run basic checks against ids in simple objects or just strings
                    isIdentical = current[field].every((fieldItem) => {
                      return !!incoming[field].find(
                        (incomingFieldItem) =>
                          fieldItem &&
                          incomingFieldItem &&
                          (fieldItem === incomingFieldItem ||
                            (fieldItem.id &&
                              incomingFieldItem.id &&
                              fieldItem.id === incomingFieldItem.id)),
                      );
                    });
                  }
                } else {
                  // doing a quick check of whether or not both of these fields together are false or not
                  isIdentical = current[field] === incoming[field];
                }
              } else {
                // if this is a single entity
                if (current[field] && incoming[field]) {
                  if (handleDeepCompare) {
                    isIdentical = current[field].compareIfSame(
                      incoming[field],
                    );
                  } else {
                    isIdentical = current[field] === incoming[field] ||
                      (current[field].id &&
                        incoming[field].id &&
                        current[field].id === incoming[field].id);
                  }
                } else {
                  // doing a quick check of whether or not both of these fields together are false or not
                  isIdentical = current[field] === incoming[field];
                }
              }
            } else if (isUnit) {
              if (
                current[field] &&
                incoming[field] &&
                current[field].size > 0 &&
                incoming[field].size > 0
              ) {
                isIdentical = current[field].every((fieldItem) => {
                  return !!incoming[field].find(
                    (incomingFieldItem) =>
                      fieldItem &&
                      incomingFieldItem &&
                      fieldItem.id &&
                      incomingFieldItem.id &&
                      fieldItem.id === incomingFieldItem.id &&
                      fieldItem.value === incomingFieldItem.value,
                  );
                });
              } else {
                // doing a quick check of whether or not both of these fields together are false or not
                isIdentical = current[field] === incoming[field];
              }
            } else {
              isIdentical = current[field] === incoming[field];
            }
          }

          if (isIdentical) {
            return entity;
          } else {
            return entity.set('edited', entity.edited.add(field));
          }
        },
        current.set('edited', Set()),
      );
    }

    /**
     * Compares if the provided entity is the same as this object
     * @param {Entity} incoming - The Entity to compare against
     * @return {boolean} Returns true if same, or false if not the same
     * @public
     */
    compareIfSame(incoming) {
      const current = this;
      return reduce(
        compareFields,
        (
          isIdentical,
          { handleCompare, handleDeepCompare, value, isEntity, isUnit },
          field,
        ) => {
          if (!isIdentical) {
            return isIdentical;
          }

          if (isFunction(handleCompare)) {
            return handleCompare(current, incoming);
          } else {
            if (isEntity) {
              // if this is an entity
              if (List.isList(value)) {
                // if this is a list of entities
                if (
                  current[field] &&
                  current[field].size > 0 &&
                  incoming[field] &&
                  incoming[field].size > 0 &&
                  current[field].size === incoming[field].size
                ) {
                  // run checks of individual items in list recursively
                  if (handleDeepCompare) {
                    return current[field].every((fieldItem) => {
                      const foundFieldItem = incoming[field].find(
                        (incomingFieldItem) =>
                          fieldItem.id === incomingFieldItem.id,
                      );
                      return (
                        !!foundFieldItem &&
                        fieldItem.compareIfSame(foundFieldItem)
                      );
                    });
                  } else {
                    // run basic checks against ids in simple objects or just strings
                    return current[field].every((fieldItem) => {
                      return !!incoming[field].find(
                        (incomingFieldItem) =>
                          fieldItem &&
                          incomingFieldItem &&
                          (fieldItem === incomingFieldItem ||
                            (fieldItem.id &&
                              incomingFieldItem.id &&
                              fieldItem.id === incomingFieldItem.id)),
                      );
                    });
                  }
                } else {
                  // doing a quick check of whether or not both of these fields together are false or not
                  return current[field] === incoming[field];
                }
              } else {
                // if this is a single entity
                if (current[field] && incoming[field]) {
                  if (handleDeepCompare) {
                    return current[field].compareIfSame(incoming[field]);
                  } else {
                    return (
                      current[field] === incoming[field] ||
                      (current[field].id &&
                        incoming[field].id &&
                        current[field].id === incoming[field].id)
                    );
                  }
                } else {
                  // doing a quick check of whether or not both of these fields together are false or not
                  return current[field] === incoming[field];
                }
              }
            } else if (isUnit) {
              if (
                current[field] &&
                incoming[field] &&
                current[field].size > 0 &&
                incoming[field].size > 0
              ) {
                return current[field].every((fieldItem) => {
                  return !!incoming[field].find(
                    (incomingFieldItem) =>
                      fieldItem &&
                      incomingFieldItem &&
                      fieldItem.id &&
                      incomingFieldItem.id &&
                      fieldItem.id === incomingFieldItem.id &&
                      fieldItem.value === incomingFieldItem.value,
                  );
                });
              } else {
                // doing a quick check of whether or not both of these fields together are false or not
                return current[field] === incoming[field];
              }
            } else {
              return current[field] === incoming[field];
            }
          }

          // we should never end up here, ever.
          return false;
        },
        true,
      );
    }

    /**
     * Returns the serialized data for the entity so that it can be sent to the server for creates/edits
     * @param {object} unitSystem - The fully denormalized unit system to send back to the serialization method
     * @return {object} Serialized object of Entity data
     * @public
     */
    serialize(data) {
      const current = this;

      return reduce(
        serializeFields,
        (
          output,
          {
            handleSerialize,
            handleDeepSerialize,
            serializeOutputField,
            value,
            isEntity,
            isUnit,
            unitType,
          },
          field,
        ) => {
          let fieldOutput;

          if (isFunction(handleSerialize)) {
            fieldOutput = handleSerialize(current, data);
          } else {
            if (isEntity) {
              // if this is an entity
              if (List.isList(value)) {
                // if this is a list of entities
                if (handleDeepSerialize) {
                  // if we want to recursively compare the entities
                  if (current[field] && current[field].size > 0) {
                    fieldOutput = current[field].reduce(
                      (cleanedItems, fieldItem) => {
                        if (fieldItem) {
                          if (fieldItem.id && fieldItem.serialize) {
                            return [...cleanedItems, fieldItem.serialize(data)];
                          } else {
                            return [...cleanedItems, fieldItem];
                          }
                        } else {
                          return cleanedItems;
                        }
                      },
                      [],
                    );
                  } else {
                    fieldOutput = [];
                  }
                } else {
                  // if we just want to compare the basic lists
                  if (current[field] && current[field].size > 0) {
                    fieldOutput = current[field].reduce(
                      (cleanedItems, fieldItem) => {
                        if (fieldItem) {
                          return [...cleanedItems, fieldItem.id || fieldItem];
                        } else {
                          return cleanedItems;
                        }
                      },
                      [],
                    );
                  } else {
                    fieldOutput = [];
                  }
                }
              } else {
                // if this is a single entity
                if (handleDeepSerialize) {
                  // if we want to recursively compare this entity
                  if (current[field]) {
                    if (current[field].id && current[field].serialize) {
                      fieldOutput = current[field].serialize(data);
                    } else {
                      fieldOutput = current[field];
                    }
                  } else {
                    fieldOutput = undefined;
                  }
                } else {
                  if (current[field] || current[field] === '') {
                    fieldOutput = current[field].id || current[field];
                  } else {
                    // we want to make this change, but this requires a lot of validation so not a quick fix
                    // fieldOutput = current[field] === null ? null : undefined;
                    fieldOutput = undefined;
                  }
                }
              }
            } else if (isUnit) {
              if (!current[field]) {
                fieldOutput = undefined;
              } else {
                const unitList =
                  (List.isList(value) && current[field].toArray()) || [];
                const unitSystem = data.unitSystem;

                if (unitList.length > 0 && unitType && unitSystem) {
                  const foundUnit = find(
                    unitList,
                    (unit) => unitSystem[unitType].id === unit.id,
                  );

                  fieldOutput = (foundUnit && foundUnit.value) || undefined;
                } else {
                  fieldOutput = undefined;
                }
              }
            } else {
              fieldOutput = current[field];
            }
          }

          return { ...output, [serializeOutputField || field]: fieldOutput };
        },
        {},
      );
    }
  };
};

export const VALIDATORS = {
  IS_REQUIRED_ENTITY: (value) =>
    value && (value.id !== null || value.id !== undefined),
  IS_REQUIRED_ENTITY_LIST: (value) =>
    value &&
    value.size > 0 &&
    value.every((v) => v.id !== null || v.id !== undefined),
  IS_OPTIONAL_STRING: (value) =>
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.length > 0),
  IS_REQUIRED_STRING: (value) =>
    value !== null &&
    value !== undefined &&
    typeof value === 'string' &&
    value.length > 0,
  IS_REQUIRED_VALID_UNIT: (value) =>
    value.size > 0 &&
    value.every(
      ({ value: unit }) => !isNaN(parseFloat(unit)) && isFinite(unit),
    ),
  IS_GREATER_THAN_ZERO: (value) => parseFloat(value) > 0,
  IS_NUMERIC: (value) => !isNaN(parseFloat(value)) && isFinite(value),
  IS_POSITIVE_INTEGER: (value) =>
    0 === value % (!isNaN(parseFloat(value)) && 0 <= ~~value),
  IS_INTEGER: (value) => {
    if (isNaN(value)) return false;
    const x = parseFloat(value);
    return (x | 0) === x;
  },
  IS_RGB_COLOR_OBJECT: ({ r, g, b } = {}) =>
    r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255,
};

export const FILTERS = {
  BOOLEAN: (currentValue, queryValue) =>
    queryValue === undefined ||
    queryValue === null ||
    currentValue === queryValue,
  STRING: (currentValue, queryValue) =>
    !queryValue ||
    (!!currentValue &&
      currentValue.toLowerCase().indexOf(queryValue.toLowerCase()) > -1),
  EXACT_STRING: (currentValue, queryValue) =>
    !queryValue || (!!currentValue && currentValue === queryValue > -1),
  ENTITY: (currentValue, queryValue, query, { normalized }) =>
    !queryValue ||
    (normalized && currentValue === queryValue) ||
    (!normalized && currentValue.id === queryValue),
  ENTITY_LIST: (currentValues, queryValue, query, { normalized }) => {
    return (
      !queryValue ||
      !!currentValues.find(
        (currentValue) =>
          (normalized && currentValue === queryValue) ||
          (!normalized && currentValue.id === queryValue),
      )
    );
  },
  DEEP_ENTITY: (currentValue, queryValue, query, opts) =>
    !!currentValue && currentValue.doesMatchQuery(query, opts),
};

// return true if both values are identical
export const COMPARATORS = {
  COLOR: (current, incoming) => {
    if (!current && !incoming) {
      return true;
    }

    const { r: cR, g: cG, b: cB } = current || {};
    const { r: iR, g: iG, b: iB } = incoming || {};

    return cR === iR && cG === iG && cB === iB;
  },

  CREATED_LIST: (current, incoming) => {
    if (!current && !incoming) {
      return true;
    }
    if (current.size === 0 && incoming.size === 0) {
      return true;
    }
    if (current.size === incoming.size) {
      return current.every(
        (currentItem) =>
          !!incoming.find(
            (incomingItem) =>
              currentItem &&
              incomingItem &&
              currentItem.value === incomingItem.value,
          ),
      );
    } else {
      return false;
    }
  },
};

export const UTILS = {
  OBJECTIZE_COLOR: (v) => {
    if (isObject(v)) {
      return v;
    } else if (isArray(v)) {
      return Color(v).object();
    } else if (typeof v === 'string') {
      return Color(JSON.parse(v)).object();
    } else {
      return {};
    }
  },
  STRINGIFY_COLOR: (v) => {
    if (isObject(v)) {
      return `[${Color.rgb(v).array()}]`;
    } else if (isArray(v)) {
      return `[${v}]`;
    } else if (isString(v)) {
      return v;
    } else {
      return [];
    }
  },
};

export default EntityRecord;
