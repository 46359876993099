import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ChromePicker } from 'react-color';


import useFloatingState from '../../../hooks/useFloatingState';
import useMergeRefs from '../../../hooks/useMergeRefs';

import Button from '../../../components/common/Button';
import Flex from '../../../components/common/Flex';
import Icon from '../../../components/common/Icon';
import Text from '../../../components/common/Text';
import ColorSwatch from '../../../components/common/ColorSwatch';

import BaseFormCell from '../../../components/form/BaseFormCell';

import Tooltip, { CONTAINERS } from '../../../components/tooltip/Tooltip';

const defaultWhite = { r: 255, g: 255, b: 255 };
const RDXColorInput = ({
  width,
  height = '100%',
  bg,
  input = {},
  defaultValue = defaultWhite,
  disabled,
  tooltipProps = {},
}) => {
  const usableValue = ((input.value === '' || input.value === null) && defaultValue !== undefined) ? defaultValue : input.value;

  const [isOpen, setIsOpen] = useState(false);

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });
  const [closeReference, closeFloating, closeFloatingStyle] = useFloatingState({ placement: 'right' });
  const parentRef = useMergeRefs(reference, closeReference);

  const toggleOpen = useCallback(() => {
    if (!disabled) {
      setIsOpen(prevIsOpen => !prevIsOpen);
    } else {
      setIsOpen(false);
    }
  }, [setIsOpen, disabled]);

  const onChange = useCallback(
    ({ rgb = {} } = {}) => {
      if (input.onChange && !disabled) {
        const { a, ...value } = rgb;
        input.onChange(value);
      }
    }, [input.onChange, disabled]
  );

  const clearColor = useCallback(_ => {
    if (input.onChange && !disabled) {
      input.onChange(null);
    }
  }, [input.onChange, disabled]);

  const onCellClick = useCallback(() => { toggleOpen(); }, [toggleOpen]);

  return (
    <BaseFormCell width={width} height={height} bg={bg} justifyContent='center' alignItems='flex-start' onClick={onCellClick} cursor={!disabled ? 'pointer' : undefined}>
      { usableValue && <ColorSwatch ref={parentRef} color={usableValue} height='100%' width='100%' cursor={!disabled ? 'pointer' : undefined} /> }
      {
        usableValue && isOpen && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid' { ...tooltipProps }>
            <ChromePicker color={usableValue} onChangeComplete={onChange} disableAlpha />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
      {
        usableValue && isOpen && ReactDOM.createPortal(
          <Tooltip ref={closeFloating} style={closeFloatingStyle} size='fluid' { ...tooltipProps }>
            <Button icon='close' small error onClick={clearColor} type='button' />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseFormCell>
  );
};

export default RDXColorInput;
