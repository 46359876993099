import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleInsulationsPath } from '../../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModuleInsulationsPath.defaultTitle}></BreadcrumbTemplate>
  );
}
