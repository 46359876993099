import React from 'react';

import Text from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Button from '../../../components/common/Button';

import colors from '../../../assets/themes/base/colors';

// validation
export function areFieldsValid({ name, material, crossSectionShape, lineType }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    material: !!material,
    crossSectionShape: !!crossSectionShape,
    lineType: !!lineType,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name, material, crossSectionShape, lineType }) {
  // takws the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!material && !!crossSectionShape && !!lineType;
}


// Cell Bodies
export const nameBody = (rowdata, onClickAction) => {

  return <div onClick={() => onClickAction(rowdata.id)} style={{ cursor: 'pointer' }} ><ComplexCellRenderer value={{ ...rowdata.summary, to: true}} placeholder='' isCapitalized={true} paddingLeft='0px' /></div>;
};

export const materialBody = (rowdata) => {
  if (rowdata.material) {
    return (
      <ComplexCellRenderer value={rowdata.material} placeholder='' isCapitalized={true} paddingLeft='0px' />
    );
  }

  return (
    <Text style={{ color: colors.gray[5] }}  >No Material</Text>
  );
};