import { take, takeEvery, fork, put, race } from 'redux-saga/effects';

import {
  WATCH_BUTTON_ACTION,
  UNWATCH_BUTTON_ACTION,
} from './constants';

import {
  setButtonActionStatus,
} from './actions';

export function* handlePrepareButtonWatcher({ payload: { id, startActions, endActions } }) {
  let currentButtonWatcherRunning = true;

  while (currentButtonWatcherRunning) {
    const { progress, unmount } = yield race({
      progress: take(startActions),
      unmount: take(UNWATCH_BUTTON_ACTION),
    });

    if (progress) {
      // button processing
      yield put(setButtonActionStatus(id, true));

      // wait for end actions
      yield take(endActions);
      yield put(setButtonActionStatus(id, false));
      
      // let loop continue
    } else {
      const { payload } = unmount;

      if (payload.id === id) {
        // exit loop and let watcher shut down
        currentButtonWatcherRunning = false;
      }
    }
  }
}

export function* watchEveryWatchButtonActionSaga() {
  yield takeEvery(WATCH_BUTTON_ACTION, handlePrepareButtonWatcher);
}

export default function* main() {
  yield fork(watchEveryWatchButtonActionSaga);
}
