import React, { useCallback, useEffect, useRef, useState } from 'react';

import BaseFormCell from '../../../components/form/BaseFormCell';
import BaseLabel from '../../../components/form/BaseLabel';
import BaseRequiredStatus from '../../../components/form/BaseRequiredStatus';
import SearchSelect from '../../../components/form/SearchSelect';
import EntitySearchSelect from '../EntitySearchSelect';

const RDXSearchSelect = ({
  input,
  meta,
  ek,
  width = '100%',
  height,
  bg,
  label,
  required,
  tabIndex,
  autoComplete,
  ...rest
}) => {
  const cellRef = useRef();
  const inputRef = useRef();
  const [currentWidth, setCurrentWidth] = useState(width || 0);
  useEffect(() => setCurrentWidth(cellRef.current.offsetWidth), []);
  const onCellClick = useCallback(() => inputRef.current.focus(), []);

  const InputComponent = ek !== undefined ? EntitySearchSelect : SearchSelect;

  return (
    <BaseFormCell ref={cellRef} width={width} height={height} bg={bg} onClick={onCellClick}>
      { label && <BaseLabel name={input.name} label={label} required={required} active={meta.active} error={meta.submitFailed} /> || null }
      { required && <BaseRequiredStatus error={meta.submitFailed} /> }
      <InputComponent ref={inputRef} ek={ek} tabIndex={tabIndex} autofilled={meta.autofilled} autoComplete={autoComplete} width={currentWidth} currentItemHeight={42} { ...input } { ...rest }/>
    </BaseFormCell>
  );
};

export default RDXSearchSelect;
