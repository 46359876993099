import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../../templates/Structures/Header';

import { PipingModuleSpecsPath } from '../../../paths';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectSpecsCounts } from '../../../entities/Piping/Specs/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';

import { selectCurrentFilteredDraftSpecs } from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectSpecsCounts(),
  selectCurrentFilteredDraftSpecs(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    isShowingArchived,
    { unarchived, total },
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && total === 0),
    title: PipingModuleSpecsPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${isShowingArchived ? total : unarchived
      } ${pluralize(
        'filtered Piping Spec',
        isShowingArchived ? total : unarchived
      )} ${(isShowingArchived && '(including archived)') ||
      '(excluding archived)'
      }`
      : `Showing ${unarchived} ${pluralize('Piping Spec', unarchived)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
