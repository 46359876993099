import React from 'react';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { selectCurrentProfileNameFromId } from '../../../entities/Framework/Profiles/selectors';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { FrameworkModuleProfilePath } from '../../../paths';

export default function () {
  const location = useLocation();
  const profileId = matchPath(location.pathname, { path: FrameworkModuleProfilePath.url }).params.id;
  const profileName = useSelector(selectCurrentProfileNameFromId(profileId));

  return <BreadcrumbTemplate title={profileName || 'Profile'} />;
}