import styled from 'styled-components';

import Flex from '../../common/Flex';

const Parent = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex: 1;
  min-width: 0;
  position: relative;
`;

const Child = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default {
  Parent,
  Child,
};