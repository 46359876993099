// : id,
// : fitting_instance_id,
// : bolt_nut_family_id,
// : stockno,
// : mccs_code,
// : selection_name,
// : _destroy
export const saveCurrentPipingSpecFittinginstanceChanges = (
  changes,
  saveAction,
) => {
  const collectionsdata = [];
  changes.forEach((item) => {
    if (item.isSelectedForSpec != item.originalRow.isSelectedForSpec && item.isSelectedForSpec) {
      // item is created
      collectionsdata.push({
        fittingInstanceId: item.id,
        boltNutFamilyId: item.boltNutFamily?.id || '',
        stockno: item.stockno,
        mccsCode: item.mccsCode,
        selectionName: item.selectionName,
      });
    } else if (item.isSelectedForSpec != item.originalRow.isSelectedForSpec && !item.isSelectedForSpec) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        fittingInstanceId: item.fittingInstanceId?.id || item.fittingInstanceId,
        boltNutFamilyId: item.boltNutFamily?.id || '',
        stockno: item.stockno,
        mccsCode: item.mccsCode,
        selectionName: item.selectionName,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};