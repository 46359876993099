import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { MATERIAL_FILTERS } from '../../../entities/Piping/Materials/model';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='name'
        label='Name'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='material'
        label='Material'
        ek={EK.MATERIALS}
        eFilter={MATERIAL_FILTERS.PIPE}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.PIPES.state}` })
);

export default enhance(FilterForm);
