import React from 'react';

import theme from '../../../../assets/themes/theme';

import BaseGraphicSVG, { fillTransitionClass } from '../BaseGraphicSVG';

const ReportingGraphic = ({
  id = 'pipe-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <BaseGraphicSVG
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
  >
    <circle style={{ fill: `${disabled ? disabledBackgroundColor : backgroundColor}` }} cx="256" cy="256" r="254" />
    <g>
      <path style={{ fill: '#495057' }} d="M295.1,123.3h-171c-6.5,0-11.8,5.3-11.8,11.8v273.6c0,6.5,5.3,11.8,11.8,11.8h205.7c6.5,0,11.8-5.3,11.8-11.8 V169.8h-46.5V123.3z"/>
      <path style={{ fill: '#CED4DA' }} d="M324.1,100h-171c-6.5,0-11.8,5.3-11.8,11.8v273.6c0,6.5,5.3,11.8,11.8,11.8h205.7c6.5,0,11.8-5.3,11.8-11.8 V146.6h-46.5V100z"/>
      <polygon style={{ fill: '#E6E9EE' }} points="324.1,100 324.1,146.6 370.6,146.6 	" />
      <polygon style={{ fill: '#CED5E0' }} points="295.1,123.3 295.1,169.8 341.6,169.8 	" />
      <path style={{ fill: '#F8F9FA' }} d="M353.1,76.8h-171c-6.5,0-11.8,5.3-11.8,11.8v273.6c0,6.5,5.3,11.8,11.8,11.8h205.7c6.5,0,11.8-5.3,11.8-11.8 V123.3h-46.5V76.8z"/>
      <polygon style={{ fill: '#CED4DA' }} points="353.1,76.8 353.1,123.3 399.7,123.3 	" />
      <rect x="194.2" y="154.1" style={{ fill: '#CED4DA' }} width="181.7" height="15.7" />
      <rect x="194.2" y="189.2" style={{ fill: '#CED4DA' }} width="181.7" height="15.7" />
      <rect x="194.2" y="224.2" style={{ fill: '#CED4DA' }} width="181.7" height="15.7" />
      <rect x="194.2" y="259.3" style={{ fill: '#CED4DA' }} width="181.7" height="15.7" />
      <rect x="194.2" y="294.3" style={{ fill: '#CED4DA' }} width="181.7" height="15.7" />
      <rect x="194.2" y="329.4" style={{ fill: '#CED4DA' }} width="103.2" height="15.7" />
    </g>
  </BaseGraphicSVG>
);

export default ReportingGraphic;
