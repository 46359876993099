import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllParameterTypes,
  createParameterTypes,
  copyParameterTypes,
  editParameterTypes,
  deleteParameterTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_PARAMETERS);

// /api/framework/profiles/:profile_id/profile_parameters

export const processFetchParameters = (profileId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllParameterTypes,
    `/framework/profiles/${profileId}/profile_parameters`,
    {
      mutateResponse: ({ profileParameters = [] }) => ({
        profileParameters: profileParameters.map(param => ({ ...param, profile: profileId, })),
      }),
    },
  );

// # POST /api/framework/profiles/:profile_id/profile_parameters
export const processCreateParameter = (profileId, newParameters) =>
  // newParameters must be an array of profiles objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createParameterTypes,
    `/framework/profiles/${profileId}/profile_parameters`,
    {
      data: {
        profile_parameters: newParameters,
      },
      mutateResponse: ({ profileParameters = [] }) => ({
        profileParameters: profileParameters.map(dim => ({ ...dim, profile: profileId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Parameter successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Parameter',
      },
    }
  );

// PATCH /api/framework/profiles/:profile_id/profile_parameters/:id
export const processEditParameter = (profileId, data) =>
  // data is a profile object containing any/all fields to be changed (not technically necessary to include all fields)
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editParameterTypes,
    `/framework/profiles/${profileId}/profile_parameters/${data.id}`,
    {
      data,
      mutateResponse: ({ profileParameter = {} }) => ({
        profileParameter: { ...profileParameter, profile: profileId, },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteParameter = (ProfileId, ParameterId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteParameterTypes,
    `/framework/profiles/${ProfileId}/profile_parameters/${ParameterId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_PARAMETERS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Parameter deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Parameter',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyParameter = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyParameterTypes,
    `/framework/profiles/${ProfileId}/profile_parameters/copy`,
    {
      data: {
        parameters: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Parameter successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Parameter',
      },
    }
  );