import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { List, Map } from 'immutable';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import space from '../../../../assets/themes/base/space';
import fontSizes from '../../../../assets/themes/base/fontSizes';
import borders from '../../../../assets/themes/base/borders';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';
import RDXCheckboxInput from '../../RDXCheckboxInput';

import { ALLOWANCES } from '../../../../entities/Settings/model';

const CategoryName = styled(Text)`
  font-size: ${fontSizes[4]};
  color: ${colors.gray[7]};
  text-transform: capitalize;
  padding: ${space[4]} calc(${space[3]} + ${space[4]});
  padding-right: calc(40% + ${space[4]} + ${space[4]});
  margin-bottom: ${space[4]};
  width: 100%;
  background-color: ${colors.gray[0]};
  text-align: right;
  font-weight: 600;
`;

const SettingField = styled(Box)`
  flex-shrink: 0;
  height: 4.75rem;
  width: 100%;
  // border: ${borders[2]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const SettingDetails = styled(Flex)`
  flex-direction: column;
  padding: ${space[3]};
  margin-right: ${space[4]};
  height: 100%;
  width: 60%;
  justify-content: center;
`;

const SettingName = styled(Text)`
  font-size: ${fontSizes[2]};
  font-weight: 500;
  color: ${colors.gray[7]};
  margin-botton: ${space[1]};
  text-align: right;
`;

const SettingDescription = styled(Text)`
  font-size: ${fontSizes[1]};
  color: ${colors.gray[6]};
  text-align: right;
`;

const OrganizationSettingsForm = ({ initialValues, handleSubmit, isPerforming, height, active }) => {
  const organizedSettings = useMemo(() => initialValues.get('settings').reduce((categories, setting) => categories.set(
    setting.category,
    categories.has(setting.category) ? categories.get(setting.category).push(setting) : List([ setting ])
  ), Map()).reduce((output, settings, category) => output.push({ category, settings }), List()), [active, initialValues]);

  return (
    <Box as="form" onSubmit={handleSubmit} style={{ height, overflow: 'auto' }}>
      {
        organizedSettings.map(({ category, settings }) => (
          <Box key={category} mb={6}>
            {
              organizedSettings.size > 1 &&
              <CategoryName>{ category }</CategoryName>
            }
            {
              settings.filter(setting => (
                setting.valueType === 'string' || setting.valueType === 'integer' || setting.valueType === 'double' || setting.valueType === 'list' || setting.valueType === 'bool'
              )).map(setting => (
                <Flex key={setting.id} flexDirection='row' p={4}>
                  <SettingDetails>
                    <SettingName>
                      { setting.name }
                    </SettingName>
                    <SettingDescription>
                      { setting.description }
                    </SettingDescription>
                  </SettingDetails>
                  <Flex flexDirection='column' justifyContent='center' width='40%' mr={4}>
                    <SettingField>
                      {
                        (setting.valueType === 'string' || setting.valueType === 'integer' || setting.valueType === 'double') && (
                          <Field
                            component={RDXTextInput}
                            name={`settings.${setting.id}.value`}
                            bg='gray.0'
                            height='100%'
                            allow={setting.valueType === 'integer' ? ALLOWANCES.INTEGER : (setting.valueType === 'double' ? ALLOWANCES.DOUBLE : null)}
                            placeholder={setting.defaultValue}
                            disabled={isPerforming}
                          />
                        ) || null
                      }
                      {
                        setting.valueType === 'list' && (
                          <Field
                            component={RDXRadioSelect}
                            name={`settings.${setting.id}.value`}
                            bg='gray.0'
                            height='100%'
                            placeholder={setting.defaultValue}
                            options={setting.listValues}
                            disabled={isPerforming}
                          />
                        )
                      }
                      {
                        setting.valueType === 'bool' && (
                          <Field
                            component={RDXCheckboxInput}
                            name={`settings.${setting.id}.value`}
                            disabled={isPerforming}
                          />
                        )
                      }
                    </SettingField>
                  </Flex>
                </Flex>
              ))
            }
          </Box>
        ))
      }
    </Box>
  );
};

const enhance = compose(
  reduxForm({
    form: `${EK.SETTINGS.state}`,
    onSubmit(model, dispatch, props) {
      const initial = props.initialValues.get('settings');
      props.onRDXSubmit(
        model.get('settings').reduce((filtered, setting) => {
          if (!setting.hasSameValueAs(initial.getIn([setting.id, 'value']))) {
            return [...filtered, { id: setting.id, ...setting.serialize() }];
          } else {
            return filtered;
          }
        }, [])
      );
    },
  })
);

export default enhance(OrganizationSettingsForm);
