import { handleActions } from 'redux-actions';
import { List, Map, Set } from 'immutable';
import isEmpty from 'lodash/isEmpty';

import createFilteredReducer from '../../../utils/reducers/createFilteredReducer';
import mergeEntitiesWithDefaultedTracking from '../../entities/utils/mergeEntitiesWithDefaultedTracking';

import { moveDrafts, mergeDrafts, addDrafts, clearDrafts } from '../actions';

const initialDraftState = Map({
  // Map of actual current drafts by key
  records: Map(),
  // Set of currently valid saveable draft ids
  saveable: Set(),
  // List of current sorting for drafts
  ids: List(),
});

const defaultSortBy = entity => entity.isLocalDraft;

const updateSaveableRecords = (incoming, current) => {
  return incoming.reduce((ids, model) => model.canSave && ids.add(model.id) || ids.delete(model.id), current);
};

// TODO: add redux-undo at some point
const createDraftsReducer = entityKey => handleActions(
  {
    // moving drafts - completely overwrites existing draft state
    [moveDrafts]: createFilteredReducer(
      (state, { payload: { drafts: { [entityKey] : models }, sortBy: { [entityKey] : sortBy } } }) => {
        const incomingDrafts = Map(models).sortBy(sortBy || defaultSortBy);

        return state
          .set('records', mergeEntitiesWithDefaultedTracking(Map(), incomingDrafts))
          .set('saveable', Set())
          .set('ids', incomingDrafts.reduce((ids, model) => ids.indexOf(model.id) < 0 ? ids.push(model.id) : ids, List()));
      },
      ({ payload: { drafts } }) => !!drafts[entityKey]
    ),
    // merging drafts -  merges draft state with incoming edits and incoming moves we want to merge
    [mergeDrafts]: createFilteredReducer(
      (state, { payload: { drafts: { [entityKey] : models }, sortBy: { [entityKey] : sortBy } } }) => {
        const incomingDrafts = Map(models).sortBy(sortBy || defaultSortBy);
        return state
          .set('records', mergeEntitiesWithDefaultedTracking(state.get('records'), incomingDrafts))
          .set('saveable', updateSaveableRecords(incomingDrafts, state.get('saveable')))
          .set('ids', incomingDrafts.reduce((ids, model) => ids.indexOf(model.id) < 0 ? ids.push(model.id) : ids, state.get('ids')));
      },
      ({ payload: { drafts } }) => !!drafts[entityKey]
    ),
    // adding drafts - exclusively used for adding drafts that are not in the draft state at the moment (will overwrite existing drafts if same id)
    [addDrafts]: createFilteredReducer(
      (state, { payload: { drafts: { [entityKey] : models }, insertAt : entityIdToInsertAt } }) => {
        let newIdsState = null;

        if (entityIdToInsertAt) {
          let indexToInsertAt = state.get('ids').findIndex(id => id === entityIdToInsertAt);

          if (indexToInsertAt > -1) {
            newIdsState = models.reduce((ids, model) => {
              // incrementing the index as we progress so that we add the
              // new drafts in the correct order
              indexToInsertAt++;
              return !ids.has(model.id) ? ids.insert(indexToInsertAt, model.id) : ids;
            }, state.get('ids'));
          } else {
            newIdsState = models.reduce((ids, model) => ids.indexOf(model.id) < 0 ? ids.push(model.id) : ids, state.get('ids'));
          }
        }

        if (!newIdsState) {
          newIdsState = models.reduce((ids, model) => ids.indexOf(model.id) < 0 ? ids.push(model.id) : ids, state.get('ids'));
        }

        return state
          .set('records', state.get('records').withMutations(s => {
            models.forEach((e, eId) => s.set(eId, e));
          }))
          .set('saveable', updateSaveableRecords(models, state.get('saveable')))
          .set('ids', newIdsState);
      },
      ({ payload: { drafts } }) => !!drafts[entityKey]
    ),
    // clearing drafts - used to remove drafts from the draft state
    [clearDrafts]: createFilteredReducer(
      (state, { payload: { drafts: { [entityKey] : ids } } }) => {
        if (isEmpty(ids) || ids.size === 0) {
          return initialDraftState;
        } else {
          return state
            .set('records', state.get('records').deleteAll(ids))
            .set('saveable', state.get('saveable').subtract(ids))
            .set('ids', state.get('ids').filterNot(id => ids.indexOf(id) > -1));
        }
      },
      ({ payload: { drafts } }) => !!drafts[entityKey]
    )
  },
  initialDraftState
);

export default createDraftsReducer;
