import { ParameterProfileParametersType } from '../../../entities/Standardize/ParameterProfileParameters/types';

export const convertToParameterProfileInstancesType = ({
  id,
  parameter,
  defaultValue,
  paramIsLocked,
  paramIsDesignated,
  isEdited=false,
  isCreated=false,
  isDeleted=false,
}: ParameterProfileParametersType) => ({
  id,
  parameter,
  defaultValue,
  paramIsDesignated,
  paramIsLocked,
  isEdited,
  isCreated,
  isDeleted
});