import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import transitions from '../../../../assets/themes/base/transitions';
import fontSizes from '../../../../assets/themes/base/fontSizes';

import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import CableGraphic from '../../../../components/common/graphics/CableGraphic';
import PipeGraphic from '../../../../components/common/graphics/PipeGraphic';
import FrameworkGraphic from '../../../../components/common/graphics/FrameworkGraphic';
import EcadGraphic from '../../../../components/common/graphics/EcadGraphic';

import { PRODUCT_MODULES } from '../../model';
import ReportingGraphic from '../../../../components/common/graphics/ReportingGraphic';

const BaseModuleLabel = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.375rem;
  padding-left: 0.625rem;
  padding-bottom: 0.375rem;
  padding-right: 0.625rem;
  user-select: none;

  background-color: ${colors.primary[0]};
  border: 1px solid ${colors.primary[4]};
  border-radius: ${radii[2]};

  transition: background-color ${transitions.speed.fast}, border-color ${transitions.speed.fast};

  ${({ disabled }) => disabled && css`
    background-color: ${colors.gray[0]};
    border-color: ${colors.gray[2]};
  `}
`;

const BaseModuleLabelGraphic = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  margin-right: 0.5rem;
`;

const BaseModuleLabelText = styled(Text)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  font-size: ${fontSizes[0]};
  font-weight: 500;
  color: ${colors.primary[4]};

  ${({ disabled }) => disabled && css`
    color: ${colors.gray[4]};
  `}
`;

const MODULE_GRAPHICS = {
  [PRODUCT_MODULES.CABLING]: CableGraphic,
  [PRODUCT_MODULES.PIPING]: PipeGraphic,
  [PRODUCT_MODULES.FRAMEWORK]: FrameworkGraphic,
  [PRODUCT_MODULES.ECAD]: EcadGraphic,
  [PRODUCT_MODULES.REPORTING]: ReportingGraphic,
};

const MODULE_TEXT = {
  [PRODUCT_MODULES.CABLING]: 'Cabling',
  [PRODUCT_MODULES.PIPING]: 'Piping',
  [PRODUCT_MODULES.FRAMEWORK]: 'Framework',
  [PRODUCT_MODULES.ECAD]: 'ECAD',
  [PRODUCT_MODULES.REPORTING]: 'Reporting',
};

export { PRODUCT_MODULES };

const ModuleLabel = ({ module, disabled, ...rest }) => {
  const SpecificGraphic = MODULE_GRAPHICS[module];

  return SpecificGraphic && (
    <BaseModuleLabel disabled={disabled} { ...rest }>
      <BaseModuleLabelGraphic>
        <SpecificGraphic disabled={disabled} />
      </BaseModuleLabelGraphic>
      <BaseModuleLabelText disabled={disabled}>
        { MODULE_TEXT[module] }
      </BaseModuleLabelText>
    </BaseModuleLabel>
  ) || null;
};

export default React.memo(ModuleLabel);