import React from 'react';
import Highlighter from 'react-highlight-words';

import { 
  SELECT_HIGHLIGHTER_CLASS,
  DISABLED_SELECT_HIGHLIGHTER_CLASS,
} from '../../../routes/App/constants';

const BaseSearchHighlighter = ({ children, disabled, ...props }) => (
  <Highlighter highlightClassName={disabled ? DISABLED_SELECT_HIGHLIGHTER_CLASS : SELECT_HIGHLIGHTER_CLASS} textToHighlight={children} { ...props } />
);

export default BaseSearchHighlighter;