import { createSelector } from 'reselect';

import { selectNormalizedSimplifiedReps } from '../../../../entities/Standardize/SimplifiedReps/selectors';

export const selectCurrentSimplifiedRep = () =>
  createSelector(
    selectNormalizedSimplifiedReps(),
    (state, ownProps) => ownProps.simplifiedRepId,
    (simplifiedReps, simplifiedRepId) =>
      simplifiedReps.get(simplifiedRepId)
  );
