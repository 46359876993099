/**
 * Create the store with asynchronously loaded reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { Map } from 'immutable';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router/immutable';

import { hasSentryConfiguration } from './configuration/environmentCheck';

import createValidateFSAMiddleware from './utils/middlewares/createValidateFSAMiddleware';
import createToastifyNotificationMiddleware from './modules/notifications/middleware';
import createToastifyNoticeMiddleware from './modules/notices/middleware';

import history from './utils/routing/history';

import createReducer from './reducers';


const sagaMiddleware = createSagaMiddleware();
const validateFSAMiddleware = process.env.NODE_ENV !== 'production'
  ? [createValidateFSAMiddleware()]
  : [];
const toastifyNotificationMiddleware = createToastifyNotificationMiddleware();
const toastifyNoticeMiddleware = createToastifyNoticeMiddleware();

//needs some configuration to ensure secrets aren't exposed in Sentry
// see: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export default function configureStore(initialState = Map({})) {
  // Create the store with middlewares
  // 1. validateFSAMiddleware: Allows only valid FSA-compliant actions
  // 2. sagaMiddleware: Makes redux-sagas work
  const middlewares = [
    ...validateFSAMiddleware,
    routerMiddleware(history),
    toastifyNotificationMiddleware,
    toastifyNoticeMiddleware,
    sagaMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers = process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // TODO: Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
      // Prevent recomputing reducers for `replaceReducer`
      shouldHotReload: false,
    })
    : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(history)(),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Create hook for async sagas
  store.runSaga = sagaMiddleware.run;

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(history)(store.injectedReducers));
    });
  }

  // Initialize it with no other reducers/sagas
  store.injectedReducers = {};
  store.injectedSagas = {};

  return store;
}
