import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

// Data Grid imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// actions
import {
  processArchivePipeFamilies,
  processFetchAllPipeFamilies,
  processUnarchivePipeFamilies,
  processCopyPipeFamily,
  processEditPipeFamily,
  processCreatePipeFamily,
  processDeletePipeFamily,
} from '../../../entities/Piping/PipeFamilies/actions';
import { processFetchAllMaterials } from '../../../entities/Piping/Materials/actions';

// sagas
import saga from './sagas';
import injectSaga from '../../../utils/sagas/injectSaga';
import EK from '../../../entities/keys';

// cell imports
import {
  nameBody,
  materialBody,
  areFieldsValid,
  doRequiredFieldsExist,
} from './components';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

// dialog imports
import DataActionDropdownCellRenderer from '../../../components/grid/DataActionDropdownCellRenderer';
import { PipeDialog } from '../../../containers/dialog/templates/PipeDialog';
import { DeletePipeDialog } from '../../../containers/dialog/templates/PipeDialog';

// misc imports
import {
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesPath,
} from '../../../paths';
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

// dropdown constants
import {
  BlankPipe,
  LINE_TYPES,
  PIPE_SHAPES,
  VALIDATION_FIELDS,
} from '../../../entities/Piping/PipeFamilies/model';

// Header imports
import Header from '../../templates/Structures/Header';
import Skeleton from 'react-loading-skeleton';
import Options from '../../templates/Structures/Options';
import { pluralize } from '../../../components/common/Header';

// selectors
import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';
import { selectCurrentFilteredPipes, selectPipeFamiliesCounts } from '../../../entities/Piping/PipeFamilies/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectNormalizedMaterials } from '../../../entities/Piping/Materials/selectors';
import { selectUnitSystems } from '../../../entities/Synchronize/UnitSystems/selectors';
import { selectMasterCatalogUnitSystem } from '../../../entities/Piping/PipingCatalogs/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredPipes(),
  selectMasterCatalogUnitSystem(),
  selectPipeFamiliesCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    defaultUnitSystem,
    {
      unarchived,
      total
    },
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isFiltering,
      defaultUnitSystem,
      total,
      unarchived,
      canCollaborate,
      hasValidLicense,
    };
  },
);

export const convertToPipeType = ({
  id,
  name,
  archivedFlag,
  minLength,
  maxLength,
  maxOverallLength,
  minBends,
  maxBends,
  crossSectionShape,
  lineType,
  unitSystem,
  material,
  pipeInstances,
  selectedPipeInstancesForSpec,
  isSelectableForPriority,
  isSelectedForPriority,
  prioritizationState,
  sizes,
  schedules,
}) => ({
  id,
  name,
  archivedFlag,
  minLength,
  maxLength,
  maxOverallLength,
  minBends,
  maxBends,
  crossSectionShape,
  lineType,
  unitSystem,
  material,
  pipeInstances,
  selectedPipeInstancesForSpec,
  isSelectableForPriority,
  isSelectedForPriority,
  prioritizationState,
  sizes,
  schedules,
});

function PipesGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);         // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  // used to provide data for the modals
  const [currentEditingRow, setCurrentEditingRow] = useState(BlankPipe);

  // header actions
  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setValidation(VALIDATION_FIELDS);
    setCurrentEditingRow(BlankPipe);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };

  const getUnitLength = (listUnitValues) => {
    // units are not stored by family, they are stored by unit id, so we need to check units/unitSystem and then get the id from there
    if(typeof(listUnitValues) == 'number') {
      // edited units are stored as a number, so there is no need to search.
      return listUnitValues;
    }
    // these two prevent "no unit system" and "no unit Value" errors
    const currentRowUnitSystem = currentEditingRow && currentEditingRow.unitSystem || reduxProps.defaultUnitSystem;
    const currentUnitValue = listUnitValues && listUnitValues.find((item) => item.id == currentRowUnitSystem.unitLength.id);

    return currentUnitValue &&
      currentUnitValue.value ||
      null;
  };

  const getDialogHeader = () => {
    if(isCreateDialogOpen) {
      return 'Create Pipe Family';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const saveAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingRow));
    if(!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      minLength: getUnitLength(currentEditingRow.minLength),
      maxLength: getUnitLength(currentEditingRow.maxLength),
      maxOverallLength: getUnitLength(currentEditingRow.maxOverallLength),
      minBends: currentEditingRow.minBends,
      maxBends: currentEditingRow.maxBends,
      crossSectionShape: currentEditingRow.crossSectionShape,
      lineType: currentEditingRow.lineType,
      unitSystemId: currentEditingRow.unitSystem && currentEditingRow.unitSystem.id || reduxProps.defaultUnitSystem.id,
      materialId: currentEditingRow.material.id,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreatePipeFamily(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyPipeFamily(editedRow));
    } else {
      dispatch(processEditPipeFamily(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    // this action is PERMANENT, only call from the delete modal
    dispatch(processDeletePipeFamily(currentEditingRow.id));
    setCurrentEditingRow(BlankPipe);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const eorDropdownActions = [
    {
      label: 'Manage Bends',
      icon: 'list',
      onClick: (pipe) => dispatch(push(PipingModulePipesBendsPath.generate({ id: pipe.id }))),
    },
    {
      label: 'Manage Bend Machines',
      icon: 'list',
      onClick: (pipe) => dispatch(push(PipingModulePipesBendMachinesPath.generate({ id: pipe.id }))),
    },
    {
      label: 'Manage Bend Springbacks',
      icon: 'list',
      onClick: (pipe) => dispatch(push(PipingModulePipesBendSpringbacksPath.generate({ id: pipe.id }))),
    },
    {
      label: 'Manage Miters',
      icon: 'list',
      onClick: (pipe) => dispatch(push(PipingModulePipesMitersPath.generate({ id: pipe.id }))),
    },
  ];

  //cell actions
  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const gotoPipeInstances = (pipeId) => {
    const pipeLink = PipingModulePipesEditPath.generate({ id: pipeId });
    dispatch(push(pipeLink));
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      minLength: rowdata.minLength,
      maxLength: rowdata.maxLength,
      maxOverallLength: rowdata.maxOverallLength,
      minBends: rowdata.minBends,
      maxBends: rowdata.maxBends,
      crossSectionShape: rowdata.crossSectionShape,
      lineType: rowdata.lineType,
      unitSystem: rowdata.unitSystem,
      material: rowdata.material,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    setCurrentEditingRow(rowdata);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchivePipeFamilies([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchivePipeFamilies([rowdata.id]));
  };

  useEffect( () => {
    dispatch(processFetchAllPipeFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading || (reduxProps.isFetching && reduxProps.total === 0)}
          title={PipingModulePipesPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Pipe',
              reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.showArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Pipe', reduxProps.unarchived)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='name' header='Name // Instances' style={{ width: '18%' }} body={(rowData) => nameBody(rowData, gotoPipeInstances)} ></Column>
          <Column field='material' header="Material" style={{ width: '17%' }} body={materialBody} ></Column>
          <Column field='crossSectionShape' header='Cross Section Shape' style={{ width: '17%' }} body={(rowData) => rowData.crossSectionShape && <Text>{PIPE_SHAPES[rowData.crossSectionShape].label}</Text>} ></Column>
          <Column field='lineType' header='Line Type' style={{ width: '17%' }} body={(rowData) => rowData.lineType && <Text>{LINE_TYPES[rowData.lineType].label}</Text>} ></Column>
          <Column header=''
            style={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => gotoPipeInstances(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
          <Column header='' body={(rowData) => <DataActionDropdownCellRenderer actions={eorDropdownActions} data={rowData} />} style={{ width: '1%' }} ></Column>
        </DataTable>
      </Flex>
      <PipeDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        defaultUnitSystem={reduxProps.defaultUnitSystem}
        getDialogHeader={getDialogHeader}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
        getUnitLength={getUnitLength}
      />
      <DeletePipeDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default injectSaga({ key: `${EK.PIPES.state}Page`, saga })(withRouter(PipesGrid));