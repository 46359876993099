import EntityRecord from '../../../modules/entities/model';

export default class Unit extends EntityRecord({
  name: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  unitType: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  abbreviation: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
}) {
  get primary() { return this.abbreviation; }
  get primaryPlaceholder() { return 'No Abbreviation'; }
}
