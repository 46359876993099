import React from 'react';
import styled from 'styled-components';
import shadows from '../../../assets/themes/base/shadows';
import radii from '../../../assets/themes/base/radii';

import Box from '../../common/Box';

const BaseTooltipBody = styled(Box)`
  background-color: transparent;
  border-radius: ${radii[1]};
  box-shadow: ${shadows[2]};

  overflow: hidden;

  & > :first-child {
    border-top-left-radius: ${radii[1]};
    border-top-right-radius: ${radii[1]};
  }

  & > :last-child {
    border-bottom-left-radius: ${radii[1]};
    border-bottom-right-radius: ${radii[1]};
  }
`;

const TooltipBody = React.forwardRef((props, ref) => (
  <BaseTooltipBody ref={ref} {...props} />
));

export default TooltipBody;
