import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';

import useFloatingState from '../../../hooks/useFloatingState';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useToggle from '../../../hooks/useToggle';

import Button from '../../common/Button';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import Text from '../../common/Text';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import TooltipBody from '../../tooltip/TooltipBody';

import BaseCell from '../BaseCell';

const DropdownItem = styled(Flex)`
  background: transparent;
  color: ${colors.gray[7]};
  cursor: pointer;
  background-color: ${colors.white};

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.gray[1]};
  }
`;

const DataActionDropdownItem = ({ onClick, data, icon, label }) => {
  const onHandleClick = useCallback(
    _ => onClick(data),
    [onClick, data]
  );

  return (
    <DropdownItem flexDirection='row' alignItems='center' p={3} onClick={onHandleClick}>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={3}>
        <Icon name={icon} />
      </Flex>
      <Flex flexDirection='column' justifyContent='center'>
        <Text fontSize={1} fontWeight={500} color='gray.7'>{ label }</Text>
      </Flex>
    </DropdownItem>
  );
};

const DataActionDropdown = ({ actions, data, toggleDropdown }) => {
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, toggleDropdown);

  return (
    <TooltipBody ref={dropdownRef}>
      { actions.map(
        ({ onClick, icon, label }, idx) => (
          <DataActionDropdownItem
            key={idx}
            onClick={onClick}
            data={data}
            icon={icon}
            label={label}
          />)
      )
      }
    </TooltipBody>
  );
};



const DataActionDropdownCellRenderer = ({ actions, data }) => {
  const [isDropdownVisible, toggleDropdownVisible] = useToggle();
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom-end' });

  return (
    <BaseCell ref={reference} flexDirection='column' justifyContent='center' alignItems='center'>
      <Button transparent subtle icon='more-vert' onClick={toggleDropdownVisible} />
      {
        isDropdownVisible && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='small'>
            <DataActionDropdown
              actions={actions}
              data={data}
              toggleDropdown={toggleDropdownVisible}
            />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default DataActionDropdownCellRenderer;