import isString from 'lodash/isString';

const empty = {};

/**
 * A decorator to inject the `convertMeasurement` method for any Entity class with measurements and units to convert to
 * @param {Entity} Entity - An Entity object that has measurements and units to convert
 * @return {Entity} The Entity object with the `convertMeasurement` method injected into it
 */
export default function hasMeasurements(Entity) {
  return class extends Entity {
    /**
     * Based on the field name and unit system, fetches the converted unit value for that field
     * @param {string} field - A string indicating the field to fetch from the Entity
     * @param {Entity} unitSystem - The UnitSystem to pull the unit id from
     * @return {number} The converted measurement that matches the supplied unit id
     * @public
     */
    convertMeasurement(field, unitSystem) {
      const matchingUnitType = isString(field) && this.unitFields && this.unitFields[field];
      if (matchingUnitType && unitSystem) {
        const unitId = unitSystem[matchingUnitType] && unitSystem[matchingUnitType].id;
        const unitIdToMatch = isString(unitId) ? unitId : (this.unit || empty).id;
        const measurements = this[field];

        if (unitIdToMatch && measurements && measurements.size > 0) {
          return measurements.find(convertedMeasurement => convertedMeasurement.id === unitIdToMatch).value;
        }
      }

      return null;
    }

    /**
     * Based on the field name and unit system, return the appropriate unit abbreviation
     * @param {string} field - A string indicating the field to fetch from the Entity
     * @param {Entity} unitSystem - The UnitSystem to pull the abbreviation from
     * @return {string} The abbreviation for the field's unit
     */
    convertAbbreviation(field, unitSystem) {
      const matchingUnitType = isString(field) && this.unitFields && this.unitFields[field];
      if (matchingUnitType) {
        return unitSystem[matchingUnitType] ? unitSystem[matchingUnitType].abbreviation : null;
      }

      return null;
    }
  };
}
