import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../../templates/Structures/Header';

import { StandardizeModuleModelTemplatesPath } from '../../../paths';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectNormalizedModelTemplatesList } from '../../../entities/Standardize/ModelTemplates/selectors';

import { selectIsLoadingInitialData } from '../../Dashboard/selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectNormalizedModelTemplatesList(),
  (isLoadingInitialData, isFetching, data) => ({
    isLoading:
      isLoadingInitialData || (isFetching && (!data || data.size === 0)),
    title: StandardizeModuleModelTemplatesPath.defaultTitle,
    subtitle: `${data.size} ${pluralize('Model Template', data.size)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
