import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Box from '../../../../components/common/Box';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import CurrentUserForm from '../../../form/templates/CurrentUserForm';
import ChangePasswordForm from '../../../form/templates/ChangePasswordForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { selectCurrentUser } from '../../../../entities/CurrentUser/selectors';

import {
  updateCurrentUser,
  changeCurrentUserPassword,
} from '../../../../entities/CurrentUser/actions';

const CurrentUserModal = ({ 
  currentUser, 
  isPerforming, 
  handleUpdateCurrentUser, 
  handleChangeCurrentUserPassword,
}) => (
  <Box>
    <ModalTitle><strong>Manage</strong> Profile</ModalTitle>

    <CurrentUserForm initialValues={currentUser} isPerforming={isPerforming} onRDXSubmit={handleUpdateCurrentUser} />

    <Box borderBottom={1} width='50%' mb={6} ml='auto' mr='auto' />

    <ModalTitle><strong>Change</strong> Password</ModalTitle>

    <ChangePasswordForm isPerforming={isPerforming} onRDXSubmit={handleChangeCurrentUserPassword} />
  </Box>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isPerforming: selectIsPerformingInModal(),
});

export default connect(
  mapStateToProps, 
  {
    handleUpdateCurrentUser: updateCurrentUser,
    handleChangeCurrentUserPassword: changeCurrentUserPassword,
  }
)(CurrentUserModal);
