import React from 'react';

const Inspect = ({ id = 'inspect-graphic', backgroundColor = '#F4DA80', iconColor = '#01C6DA' }) => (
  <svg viewBox='0 0 107 105'>
    <defs>
      <path d='M53.443,104.91 C24.219,104.91 0.443,81.504 0.443,52.735 C0.443,23.965 24.219,0.56 53.443,0.56 C82.668,0.56 106.443,23.965 106.443,52.735 C106.443,81.504 82.668,104.91 53.443,104.91' id={`${id}-path-1`}></path>
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path d='M53.443,104.91 C24.219,104.91 0.443,81.504 0.443,52.735 C0.443,23.965 24.219,0.56 53.443,0.56 C82.668,0.56 106.443,23.965 106.443,52.735 C106.443,81.504 82.668,104.91 53.443,104.91' fill={backgroundColor}></path>;
      <g>
        <mask id={`${id}-mask-2`} fill='white'>
          <use href={`#${id}-path-1`}></use>
        </mask>
        <g></g>
        <path d='M54.829,77.746 C47.995,77.759 41.593,75.115 36.78,70.308 C26.854,60.393 26.852,44.258 36.771,34.34 C41.556,29.555 47.929,26.913 54.714,26.899 C61.536,26.883 67.938,29.525 72.745,34.327 C77.548,39.125 80.192,45.508 80.19,52.318 C80.192,59.119 77.552,65.512 72.761,70.312 C67.979,75.093 61.616,77.734 54.829,77.746 M86.032,52.316 C86.028,43.953 82.776,36.096 76.872,30.199 C70.96,24.284 63.085,21.034 54.702,21.05 C46.358,21.075 38.518,24.326 32.64,30.208 C20.452,42.406 20.452,62.251 32.653,74.439 C38.571,80.356 46.454,83.604 54.837,83.589 C63.181,83.571 71.009,80.317 76.89,74.436 C82.786,68.533 86.036,60.68 86.032,52.316' fill='#30363D' mask={`url(#${id}-mask-2)`}></path>
        <path d='M54.714,26.987 C47.952,26.998 41.601,29.633 36.83,34.401 C26.946,44.284 26.95,60.367 36.843,70.246 C41.636,75.038 48.019,77.67 54.827,77.659 C61.595,77.649 67.931,75.013 72.698,70.247 C77.476,65.466 80.106,59.097 80.105,52.318 C80.106,45.534 77.468,39.17 72.685,34.387 C67.894,29.602 61.511,26.971 54.714,26.987' fill='#FFFFFF' mask={`url(#${id}-mask-2)`}></path>
        <path d='M33.023,63.634 C31.222,60.185 30.269,56.33 30.269,52.327 C30.267,45.771 32.81,39.621 37.431,35.003 C42.038,30.391 48.179,27.845 54.712,27.83 C54.725,27.83 54.737,27.83 54.75,27.83 C61.292,27.83 67.44,30.359 72.061,34.963 L72.085,34.985 C75.435,38.333 77.689,42.487 78.681,47.01 C77.539,44.816 76.06,42.787 74.261,40.992 L74.233,40.969 C69.62,36.37 63.485,33.84 56.949,33.84 C56.929,33.84 56.909,33.84 56.89,33.84 C50.355,33.851 44.216,36.397 39.604,41.009 C34.983,45.627 32.442,51.781 32.442,58.333 C32.444,60.139 32.646,61.913 33.023,63.634' fill='#FFFFFF' mask={`url(#${id}-mask-2)`}></path>
        <path d='M35.757,71.333 C25.267,60.854 25.261,43.798 35.745,33.318 C40.806,28.258 47.54,25.464 54.71,25.453 C61.919,25.437 68.687,28.226 73.769,33.302 L75.623,31.449 L75.583,31.409 C70.005,25.853 62.595,22.807 54.71,22.822 C46.841,22.84 39.446,25.908 33.892,31.458 C22.392,42.963 22.392,61.685 33.905,73.187 L35.763,71.334 L35.757,71.333' fill='#474F59' mask={`url(#${id}-mask-2)`}></path>
        <path d='M58.722,88.972 C58.755,86.601 58.788,84.226 58.817,81.843 C58.817,80.746 58.388,79.741 57.675,79.041 C56.751,79.135 55.815,79.183 54.873,79.183 C53.896,79.183 52.923,79.137 51.96,79.038 C51.255,79.737 50.817,80.741 50.815,81.83 C50.782,84.213 50.749,86.601 50.716,89 C50.714,91.277 52.442,92.229 54.71,92.197 L54.72,92.199 C56.995,92.192 58.729,91.23 58.722,88.972' fill='#30363D' mask={`url(#${id}-mask-2)`}></path>
        <polygon fill='#484F59' mask={`url(#${id}-mask-2)`} points='48.378 104.91 61.153 104.91 61.153 88.568 48.378 88.568'></polygon>
        <polygon fill='#30363D' mask={`url(#${id}-mask-2)`} points='54.854 104.91 60.132 104.91 60.132 88.568 54.854 88.568'></polygon>
        <polygon fill='#E5E8EC' mask={`url(#${id}-mask-2)`} points='48.378 91.637 61.153 91.637 61.153 88.568 48.378 88.568'></polygon>
        <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='54.319 91.379 59.964 91.379 59.964 89.271 54.319 89.271'></polygon>
        <path d='M72.294,52.042 C72.294,61.725 64.448,69.573 54.763,69.573 C45.083,69.573 37.237,61.725 37.237,52.042 C37.237,42.362 45.083,34.517 54.763,34.517 C64.448,34.517 72.294,42.362 72.294,52.042' fill={iconColor} mask={`url(#${id}-mask-2)`}></path>;
        <path d='M65.022,52.042 C65.022,57.711 60.431,62.301 54.763,62.301 C49.099,62.301 44.509,57.711 44.509,52.042 C44.509,46.379 49.099,41.784 54.763,41.784 C60.431,41.784 65.022,46.379 65.022,52.042' fill='#10303F' mask={`url(#${id}-mask-2)`}></path>
        <path d='M67.819,50.106 C66.513,51.821 64.062,52.153 62.351,50.851 C60.634,49.542 60.304,47.094 61.606,45.379 C62.913,43.665 65.36,43.335 67.075,44.64 C68.792,45.942 69.124,48.393 67.819,50.106' fill='#FFFFFF' mask={`url(#${id}-mask-2)`}></path>
        <path d='M64.249,56.33 C63.655,57.109 62.538,57.258 61.759,56.667 C60.981,56.076 60.829,54.959 61.425,54.181 C62.017,53.401 63.13,53.25 63.907,53.842 C64.688,54.435 64.841,55.55 64.249,56.33' fill='#FFFFFF' mask={`url(#${id}-mask-2)`}></path>
      </g>
    </g>
  </svg>
);

export default Inspect;
