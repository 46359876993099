import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../../../../assets/themes/base/colors';
import radii from '../../../../../../assets/themes/base/radii';
import transitions from '../../../../../../assets/themes/base/transitions';
import shadows from '../../../../../../assets/themes/base/shadows';
import borders from '../../../../../../assets/themes/base/borders';
import space from '../../../../../../assets/themes/base/space';

import { useDrag, useDrop } from 'react-dnd';

import EK from '../../../../../../entities/keys';

import Box from '../../../../../../components/common/Box';
import Button from '../../../../../../components/common/Button';
import Flex from '../../../../../../components/common/Flex';
import Icon from '../../../../../../components/common/Icon';
import Text from '../../../../../../components/common/Text';

const LogicToken = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 1.5rem;
  left: 50%;
  margin-left: -1.5rem;
  width: 3rem;
  z-index: 1;
  border-radius: ${radii[2]};

  transition: box-shadow ${transitions.speed.fast}
    ${transitions.type.inOut};

  &:hover {
    box-shadow: ${shadows[2]};
  }

  ${({ isOr }) =>
    (isOr &&
      css`
        bottom: -0.875rem;
        background-color: ${colors.primary[1]};
        border: 1px solid ${colors.primary[4]};
      `) ||
    css`
      bottom: -0.75rem;
      background-color: ${colors.success[1]};
      border: 1px solid ${colors.success[4]};
    `}
`;

const Handle = styled(Flex)`
  cursor: move;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${space[2]};
  padding-right: ${space[2]};
  background: ${colors.gray[0]};
`;

const ValueToken = styled(Text)`
  font-family: monospace;
  display: inline;
`;

const ListItem = styled(Flex)`
  position: relative;
  padding-right: ${space[3]};
  border-bottom: ${borders[2]};
  border-left: ${borders[2]};
  border-right: ${borders[2]};
  background-color: ${colors.white};

  ${({ $firstInGroup }) =>
    $firstInGroup &&
    css`
      border-top: ${borders[2]};
      border-top-left-radius: ${radii[2]};
      border-top-right-radius: ${radii[2]};

      & ${Handle} {
        border-top-left-radius: ${radii[2]};
      }
    `}

  ${({ $lastInGroup }) =>
    $lastInGroup &&
    css`
      border-bottom-left-radius: ${radii[2]};
      border-bottom-right-radius: ${radii[2]};
      margin-bottom: ${space[1]};

      & ${Handle} {
        border-bottom-left-radius: ${radii[2]};
      }

      &:last-of-type ${LogicToken} {
        display: none;
        visibility: hidden;
      }
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${colors.primary[1]};
    `}

  ${({ $isHovering }) =>
    $isHovering &&
    css`
      background-color: ${colors.primary[0]};
    `}
`;

const ConnectedListItem = ({
  item,
  index,
  isSelected,
  onMoveComplete,
  onToggleSelect,
  onToggleConnector,
  onDelete,
  firstInGroup,
  lastInGroup,
}) => {
  const ref = useRef();

  const handleToggleSelect = useCallback(() => onToggleSelect(item), [
    item,
    onToggleSelect,
  ]);
  const handleToggleConnector = useCallback(() => onToggleConnector(item), [
    item,
    onToggleConnector,
  ]);
  const handleDelete = useCallback(() => onDelete && onDelete(item), [
    item,
    onDelete,
  ]);

  const [{ isHovering }, drop] = useDrop({
    accept: `${EK.SIMPLIFIED_REP_RULESET_RULES.state}`,
    collect: (monitor) => ({ isHovering: monitor.isOver() }),
    drop: (_) => ({ id: item.id, index }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: `${EK.SIMPLIFIED_REP_RULESET_RULES.state}`,
      id: item.id,
      index,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        const drop = monitor.getDropResult();
        onMoveComplete(item, drop.index);
      }
    },
  });

  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      $isHovering={!isDragging && isHovering}
      $isSelected={isSelected}
      flexDirection="row"
      $firstInGroup={firstInGroup}
      $lastInGroup={lastInGroup}
    >
      {onMoveComplete && !isSelected && (
        <Handle pl={2} pr={2} bg="gray.1">
          <Icon color="gray.6" name="menu" />
        </Handle>
      )}
      <Flex
        flexDirection="column"
        justifyContent="center"
        onClick={handleToggleSelect}
        cursor="pointer"
        pl={3}
        pr={3}
      >
        <Icon
          fontSize={6}
          color="primary.4"
          name={isSelected ? 'chevron-left' : 'chevron-right'}
        />
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        style={{ flexGrow: 1 }}
        onClick={handleToggleSelect}
        cursor="pointer"
        pl={2}
        pt={3}
        pb={3}
      >
        <Text
          fontSize={2}
          color={isSelected ? 'primary.4' : 'gray.7'}
          fontWeight={isSelected ? '600' : '400'}
        >
          {item.primary || item.primaryPlaceholder}
        </Text>
        {(item.secondary && (
          <Box>
            {item.secondary.map((value, index) => (
              <ValueToken
                key={index}
                as="span"
                fontSize={1}
                color={isSelected ? 'primary.3' : 'gray.6'}
                fontWeight={item.secondary.size === index + 1 ? '600' : '400'}
              >
                {value}
                {'  '}
              </ValueToken>
            ))}
          </Box>
        )) || (
          <Text
            fontSize={1}
            color={isSelected ? 'primary.3' : 'gray.6'}
            fontWeight={isSelected ? '600' : '400'}
          >
            {item.secondaryPlaceholder}
          </Text>
        )}
      </Flex>
      {onDelete && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            transparent
            subtle
            error
            icon="delete"
            onClick={handleDelete}
          />
        </Flex>
      )}
      <LogicToken isOr={item.isOrLogic} onClick={handleToggleConnector}>
        <Text color="gray.6" fontSize={1} fontWeight={600}>
          {item.isOrLogic ? 'OR' : 'AND'}
        </Text>
      </LogicToken>
    </ListItem>
  );
};

export default ConnectedListItem;
