import React from 'react';

import theme from '../../../../assets/themes/theme';

const AboutUsGraphic = ({
  id = 'about-us-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <g>
      <circle fill={backgroundColor} cx="64" cy="64" r="63.5" />
      <path
        fill={theme.colors.gray[0]}
        d="M102.5,93.8H23.6c-1.6,0-2.8-1.3-2.8-2.8V35.7c0-1.6,1.3-2.8,2.8-2.8h78.9c1.6,0,2.8,1.3,2.8,2.8V91
		C105.3,92.5,104.1,93.8,102.5,93.8z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M93.6,88.9H32.5c-0.5,0-0.9-0.4-0.9-0.9c0-2.7-2.2-4.9-4.9-4.9c-0.5,0-0.9-0.4-0.9-0.9V44.5
		c0-0.5,0.4-0.9,0.9-0.9c2.7,0,4.9-2.2,4.9-4.9c0-0.5,0.4-0.9,0.9-0.9h61.1c0.5,0,0.9,0.4,0.9,0.9c0,2.7,2.2,4.9,4.9,4.9
		c0.5,0,0.9,0.4,0.9,0.9v37.6c0,0.5-0.4,0.9-0.9,0.9c-2.7,0-4.9,2.2-4.9,4.9C94.5,88.4,94.1,88.9,93.6,88.9z M33.3,87h59.4
		c0.4-3,2.8-5.4,5.8-5.8V45.4c-3-0.4-5.4-2.8-5.8-5.8H33.3c-0.4,3-2.8,5.4-5.8,5.8v35.9C30.6,81.7,32.9,84,33.3,87z"
      />
      <rect x="47" y="44.3" fill={backgroundColor} width="32" height="4.4" />
      <rect
        x="34.3"
        y="53.3"
        fill={theme.colors.gray[4]}
        width="54.5"
        height="2.8"
      />
      <rect
        x="34.3"
        y="59.1"
        fill={theme.colors.gray[4]}
        width="54.5"
        height="2.8"
      />
      <rect
        x="41.5"
        y="65"
        fill={theme.colors.gray[4]}
        width="40.1"
        height="2.8"
      />
      <polygon
        fill={theme.colors.gray[7]}
        points="75.2,82.1 80,84.5 68.3,107.9 65.3,102 58.8,103.1 70.5,79.7 	"
      />
      <polygon
        fill={theme.colors.gray[7]}
        points="78,85.2 73.2,87.6 84.9,111 87.9,105.2 94.4,106.3 82.7,82.9 	"
      />
      <path
        fill={theme.colors.error[4]}
        d="M94.9,76.7L94.9,76.7c1.1-1.1,1.1-2.9,0-4l0,0c-0.8-0.8-1.1-2-0.6-3.1l0,0c0.6-1.5-0.1-3.1-1.5-3.7l0,0
		c-1.1-0.4-1.8-1.5-1.8-2.6v0c0-1.6-1.3-2.9-2.9-2.9h0c-1.2,0-2.2-0.7-2.6-1.8l0,0c-0.6-1.5-2.3-2.1-3.7-1.5l0,0
		c-1.1,0.4-2.3,0.2-3.1-0.6l0,0c-1.1-1.1-2.9-1.1-4,0l0,0c-0.8,0.8-2,1.1-3.1,0.6l0,0c-1.5-0.6-3.1,0.1-3.7,1.5l0,0
		c-0.4,1.1-1.5,1.8-2.6,1.8h0c-1.6,0-2.9,1.3-2.9,2.9v0c0,1.2-0.7,2.2-1.8,2.6l0,0c-1.5,0.6-2.1,2.3-1.5,3.7l0,0
		c0.4,1.1,0.2,2.3-0.6,3.1l0,0c-1.1,1.1-1.1,2.9,0,4l0,0c0.8,0.8,1.1,2,0.6,3.1l0,0c-0.6,1.5,0.1,3.1,1.5,3.7l0,0
		c1.1,0.4,1.8,1.5,1.8,2.6v0c0,1.6,1.3,2.9,2.9,2.9h0c1.2,0,2.2,0.7,2.6,1.8l0,0c0.6,1.5,2.3,2.1,3.7,1.5l0,0
		c1.1-0.4,2.3-0.2,3.1,0.6l0,0c1.1,1.1,2.9,1.1,4,0l0,0c0.8-0.8,2-1.1,3.1-0.6l0,0c1.5,0.6,3.1-0.1,3.7-1.5l0,0
		c0.4-1.1,1.5-1.8,2.6-1.8h0c1.6,0,2.9-1.3,2.9-2.9v0c0-1.2,0.7-2.2,1.8-2.6l0,0c1.5-0.6,2.1-2.3,1.5-3.7l0,0
		C93.8,78.8,94.1,77.5,94.9,76.7z"
      />
      <circle fill={theme.colors.gray[0]} cx="76.6" cy="74.7" r="13.3" />
      <circle fill={theme.colors.error[4]} cx="76.6" cy="74.7" r="11.7" />
    </g>
  </svg>
);

export default AboutUsGraphic;
