import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveConfigProfileMapkeysTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.CONFIG_PROFILE_MAPKEYS);

export const processSaveConfigProfileMapkeys = (profileId, data) => client(REQUEST_TYPES.PATCH, 'plural', saveConfigProfileMapkeysTypes, `/synchronize/config_profiles/${profileId}/mapkeys/collection?diff_result=true`, {
  data: { [EK.CONFIG_PROFILE_MAPKEYS.collection]: data },
  mutateResponse: ({ [EK.CONFIG_PROFILES.single]: { id } = {}, [EK.CONFIG_PROFILE_MAPKEYS.plural]: { created = [], destroyed = [], updated = [] } = {} }) => ({
    [EK.CONFIG_PROFILE_MAPKEYS.plural]: {
      created: created.map(mapkey => ({
        ...mapkey,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
      destroyed: destroyed.map(mapkey => ({
        ...mapkey,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
      updated: updated.map(mapkey => ({
        ...mapkey,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});