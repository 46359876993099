import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';
import RDXSearchSelect from '../../RDXSearchSelect';
import RDXMultiEntitySearchSelect from '../../RDXMultiEntitySearchSelect';

import { ALLOWANCES } from '../../../../entities/Standardize/ModelTemplates/model';
import { validations } from '../../../../entities/Standardize/ModelTemplates/formValidation';

import {
  CAD_MODEL_TYPES,
  CAD_MODEL_TYPE_OPTIONS,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
} from '../../../../entities/Standardize/ModelTemplates/model';

const ModelTemplateForm = ({
  handleSubmit,
  isCopying,
  isPerforming,
  valid,
  cadModelType,
}) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="modelTemplateModelName"
          label="Model Name"
          required
          allow={ALLOWANCES.MODEL_TEMPLATE_MODEL_NAME}
          validate={validations['modelTemplateModelName']}
        />
      </BaseFormRow>
      {!isCopying &&
        <BaseFormRow>
          <Field
            component={RDXRadioSelect}
            name="cadModelType"
            label="Model Type"
            options={CAD_MODEL_TYPE_OPTIONS}
            required
          />
        </BaseFormRow>
      }
      {cadModelType === CAD_MODEL_TYPES.ASSEMBLY && !isCopying && (
        <BaseFormRow>
          <Field
            component={RDXRadioSelect}
            name="cadModelAssemblySubtype"
            label="Assembly Type"
            options={CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS}
            required
          />
        </BaseFormRow>
      )}
      {cadModelType === CAD_MODEL_TYPES.PART && !isCopying && (
        <BaseFormRow>
          <Field
            component={RDXRadioSelect}
            name="cadModelPartSubtype"
            label="Part Type"
            options={CAD_MODEL_PART_SUBTYPE_OPTIONS}
            required
          />
        </BaseFormRow>
      )}
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="modelParameterProfile"
          label="Model Parameter Profile"
          ek={EK.PARAMETER_PROFILES}
          clearable
          width={cadModelType === CAD_MODEL_TYPES.DRAWING ? '100%' : '50%'}
        />
        {cadModelType !== CAD_MODEL_TYPES.DRAWING && (
          <Field
            component={RDXSearchSelect}
            name="componentParameterProfile"
            label="Component Parameter Profile"
            ek={EK.PARAMETER_PROFILES}
            clearable
            width="50%"
          />
        )}
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="noteProfile"
          label="Note Profile"
          ek={EK.NOTE_PROFILES}
          clearable
        />
      </BaseFormRow>
      {cadModelType !== CAD_MODEL_TYPES.DRAWING && (
        <BaseFormRow>
          <Field
            component={RDXMultiEntitySearchSelect}
            name="orientations"
            label="Orientations"
            ek={EK.ORIENTATIONS}
            clearable
          />
        </BaseFormRow>
      )}
      {cadModelType !== CAD_MODEL_TYPES.DRAWING && (
        <BaseFormRow>
          <Field
            component={RDXMultiEntitySearchSelect}
            name="layers"
            label="Layers"
            ek={EK.LAYERS}
            clearable
          />
        </BaseFormRow>
      )}
      {cadModelType !== CAD_MODEL_TYPES.DRAWING && (
        <BaseFormRow>
          <Field
            component={RDXMultiEntitySearchSelect}
            name="sections"
            label="Sections"
            ek={EK.SECTIONS}
            clearable
          />
        </BaseFormRow>
      )}
      {cadModelType === CAD_MODEL_TYPES.ASSEMBLY && (
        <BaseFormRow>
          <Field
            component={RDXMultiEntitySearchSelect}
            name="simplifiedReps"
            label="Simplified Reps"
            ek={EK.SIMPLIFIED_REPS}
            clearable
          />
        </BaseFormRow>
      )}
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      { isCopying ? 'Copy' : 'Save' }
    </Button>
  </Box>
);

const modelTemplateFormSelector = formValueSelector(
  EK.MODEL_TEMPLATES.state
);

const mapStateToProps = createSelector(
  (state) => modelTemplateFormSelector(state, 'cadModelType'),
  (cadModelType) => ({ cadModelType })
);

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: EK.MODEL_TEMPLATES.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(ModelTemplateForm);
