import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import selectEntities from '../../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectNormalizedSpecs } from '../../../entities/Piping/Specs/selectors';
import SpecSchema from '../../../entities/Piping/Specs/schema';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

const specFilterForm = formValueSelector(`filters.${EK.SPECS.state}`);

export const selectCurrentFilteredDraftSpecs = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectEntities(),
  selectNormalizedSpecs(),
  state => specFilterForm(state, 'specName', 'mnemonic'),
  (isFiltering, isShowingArchived, entities, specs, query) => denormalize(
    isFiltering ?
      specs.filter(spec => (isShowingArchived || !spec.archivedFlag) && spec.doesMatchQuery(query)) :
      specs.filter(spec => !spec.archivedFlag),
    [SpecSchema],
    entities.delete(EK.SPEC_PIPE_INSTANCES.state).delete(EK.SPEC_FITTING_INSTANCES.state)
  )
);