import React from 'react';

import Text from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Button from '../../../components/common/Button';

import colors from '../../../assets/themes/base/colors';

// validation
export function areFieldsValid({ specName, appearance}) {
  const _VALIDATION_FIELDS = {
    specName: !!specName,
    appearance: !!appearance
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ specName, appearance }) {
  // takes the VALIDATION_FIELDS type, so all values are booleans
  return !!specName && !!appearance;
}

// Cell Bodies
export const nameBody = (rowdata, onClickAction) => {

  return <div onClick={() => onClickAction(rowdata.id)} style={{ cursor: 'pointer' }} ><ComplexCellRenderer showSecondary={false} value={{ ...rowdata.summary, to: true }} placeholder='' isCapitalized={true} paddingLeft='0px' /></div>;
};

export const descriptionBody = (rowdata) => {
  return rowdata.description && <Text>{rowdata.description}</Text> || <Text style={{ color: colors.gray[5] }}>No Description</Text>;
};

// Misc. Dialog Components
export const dialogFooter = (isCreateDialog, isCopyDialog, deleteAction, saveAction, cancelAction) => (
  <>
    {!isCreateDialog && !isCopyDialog && <Button error={true} onClick={deleteAction} style={{ float: 'left' }} >Delete</Button>}
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    {!isCopyDialog ?
      <Button primary={true} onClick={saveAction} >Save</Button> :
      <Button primary={true} onClick={saveAction} >Copy</Button>}
  </>
);

export const deleteDialogFooter = (cancelAction, deleteAction, canDelete) => (
  <>
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    {canDelete && <Button error={true} onClick={deleteAction} >Delete</Button>}
  </>
);