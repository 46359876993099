import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'COMBINED_STATES';

export const fetchAllCombinedStateTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createCombinedStateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyCombinedStateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editCombinedStateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteCombinedStateTypes = generateAPHTypes(
  resource,
  'DELETE'
);