import { takeLatest, put, fork } from 'redux-saga/effects';

import { processFetchCurrentUser } from '../../modules/auth/actions';

import { createOrganizationTypes } from '../../entities/Organizations/constants';

export function* handleCreateOrganizationSuccess({ payload = {} }) {
  yield put(processFetchCurrentUser());
}

export function* watchCreateOrganizationSaga() {
  yield takeLatest(createOrganizationTypes.SUCCESS, handleCreateOrganizationSuccess);
}

// final output saga
export default function* main() {
  yield fork(watchCreateOrganizationSaga);
}
