import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectProjectsList, selectNormalizedProjects } from '../../../entities/Piping/Projects/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

import { PipingModuleProjectsEditPath } from '../../../paths';

const projectFilterForm = formValueSelector(`filters.${EK.PROJECTS.state}`);

export const selectCurrentFilteredProjects = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectProjectsList(),
  state => projectFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, projects, name) => isFiltering ?
    projects.filter(project => (isShowingArchived || !project.archivedFlag) && project.doesMatchQuery({ name })):
    projects.filter(project => !project.archivedFlag)
);