import React from 'react';
import styled from 'styled-components';

import Flex from '../../../common/Flex';
import Icon from '../../../common/Icon';

import BaseAppend from '../../BaseAppend';
import { DefaultOptionTemplate } from '../';

const CreatedValue = styled(Flex).attrs(props => ({
  $ellipsis: true,
  flexDirection: props.flexDirection || 'column',
  justifyContent: props.justifyContent || 'center',
}))`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const renderCreatedValueForList = ({ item, isSelected, onDelete }) => (
  <Flex flexDirection='row' justifyContent='center' alignItems='center'>
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%' mr={3}>
      <Icon name={isSelected ? 'checkbox' : 'checkbox-outline'} color={isSelected ? 'primary.4' : 'gray.6'} />
    </Flex>

    <CreatedValue>
      <DefaultOptionTemplate item={item} />
    </CreatedValue>

    <BaseAppend onClick={onDelete}>
      <Icon name='close' />
    </BaseAppend>
  </Flex>
);

export default renderCreatedValueForList;
