import { createSelector } from 'reselect';

import selectStatus from '../status/selectors';
import { selectNormalizedCurrentUser } from '../../entities/CurrentUser/selectors';

export const selectAuth = () => state => state.get('auth');
export const selectIsLoggingIn = () => createSelector(
  selectAuth(),
  selectStatus(),
  selectNormalizedCurrentUser(),
  (auth, status, currentUser) => auth.get('isLoggingIn') || (!currentUser && !!status.get('isConsolidatingFetch'))
);
export const selectIsAuthError = () => createSelector(
  selectAuth(),
  auth => auth.get('isAuthError')
);
export const selectIsAuthSuccess = () => createSelector(
  selectAuth(),
  auth => auth.get('isAuthSuccess')
);
export const selectPasswordResetRequestSubmitted = () => createSelector(
  selectAuth(),
  auth => auth.get('passwordResetRequestSubmitted')
);
export const selectPasswordResetError = () => createSelector(
  selectAuth(),
  auth => auth.get('passwordResetError')
);
