import React, { useState } from 'react';

const ToggleOff = ({ size = '1em', style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 37" style={style} >
      <path d="M11.542 29.167q-3.792 0-6.48-2.667-2.687-2.667-2.687-6.5t2.687-6.5q2.688-2.667 6.48-2.667h16.916q3.792 0 6.48 2.667 2.687 2.667 2.687 6.5t-2.687 6.5q-2.688 2.667-6.48 2.667Zm.041-2.125h16.875q2.875 0 4.938-2.063 2.062-2.062 2.062-4.979 0-2.917-2.062-4.979-2.063-2.063-4.938-2.063H11.583q-2.958 0-5 2.063Q4.542 17.083 4.542 20q0 2.917 2.041 4.979 2.042 2.063 5 2.063Zm-.041-2.417q1.958 0 3.312-1.333 1.354-1.334 1.354-3.292 0-1.958-1.354-3.292-1.354-1.333-3.312-1.333-1.917 0-3.292 1.333Q6.875 18.042 6.875 20q0 1.958 1.375 3.292 1.375 1.333 3.292 1.333ZM20 20Z" />
    </svg>
  );
};

const ToggleOn = ({ size = '1em', style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 37" style={style} >
      <path d="M11.542 29.167q-3.792 0-6.48-2.667-2.687-2.667-2.687-6.5t2.687-6.5q2.688-2.667 6.48-2.667h16.916q3.792 0 6.48 2.667 2.687 2.667 2.687 6.5t-2.687 6.5q-2.688 2.667-6.48 2.667Zm16.916-4.542q1.917 0 3.292-1.333 1.375-1.334 1.375-3.292 0-1.958-1.375-3.292-1.375-1.333-3.292-1.333-1.958 0-3.312 1.333-1.354 1.334-1.354 3.292 0 1.958 1.354 3.292 1.354 1.333 3.312 1.333Z" />
    </svg>
  );
};

export default function ToggleSwitch({ size, style, value }) {
  
  return (value ? 
    (<ToggleOn size={size} style={style} />) : 
    (<ToggleOff size={size} style={style} />)
  );
}