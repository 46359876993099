import { useEffect, useState } from 'react';

const useIsOverflowing = (ref, { height = false, width = true } = {}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      setIsOverflowing(false);
    } else {
      const el = ref.current;

      if (width || height) {
        if (width && height) {
          setIsOverflowing(el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight);
        } else if (width) {
          setIsOverflowing(el.scrollWidth > el.clientWidth);
        } else if (height) {
          setIsOverflowing(el.scrollHeight > el.clientHeight);
        } else {
          setIsOverflowing(false);
        }
      } else {
        setIsOverflowing(false);
      }
    }
  }, [ref]);

  return isOverflowing;
};

export default useIsOverflowing;