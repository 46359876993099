import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModulePipesBendsEditPath } from '../../../paths';
import { selectCurrentBendFamily } from '../../../entities/Piping/BendFamilies/selectors';

function BendEditBread ({ location }) {
  const match = matchPath(location.pathname, { path: PipingModulePipesBendsEditPath.url });
  const bendId = (match && match.params.familyId) || null;
  const bendFamily = useSelector(selectCurrentBendFamily(bendId));

  return <BreadcrumbTemplate title={(bendFamily && bendFamily.name) || PipingModulePipesBendsEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);
