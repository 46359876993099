import React from 'react';

export default function FitValveIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <polygon points="14.5,14 13.5,14 13.5,6.5 9.96,6.5 9.96,5.5 18,5.5 18,6.5 14.5,6.5 " />
      <path d="M5.02,23.4V11.627l9.963,5.57L5.02,23.4z M6.02,13.333V21.6l6.997-4.355L6.02,13.333z" />
      <path d="M23,23.399l-9.985-6.202L23,11.628V23.399z M14.985,17.244L22,21.601v-8.269L14.985,17.244z" />
      <path d="M14.04,20.54c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S15.97,20.54,14.04,20.54z M14.04,14.54c-1.378,0-2.5,1.121-2.5,2.5s1.122,2.5,2.5,2.5c1.379,0,2.5-1.121,2.5-2.5S15.419,14.54,14.04,14.54z" />
    </svg>
  );
}
