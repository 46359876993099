import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import fontSizes from '../../../assets/themes/base/fontSizes';
import transitions from '../../../assets/themes/base/transitions';
import space from '../../../assets/themes/base/space';

import Flex from '../../common/Flex';

const StyledLabel = styled.label`
  padding: ${space[2]} ${space[4]};
  display: inline-flex;
  cursor: pointer;
  transition: background 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: 2px solid transparent;
  border-radius: ${radii[2]};
  margin-right: 0;
  color: ${colors.gray[5]};
  &:last-child {
    margin-right: 0;
  }

  font-size: ${fontSizes[1]};
  background: ${colors.white};

  transition: border-color ${transitions.speed.fast}, color ${transitions.speed.fast};

  ${({ checked }) => checked && css`
    border-color: ${colors.primary[6]};
  `}

  ${({ checked, disabled }) => checked && disabled && css`
    border-color: ${colors.gray[2]};
  `}

  ${({ checked }) => checked && css`
    color: ${colors.gray[7]};
  `}

  ${({ disabled }) => disabled && css`
    background: ${colors.gray[0]};
    color: ${colors.gray[4]};
  `}

  &:hover,
  &:focus-within {
    ${({ disabled }) =>
    (disabled &&
        css`
          cursor: default;
        `) ||
      css`
        cursor: pointer;
      `
}
  }
`;

export const Container = styled(Flex)`
  flex-direction: row;
  border-radius: ${radii[2]};
  overflow: hidden;
  border: 2px solid ${colors.gray[0]};
`;

const BaseRadioButton = React.forwardRef(
  ({ label, checked, disabled, children, ...props }, ref) => {
    return (
      <StyledLabel checked={checked} disabled={disabled} >
        <input
          ref={ref}
          type="radio"
          disabled={disabled}
          {...props}
          checked={checked}
          tabIndex="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
        { children }
      </StyledLabel>
    );
  }
);

export default BaseRadioButton;
