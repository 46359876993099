import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleInsulationSpecsPath } from '../../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModuleInsulationSpecsPath.defaultTitle}></BreadcrumbTemplate>
  );
}
