import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_PARAMETERS';

export const fetchAllParameterTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createParameterTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyParameterTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editParameterTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteParameterTypes = generateAPHTypes(
  resource,
  'DELETE'
);