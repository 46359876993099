import styled, { css } from 'styled-components';
import { space, fontSize, fontWeight, color, lineHeight, letterSpacing, textAlign } from 'styled-system';
import colors from '../../../assets/themes/base/colors';
import transitions from '../../../assets/themes/base/transitions';

const Text = styled.div`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${lineHeight}
  ${letterSpacing}
  ${textAlign}

  ${
  ({ href, link }) => (href || link) && css`
      text-decoration: none;

      transition: color ${transitions.speed.fast};

      color: ${colors.gray[5]};

      &:link,
      &:visited {
        color: ${colors.gray[5]};
      }

      &:hover,
      &:active {
        color: ${colors.gray[6]};
      }

      ${
  ({ $primary }) => $primary && css`
          color: ${colors.primary[4]};

          &:link,
          &:visited {
            color: ${colors.primary[4]};
          }

          &:hover,
          &:active {
            color: ${colors.primary[5]};
          }
        `
}
    `
}

  ${
  ({ $ellipsis }) => $ellipsis && css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
}
`;

export default Text;
