import React from 'react';

import theme from '../../../../assets/themes/theme';

const LogOutGraphic = ({
  id = 'log-out-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <circle fill={backgroundColor} cx="256" cy="256" r="251" />
      <g>
        <path
          fill={theme.colors.gray[7]}
          d="M375.2,412.9H136.8V99.1h238.4V412.9z M155.3,394.4h201.5V117.6H155.3V394.4z"
        />
        <polygon
          fill={theme.colors.gray[0]}
          points="278.8,378.3 155.3,394.4 155.3,117.6 278.8,133.7 		"
        />
        <circle fill={theme.colors.gray[4]} cx="256" cy="256" r="9.4" />
      </g>
    </g>
  </svg>
);

export default LogOutGraphic;
