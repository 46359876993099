import React, { useCallback, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';
import styled, { css } from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import space from '../../../../assets/themes/base/space';
import fontSizes from '../../../../assets/themes/base/fontSizes';

import {
  submit,
  isValid,
  isDirty,
} from 'redux-form/immutable';
import useBoundingclientrectRef from '@rooks/use-boundingclientrect-ref';

import useStateWithCallback from '../../../../hooks/useStateWithCallback';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Label from '../../../../components/common/Label';
import Loading from '../../../../components/common/Loading';
import Tabs from '../../../../components/common/Tabs';
import Text from '../../../../components/common/Text';
import Typography from '../../../../components/common/Typography';

import FixedItemSizeList from '../../../../components/common/FixedItemSizeList';

import OrganizationSettingsForm from '../../../form/templates/OrganizationSettingsForm';
import ModuleLabel, { PRODUCT_MODULES } from '../../../../entities/Organizations/components/ModuleLabel';

import UserStatusIndicator from '../../../../modules/permissions/components/UserStatusIndicator';

import {
  selectIsFetchingInModal,
  selectIsPerformingInModal,
} from '../../../../modules/utility/selectors';

import { showModal } from '../../../../modules/modal/actions';

import { USER_ROLE_TYPES } from '../../../../modules/permissions/constants';

import {
  SETTING_PRODUCTS,
  SETTING_PRODUCTS_LIST,
} from '../../../../entities/Settings/model';
import { selectNormalizedSettings } from '../../../../entities/Settings/selectors';
import { processSaveSettings } from '../../../../entities/Settings/actions';

import {
  selectCurrentUser,
  selectCurrentOrganization,
} from '../../../../entities/CurrentUser/selectors';

import {
  processDeleteOrganizationUserInvite,
  processResendOrganizationUserInvite,
} from '../../../../entities/OrganizationUserInvites/actions';

import {
  selectOrganizationDirectory,
  selectIsCurrentlyActiveModal,
} from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const Modal = styled(Flex)`
  width: 80%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[1]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const OrganizationDetails = styled(Box)`
  flex-grow: 1;
`;

const Header = styled(Flex)`
  display: flex;
  flex-direction: row;
  margin-top: ${space[6]};
  padding: ${space[4]} ${space[6]};
  align-items: center;
  background: ${colors.gray[1]};
`;

const BaseHeaderButtonGroup = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
`;

const HeaderButton = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${space[2]} ${space[6]};
  margin-left: ${space[4]};
  background: transparent;
  border: 1px solid transparent;
  color: ${colors.gray[7]};
  font-size: ${fontSizes[2]};
  border-radius: ${radii[2]};
  cursor: pointer;
  font-weight: 600;

  transition: 0.2s background, 0.2s border-color, 0.2s color;

  &:hover {
    color: ${colors.primary[4]};
  }

  ${({ active }) => active && css`
    background: ${colors.primary[0]};
    border-color: ${colors.primary[4]};
    color: ${colors.primary[4]};

    &:hover {
      background: ${colors.primary[0]};
      border-color: ${colors.primary[4]};
    }
  `}
`;

const HeaderButtonGroup = ({ onClick, options, active }) => (
  <BaseHeaderButtonGroup>
    {options.map(({ value, label }) => <HeaderButton active={active === value} key={value} onClick={() => onClick(value)}>{label}</HeaderButton>)}
  </BaseHeaderButtonGroup>
);

const HeaderText = styled(Box)`
  flex-grow: 1;
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;

  border-top-left-radius: ${radii[2]};
  background: ${colors.white};
`;

const DynamicContentContainer = styled(Box)`
  flex-grow: 1;
`;

const DynamicContent = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ContentHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;

  padding: ${space[4]} ${space[6]};
  border-bottom: 1px solid ${colors.gray[0]};
`;

const ContentHeaderDescription = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ContentHeaderAction = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 30%;
`;

const TabsHeader = styled(Box)`
  padding: 0 ${space[4]};
  border-bottom: 1px solid ${colors.gray[0]};
`;

const AccountHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[0]};
  padding: ${space[2]} ${space[6]};
`;

const AccountHeaderColumn = styled(Box)`
  ${({ expand }) => expand && css`
    width: calc( 100% - 47rem );
    min-width: fit-content;
  `}
  font-size: ${fontSizes[0]};
  font-weight: 600;
  color: ${colors.gray[6]};
  text-transform: uppercase;
`;

const AccountContainer = styled(Flex)`
  flex-direction: row;
  width: 100%;
  height: 4rem;
  padding: 0 ${space[6]};
  background: transparent;
  border-bottom: 1px solid ${colors.gray[0]};
  transition: 0.2s background;

  &:hover {
    background: ${colors.primary[0]};
  }
`;


const AccountDetails = styled(Flex)`
  flex-direction: row;
  align-items: center;
  width: calc( 100% - 47rem );
  min-width: fit-content;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`;

const Account = ({ style, account, isOwner, onEdit, onResend, onDelete }) => {
  const isNamePresent = account.isNamePresent;
  const accountIsInactive = account.activeFlag === false;

  const onHandleEdit = useCallback(() => { onEdit && onEdit(account); }, [account, onEdit]);
  const onHandleResend = useCallback(() => { onResend && onResend(account); }, [account, onResend]);
  const onHandleDelete = useCallback(() => { onDelete && onDelete(account); }, [account, onDelete]);

  return (
    <AccountContainer style={style}>
      <AccountDetails onClick={!account.isPending && !!onEdit && onHandleEdit || undefined} >
        <Flex flexDirection='column' justifyContent='center' mr={4}>
          <Text color='gray.7' fontSize={2} fontWeight='600'>{isNamePresent ? account.fullName : account.email}</Text>
          {isNamePresent && <Text color='gray.6' fontWeight='400' fontSize={1}>{account.email}</Text> || null}
        </Flex>
        {isOwner && <Label primary>Owner</Label>}
      </AccountDetails>
      <Flex width='10rem' flexDirection='row' alignItems='center' justifyContent='flex-start'>
        {
          accountIsInactive ? (
            <React.Fragment><UserStatusIndicator inactive /><Text color='gray.6' fontSize={1} fontWeight='500'>Disabled</Text></React.Fragment>
          ) : (
            (account.isPending && <React.Fragment><UserStatusIndicator pending /><Text color='success.4' fontSize={1} fontWeight='500'>Sent</Text></React.Fragment>) ||
            (account.role === USER_ROLE_TYPES.ADMIN && <React.Fragment><Text color='gray.7' fontSize={1} fontWeight='500'>Admin</Text></React.Fragment>) ||
            (account.role === USER_ROLE_TYPES.COLLABORATOR && <React.Fragment><Text color='gray.7' fontSize={1} fontWeight='500'>Collaborator</Text></React.Fragment>) ||
            (account.role === USER_ROLE_TYPES.USER && <React.Fragment><Text color='gray.7' fontSize={1} fontWeight='500'>User</Text></React.Fragment>)
          )
        }
      </Flex>
      <Flex width='31rem' flexDirection='row' alignItems='center' justifyContent='flex-start'>
        <ModuleLabel module={PRODUCT_MODULES.PIPING} disabled={!account.hasModulePiping} mr='0.5rem' />
        <ModuleLabel module={PRODUCT_MODULES.CABLING} disabled={!account.hasModuleCabling} mr='0.5rem' />
        <ModuleLabel module={PRODUCT_MODULES.FRAMEWORK} disabled={!account.hasModuleFramework} mr='0.5rem' />
        <ModuleLabel module={PRODUCT_MODULES.ECAD} disabled={!account.hasModuleEcad} mr='0.5rem' />
        <ModuleLabel module={PRODUCT_MODULES.REPORTING} disabled={!account.hasModuleReporting} />
      </Flex>
      <Flex width='6rem' flexDirection='row' alignItems='center' justifyContent='flex-end'>
        {!account.isPending && onEdit && <Button onClick={onHandleEdit} transparent subtle primary icon='edit' />}
        {onResend && <Button onClick={onHandleResend} transparent subtle icon='mail-outline' />}
        {onDelete && <Button onClick={onHandleDelete} transparent subtle error icon='delete' />}
      </Flex>
    </AccountContainer>
  );
};

const createSelectInitialSettingsSelector = () => createSelector(
  selectNormalizedSettings(),
  (_, productTier) => productTier,
  (settings, productTier = null,) => Map({ settings: settings.filter(setting => setting.productTier === productTier) }),
);

export const ORGANIZATION_SECTIONS = {
  DIRECTORY: 'directory',
  SETTINGS: 'settings',
};

const ORGANIZATION_SECTIONS_OPTIONS = List([
  {
    value: ORGANIZATION_SECTIONS.DIRECTORY,
    label: 'Directory',
  },
  {
    value: ORGANIZATION_SECTIONS.SETTINGS,
    label: 'Settings',
  },
]);

const SETTINGS_FORM_KEY = EK.SETTINGS.state;

const OrganizationModal = ({
  isFetching,
  isPerforming,
  currentUser,
  organization,
  directory = {},
  productTier: initialProductTier,
  section: initialSection,
  onCreateUser,
  onCreateInvite,
  onEditUser,
  onDeleteInvite,
  onResendInvite,
  isCurrentlyActiveModal,
  isSettingsDirty,
  isSettingsValid,
  submitSettings,
  onSubmitSettings,
  onEditMasterCatalogUnitSystem,
}) => {
  const { accounts, invitePipingModuleCount, activeUserCount, activeUserModulePipingCount, activeUserModuleCablingCount, activeUserModuleFrameworkCount, activeUserModuleReportingCount, activeUserModuleEcadCount } = directory;
  const isAdmin = currentUser.isAdmin;
  const orgOwner = organization.owner;

  const selectInitialSettings = useMemo(
    createSelectInitialSettingsSelector,
    []
  );

  const [dynamicContentRef, dynamicContentRect, updateContentRectHeight] = useBoundingclientrectRef();

  useEffect(() => {
    if (isCurrentlyActiveModal) {
      updateContentRectHeight();
    }
  }, [isCurrentlyActiveModal, updateContentRectHeight]);

  const [section, setSection] = useStateWithCallback(initialSection || ORGANIZATION_SECTIONS.DIRECTORY, updateContentRectHeight);
  const [productTier, setProductTier] = useStateWithCallback(initialProductTier || SETTING_PRODUCTS.SYNCHRONIZE, updateContentRectHeight);

  const initialSettings = useSelector(state => selectInitialSettings(state, productTier));

  const onDelete = useCallback(invite => {
    onDeleteInvite(organization.id, invite.id);
  }, [organization, onDeleteInvite]);

  const onResend = useCallback(invite => {
    onResendInvite(organization.id, invite.id);
  }, [organization, onResendInvite]);

  return isFetching ? <Loading /> : (
    <Modal style={{width: '95%'}} >
      <Flex flexDirection='column' width='20%' height='100%' p={6}>
        <OrganizationDetails>
          <Typography.F1 label='Organization'>{organization.name}</Typography.F1>
          <Typography.F2 label='Plan' sub={'Renewal: ' + organization.productExpirationFormatted} capitalize>{organization.productTier}</Typography.F2>
          <Typography.F2 label='Active Users'>
            <Text
              as='span'
              color={organization.productTierUserCountErrorFlag ? 'error.4' : (activeUserCount >= organization.productTierUserCount ? 'gray.7' : 'primary.4')}
            >
              {activeUserCount}
            </Text>/{organization.productTierUserCount}
          </Typography.F2>
          {
            organization.modulePipingUserCount === 0 ? (
              <Typography.F3 label='Piping Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Piping Module Users'>
                <Text
                  as='span'
                  color={organization.modulePipingUserCountErrorFlag ? 'error.4' : (activeUserModulePipingCount >= organization.modulePipingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModulePipingCount}
                </Text>/{organization.modulePipingUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleCablingUserCount === 0 ? (
              <Typography.F3 label='Cabling Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Cabling Module Users'>
                <Text
                  as='span'
                  color={organization.moduleCablingUserCountErrorFlag ? 'error.4' : (activeUserModuleCablingCount >= organization.moduleCablingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleCablingCount}
                </Text>/{organization.moduleCablingUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleFrameworkUserCount === 0 ? (
              <Typography.F3 label='Framework Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Framework Module Users'>
                <Text
                  as='span'
                  color={organization.moduleFrameworkUserCountErrorFlag ? 'error.4' : (activeUserModuleFrameworkCount >= organization.moduleFrameworkUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleFrameworkCount}
                </Text>/{organization.moduleFrameworkUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleEcadUserCount === 0 ? (
              <Typography.F3 label='ECAD Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='ECAD Module Users'>
                <Text
                  as='span'
                  color={organization.moduleEcadUserCountErrorFlag ? 'error.4' : (activeUserModuleEcadCount >= organization.moduleEcadUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleEcadCount}
                </Text>/{organization.moduleEcadUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleReportingUserCount === 0 ? (
              <Typography.F3 label='Reporting Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Reporting Module Users'>
                <Text
                  as='span'
                  color={organization.moduleReportingUserCountErrorFlag ? 'error.4' : (activeUserModuleReportingCount >= organization.moduleReportingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleReportingCount}
                </Text>/{organization.moduleReportingUserCount}
              </Typography.F2>
            )
          }
        </OrganizationDetails>
        <Typography.F3 label='Owner' sub={orgOwner.email} mb={null}>{orgOwner.fullName}</Typography.F3>
      </Flex>
      <Flex flexDirection='column' width='80%' height='100%'>
        <Header>
          <HeaderText>
            <Text
              color='gray.7'
              fontSize={5}
            >
              {section === ORGANIZATION_SECTIONS.SETTINGS ? 'Organization Settings' : 'Accounts & Invites'}
            </Text>
          </HeaderText>
          <HeaderButtonGroup onClick={setSection} options={ORGANIZATION_SECTIONS_OPTIONS} active={section} />
        </Header>
        <Content>
          <ContentHeader>
            <ContentHeaderDescription>
              {
                section === ORGANIZATION_SECTIONS.SETTINGS && (
                  <Text color='gray.7' fontSize={1}>Manage the various settings for your CadActive modules. Please note that these settings here <strong>will affect your entire organization</strong>.</Text>
                ) || (
                  <Text color='gray.7' fontSize={1}>View your organization&apos;s accounts, invite or create new users, and manage user permissions and product privileges. </Text>
                )
              }
            </ContentHeaderDescription>
            <ContentHeaderAction>
              {
                section === ORGANIZATION_SECTIONS.SETTINGS && (
                  <Button primary onClick={submitSettings} disabled={isPerforming}>Save Changes</Button>
                ) || (
                  <Button primary onClick={onCreateInvite}>Invite Users</Button>
                )
              }
            </ContentHeaderAction>
          </ContentHeader>
          {
            organization.hasModulePipingPrivileges &&
            section === ORGANIZATION_SECTIONS.SETTINGS &&
            productTier === SETTING_PRODUCTS.MODULE && (
              <ContentHeader>
                <ContentHeaderDescription>
                  <Text
                    color='gray.7'
                    fontSize={1}
                  >
                    Change your <strong>Piping Catalog Unit System</strong>.
                    This will not change any existing unit systems for your catalog - it will only change the default for new entries.
                  </Text>
                </ContentHeaderDescription>
                <ContentHeaderAction>
                  <Button secondary onClick={onEditMasterCatalogUnitSystem} disabled={isPerforming}>Edit Unit System</Button>
                </ContentHeaderAction>
              </ContentHeader>
            ) || null
          }
          {
            section === ORGANIZATION_SECTIONS.SETTINGS && (
              <TabsHeader>
                <Tabs tabs={SETTING_PRODUCTS_LIST} activeTabId={productTier} onChange={setProductTier} />
              </TabsHeader>
            ) || (
              <AccountHeader>
                <AccountHeaderColumn expand >User</AccountHeaderColumn>
                <AccountHeaderColumn width='10rem'>Role</AccountHeaderColumn>
                <AccountHeaderColumn width='31rem'>Modules</AccountHeaderColumn>
                <AccountHeaderColumn width='6rem'></AccountHeaderColumn>
                {dynamicContentRect && (dynamicContentRect.height < (accounts.size * 64)) && <AccountHeaderColumn width='1rem' />}
              </AccountHeader>
            )
          }
          <DynamicContentContainer ref={dynamicContentRef} />
          <DynamicContent height={dynamicContentRect && dynamicContentRect.height} width={dynamicContentRect && dynamicContentRect.width}>
            {
              dynamicContentRect && dynamicContentRect.height && (
                section === ORGANIZATION_SECTIONS.SETTINGS ? (
                  <OrganizationSettingsForm
                    form={SETTINGS_FORM_KEY}
                    height={dynamicContentRect.height}
                    enableReinitialize
                    initialValues={initialSettings}
                    active={productTier}
                    isPerforming={isPerforming}
                    onRDXSubmit={onSubmitSettings}
                  />
                ) : (
                  <FixedItemSizeList
                    itemCount={accounts.size}
                    height={dynamicContentRect.height}
                    width='100%'
                    itemSize={64}
                  >
                    {
                      ({ index, style }) => {
                        const account = accounts.get(index);

                        return (
                          <Account
                            key={index}
                            index={index}
                            style={style}
                            account={account}
                            isOwner={!account.isPending && account.id === orgOwner.id}
                            onEdit={isAdmin && !account.isPending && onEditUser || null}
                            onDelete={isAdmin && account.isPending && onDelete || null}
                            onResend={isAdmin && account.isPending && onResend || null}
                          />
                        );
                      }
                    }
                  </FixedItemSizeList>
                )
              ) || null
            }
          </DynamicContent>
        </Content>
      </Flex>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetchingInModal(),
  isPerforming: selectIsPerformingInModal(),
  currentUser: selectCurrentUser(),
  organization: selectCurrentOrganization(),
  directory: selectOrganizationDirectory(),
  isCurrentlyActiveModal: selectIsCurrentlyActiveModal(),
  isSettingsValid: isValid(SETTINGS_FORM_KEY),
  isSettingsDirty: isDirty(SETTINGS_FORM_KEY),
});

const mapDispatchToProps = dispatch => ({
  onCreateInvite() { dispatch(showModal('ORGANIZATION_USER_INVITES_MODAL')); },
  onDeleteInvite(organizationId, inviteId) { dispatch(processDeleteOrganizationUserInvite(organizationId, inviteId)); },
  onResendInvite(organizationId, inviteId) { dispatch(processResendOrganizationUserInvite(organizationId, inviteId)); },
  onCreateUser() { dispatch(showModal('CREATE_ORGANIZATION_USER_MODAL')); },
  onEditUser(user) { dispatch(showModal('ORGANIZATION_USER_MODAL', { userId: user.id })); },
  onEditMasterCatalogUnitSystem() { dispatch(showModal('MASTER_CATALOG_UNIT_SYSTEM_MODAL')); },
  submitSettings() { dispatch(submit(SETTINGS_FORM_KEY)); },
  onSubmitSettings(settings) { dispatch(processSaveSettings(null, settings)); },
});

const enhance = compose(
  injectSaga({ key: `${EK.ORGANIZATIONS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(OrganizationModal);
