import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllGlobalCadSearchRulesTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.GLOBAL_CAD_SEARCH_RULES);

export const processFetchAllGlobalCadSearchRules = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllGlobalCadSearchRulesTypes,
    '/global_cad_search_rules?with_full_detail=true',
    {
      mutateResponse: ({ globalCadSearchRules = [] }) => ({
        [EK.GLOBAL_CAD_SEARCH_RULES.plural]: globalCadSearchRules.map(
          (rule) => ({
            ...rule,
            [EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.plural]: rule[
              EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.plural
            ].map((criterion) => ({
              ...criterion,
              globalCadSearchRuleId: `${rule.id}`,
            })),
          })
        ),
      }),
    }
  );
