import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  CATEGORY_NAME: /^(.{0,255})$/,
  SYMBOL_FILE: /^(.{0,2048})$/,
};

class FittingCategory extends EntityRecord({
  categoryName: {
    value: null,
    handleCopy: (entity) => `${entity.categoryName} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.categoryName) &&
      ALLOWANCES.CATEGORY_NAME.test(entity.categoryName),
    validateError: 'A valid category name must be present',
    filter: FILTERS.STRING,
  },
  symbolFile: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.symbolFile) &&
      ALLOWANCES.SYMBOL_FILE.test(entity.symbolFile),
    validateError: 'A valid symbol file must be present',
    filter: FILTERS.STRING,
  },
}) {
  get primary() {
    return this.categoryName;
  }
  get primaryPlaceholder() {
    return 'No Category Name';
  }
  get secondary() {
    return this.symbolFile;
  }
  get secondaryPlaceholder() {
    return '--';
  }
}

export const DEFAULT_SYMBOL_FILE_ICONS = [
  'fit_assembly',
  'fit_branch',
  'fit_elbow',
  'fit_flange',
  'fit_general',
  'fit_nobreak',
  'fit_red',
  'fit_valve',
  'fit_hose_elb',
  'fit_hose_str',
];

export default FittingCategory;
