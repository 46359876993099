import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';

import validateRules from '../../../../utils/validation/validateRules';

var organizationNameRegex = /[A-Za-z0-9 .,!&/$-]+/;

const organizationNameRules = {
  alphanumericSpaceCharacters: {
    message: 'Can only contain letters, numbers, spaces, commas, hyphens, !, &, and $',
    validator: value => value && organizationNameRegex.test(value),
  },
  minLength: {
    message: 'Must be at least 2 characters long',
    validator: value => value && value.length >= 2,
  },
  maxLength: {
    message: 'Must be shorter than 40 characters',
    validator: value => value && value.length <= 40,
  },
};

const organizationNameValidations = validateRules(organizationNameRules);

const OrganizationForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field 
          component={RDXTextInput} 
          name='name' 
          label='Organization Name' 
          required validate={organizationNameValidations}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button primary type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Create Organization</Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.ORGANIZATIONS.state,
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

export default enhance(OrganizationForm);
