import React from 'react';
import styled from 'styled-components';

const ParentBox = styled.div`
  position: relative;
  overflow: hidden;
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
`;
const OverlayBox = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(255,255,255,1));
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 1;
  max-width: 200px;
`;

/**
 * puts a 0 - 1 opacity component over the content to soften the end of an overflow: hidden
 * @param {Component} content a react component that gets overlayed
 * @returns a component containing your component and an overlay
 */
export default function ({ content, maxWidth }) {
  return (
    <ParentBox $maxWidth={maxWidth}>
      {content}
      <OverlayBox />
    </ParentBox>
  );
}