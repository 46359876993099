/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createReducer = (history) => (injectedReducers) =>
  combineReducers({
    router: connectRouter(history),
    ...(injectedReducers || {}),
  });

export default createReducer;
