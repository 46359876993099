import { createSelector } from 'reselect';

import { selectUsers } from '../../../../entities/Users/selectors';

const selectUserId = () => (state, props) => props.userId;

const selectUser = () => createSelector(
  selectUsers(),
  selectUserId(),
  (users, id) => users.get(id)
);

export default selectUser;
