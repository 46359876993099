import baseSchema from '../../../modules/entities/utils/schema';
import EK from '../../keys';

import AppearanceSchema from '../../Synchronize/Appearances/schema';
import InsulationFamilySchema from '../InsulationFamilies/schema';

const insulationSpecSchema = baseSchema(EK.INSULATION_SPECS.state, {
  appearance: { schema: AppearanceSchema, isId: true },
  insulationFamily: { schema: InsulationFamilySchema, isId: true },
});

export default insulationSpecSchema;
