import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { createSelector } from 'reselect';

import { selectCurrentFilteredSections, selectSectionsCount } from '../../../entities/Standardize/Sections/selectors';

import {
  dialogFooter,
  deleteDialogFooter,
  doRequiredFieldsExist
} from './components';

import {
  SECTION_METHOD_TYPE_OPTIONS,
} from '../../../entities/Standardize/Sections/model';

import {
  processFetchAllSections,
  processSaveSections
} from '../../../entities/Standardize/Sections/actions';
import { StandardizeModuleSectionsPath } from '../../../paths';
import { pluralize } from '../../../components/common/Header';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredSections(),
  selectSidebarIsFiltering(),
  selectSectionsCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      hasValidLicense,
      canCollaborate,
      isFiltering,
      count,
    };
  },
);

const BLANK_SECTION = {
  id: '',
  name: '',
  sectionMethod: '',
  datumFeatureName: '',
  offsetDistance: '',
  description: ''
};

export default function(){

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open

  const [currentEditingRow, setCurrentEditingRow] = useState({});

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Section';
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow({});
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow({});
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      sectionMethod: rowdata.sectionMethod,
      datumFeatureName: rowdata.datumFeatureName,
      offsetDistance: rowdata.offsetDistance,
      description: rowdata.description,

    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processSaveSections([{id: currentEditingRow.id, _destroy:'1'}]));
    setCurrentEditingRow(BLANK_SECTION);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_SECTION);
  };

  const saveAction = () => {
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      sectionMethod: currentEditingRow.sectionMethod,
      datumFeatureName: currentEditingRow.datumFeatureName,
      offsetDistance: currentEditingRow.offsetDistance,
      description: currentEditingRow.description,
    };

    const {id, ...rest} = editedRow;

    if (isCreateDialogOpen) {
      dispatch(processSaveSections([rest]));
    } else {
      dispatch(processSaveSections([editedRow]));
    }
    cancelDialogs();
  };

  useEffect(() => {
    dispatch(processFetchAllSections());
  }, []);

  return(
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleSectionsPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Section', reduxProps.count)}` :
            `Showing ${reduxProps.count} ${pluralize('Section', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' stripedRows scrollable scrollHeight='flex' >
          <Column field='name' header='Name' style={{ width: '20%'}}></Column>
          <Column field='sectionMethod' header='Section Method' style={{ width: '20%'}}></Column>
          <Column field='datumFeatureName' header='Datum Feature Name' style={{ width: '20%'}}></Column>
          <Column field='offsetDistance' header='Offset Distance' style={{ width: '20%'}}></Column>
          <Column field='description' header='Description' style={{ width: '20%'}}></Column>
          <Column header=''
            style={{textAlign: 'right', width: '20%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, false, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')}/>
          <label>Section Method</label>
          <Dropdown value={currentEditingRow.sectionMethod} options={SECTION_METHOD_TYPE_OPTIONS} optionLabel='label' optionValue='id' onChange={(e) => handleEditedRowChange(e.target.value, 'sectionMethod')}/>
          <label>Datum Feature Name</label>
          <InputText value={currentEditingRow.datumFeatureName} onChange={(e) => handleEditedRowChange(e.target.value, 'datumFeatureName')}/>
          <label>Offset Distance</label>
          <InputNumber value={currentEditingRow.offsetDistance} onChange={(e) => handleEditedRowChange(e.value, 'offsetDistance')}/>
          <label>Description</label>
          <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')}/>
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Section'${currentEditingRow.name}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}
