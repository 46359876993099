import { Map } from 'immutable';

import { LOCATION_CHANGE } from 'connected-react-router';

import resetOnLogout from '../../utils/reducers/resetOnLogout';

import { 
  IS_INITIAL_LOAD_COMPLETE, 
  SET_SIDEBAR_MINIMIZED, 
  SET_SIDEBAR_FILTERING, 
  TOGGLE_SIDEBAR_MINIMIZED,
  TOGGLE_SIDEBAR_FILTERING,
  TOGGLE_HIDDEN_GRID_COLUMNS,
} from './constants';

const initialDashboardState = Map({
  isInitialLoadComplete: false,
  sidebarIsMinimized: false,
  sidebarIsFiltering: false,
  showHiddenGridColumns: false,
});

const dashboardReducer = (state = initialDashboardState, { type, payload }) => {
  if (type === IS_INITIAL_LOAD_COMPLETE) {
    return state.set('isInitialLoadComplete', true);
  } else if (type === SET_SIDEBAR_MINIMIZED) {
    return state.set('sidebarIsMinimized', !!payload.isMinimized);
  } else if (type === TOGGLE_SIDEBAR_MINIMIZED) { 
    return state.set('sidebarIsMinimized', !state.get('sidebarIsMinimized'));
  } else if (type === SET_SIDEBAR_FILTERING) {
    return state.set('sidebarIsFiltering', !!payload.isFiltering);
  } else if (type === TOGGLE_SIDEBAR_FILTERING) {
    return state.set('sidebarIsFiltering', !state.get('sidebarIsFiltering'));
  } else if (type === TOGGLE_HIDDEN_GRID_COLUMNS) {
    return state.set('showHiddenGridColumns', !state.get('showHiddenGridColumns'));
  } else if (type === LOCATION_CHANGE) {
    return state.set('sidebarIsFiltering', false).set('showHiddenGridColumns', false);
  } else {
    return state;
  }
};

export default resetOnLogout(dashboardReducer);
