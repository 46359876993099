import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectNormalizedPipeFamilies } from '../../../entities/Piping/PipeFamilies/selectors';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  data: selectNormalizedPipeFamilies(),
});

const enhance = compose(
  connect(mapStateToProps)
);

const SpecsPipesNotices = ({ isFetching, data }) => isFetching && data && data.size > 0 && <Notice id='specsPipes' message='Loading data in the background...' /> || null;

export default enhance(SpecsPipesNotices);
