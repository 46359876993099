import { List } from 'immutable';

import resetOnLogout from '../../utils/reducers/resetOnLogout';

import { SHOW_MODAL, RELOAD_MODAL, HIDE_MODAL, AFTER_HIDE_MODAL } from './constants';

const initialModalState = List();

const modalReducer = (state = initialModalState, { type, payload }) => {
  if (type === SHOW_MODAL) {
    const { modalType, modalProps, modalSize } = payload;
    return state.push({ modalType, modalProps, modalSize });
  } else if (type === RELOAD_MODAL) {
    const { modalType, modalProps, closeCurrentModal } = payload;
    const modalIndex = state.findLastIndex(modal => modal.modalType === modalType);
    let updated = state;
    if (modalIndex > -1) {
      updated = state.set(modalIndex, {
        modalType,
        modalProps: {
          ...state.getIn([modalIndex, 'modalProps']),
          ...modalProps,
        },
        modalSize: state.getIn([modalIndex, 'modalSize']),
      });
    }

    if (closeCurrentModal) {
      return updated.butLast();
    } else {
      return updated;
    }
  } else if (type === HIDE_MODAL) {
    return payload.shouldClear ? initialModalState : state.butLast();
  } else if (type === AFTER_HIDE_MODAL) {
    return state.size > 0 ? state : initialModalState;
  } else {
    return state;
  }
};

export default resetOnLogout(modalReducer);
