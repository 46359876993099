import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentSpec } from './selectors';

import { PipingModuleSpecsEditPath } from '../../paths';

const mapStateToProps = createSelector(
  selectCurrentSpec(),
  (spec) => ({
    title:
      (spec && spec.specName) ||
      PipingModuleSpecsEditPath.defaultTitle,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
