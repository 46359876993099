import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'GLOBAL_CAD_SEARCH_RULES';

export const fetchAllGlobalCadSearchRulesTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createGlobalCadSearchRuleTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const saveGlobalCadSearchRulesTypes = generateAPHTypes(
  resource,
  'COLLECTION'
);
