import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'MATERIALS';

export const fetchAllMaterialsTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const saveMaterialsTypes = generateAPHTypes(resource, 'COLLECTION');

export const newMaterialRows = [
  { id: 'NEW_ROW_1', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_2', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_3', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_4', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_5', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_6', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_7', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_8', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_9', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_10', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_11', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_12', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_13', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_14', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
  { id: 'NEW_ROW_15', code: '', description: '', filename: '', density: '', defaultUnitSystem: '', unitSystem: '', isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false },
];