import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import space from '../../../../assets/themes/base/space';

import useClipboard from 'react-use-clipboard';

import EK from '../../../../entities/keys';

import { ResetPasswordPath } from '../../../../paths';

import Box from '../../../../components/common/Box';
import Alert from '../../../../components/common/Alert';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';
import Typography from '../../../../components/common/Typography';

import {
  Container as UserRoleRadioButtonContainer
} from '../../../../components/form/BaseRadioButton';

import UserRoleRadioButton from '../../../../components/management/UserRoleRadioButton';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { USER_ROLE_TYPES } from '../../../../modules/permissions/constants';

import { processForceResetPassword } from '../../../../modules/auth/actions';

import {
  selectCurrentUser,
  selectCurrentOrganizationId,
  selectCurrentUserAllowedRoleAssignments,
} from '../../../../entities/CurrentUser/selectors';

import {
  processEditUser,
} from '../../../../entities/Users/actions';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

import selectUser from './selectors';

const ModalContainer = styled(Flex)`
  width: 40%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(Flex)`
  margin: auto;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[1]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: relative;

  border-top-left-radius: ${radii[2]};
  background: ${colors.white};
`;

const ContentSection = styled(Flex).attrs(({ flexDirection = 'row' }) => ({
  flexDirection,
}))`
  align-items: center;
  padding: ${space[5]} ${space[5]};
  border-bottom: 1px solid ${colors.gray[0]};

  &:last-child {
    border-bottom: none;
  }
`;

const ContentSectionText = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const UserRadioGroup = ({ onChange, value, disabled, allowed }) => {
  return (
    <UserRoleRadioButtonContainer>
      <UserRoleRadioButton
        type="radio"
        value={USER_ROLE_TYPES.USER}
        label="User"
        role={USER_ROLE_TYPES.USER}
        checked={value === USER_ROLE_TYPES.USER}
        disabled={disabled}
        onChange={onChange}
      />
      <UserRoleRadioButton
        type="radio"
        value={USER_ROLE_TYPES.COLLABORATOR}
        label="Collaborator"
        role={USER_ROLE_TYPES.COLLABORATOR}
        checked={value === USER_ROLE_TYPES.COLLABORATOR}
        disabled={disabled || !allowed.has(USER_ROLE_TYPES.COLLABORATOR)}
        onChange={onChange}
      />
      <UserRoleRadioButton
        type="radio"
        value={USER_ROLE_TYPES.ADMIN}
        label="Admin"
        role={USER_ROLE_TYPES.ADMIN}
        checked={value === USER_ROLE_TYPES.ADMIN}
        disabled={disabled || !allowed.has(USER_ROLE_TYPES.ADMIN)}
        onChange={onChange}
      />
    </UserRoleRadioButtonContainer>
  );
};

const OrganizationUserModal = ({
  user,
  currentUser,
  currentOrganizationId,
  roles,
  isPerforming,
}) => {
  const dispatch = useDispatch();

  const toggleUserActiveFlag = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { activeFlag: !user.activeFlag }));
  }, [dispatch, user, currentOrganizationId]);

  const setUserRole = useCallback(e => {
    dispatch(processEditUser(currentOrganizationId, user.id, { roleName: e.target.value }));
  }, [dispatch, user, currentOrganizationId]);

  const toggleUserHasModulePiping = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { hasModulePiping: !user.hasModulePiping }));
  }, [dispatch, user, currentOrganizationId]);

  const toggleUserHasModuleCabling = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { hasModuleCabling: !user.hasModuleCabling }));
  }, [dispatch, user, currentOrganizationId]);

  const toggleUserHasModuleFramework = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { hasModuleFramework: !user.hasModuleFramework }));
  }, [dispatch, user, currentOrganizationId]);

  const toggleUserHasModuleEcad = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { hasModuleEcad: !user.hasModuleEcad }));
  }, [dispatch, user, currentOrganizationId]);

  const toggleUserHasModuleReporting = useCallback(() => {
    dispatch(processEditUser(currentOrganizationId, user.id, { hasModuleReporting: !user.hasModuleReporting }));
  }, [dispatch, user, currentOrganizationId]);

  const forcePasswordReset = useCallback(() => {
    dispatch(processForceResetPassword({ email: user.email, forced: true }));
  }, [dispatch, user]);

  const isCurrentUser = user.id === currentUser.id;

  const passwordResetUrl = user.passwordResetToken ? `${ResetPasswordPath.generate({ token: user.passwordResetToken })}` : '';
  const isShowingPasswordResetLink = !!user.passwordResetToken;
  const [isPasswordTokenCopied, setPasswordTokenCopied] = useClipboard(passwordResetUrl);

  return (
    <ModalContainer>
      <Modal>
        <Flex flexDirection='column' width='100%' height='100%' style={{ overflowY: 'scroll' }}>
          <Box pt={4} pb={4} pl={5}>
            <Typography.F3 label='Account' sub={user.email} mb={null}>{user.fullName}</Typography.F3>
          </Box>
          <Content>
            {isCurrentUser && <ContentSection>
              <Alert error ml="auto" mr="auto">
                <Text fontSize={1} as="span">
                  You aren&apos;t allowed to change your own role, reset your password,
                  or deactivate yourself. You&apos;ll have to get someone else to do it
                  for you.
                </Text>
              </Alert>
            </ContentSection>}
            <ContentSection flexDirection='column' justifyContent='center'>
              <UserRadioGroup value={user.role} onChange={setUserRole} disabled={isPerforming || !user.activeFlag || isCurrentUser} allowed={roles} />
              <Flex flexDirection='column' justifyContent='center' width='100%' mt={4}>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  The user is currently a(n) <strong>{user.role}</strong>. Users only allowed to view data. Collaborators are allowed to view and edit data. Admins are allowed to view and edit data as well as manage user accounts in the organization.
                </Text>
              </Flex>
            </ContentSection>
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  secondary={user.hasModulePiping}
                  primary={!user.hasModulePiping}
                  onClick={toggleUserHasModulePiping}
                  disabled={isPerforming || !user.activeFlag}
                  small
                >{user.hasModulePiping ? 'Disable Piping' : 'Enable Piping'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? (user.hasModulePiping ? 'primary.4' : 'gray.7') : 'gray.4'} fontWeight='600'>
                  {user.hasModulePiping
                    ? 'Piping Module Enabled'
                    : 'Piping Module Disabled'}
                </Text>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  Enables the CadActive piping module for this user; piping module seats are separate from the total user count for an organization
                </Text>
              </ContentSectionText>
            </ContentSection>
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  secondary={user.hasModuleCabling}
                  primary={!user.hasModuleCabling}
                  onClick={toggleUserHasModuleCabling}
                  disabled={isPerforming || !user.activeFlag}
                  small
                >{user.hasModuleCabling ? 'Disable Cabling' : 'Enable Cabling'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? (user.hasModuleCabling ? 'primary.4' : 'gray.7') : 'gray.4'} fontWeight='600'>
                  {user.hasModuleCabling
                    ? 'Cabling Module Enabled'
                    : 'Cabling Module Disabled'}
                </Text>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  Enables the CadActive cabling module for this user; cabling module seats are separate from the total user count for an organization
                </Text>
              </ContentSectionText>
            </ContentSection>
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  secondary={user.hasModuleFramework}
                  primary={!user.hasModuleFramework}
                  onClick={toggleUserHasModuleFramework}
                  disabled={isPerforming || !user.activeFlag}
                  small
                >{user.hasModuleFramework ? 'Disable Framework' : 'Enable Framework'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? (user.hasModuleFramework ? 'primary.4' : 'gray.7') : 'gray.4'} fontWeight='600'>
                  {user.hasModuleFramework
                    ? 'Framework Module Enabled'
                    : 'Framework Module Disabled'}
                </Text>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  Enables the CadActive framework module for this user; framework module seats are separate from the total user count for an organization
                </Text>
              </ContentSectionText>
            </ContentSection>
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  secondary={user.hasModuleEcad}
                  primary={!user.hasModuleEcad}
                  onClick={toggleUserHasModuleEcad}
                  disabled={isPerforming || !user.activeFlag}
                  small
                >{user.hasModuleEcad ? 'Disable Ecad' : 'Enable Ecad'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? (user.hasModuleEcad ? 'primary.4' : 'gray.7') : 'gray.4'} fontWeight='600'>
                  {user.hasModuleEcad
                    ? 'Ecad Module Enabled'
                    : 'Ecad Module Disabled'}
                </Text>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  Enables the CadActive Ecad module for this user; Ecad module seats are separate from the total user count for an organization
                </Text>
              </ContentSectionText>
            </ContentSection>
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  secondary={user.hasModuleReporting}
                  primary={!user.hasModuleReporting}
                  onClick={toggleUserHasModuleReporting}
                  disabled={isPerforming || !user.activeFlag}
                  small
                >{user.hasModuleReporting ? 'Disable Reporting' : 'Enable Reporting'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? (user.hasModuleReporting ? 'primary.4' : 'gray.7') : 'gray.4'} fontWeight='600'>
                  {user.hasModuleReporting
                    ? 'Reporting Module Enabled'
                    : 'Reporting Module Disabled'}
                </Text>
                <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                  Enables the CadActive reporting module for this user; reporting module seats are separate from the total user count for an organization
                </Text>
              </ContentSectionText>
            </ContentSection>
            {
              (isShowingPasswordResetLink) ? (
                <ContentSection>
                  <Alert primary ml="auto" mr="auto">
                    <Text fontSize={1} mb={2}>
                      This user currently has a pending password reset. You can provide them this link to reset their password: <Text as="a" target="_blank" href={passwordResetUrl}>{passwordResetUrl}</Text>
                    </Text>
                    <Button
                      onClick={setPasswordTokenCopied}
                      primary
                      small
                      disabled={isPasswordTokenCopied}>
                      {isPasswordTokenCopied ? 'Copied' : 'Copy to clipboard'}
                    </Button>
                  </Alert>
                </ContentSection>
              ) : (
                <ContentSection>
                  <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                    <Button
                      secondary
                      onClick={forcePasswordReset}
                      disabled={isPerforming || !user.activeFlag || isCurrentUser}
                      small
                    >Reset Password</Button>
                  </Flex>
                  <ContentSectionText>
                    <Text fontSize={1} color={user.activeFlag ? 'gray.7' : 'gray.4'}>
                      Forces the user to change their password the next time they log in.
                    </Text>
                  </ContentSectionText>
                </ContentSection>
              )
            }
            <ContentSection>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' pr={6}>
                <Button
                  error={user.activeFlag}
                  primary={!user.activeFlag}
                  onClick={toggleUserActiveFlag}
                  disabled={isPerforming || isCurrentUser}
                  small
                >{user.activeFlag ? 'Disable User' : 'Activate User'}</Button>
              </Flex>
              <ContentSectionText>
                <Text fontSize={2} color={user.activeFlag ? 'gray.7' : 'error.4'} fontWeight='600'>
                  {user.activeFlag
                    ? 'User Currently Active'
                    : 'User Currently Deactivated'}
                </Text>
                <Text fontSize={1} color='gray.7'>
                  Deactivating a user prevents them from being able to log in or
                  use this platform (both in the web and in Creo)
                </Text>
              </ContentSectionText>
            </ContentSection>
          </Content>
        </Flex>
      </Modal>
    </ModalContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser(),
  currentUser: selectCurrentUser(),
  currentOrganizationId: selectCurrentOrganizationId(),
  roles: selectCurrentUserAllowedRoleAssignments(),
  isPerforming: selectIsPerformingInModal(),
});

const enhance = compose(
  injectSaga({ key: `${EK.USERS.state}Modal`, saga }),
  connect(mapStateToProps)
);

export default enhance(OrganizationUserModal);
