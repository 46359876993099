import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { StandardizeModuleParametersPath } from '../../../paths';

const mapStateToProps = createSelector(
  () => StandardizeModuleParametersPath.defaultTitle,
  (title) => ({ title })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
