import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../../templates/Structures/Header';

import { PipingModulePipesBendsPath } from '../../../paths';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';

import {
  selectCurrentPipesBendsCount,
  selectCurrentFilteredPipesBends,
} from '../../../entities/Piping/BendFamilies/selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipesBendsCount(),
  selectCurrentFilteredPipesBends(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    isShowingArchived,
    { unarchived, total },
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && total === 0),
    title: PipingModulePipesBendsPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${
        isShowingArchived ? total : unarchived
      } ${pluralize(
        'filtered Bend',
        isShowingArchived ? total : unarchived
      )} ${
        (isShowingArchived && '(including archived)') ||
          '(excluding archived)'
      } for this Pipe`
      : `Showing ${unarchived} ${pluralize('Bend', unarchived)} for this Pipe`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
