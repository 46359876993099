import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectCurrentProfileId } from '../Profiles/selectors';

export const selectNormalizedDimensions = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.DIMENSIONS.state)
);
export const selectNormalizedProfileDimensionsList = () => createSelector(
  selectCurrentProfileId(),
  selectNormalizedDimensions(),
  (currentProfileId, dimensions) => dimensions.filter( dimension => dimension.profile == currentProfileId).toList()
);
export const selectNormalizedProfileDimensionsListById = (profileId) => createSelector(
  selectNormalizedDimensions(),
  (dimensions) => dimensions.filter(dimension => dimension.profile == profileId).toList()
);
