import { take, put, fork, select } from 'redux-saga/effects';

import { processFetchAllBoltNutFamilies } from '../../entities/Piping/BoltNutFamilies/actions';
import { processFetchAllEndTypeCompatibilities } from '../../entities/Piping/EndTypeCompatibilities/actions';
import { processFetchAllEndTypes } from '../../entities/Piping/EndTypes/actions';
import { processFetchAllFittingCategories } from '../../entities/Piping/FittingCategories/actions';
import { processFetchAllFittingCodes } from '../../entities/Piping/FittingCodes/actions';
import { processFetchMasterCatalog } from '../../entities/Piping/PipingCatalogs/actions';
import { processFetchAllMaterials } from '../../entities/Piping/Materials/actions';
import { processFetchAllSchedules } from '../../entities/Piping/Schedules/actions';
import { processFetchAllSizes } from '../../entities/Piping/Sizes/actions';

import { selectIsFetching } from '../../modules/utility/selectors';

import { signout } from '../../modules/auth/sagas';

import { isInitialLoadComplete } from '../Dashboard/actions';

export function* watchLoadPipingModuleSaga() {
  try {
    yield put(processFetchAllBoltNutFamilies());
    yield put(processFetchAllEndTypeCompatibilities());
    yield put(processFetchAllEndTypes());
    yield put(processFetchAllFittingCategories());
    yield put(processFetchAllFittingCodes());
    yield put(processFetchMasterCatalog());
    yield put(processFetchAllMaterials());
    yield put(processFetchAllSchedules());
    yield put(processFetchAllSizes());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadPipingModuleSaga);
}
