import styled from 'styled-components';
import transitions from '../../../assets/themes/base/transitions';
import fontSizes from '../../../assets/themes/base/fontSizes';
import space from '../../../assets/themes/base/space';

import Text from '../../common/Text';

const BaseLabel = styled(Text).attrs(({ name, label, active, error }) => ({
  as: 'label',
  htmlFor: name,
  color: error ? 'error.4' : (active ? 'primary.4' : 'gray.6'),
  children: label,
}))`
  flex-grow: 0;
  display: block;
  font-size: ${fontSizes[1]};
  font-weight: 500;
  transition: color ${transitions.speed.fast} ${transitions.type.inOut};
  margin-right: ${space[2]};
  margin-left: '1px';
  padding-bottom: ${space[1]};
`;

export default BaseLabel;
