import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import Header from './Header';
import Options from '../../templates/Structures/Options';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';

import { createSelector } from 'reselect';

import { selectStandardizeModulePermissionsAndState } from '../../Dashboard/selectors';

import { selectLayers } from './selectors';

import {
  dialogFooter,
  deleteDialogFooter,
  doRequiredFieldsExist
} from './components';

import {
  processFetchAllLayers,
  processSaveLayers
} from '../../../entities/Standardize/Layers/actions';


const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectLayers(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const BLANK_LAYER = {
  name: '',
  description: '',
};

export default function(){

  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_LAYER);

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Layer';
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow({});
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow({});
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      description: rowdata.description,

    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processSaveLayers([{id: currentEditingRow.id, _destroy:'1'}]));
    setCurrentEditingRow(BLANK_LAYER);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_LAYER);
  };

  const saveAction = () => {
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      description: currentEditingRow.description,
    };

    const {id, ...rest} = editedRow;

    if (isCreateDialogOpen) {
      dispatch(processSaveLayers([rest]));
    } else {
      dispatch(processSaveLayers([editedRow]));
    }
    cancelDialogs();
  };

  useEffect(() => {
    dispatch(processFetchAllLayers());
  }, []);

  return(
    <>
      <Flex flexDirection="row" mb={4}>
        <Header/>
        <Options
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' stripedRows scrollable scrollHeight='flex' >
          <Column field='name' header='Name' style={{ width: '20%'}}/>
          <Column field='description' header='Description' style={{ width: '20%'}}/>
          <Column header=''
            style={{textAlign: 'right',  width: '20%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
              />}
          />
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, false, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')}/>
          <label>Description</label>
          <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')}/>
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Layer '${currentEditingRow.name}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}
