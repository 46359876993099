import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';

import ConfigSettingCategorySchema from '../ConfigSettingCategories/schema';

const configSettingSchema = baseSchema(EK.CONFIG_SETTINGS.state, {
  configSettingCategory: { schema: ConfigSettingCategorySchema, isId: true },
});

export default configSettingSchema;
