import React from 'react';

import Box from '../../../../../components/common/Box';
import Text from '../../../../../components/common/Text';

const ExternalPageLead = ({ children }) => (
  <Box>
    <Text color='gray.6' fontSize={11} textAlign='center'>{ children }</Text>
  </Box>
);

export default ExternalPageLead;
