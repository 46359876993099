import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { List, Set } from 'immutable';

import EK from '../../entities/keys';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectNormalizedFittingFamilies } from '../../entities/Piping/FittingFamilies/selectors';

import { selectNormalizedPipeFamilies } from '../../entities/Piping/PipeFamilies/selectors';

import { selectNormalizedSpecs } from '../../entities/Piping/Specs/selectors';

import {
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
} from '../../paths';

export const selectCurrentSpecId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsEditPath.url,
    });
    return (match && match.params.id) || null;
  });

export const selectCurrentSpec = (altSpecId = null) =>
  createSelector(
    selectCurrentSpecId(),
    selectNormalizedSpecs(),
    (specId, specs) => {
      const specIdToGet = specId || altSpecId || null;
      return specIdToGet && specs.get(specIdToGet) || undefined;
    }
  );

export const selectCurrentEditableSpecFamilySorting = () => (state) =>
  state.getIn([`${EK.SPECS.state}EditSorting`, 'familySortingOrder']);


export const selectisPrioritizedFamilySortingEnabled = () => (state) =>
  state.getIn([`${EK.SPECS.state}EditSorting`, 'isPrioritizedFamilySortingEnabled']);

export const selectCurrentFittingId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsFittingInstancesEditPath.url,
    });
    return (match && match.params.familyId) || null;
  });

export const selectCurrentFitting = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    (fittingId, fittings) => fittings.get(fittingId)
  );

export const selectCurrentFittingInstancesBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsFittingInstancesEditPath.url,
    });
    // returns  { id, familyId }
    return (match && match.params) || null;
  });

export const selectCurrentPipeId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsPipeInstancesEditPath.url,
    });
    return (match && match.params.familyId) || null;
  });

export const selectCurrentPipe = () =>
  createSelector(
    selectCurrentPipeId(),
    selectNormalizedPipeFamilies(),
    (pipeId, pipes) => pipes.get(pipeId)
  );

export const selectCurrentPipeInstancesBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsPipeInstancesEditPath.url,
    });
    // returns  { id, familyId }
    return (match && match.params) || null;
  });