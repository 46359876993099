import { toast } from 'react-toastify';

import insulationSpecClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllInsulationSpecsTypes,
  saveInsulationSpecsTypes,
} from './constants';

export const processFetchAllInsulationSpecs = () =>
  insulationSpecClient(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllInsulationSpecsTypes,
    '/piping/insulation_specs?all=true'
  );

export const processSaveInsulationSpecs = (data) =>
  insulationSpecClient(
    REQUEST_TYPES.PATCH,
    'plural',
    saveInsulationSpecsTypes,
    '/piping/insulation_specs/collection?diff_result=true',
    {
      data: { insulationSpecsAttributes: data },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );
