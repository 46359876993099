import React from 'react';
import styled, { css } from 'styled-components';
import radii from '../../../assets/themes/base/radii';

import {
  borderRadius,
  borderColor,
  borders,
  boxShadow,
  display,
  space,
  color,
  width,
  height,
  position,
  lineHeight,
  top,
  left,
  bottom,
  right,
  textAlign,
} from 'styled-system';

const BaseBox = styled.div`
  ${borderRadius}
  ${borderColor}
  ${borders}
  ${boxShadow}
  ${display}
  ${space}
  ${color}
  ${width}
  ${height}
  ${position}
  ${lineHeight}
  ${textAlign}

  ${top}
  ${left}
  ${bottom}
  ${right}

  ${
  ({ minWidth }) => minWidth !== undefined && css`
      min-width: ${minWidth};
    `
}

  ${
  ({ cursor }) => cursor && css`
      cursor: ${cursor};
    `
}

  ${
  ({ borderRadii, borderRadius }) => borderRadii && Array.isArray(borderRadii) ?
    css`
      border-top-left-radius: ${radii[borderRadii[0]]};
      border-top-right-radius: ${radii[borderRadii[1]]};
      border-bottom-right-radius: ${radii[borderRadii[2]]};
      border-bottom-left-radius: ${radii[borderRadii[3]]};
    ` :
    !borderRadius && css`
      border-radius: ${radii[borderRadii || 0]};
    `
}

  ${
  ({ $ellipsis }) => $ellipsis && css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      word-break: break-all;
    `
}
`;

const Box = React.forwardRef((props, ref) => <BaseBox ref={ref} { ...props } />);

export default Box;