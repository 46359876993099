import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';
import fontSizes from '../../../assets/themes/base/fontSizes';


import Box from '../../common/Box';

const BaseTooltipSection = styled(Box)`
  background-color: ${colors.gray[0]};
  padding: ${space[3]} ${space[4]};
  color: ${colors.gray[7]};

  ${
  ({ small }) => small && css`
      padding: ${space[2]} ${space[3]};
      font-size: ${fontSizes[1]};
    `
}

  ${
  ({ large }) => large && css`
      padding: ${space[3]} ${space[4]};
    `
}

  ${
  ({ primary }) => primary && css`
      background-color: ${colors.primary[4]};
      color: ${colors.white};
    `
}

  ${
  ({ main }) => main && css`
      background-color: ${colors.white};
    `
}

  ${
  ({ secondary }) => secondary && css`
      background-color: ${colors.gray[0]};
    `
}

  ${
  ({ error }) => error && css`
      background-color: ${colors.error[4]};
      color: ${colors.white};
    `
}

  ${
  ({ inverse }) => inverse && css`
      background-color: ${colors.gray[7]};
      color: ${colors.gray[0]};
    `
}
`;

const TooltipSection = React.forwardRef((props, ref) => <BaseTooltipSection ref={ref} { ...props } />);

export default TooltipSection;