import { List } from 'immutable';
import isArray from 'lodash/isArray';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

class GlobalCadSearchRuleCriterion extends EntityRecord({
  name: {
    value: null,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    filter: FILTERS.STRING,
  },
  defaultValue: {
    value: null,
  },
  valueType: {
    value: null,
  },
  listValues: {
    value: List(),
    isList: true,
  },
  sortOrder: {
    value: 1,
  },
  globalCadSearchRuleId: {
    value: null,
  },
}) {
  constructor({ valueType, listValues, ...other } = {}) {
    super({
      ...other,
      valueType,
      listValues:
        valueType === 'list' && isArray(listValues)
          ? List(
            listValues.map((value) => {
              return { value, label: value, primary: value };
            })
          )
          : List(),
    });
  }

  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}

export default GlobalCadSearchRuleCriterion;
