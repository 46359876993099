import React from 'react';
import styled from 'styled-components';
import radii from '../../../assets/themes/base/radii';
import space from '../../../assets/themes/base/space';
import shadows from '../../../assets/themes/base/shadows';

import Box from '../Box';

const Swatch = styled(Box).attrs(props => ({
  mt: props.mt !== undefined && props.mt || 2,
  mr: props.mr !== undefined && props.mr || 0,
  mb: props.mb !== undefined && props.mb || 2,
  ml: props.ml !== undefined && props.ml || 0,
}))`
  width: ${space[8]};
  height: ${space[5]};
  padding: ${space[1]};
  background: white;
  border-radius: ${radii[1]};
  box-shadow: ${shadows[1]};
  display: block;
  cursor: ${ ({ onClick }) => onClick ? 'cursor' : 'default' };
`;

const Color = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: ${radii[2]};
  background: ${ ({ color }) => color && `rgb(${color.r}, ${color.g}, ${color.b})` || 'transparent' };
`;

const ColorSwatch = React.forwardRef(({ color, onClick, ...props }, ref) => (
  <Swatch onClick={onClick} ref={ref} { ...props }>
    <Color color={color} />
  </Swatch>
));

export default ColorSwatch;

