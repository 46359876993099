import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link, useLocation } from 'react-router-dom';

import Box from '../../components/common/Box';
import Alert from '../../components/common/Alert';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import CadActiveTextLogoGraphic from '../../components/common/graphics/CadActiveTextLogoGraphic';
import Rocket from '../../components/common/graphics/Rocket';

import LoginForm from '../../containers/form/templates/LoginForm';

import ExternalPage from '../templates/ExternalPage';

import { UserIsNotAuthenticated } from '../../modules/auth/utils/routes';

import { selectIsAuthError } from '../../modules/auth/selectors';

import { selectIsPerforming } from '../../modules/utility/selectors';

import { selectIsFromPasswordReset } from './selectors';

import { processLogin, processSAMLLogin } from '../../modules/auth/actions';

import injectSaga from '../../utils/sagas/injectSaga';

import loginSaga from './sagas';
import samlLoginSaga from './SAMLSaga';

import Button from '../../components/common/Button';
import { ForgotPasswordRequestPath, RegisterPath, LoginPath } from '../../paths';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Login = ({ login, samlLogin, isPerforming, isError, isFromPasswordReset }) => {
  const query = useQuery();
  const token = query.get('token');
  if (token) {
    samlLogin(token);
  }

  return (
    <ExternalPage Page title={LoginPath.title}>
      <Box width="20rem" ml="auto" mr="auto" mb={4}>
        <CadActiveTextLogoGraphic />
      </Box>

      <Box width="14rem" ml="auto" mr="auto" mb={7}>
        <Rocket />
      </Box>

      <Flex flexDirection="row" justifyContent="center" ml="auto" mr="auto" mb={7}>
        <Text as="span" fontSize={5} color="gray.7">
          <strong>Welcome</strong> Back
        </Text>
      </Flex>

      {isFromPasswordReset && (
        <Alert primary ml="auto" mr="auto" mb={3}>
          <Text fontSize={1} as="span">
            Your password has been successfully reset.
          </Text>
        </Alert>
      )}

      <Box ml="auto" mr="auto" mb={5}>
        <LoginForm isPerforming={isPerforming} onRDXSubmit={login} />
      </Box>

      <Box ml="auto" mr="auto" mb={5}>
        <Button width='100%' primary large full as="a" href="/auth/sessions/web">
          Login with SSO
        </Button>
      </Box>

      {isError && (
        <Alert error ml="auto" mr="auto" mb={3}>
          <Text fontSize={1} as="span">
            We were unable to log you in. Please double check your email and password.
          </Text>
        </Alert>
      )}

      <Flex flexDirection="row" justifyContent="center" ml="auto" mr="auto" mb={8}>
        <Text as={isPerforming ? 'span' : Link} link to={ForgotPasswordRequestPath.url}>
          Forgot your password?
        </Text>
      </Flex>

      <Box ml="auto" mr="auto">
        <Text as="p" color="gray.7" fontSize={2} textAlign="center" m={0} p={0}>
          CadActive Technologies &copy; {new Date().getFullYear()}
        </Text>
      </Box>
    </ExternalPage>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerforming(),
  isError: selectIsAuthError(),
  isFromPasswordReset: selectIsFromPasswordReset(),
});

const mapDispatchToProps = (dispatch) => ({
  login(credentials) {
    dispatch(processLogin(credentials, true));
  },
  samlLogin(token) {
    dispatch(processSAMLLogin(token));
  },
});

const enhance = compose(
  withRouter,
  injectSaga({ key: 'login', saga: loginSaga }),
  injectSaga({ key: 'samlLogin', saga: samlLoginSaga }),
  connect(mapStateToProps, mapDispatchToProps),
  UserIsNotAuthenticated
);

export default enhance(Login);
