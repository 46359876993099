import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { hideModal } from '../../../../modules/modal/actions';

import { adminCreateUserTypes } from '../../../../entities/Users/constants';

export function* watchCreateOrganizationUserSaga() {
  yield takeLatest(adminCreateUserTypes.SUCCESS, handleCloseCreateOrganizationUserFamilyModal);
}

export function* handleCloseCreateOrganizationUserFamilyModal() {
  yield put(hideModal());
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateOrganizationUserSaga),
  ]);
}
