import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllTableColumnTypes,
  createTableColumnTypes,
  copyTableColumnTypes,  // do we want to add this in?
  editTableColumnTypes,
  deleteTableColumnTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_TABLE_COLUMNS);

// # GET /api/framework/profile_tables/:profile_table_id/profile_table_columns
export const processFetchTableColumns = (tableId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllTableColumnTypes,
    `/framework/profile_tables/${tableId}/profile_table_columns`,
    {
      mutateResponse: ({ profileTableColumns = [] }) => ({
        profileTableColumns: profileTableColumns.map((col) => ({ table: tableId, ...col })),
      })
    },
  );

//# POST /api/framework/profile_tables/:profile_table_id/profile_table_columns
export const processCreateTableColumn = (tableId, newColumns) =>
  /// newTableRows must be an array of column objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createTableColumnTypes,
    `/framework/profile_tables/${tableId}/profile_table_columns`,
    {
      data: {
        profile_table_columns: newColumns,
      },
      mutateResponse: ({ profileTableColumns = [] }) => ({
        profileTableColumns: profileTableColumns.map((col) => ({ table: tableId, ...col })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Column successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Column',
      },
    }
  );

// # PATCH /api/framework/profile_tables/:profile_table_id/profile_table_columns/:id
export const processEditTableColumn = (tableId, data) =>
  // data is a profile object containing any/all fields to be changed (not technically necessary to include all fields)
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editTableColumnTypes,
    `/framework/profile_tables/${tableId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

// # DELETE /api/framework/profile_tables/:profile_table_id/profile_table_columns/:id
export const processDeleteTableColumn = (TableId, columnId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteTableColumnTypes,
    `/framework/profile_tables/${TableId}/profile_table_columns/${columnId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_TABLE_COLUMNS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Column deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Column',
      },
    }
  );