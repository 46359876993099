import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import { ACTION_OPTIONS } from '../../../../entities/Standardize/SimplifiedRepRulesets/model';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';

import SimplifiedRepRulesetModel, {
  ALLOWANCES,
} from '../../../../entities/Standardize/SimplifiedRepRulesets/model';
// import { validations } from "../../../../entities/SimplifiedReps/formValidation";

const DefaultSimplifiedRepRuleset = new SimplifiedRepRulesetModel();

const SimplifiedRepRulesetForm = ({
  handleSubmit,
  isPerforming,
  valid,
  onCancel,
}) => (
  <Box as="form" style={{ width: '100%' }} onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          // validate={validations["name"]}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXRadioSelect}
          name="creoAction"
          label="Action"
          options={ACTION_OPTIONS}
          required
          // validate={validations["creoAction"]}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      mb={3}
    >
      Save
    </Button>

    <Button secondary onClick={onCancel} large full disabled={isPerforming}>
      Cancel
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.SIMPLIFIED_REP_RULESETS.state,
    initialValues: DefaultSimplifiedRepRuleset,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
    onSubmitSuccess(_, dispatch, props) {
      props.onCancel();
    },
  })
);

export default enhance(SimplifiedRepRulesetForm);
