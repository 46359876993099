import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { captureException } from '@sentry/core';

import { hasSentryConfiguration } from '../../configuration/environmentCheck';

import { push } from 'connected-react-router';

import { handleLogout } from '../auth/actions';

import { PageNotFoundPath, ServerErrorPath } from '../../paths';

export function* handleError({ payload = {}, meta }) {
  const name = payload.name;
  let error;

  if (name === 'ApiError') {
    error = payload;

    const status = payload && payload.status;
    if (status === 'CONN_REFUSED') {
      // TODO: if the connection is refused, show some sort of informative modal letting the user know
      // that we might currently be doing server maintenance or that there is something going on
      // with their internet
    } else if (status === 401) {
      // TODO: if the status is 401, we should give the user the option of being able to log back in
      // via a modal IF they're in the dashboard (in case they're working on something);
      yield put(handleLogout());
    } else {
      if ((status === 403 || status === 404) && !meta.disableErrorRedirect) {
        yield put(push(PageNotFoundPath.url));
      } else if (status === 500) {
        yield put(push(ServerErrorPath.url));
      }
    }
  } else {
    // ['InvalidRSAA', 'InternalError', 'RequestError'].includes(name)
    // right now, this also includes these error states from our saga implementation for APH
    // because this is all handled the same
    error = payload;
  }

  if (hasSentryConfiguration()) {
    yield call(captureException, error);
  }
}

export function* watchErrorSaga() {
  yield takeEvery((action) => action.error, handleError);
}

export default function* main() {
  yield fork(watchErrorSaga);
}
