import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.BOLT_NUTS.state;

export const fetchAllBoltNutFamiliesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualBoltNutFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createBoltNutFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyBoltNutFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editBoltNutFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteBoltNutFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveBoltNutFamiliesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveBoltNutFamiliesTypes = generateAPHTypes(resource, 'UNARCHIVE');
