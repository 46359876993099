import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Button from '../../components/common/Button';
import TinySpinner from '../../components/common/TinySpinner';

import InspectGraphic from '../../components/common/graphics/Inspect';

import ExternalPage from '../templates/ExternalPage';
import ExternalPageLead from '../templates/ExternalPage/components/ExternalPageLead';
import ExternalPageHeader from '../templates/ExternalPage/components/ExternalPageHeader';
import ExternalPageGraphic from '../templates/ExternalPage/components/ExternalPageGraphic';
import ExternalPageText from '../templates/ExternalPage/components/ExternalPageText';
import ExternalPageButtons from '../templates/ExternalPage/components/ExternalPageButtons';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoggingIn } from '../../modules/auth/selectors';

import { selectNormalizedCurrentUser } from '../../entities/CurrentUser/selectors';

import theme from '../../assets/themes/theme';

import {
  LoginPath,
  DashboardPath,
  ServerErrorPath,
} from '../../paths';

const InternalError = ({ isLoading, isLoggedIn }) => (
  <ExternalPage title={ ServerErrorPath.title }>
    <ExternalPageLead>{ !isLoading ? '500' : <Skeleton /> }</ExternalPageLead>
    <ExternalPageHeader>{ !isLoading ? 'Server Error' : <Skeleton /> }</ExternalPageHeader>
    <ExternalPageGraphic>
      <InspectGraphic backgroundColor={theme.colors.error[4]} iconColor={theme.colors.primary[4]} />
    </ExternalPageGraphic>
    <ExternalPageText>
      { !isLoading ? 'It looks like something went wrong. Don\'t worry though - we\'ve deployed our team of elite engineers to get things patched up.' : <Skeleton count={3} /> }
    </ExternalPageText>
    <ExternalPageButtons>
      { isLoading ? <TinySpinner /> : <Button primary as={Link} to={isLoggedIn ? DashboardPath.url : LoginPath.url}>{ isLoggedIn ? 'Back to Dashboard' : 'Log In' }</Button> }
    </ExternalPageButtons>
  </ExternalPage>
);

const mapStateToProps = createSelector(
  selectIsFetching(),
  selectIsLoggingIn(),
  selectNormalizedCurrentUser(),
  (isFetching, isLoggingIn, currentUser) => ({ isLoading: isLoggingIn || (isFetching && !currentUser), isLoggedIn: !!currentUser })
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps)
);

export default enhance(InternalError);
