import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllProfileTypes,
  createProfileTypes,
  copyProfileTypes,
  editProfileTypes,
  deleteProfileTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILES);

// API PATH FOR PROFILES: '/api/framework/profile_libraries/:profile_library_id/profiles'
export const processFetchProfiles = (profileLibraryId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllProfileTypes,
    `/framework/profile_libraries/${profileLibraryId}/profiles`,
    {
      mutateResponse: ({profiles}) => {
        return ( {profiles: profiles.map((item) => ({ ...item, profileLibrary: profileLibraryId}) )} );
      }
    }
  );

export const processCreateProfile = (profileLibraryId, newProfiles) =>
  /// newProfiles must be an array of profiles objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createProfileTypes,
    `/framework/profile_libraries/${profileLibraryId}/profiles`,
    {
      data: {
        profiles: newProfiles,
      },
      mutateResponse: ({ profiles }) => {
        return ({ profiles: profiles.map((item) => ({ ...item, profileLibrary: profileLibraryId })) });
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Profile',
      },
    }
  );

// # PATCH /api/framework/profiles/:id
export const processEditProfile = (data) =>
  // data is a profile object containing any/all fields to be changed (not technically necessary to include all fields)
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editProfileTypes,
    `/framework/profiles/${data.id}`,
    {
      data: {
        name: data.name,
        fileName: data.fileName,
        description: data.description,
        unitSystemId: data.unitSystemId
      },
      mutateResponse: ({ profile }) => {
        return ({
          profile: { ...profile, profileLibraryId: data.profileLibrary}
        });
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteProfile = (ProfileId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteProfileTypes,
    `/framework/profiles/${ProfileId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Profile',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyProfile = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyProfileTypes,
    '/framework/profile_libraries/copy',
    {
      data: {
        profileLibraries: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Profile',
      },
    }
  );