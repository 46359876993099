import React from 'react';
import Text from '../../../components/common/Text';
import Button from '../../../components/common/Button';

export const nameCellBody = (rowdata) => {
  return (
    <Text>{rowdata.name}</Text>
  );
};

const unarchivedEntityTools = ({changesAction, editAction, copyAction, archiveAction}) => {
  return (
    <>
      <Button icon='m' onClick={() => changesAction()} />
      <Button icon='edit' onClick={() => editAction()} />
      <Button icon='copy' onClick={() => copyAction()} />
      <Button icon='archive' onClick={() => archiveAction()} />
    </>
  );
};

const archivedEntityTools = ({copyAction, unarchiveAction}) => {

  return (
    <>
      <Button icon='copy' onClick={() => copyAction()} />
      <Button icon='archive' onClick={() => unarchiveAction()} />
    </>
  );
};

export const EntityTools = ({ isArchived, changesAction, editAction, copyAction, archiveAction, unarchiveAction}) => {
  return isArchived &&
    archivedEntityTools({copyAction, unarchiveAction}) ||
    unarchivedEntityTools({ changesAction, editAction, copyAction, archiveAction });
};

