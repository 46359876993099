import React from 'react';

import Icon from '../../Icon';
import Box from '../../Box';

/**
 * component that displays either 'undo' 'delete' or 'lock' based on the isEdited, isCreated, isDeleted properties of the rowdata and handles clicks based on current icon displayed.
 * 'undo' should remove from changes, 'delete' should add item to changes to be deleted, 'lock' should do nothing
 * @param {any} rowdata the current version of the row
 * @param {any} handleRowAction function containg a switch statement that performs actions based on the icon name, method is kept/maintained in the body.js file of the page or using 'useOnCellEditComplete' as it uses local state
 * @returns {React.FC<ERTProps>}
 */
const EntityRowTools = ({ rowdata, handleRowAction }) => {
  let [iconName, iconColor] = rowdata && (rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited) ? ['undo', 'gray.6'] : ['delete', 'error.4'];
  let clickStyle = 'pointer';
  if (rowdata && !rowdata.canSafelyDelete && !rowdata.isEdited) {
    [iconName, iconColor] = ['lock', 'gray.6'];
    clickStyle = 'inherit';
  }
  if (rowdata && rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
    iconColor = 'gray.2';
  }
  return (
    <Box cursor={clickStyle} onClick={() => handleRowAction(rowdata, iconName)}>
      <Icon name={iconName} color={iconColor} />
    </Box>
  );
};

export default EntityRowTools;