import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.FITTING_FAMILIES.state;

export const fetchAllFittingFamiliesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualFittingFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createFittingFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyFittingFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editFittingFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteFittingFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveFittingFamiliesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveFittingFamiliesTypes = generateAPHTypes(resource, 'UNARCHIVE');
