import React, { useCallback, useRef } from 'react';

import Flex from '../../../components/common/Flex';

import BaseFormCell from '../../../components/form/BaseFormCell';
import BaseLabel from '../../../components/form/BaseLabel';
import BaseRequiredStatus from '../../../components/form/BaseRequiredStatus';
import BaseInput from '../../../components/form/BaseInput';
import BaseDebounceInput from '../../../components/form/BaseDebounceInput';
import BaseAppend from '../../../components/form/BaseAppend';

export const FORMATS = {
  DIMENSION: unitId => value => {
    const foundUnit = unitId && value && value.find(dimension => `${dimension['id']}` === unitId) || null;
    return foundUnit && foundUnit['value'] || null;
  },
};

export const NORMALIZATIONS = {
  DIMENSION: unitId => (value, previousValue, ...args) => {
    if (unitId) {
      if (previousValue.size > 0) {
        return previousValue.update(
          previousValue.findIndex(d => `${d['id']}` === unitId),
          listItem => ({ ...listItem, value })
        );
      } else {
        return previousValue.push({ id: unitId, value });
      }
    } else {
      return null;
    }
  },
};

const RDXTextInput = ({
  input,
  meta,
  type = 'text',
  width = '100%',
  height,
  bg,
  label,
  required,
  append,
  onAppendClick,
  tabIndex,
  autoComplete,
  wait,
  ...rest
}) => {
  const inputRef = useRef();
  const onCellClick = useCallback(() => inputRef.current.focus(), []);

  const TextComponent = wait ? BaseDebounceInput : BaseInput;

  return (
    type !== 'hidden' && (
      <BaseFormCell width={width} height={height} bg={bg} style={{ justifyContent: 'center' }} onClick={onCellClick}>
        { label && <BaseLabel name={input.name} label={label} required={required} active={meta.active} error={meta.submitFailed} /> || null }
        { required && <BaseRequiredStatus error={meta.submitFailed} /> }
        <Flex flexDirection='row' alignItems='center' height='100%'>
          <TextComponent ref={inputRef} type={type} tabIndex={tabIndex} autofilled={meta.autofilled} autoComplete={autoComplete} wait={wait} { ...input } { ...rest }/>
          { append && <BaseAppend onClick={onAppendClick}>{ append }</BaseAppend>}
        </Flex>
      </BaseFormCell>
    ) || (
      <TextComponent ref={inputRef} type={type} tabIndex={tabIndex} autofilled={meta.autofilled} autoComplete={autoComplete} wait={wait} { ...input } { ...rest }/>
    )
  );
};

export default RDXTextInput;
