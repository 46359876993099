import { takeLatest, take, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../entities/keys';

import { PipingModuleBoltNutsEditPath } from '../../../paths';

import {
  createBoltNutFamilyTypes,
  copyBoltNutFamilyTypes,
} from '../../../entities/Piping/BoltNutFamilies/constants';
import {
  selectNormalizedBoltNutFamilies
} from '../../../entities/Piping/BoltNutFamilies/selectors';

export function* handleCreateBoltNutFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.BOLT_NUTS.plural}[0].id`)}`;
  if (id) {
    let boltNuts = yield select(selectNormalizedBoltNutFamilies());
    let copiedBoltNut = boltNuts.get(id);

    while (!copiedBoltNut) {
      yield take();
      boltNuts = yield select(selectNormalizedBoltNutFamilies());
      copiedBoltNut = boltNuts.get(id);
    }

    yield put(push(PipingModuleBoltNutsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* watchCreateBoltNutSaga() {
  yield takeLatest([
    createBoltNutFamilyTypes.SUCCESS,
    copyBoltNutFamilyTypes.SUCCESS,
  ], handleCreateBoltNutFamilySuccess
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateBoltNutSaga),
  ]);
}
