import isEmpty from 'lodash/isEmpty';

import EntityRecord from '../../../modules/entities/model';

export default class FittingCode extends EntityRecord({
  name: {
    value: null,
  },
  description: {
    value: null,
  },
  changesSize: {
    value: false,
  },
  breaksPipeline: {
    value: false,
  },
  endCount: {
    value: 0,
  }
}) {
  get primary() { return this.name; }
  get primaryPlaceholder() { return 'No Name'; }
  get secondary() { return this.description; }
  get secondaryPlaceholder() { return '--'; }
}
