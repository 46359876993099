import { takeLatest, put, select, fork, all } from 'redux-saga/effects';

import { selectCurrentOrganization } from '../../../../entities/CurrentUser/selectors';

import { processFetchAllUsers } from '../../../../entities/Users/actions';
import { processForceResetPasswordTypes } from '../../../../modules/auth/constants';

export function* handleForceResetPassword() {
  const currentOrganization = yield select(selectCurrentOrganization());

  yield put(processFetchAllUsers(currentOrganization.id));
}

export function* watchForceResetPasswordSaga() {
  yield takeLatest(processForceResetPasswordTypes.SUCCESS, handleForceResetPassword);
}

// final output saga
export default function* main() {
  yield all([
    fork(watchForceResetPasswordSaga),
  ]);
}
