import React, { useCallback } from 'react';

import Flex from '../../../common/Flex';
import Icon from '../../../common/Icon';

import BaseInput from '../../BaseInput';
import BaseAppend from '../../BaseAppend';
import BaseMultiOptionValue from '../../BaseMultiOptionValue';
import BaseSelectRelativableContainer from '../../BaseSelectRelativableContainer';
import BaseSelectDefaultEditableCurrentOptionTemplate from '../../BaseSelectDefaultEditableCurrentOptionTemplate';

const BaseCurrentEditableMultiOptionValue = ({ forwardRef, isLoading, getInputProps, inputValue, selectedItems, name, placeholder, currentHighlightedItem, isOpen, setSelectState, isCapitalized, itemHeight }) => {
  const onFocus = useCallback(() => {
    setSelectState(prev => ({ ...prev, isOpen: true }));
  }, [setSelectState]);
  const onClick = useCallback(e => {
    e.stopPropagation();

    if (forwardRef && forwardRef.current) {
      if (isOpen) {
        setSelectState(prev => ({ ...prev, isOpen: false, inputValue: '', highlightedIndex: 0 }));
      } else {
        setSelectState(prev => ({ ...prev, isOpen: true }));
        forwardRef && forwardRef.current && forwardRef.current.focus();
      }
    }
  }, [forwardRef, setSelectState, isOpen]);

  return (
    <Flex flexDirection='row' alignItems='center' height='100%' cursor='pointer'>
      <BaseSelectRelativableContainer.Parent>
        <BaseSelectDefaultEditableCurrentOptionTemplate isLoading={isLoading} item={currentHighlightedItem} itemHeight={itemHeight} invisible={!isOpen}>
          <BaseInput 
            { 
              ...getInputProps({ 
                placeholder: isOpen && currentHighlightedItem && currentHighlightedItem.primary || placeholder,
                name,
                value: inputValue,
                onFocus,
                ref: forwardRef,
              }) 
            } 
          />
        </BaseSelectDefaultEditableCurrentOptionTemplate>
        {
          !isOpen && (
            <BaseSelectRelativableContainer.Child>
              <BaseMultiOptionValue selectedItems={selectedItems} isCapitalized={isCapitalized} onClick={selectedItems.size === 0 && onClick || null} />
            </BaseSelectRelativableContainer.Child>
          )
        }
      </BaseSelectRelativableContainer.Parent>
      <BaseAppend onClick={onClick}>
        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </BaseAppend>
    </Flex>
  );
};

const CurrentEditableOptionValue = React.forwardRef((props, ref) => <BaseCurrentEditableMultiOptionValue forwardRef={ref} { ...props } />);

export default CurrentEditableOptionValue;
