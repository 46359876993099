import React from 'react';

const EmailOpen = ({ id = 'email-open-graphic', backgroundColor = '#484F59', iconColor = '#01C6DA', iconAccentColor = '#01BACC' }) => (
  <svg viewBox='0 0 107 105'>
    <defs>
      <path d='M53.291,104.475 C24.066,104.475 0.291,81.069 0.291,52.299 C0.291,23.53 24.066,0.125 53.291,0.125 C82.516,0.125 106.291,23.53 106.291,52.299 C106.291,81.069 82.516,104.475 53.291,104.475 Z' id={`${id}-path-1`}></path>
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path d='M53.291,104.475 C24.066,104.475 0.291,81.069 0.291,52.299 C0.291,23.53 24.066,0.125 53.291,0.125 C82.516,0.125 106.291,23.53 106.291,52.299 C106.291,81.069 82.516,104.475 53.291,104.475' fill={backgroundColor}></path>
      <g>
        <mask id={`${id}-mask-2`} fill='white'>
          <use href={`#${id}-path-1`}></use>
        </mask>
        <g></g>
        <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='12.71 104.475 96.011 104.475 96.011 57.688 12.71 57.688'></polygon>
        <path d='M12.7,57.72 L50.278,57.72 C51.401,57.72 52.884,57.72 54.364,57.72 C55.847,57.72 57.327,57.72 58.452,57.72 L96.03,57.72 L78.308,42.605 C77.45,41.875 76.325,40.914 75.198,39.951 L65.472,31.658 L57.476,24.836 C55.765,23.377 52.964,23.377 51.255,24.836 L33.532,39.951 C32.405,40.914 31.278,41.875 30.423,42.605 L12.7,57.72' fill='#CDD5D7' mask={`url(#${id}-mask-2)`}></path>
        <polygon fill={iconColor} mask={`url(#${id}-mask-2)`} points='17.735 93.682 90.56 93.682 90.56 30.061 17.735 30.061'></polygon>
        <path d='M95.974,57.782 L85.612,66.616 L78.306,72.85 C77.45,73.578 76.323,74.54 75.196,75.502 L67.921,81.708 L40.808,81.708 L33.532,75.502 C32.405,74.54 31.278,73.578 30.421,72.85 L23.116,66.616 L12.755,57.782 L12.71,57.782 L12.71,105.803 L96.085,105.803 L96.085,57.782 L95.974,57.782' fill='#E5E8EC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M30.421,89.959 L12.71,105.793 L12.71,105.794 L30.421,90.567 C31.278,89.83 32.405,88.86 33.532,87.892 L40.767,81.673 L40.218,81.202 L33.532,87.18 C32.405,88.189 31.278,89.196 30.421,89.959' fill='#CDD5D7' mask={`url(#${id}-mask-2)`}></path>
        <path d='M78.306,89.959 C77.45,89.196 76.323,88.189 75.196,87.18 L68.511,81.202 L67.962,81.673 L75.196,87.892 C76.323,88.86 77.45,89.83 78.306,90.567 L96.03,105.803 L78.306,89.959' fill='#CDD5D7' mask={`url(#${id}-mask-2)`}></path>
        <path d='M55.442,43.682 C55.327,43.682 55.216,43.64 55.128,43.553 C54.954,43.379 54.954,43.098 55.128,42.925 L58.827,39.223 C58.999,39.049 59.28,39.049 59.456,39.223 C59.63,39.397 59.63,39.678 59.456,39.852 L55.757,43.553 C55.671,43.64 55.556,43.682 55.442,43.682' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M50.554,41.256 L50.534,41.256 C50.288,41.243 50.101,41.036 50.11,40.792 L50.364,35.159 C50.376,34.916 50.573,34.74 50.829,34.737 C51.075,34.748 51.263,34.956 51.253,35.199 L50.999,40.832 C50.987,41.072 50.79,41.256 50.554,41.256' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M57.866,48.569 C57.63,48.569 57.435,48.381 57.423,48.143 C57.413,47.897 57.603,47.692 57.847,47.68 L63.478,47.425 C63.737,47.434 63.929,47.604 63.94,47.85 C63.952,48.094 63.763,48.301 63.517,48.313 L57.888,48.569 L57.866,48.569' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M34.579,64.542 C34.466,64.542 34.353,64.5 34.267,64.411 C34.091,64.238 34.091,63.957 34.267,63.783 L37.964,60.082 C38.138,59.907 38.419,59.907 38.593,60.082 C38.767,60.256 38.767,60.535 38.593,60.709 L34.894,64.411 C34.808,64.5 34.694,64.542 34.579,64.542' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M42.911,68.899 L42.892,68.899 C42.646,68.888 42.456,68.68 42.468,68.434 L42.722,62.804 C42.733,62.565 42.931,62.381 43.167,62.381 L43.187,62.381 C43.433,62.391 43.62,62.598 43.61,62.842 L43.354,68.475 C43.345,68.713 43.147,68.899 42.911,68.899' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <path d='M30.224,56.209 C29.987,56.209 29.79,56.025 29.778,55.783 C29.769,55.54 29.958,55.333 30.202,55.323 L35.833,55.067 C36.112,55.061 36.286,55.246 36.298,55.491 C36.308,55.738 36.118,55.944 35.872,55.956 L30.243,56.209 L30.224,56.209' fill='#F7F8FC' mask={`url(#${id}-mask-2)`}></path>
        <polyline fill={iconAccentColor} mask={`url(#${id}-mask-2)`} points='66.536 61.848 45.853 56.446 51.255 77.127 54.841 70.574 61.323 77.056 66.462 71.914 59.976 65.436 66.536 61.848'></polyline>
        <polyline fill='#FFFFFF' mask={`url(#${id}-mask-2)`} points='67.866 58.259 47.183 52.858 52.587 73.539 56.171 66.984 62.653 73.465 67.792 68.325 61.306 61.846 67.866 58.259'></polyline>
        <polyline fill='#E5E8EC' mask={`url(#${id}-mask-2)`} points='47.396 52.911 47.183 52.858 52.587 73.539 56.171 66.984 62.653 73.465 65.302 70.817 47.396 52.911'></polyline>
        <polyline fill='#FFFFFF' mask={`url(#${id}-mask-2)`} points='50.106 55.688 50.606 54.822 58.751 59.523 58.251 60.388 50.106 55.688'></polyline>
      </g>
    </g>
  </svg>
);

export default EmailOpen;
