import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../../modules/entities/model';

export const VALUE_TYPES = [
  {value: 'bool', label: 'Boolean'},
  {value: 'string', label: 'String'},
  { value: 'double', label: 'Number'},
  { value: 'integer', label: 'Integer'}
];
export const VALUE_TYPE_DEFAULT = {
  bool: 'false',
  string: '',
  double: '0.00',
  integer: '0'
};

// prettier-ignore
export default class ProfileParameter extends EntityRecord({
  parameterName: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  valueType: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  defaultValue: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  isShownParameter: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  },
  isInputParameter: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  },
  profile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileId',
  },
  rowType: {
    value: 'Parameter',
    handleCopy: false,
    handleCompare: false,
    handleSerialize: false,
  }
}) {}
