import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  StandardizeModuleNoteProfilesPath,
  NoteProfilesEditPath,
} from '../../../../paths';

import {
  createNoteProfileTypes,
  copyNoteProfileTypes,
  editNoteProfileTypes,
  deleteNoteProfileTypes,
} from '../../../../entities/Standardize/NoteProfiles/constants';
import  {
  selectNormalizedNoteProfiles
} from '../../../../entities/Standardize/NoteProfiles/selectors';

export function* handleCreateNoteProfileSuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.NOTE_PROFILES.plural}[0].id`)}`;
  if (id) {
    let noteProfiles = yield select(selectNormalizedNoteProfiles());
    let copiedNoteProfile = noteProfiles.get(id);

    while (!copiedNoteProfile) {
      yield take();
      noteProfiles = yield select(selectNormalizedNoteProfiles());
      copiedNoteProfile = noteProfiles.get(id);
    }

    yield put(push(NoteProfilesEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteNoteProfileSuccess() {
  yield put(push(StandardizeModuleNoteProfilesPath.url));
}

export function* watchCreateNoteProfileSaga() {
  yield takeLatest([
    createNoteProfileTypes.SUCCESS,
    copyNoteProfileTypes.SUCCESS,
  ], handleCreateNoteProfileSuccess
  );
}

export function* watchDeleteNoteProfileSaga() {
  yield takeLatest(
    deleteNoteProfileTypes.SUCCESS,
    handleDeleteNoteProfileSuccess
  );
}

export function* handleCloseNoteProfileModal() {
  yield put(hideModal());
}

export function* watchCloseNoteProfileModalSaga() {
  yield takeLatest(
    [
      createNoteProfileTypes.SUCCESS,
      editNoteProfileTypes.SUCCESS,
      deleteNoteProfileTypes.SUCCESS,
    ],
    handleCloseNoteProfileModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateNoteProfileSaga),
    fork(watchDeleteNoteProfileSaga),
    fork(watchCloseNoteProfileModalSaga),
  ]);
}
