/**
 * TODO: validate that these are appropriate letterSpacings to use based on some sort of standard
 */

export default {
  normal: 'normal',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em',
};
