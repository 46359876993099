import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import { showModal } from '../../../../modules/modal/actions';

import { selectIsLoadingInitialData } from '../../../Dashboard/selectors';
import { isInitialLoadComplete } from '../../../Dashboard/actions';

// const desktopPluginDownloadUrl = `${isNodeEnvDevelopment() ? `${process.env.REACT_APP_DOWNLOADS_HOST}` : '/downloads'}/launcher/cadactive_installer.msi`
const desktopPluginDownloadUrl = 'https://cadactive-cadapi.s3.us-west-2.amazonaws.com/launcher/cadactive_installer.msi';

const HomeItem = styled(Flex)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const GettingStarted = ({
  isLoading,
  isInitialLoadComplete,
  openOrganizationUsersDirectory,
}) => {
  useEffect(() => {
    isInitialLoadComplete();
  }, []);
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      ml="auto"
      mr="auto"
    >
      <Box width="50rem">
        <Box mb={6}>
          <Text fontSize={5} textAlign="center" color="gray.7">
            {!isLoading ? (
              <React.Fragment>
                Welcome to <strong>CadActive</strong>
              </React.Fragment>
            ) : (
              <Skeleton />
            )}
          </Text>
        </Box>

        {desktopPluginDownloadUrl && (
          <Flex
            flexDirection="row"
            alignItems="center"
            bg="white"
            border={2}
            borderRadius={2}
            p={3}
            mb={4}
          >
            <HomeItem flexDirection="column" justifyContent="center" mr={3}>
              <Text fontSize={3} color="gray.7" mb={2}>
                {!isLoading ? (
                  <React.Fragment>
                    <strong>Download</strong> Plugin
                  </React.Fragment>
                ) : (
                  <Skeleton />
                )}
              </Text>
              <Text fontSize={2} color="gray.7">
                {!isLoading ? (
                  'Download the desktop plugin to get started with CadActive.'
                ) : (
                  <Skeleton />
                )}
              </Text>
            </HomeItem>
            {!isLoading && (
              <Flex flexDirection="column" justifyContent="center">
                <Button
                  primary
                  as="a"
                  href={desktopPluginDownloadUrl}
                  target="_blank"
                  width="10rem"
                >
                  Download
                </Button>
              </Flex>
            )}
          </Flex>
        )}

        <Flex
          flexDirection="row"
          alignItems="center"
          bg="white"
          border={2}
          borderRadius={2}
          p={3}
          mb={4}
        >
          <HomeItem flexDirection="column" justifyContent="center" mr={3}>
            <Text fontSize={3} color="gray.7" mb={2}>
              {!isLoading ? (
                <React.Fragment>
                  <strong>Manage</strong>{' '} Users
                </React.Fragment>
              ) : (
                <Skeleton />
              )}
            </Text>
            <Text fontSize={2} color="gray.7">
              {!isLoading ? (
                'Add or Invite members to your organization, assign product licenses, and manage permissions.'
              ) : (
                <Skeleton />
              )}
            </Text>
          </HomeItem>
          {!isLoading && (
            <Flex flexDirection="column" justifyContent="center">
              <Button
                primary
                onClick={openOrganizationUsersDirectory}
                width="10rem"
              >
                Manage Users
              </Button>
            </Flex>
          )}
        </Flex>

        <Flex
          flexDirection="row"
          alignItems="center"
          bg="white"
          border={2}
          borderRadius={2}
          p={3}
          mb={4}
        >
          <HomeItem flexDirection="column" justifyContent="center" mr={3}>
            <Text fontSize={3} color="gray.7" mb={2}>
              {!isLoading ? (
                <React.Fragment>
                  <strong>Questions</strong>?
                </React.Fragment>
              ) : (
                <Skeleton />
              )}
            </Text>
            <Text fontSize={2} color="gray.7">
              {!isLoading ? (
                'Browse through support documentation, submit bugs or feedback, or contact our support team.'
              ) : (
                <Skeleton />
              )}
            </Text>
          </HomeItem>
          {!isLoading && (
            <Flex flexDirection="column" justifyContent="center">
              <Button
                primary
                as="a"
                href="//support.cadactive.com/support/solutions"
                target="_blank"
                width="10rem"
              >
                Support
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoadingInitialData(),
});

const mapDispatchToProps = dispatch => ({
  isInitialLoadComplete() { dispatch(isInitialLoadComplete()); },
  openOrganizationUsersDirectory() { dispatch(showModal('ORGANIZATION_MODAL')); },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(GettingStarted);
