import React from 'react';

import Button from '../../../components/common/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { FrameworkModuleProfileTablesPath } from '../../../paths';

import colors from '../../../assets/themes/base/colors';
import { push } from 'connected-react-router';

//popup styling
const contentStyle = { width: 'fit-content' };
export const ConstructedOptions = (profileId, libraryId, dispatch) => {
  const tablesLink = FrameworkModuleProfileTablesPath.generate({ id: profileId, libraryId: libraryId });
  return (
    <>
      <Popup trigger={() => <Button icon="dynamic-feed" secondary={true} onClick={() => dispatch(push(tablesLink))} style={{ marginRight: '10px' }} />} position='bottom center' on={['hover']} {...{contentStyle}} >
        To Tables
      </Popup>
    </>
  );
};