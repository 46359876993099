import React from 'react';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';

import Flex from '../../common/Flex';
import Text from '../../common/Text';

const StyledCreateOption = styled(Flex).attrs(({disabled}) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  pl: 3,
  pr: 3,
}))`
  background-color: ${colors.gray[1]};

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.gray[2]};
  }
`;

const BaseCreateOption = React.memo(({ width, height, currentValue, disabled, onClick }) => (
  <StyledCreateOption style={{ width, height }} disabled={disabled} onClick={!disabled && onClick || null}>
    <Text color='gray.7' fontWeight={600} >Create new item <strong>{ currentValue }</strong></Text>
  </StyledCreateOption>
));

export default BaseCreateOption;