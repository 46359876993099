import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

// Data Grid imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// sagas
import saga from './sagas';
import injectSaga from '../../../utils/sagas/injectSaga';
import EK from '../../../entities/keys';

// cell imports
import {
  nameBody,
  descriptionBody,
  codeBody,
} from './components';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

// dialog imports
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {
  dialogFooter,
  deleteDialogFooter,
} from './components';
import { areFieldsValid, doRequiredFieldsExist } from './components';
import { ALLOWANCES, BlankBoltNutFamily, VALIDATION_FIELDS } from '../../../entities/Piping/BoltNutFamilies/model';

// misc imports
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

// Header imports
import Header from '../../templates/Structures/Header';
import Skeleton from 'react-loading-skeleton';
import Options from '../../templates/Structures/Options';
import { pluralize } from '../../../components/common/Header';
import { PipingModuleBoltNutsPath, PipingModuleBoltNutsEditPath } from '../../../paths';

// actions
import {
  processArchiveBoltNutFamilies,
  processUnarchiveBoltNutFamilies,
  processCopyBoltNutFamily,
  processCreateBoltNutFamily,
  processDeleteBoltNutFamily,
  processEditBoltNutFamily,
  processFetchAllBoltNutFamilies,
} from '../../../entities/Piping/BoltNutFamilies/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';
import {
  selectCurrentFilteredBoltNuts,
  selectBoltNutFamiliesCounts,
} from '../../../entities/Piping/BoltNutFamilies/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { BoltNutDialog, DeleteBoltNutDialog } from '../../../containers/dialog/templates/BoltNutDialog';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredBoltNuts(),
  selectBoltNutFamiliesCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    {
      total,
      unarchived
    },
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      total,
      unarchived,
      canCollaborate,
      hasValidLicense,
    };
  },
);

function BoltNutsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);         // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  // used to provide data for the modals
  const [currentEditingRow, setCurrentEditingRow] = useState(BlankBoltNutFamily);

  // header actions
  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BlankBoltNutFamily);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Bolt Nut Family';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const saveAction = () => {
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      boltNutCode: currentEditingRow.boltNutCode,
      description: currentEditingRow.description,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateBoltNutFamily(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyBoltNutFamily(editedRow));
    } else {
      dispatch(processEditBoltNutFamily(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteBoltNutFamily(currentEditingRow.id));
    setCurrentEditingRow(BlankBoltNutFamily);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    // closes the other dialogs and opens the delete Dialog
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  //cell actions
  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const gotoBoltNutInstances = (insulationId) => {
    const link = PipingModuleBoltNutsEditPath.generate({ id: insulationId });
    dispatch(push(link));
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      boltNutCode: rowdata.boltNutCode,
      description: rowdata.description,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    setCurrentEditingRow(rowdata);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveBoltNutFamilies([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveBoltNutFamilies([rowdata.id]));
  };

  useEffect(() => {
    dispatch(processFetchAllBoltNutFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading || (reduxProps.isFetching && reduxProps.total === 0)}
          title={PipingModuleBoltNutsPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Bolt Nut',
              reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.showArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Bolt Nut', reduxProps.unarchived)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='name' header='Name // Instances' body={(rowData) => nameBody(rowData, gotoBoltNutInstances)} style={{ width: '25%' }} ></Column>
          <Column field='boltNutCode' header='Bolt Nut Code' body={codeBody} style={{ width: '25%' }} ></Column>
          <Column field='description' header='Description' body={descriptionBody} style={{ width: '25%' }} ></Column>
          <Column header=''
            style={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => gotoBoltNutInstances(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <BoltNutDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        getDialogHeader={getDialogHeader}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
      />
      <DeleteBoltNutDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default injectSaga({ key: `${EK.BOLT_NUTS.state}Page`, saga })(withRouter(BoltNutsGrid));
