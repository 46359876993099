import EntityRecord, { FILTERS } from '../../../modules/entities/model';

// prettier-ignore
export default class ProfileTableColumn extends EntityRecord({
  columnType: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
  },
  profileDimensionId: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileDimensionId',
  },
  profileParameterId: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileParameterId',
  },
  profileSubtableColumnName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileSubtableColumnName',
  },
  table: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: false,
  }
}) {}