import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import fontSizes from '../../../assets/themes/base/fontSizes';

import Flex from '../Flex';
import Text from '../Text';

const Wrapper = styled(Flex).attrs(({ mb }) => ({
  mb: mb !== undefined ? mb : 2,
}))`
  flex-direction: column;
`;

const Label = styled(Text)`
  color: ${colors.gray[5]};
  font-size: ${fontSizes[2]};
  font-weight: 500;
`;

const Main = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
`;

const Field = styled(Text)`
  color: ${colors.gray[7]};
  font-weight: 400;

  ${({ $capitalize }) => $capitalize && css`
    text-transform: capitalize;
  `}
`;

const Sub = styled(Text)`
  color: ${colors.gray[6]};
  font-size: ${fontSizes[1]};
  font-weight: 400;
  padding-left: 1px;
`;

export const F1 = ({ label, sub, children, capitalize, ...props }) => (
  <Wrapper { ...props }>
    <Label $ellipsis>{ label }</Label>
    <Main>
      <Field fontSize={6} $ellipsis $capitalize={capitalize}>{ children }</Field>
      { sub && <Sub $ellipsis>{ sub }</Sub> }
    </Main>
  </Wrapper>
);

export const F2 = ({ label, sub, children, capitalize, ...props }) => (
  <Wrapper { ...props }>
    <Label $ellipsis>{ label }</Label>
    <Main>
      <Field fontSize={5} $ellipsis capitalize={capitalize}>{ children }</Field>
      { sub && <Sub $ellipsis>{ sub }</Sub> }
    </Main>
  </Wrapper>
);

export const F3 = ({ label, sub, children, capitalize, ...props }) => (
  <Wrapper { ...props }>
    <Label $ellipsis>{ label }</Label>
    <Main>
      <Field fontSize={4} $ellipsis capitalize={capitalize}>{ children }</Field>
      { sub && <Sub $ellipsis>{ sub }</Sub> }
    </Main>
  </Wrapper>
);

export default {
  F1, F2, F3
};
