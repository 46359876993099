import React from 'react';

import theme from '../../../../assets/themes/theme';

const ChecklistGraphic = ({
  id = 'checklist-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <circle
      fill={disabled ? disabledBackgroundColor : backgroundColor}
      cx="256"
      cy="256"
      r="254"
    />
    <g>
      <path
        fill={theme.colors.gray[0]}
        d="M319.2,94.8H148.9c-7.2,0-13.1,5.8-13.1,13.1v296.4c0,7.2,5.8,13.1,13.1,13.1h214.3
        c7.2,0,13.1-5.8,13.1-13.1V151.8h-44c-7.2,0-13.1-5.8-13.1-13.1V94.8z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M319.2,94.8v44c0,7.2,5.8,13.1,13.1,13.1h44L319.2,94.8z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M201.9,235h-31c-1.1,0-2-0.9-2-2v-31c0-1.1,0.9-2,2-2h31c1.1,0,2,0.9,2,2v31
        C203.9,234.1,203,235,201.9,235z M172.9,231.1h27v-27h-27V231.1z"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M188.5,226.2c-1.2,0-2.4-0.6-3.2-1.6l-7.2-9.5c-1.3-1.8-1-4.2,0.8-5.6c1.8-1.3,4.2-1,5.6,0.8l4,5.3
        l22.5-29.8c1.3-1.7,3.8-2.1,5.6-0.8c1.7,1.3,2.1,3.8,0.8,5.6l-25.7,34C190.9,225.6,189.8,226.2,188.5,226.2z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M201.9,280.6h-31c-1.1,0-2-0.9-2-2v-31c0-1.1,0.9-2,2-2h31c1.1,0,2,0.9,2,2v31
          C203.9,279.7,203,280.6,201.9,280.6z M172.9,276.6h27v-27h-27V276.6z"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M188.5,271.8c-1.2,0-2.4-0.6-3.2-1.6l-7.2-9.5c-1.3-1.7-1-4.2,0.8-5.6c1.8-1.3,4.2-1,5.6,0.8l4,5.3
          l22.5-29.8c1.3-1.7,3.8-2.1,5.6-0.8c1.7,1.3,2.1,3.8,0.8,5.6l-25.7,34C190.9,271.2,189.8,271.8,188.5,271.8z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M201.9,326.2h-31c-1.1,0-2-0.9-2-2v-31c0-1.1,0.9-2,2-2h31c1.1,0,2,0.9,2,2v31
          C203.9,325.3,203,326.2,201.9,326.2z M172.9,322.2h27v-27h-27V322.2z"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M188.5,317.3c-1.2,0-2.4-0.6-3.2-1.6l-7.2-9.5c-1.3-1.7-1-4.2,0.8-5.6c1.8-1.3,4.2-1,5.6,0.8l4,5.3
          L211,277c1.3-1.7,3.8-2.1,5.6-0.8c1.7,1.3,2.1,3.8,0.8,5.6l-25.7,34C190.9,316.8,189.8,317.3,188.5,317.3z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M201.9,371.8h-31c-1.1,0-2-0.9-2-2v-31c0-1.1,0.9-2,2-2h31c1.1,0,2,0.9,2,2v31
          C203.9,370.9,203,371.8,201.9,371.8z M172.9,367.8h27v-27h-27V367.8z"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M188.5,362.9c-1.2,0-2.4-0.6-3.2-1.6l-7.2-9.5c-1.3-1.7-1-4.2,0.8-5.6c1.8-1.3,4.2-1,5.6,0.8l4,5.3
        l22.5-29.8c1.3-1.7,3.8-2.1,5.6-0.8c1.7,1.3,2.1,3.8,0.8,5.6l-25.7,34C190.9,362.3,189.8,362.9,188.5,362.9z"
      />
      <rect
        x="244.8"
        y="201.6"
        fill={theme.colors.gray[4]}
        width="102.9"
        height="7.2"
      />
      <rect
        x="244.8"
        y="218.6"
        fill={theme.colors.gray[4]}
        width="63.5"
        height="7.2"
      />
      <rect
        x="244.8"
        y="251"
        fill={theme.colors.gray[4]}
        width="102.9"
        height="7.2"
      />
      <rect
        x="244.8"
        y="268"
        fill={theme.colors.gray[4]}
        width="63.5"
        height="7.2"
      />
      <rect
        x="244.8"
        y="300.4"
        fill={theme.colors.gray[4]}
        width="102.9"
        height="7.2"
      />
      <rect
        x="244.8"
        y="317.4"
        fill={theme.colors.gray[4]}
        width="63.5"
        height="7.2"
      />
      <rect
        x="244.8"
        y="349.8"
        fill={theme.colors.gray[4]}
        width="102.9"
        height="7.2"
      />
      <rect
        x="244.8"
        y="366.8"
        fill={theme.colors.gray[4]}
        width="63.5"
        height="7.2"
      />
    </g>
  </svg>
);

export default ChecklistGraphic;
