import { createAction } from 'redux-actions';

import {
  PREPARE_CONFIG_PROFILE_EDITOR_MODAL,
  CONFIG_PROFILE_EDITOR_MODAL_PREPARED,
  SAVE_CONFIG_PROFILE,
} from './constants';

export const prepareConfigProfileEditorModal = createAction(PREPARE_CONFIG_PROFILE_EDITOR_MODAL, (id, copyEntity) => ({ id, copyEntity }));
export const configProfileEditorModalPrepared = createAction(CONFIG_PROFILE_EDITOR_MODAL_PREPARED);
export const saveConfigProfile = createAction(SAVE_CONFIG_PROFILE);