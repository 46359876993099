import { toast } from 'react-toastify';

import sizeClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllSizesTypes, saveSizesTypes } from './constants';

export const processFetchAllSizes = () => sizeClient(REQUEST_TYPES.GET, 'plural', fetchAllSizesTypes, '/piping/sizes');

export const processSaveSizes = (data) => sizeClient(REQUEST_TYPES.PATCH, 'plural', saveSizesTypes, '/piping/sizes/collection?diff_result=true', {
  data: { sizesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
