import React from 'react';
import { compose } from 'redux';
import { FormSection, FieldArray, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import space from '../../../../assets/themes/base/space';
import colors from '../../../../assets/themes/base/colors';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';

import {
  InviteFieldEmailFormSection,
  InviteFieldPermissionsFormSection,
} from '../OrganizationUserInviteForm';

const Invite = styled(Flex)`
  flex-direction: row;
  align-items: center;
  height: 4rem;
  padding-left: ${space[4]};
  padding-right: ${space[4]};
  border-bottom: 1px solid ${colors.gray[0]};

  &:last-child {
    border-bottom: none;
  }
`;

const renderInvites = ({ fields, isPerforming, allowedRoles }) => {
  return (fields.map((invite, index) => (
    <Invite key={index} >
      <InviteFieldEmailFormSection invite={invite} isPerforming={isPerforming} />
      <InviteFieldPermissionsFormSection invite={invite} isPerforming={isPerforming} allowedRoles={allowedRoles} />
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='3rem'>
        <Button transparent subtle icon='close' type='button' error onClick={() => fields.remove(index)} />
      </Flex>
    </Invite>
  )));
};

const OrganizationUserInvitesForm = ({ handleSubmit, isPerforming, allowedRoles }) => {
  return (
    <Box as="form" onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto' }}>
      <FieldArray name={EK.ORGANIZATION_USER_INVITES.plural} component={renderInvites} allowedRoles={allowedRoles} isPerforming={isPerforming} />
    </Box>
  );
};

const enhance = compose(
  reduxForm({
    form: `${EK.ORGANIZATION_USER_INVITES.state}`,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.get(EK.ORGANIZATION_USER_INVITES.state).map(invite => invite.serialize()).toArray());
    },
  })
);

export default enhance(OrganizationUserInvitesForm);
