export function areFieldsValid({ name, noteBody }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    noteBody: !!noteBody
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name, noteBody }) {
  // takws the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!noteBody;
}
