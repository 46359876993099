import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { hideModal } from '../../../../modules/modal/actions';

import { createNoteBlockTypes, editNoteBlockTypes } from '../../../../entities/Standardize/NoteBlocks/constants';

export function* handleCloseNoteBlockModal() {
  yield put(hideModal());
}

export function* watchCloseNoteBlockModalSaga() {
  yield takeLatest([
    createNoteBlockTypes.SUCCESS,
    editNoteBlockTypes.SUCCESS,
  ], handleCloseNoteBlockModal);
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCloseNoteBlockModalSaga),
  ]);
}
