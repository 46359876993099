import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';
import transitions from '../../../assets/themes/base/transitions';

const StatusRequired = styled.div`
  position: absolute;
  top: ${space[3]};
  right: ${space[3]};

  height: ${space[2]};
  width: ${space[2]};
  background-color: ${colors.primary[3]};

  ${
  ({ error }) => error && css`
      background-color: ${colors.error[3]};
    `
}

  border-radius: 100%;

  transition: transform ${transitions.speed.fast} ${transitions.type.out};

  &:hover {
    cursor: pointer;
    transform: scale(1.4);
  }
`;

export default StatusRequired;