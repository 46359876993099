import { createSelector } from 'reselect';

import { selectIsFetching, selectIsPerforming } from '../../modules/utility/selectors';

import {
  selectCurrentUserCanCollaborate,
  selectCurrentUserCanAdmin,
  selectCurrentUserHasPipingModule,
  selectCurrentUserHasSynchronizeModule,
  selectCurrentUserHasStandardizeModule,
  selectCurrentOrganizationIsValid,
  selectCurrentUserHasFrameworkModule,
} from '../../modules/auth/utils/_selectors';

export const selectIsLoadingInitialData = () => state => !state.getIn(['dashboard', 'isInitialLoadComplete']);

export const selectSidebarIsMinimized = () => state => state.getIn(['dashboard', 'sidebarIsMinimized']);
export const selectSidebarIsFiltering = () => state => state.getIn(['dashboard', 'sidebarIsFiltering']);
export const selectShowHiddenGridColumns = () => state => state.getIn(['dashboard', 'showHiddenGridColumns']);

export const selectIsDoneFetchingInitialData = () => createSelector(
  selectIsFetching(),
  selectIsLoadingInitialData(),
  (isFetching, isLoadingInitialData) => !isLoadingInitialData && !isFetching
);

const basePermissionsSelector = () => createSelector(
  selectIsLoadingInitialData(),
  selectSidebarIsFiltering(),
  selectIsFetching(),
  selectIsPerforming(),
  selectCurrentUserCanCollaborate(),
  (isLoadingInitialData, isFiltering, isFetching, isPerforming, canCollaborate) => ({
    isLoadingInitialData,
    isFiltering,
    isFetching,
    isPerforming,
    canCollaborate
  })
);

export const selectSynchronizeModulePermissionsAndState = () => createSelector(
  basePermissionsSelector(),
  selectCurrentUserHasSynchronizeModule(),
  ({isLoadingInitialData, isFiltering, isFetching, isPerforming, canCollaborate}, hasValidLicense) => ({
    isLoadingInitialData,
    isFiltering,
    isFetching,
    isPerforming,
    product: 'Synchronize',
    canCollaborate,
    hasValidLicense,
  })
);

export const selectStandardizeModulePermissionsAndState = () => createSelector(
  basePermissionsSelector(),
  selectCurrentUserHasStandardizeModule(),
  ({isLoadingInitialData, isFiltering, isFetching, isPerforming, canCollaborate}, hasValidLicense) => ({
    isLoadingInitialData,
    isFiltering,
    isFetching,
    isPerforming,
    product: 'Standardize',
    canCollaborate,
    hasValidLicense,
  })
);

export const selectPipingModulePermissionsAndState = () => createSelector(
  basePermissionsSelector(),
  selectCurrentUserHasPipingModule(),
  ({isLoadingInitialData, isFiltering, isFetching, isPerforming, canCollaborate}, hasValidLicense) => ({
    isLoadingInitialData,
    isFiltering,
    isFetching,
    isPerforming,
    product: 'Piping',
    canCollaborate,
    hasValidLicense,
  })
);

export const selectFrameworkModulePermissionsAndState = () => createSelector(
  basePermissionsSelector(),
  selectCurrentUserHasFrameworkModule(),
  ({isLoadingInitialData, isFiltering, isFetching, isPerforming, canCollaborate}, hasValidLicense) => ({
    isLoadingInitialData,
    isFiltering,
    isFetching,
    isPerforming,
    product: 'Framework',
    canCollaborate,
    hasValidLicense,
  })
);