import React from 'react';

export default function FitAssemblyIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="131.04 59.04 25.92 25.92"
      enableBackground="new 131.04 59.04 25.92 25.92"
      xmlSpace="preserve"
      style={style}
    >
      <polygon points="143.451,65.445 143.435,62.913 137.417,62.915 137.416,61.915 144.429,61.908 144.429,61.913 150.533,61.911 150.533,62.911 144.435,62.913 144.451,65.439 " />
      <path d="M150.065,75.02h-12.255l4.435-5.047l-4.429-5.027l12.256-0.004l-4.438,5.032L150.065,75.02z M140.021,74.02h7.836l-3.554-4.048l3.554-4.029l-7.827,0.002l3.548,4.027L140.021,74.02z" />
      <path d="M143.932,82.092c-1.294,0-5.517-0.109-5.517-1.523v-2.506h11.044l0.005,2.505C149.465,81.982,145.23,82.092,143.932,82.092zM139.416,80.458c0.34,0.235,1.915,0.634,4.517,0.634c2.611,0,4.191-0.398,4.533-0.635l-0.003-1.395h-9.046V80.458z" />
      <rect x="143.44" y="74.52" width="1" height="4.043" />
    </svg>
  );
}
