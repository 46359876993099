import EntityRecord from '../../../modules/entities/model';

class PipingCatalog extends EntityRecord({
  name: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
}) { }

export default PipingCatalog;