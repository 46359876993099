import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// header imports
import { SynchronizeModuleMapkeysPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

// general imports
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

// grid imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { keystrokeCell, sanitizeMapkeyAction, mapkeyActionFieldStyle } from './components';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// dialog imports
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Button from '../../../components/common/Button/index';

// selectors
import { selectNormalizedMapkeysList } from '../../../entities/Synchronize/Mapkeys/selectors';
import { selectSynchronizeModulePermissionsAndState } from '../../Dashboard/selectors';

// actions
import {
  processFetchAllMapkeys,
  processDeleteMapkey,
  processCreateMapkey,
  processEditMapkey,
} from '../../../entities/Synchronize/Mapkeys/actions';

const mapStateToProps = createSelector(
  selectSynchronizeModulePermissionsAndState(),
  selectNormalizedMapkeysList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense,
      canCollaborate,
      hasValidLicense,
    };
  },
);

function MapkeyGrid (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentEditableRow, setCurrentEditableRow] = useState({ keystroke: '', name: '', description: '', mapkeyAction: '' });

  const editRow = (rowData) => {
    setCurrentEditableRow({ id: rowData.id, keystroke: rowData.keystroke, name: rowData.name, description: rowData.description, mapkeyAction: rowData.mapkeyAction });
    setIsDialogOpen(true);
  };

  const cancelDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setCurrentEditableRow({ keystroke: '', name: '', description: '', mapkeyAction: '' });
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditableRow({ keystroke: '', name: '', description: '', mapkeyAction: '' });
  };

  const dialogFooter = () => (
    <>
      {!isCreateDialogOpen && <Button error={true} onClick={openDeleteDialog} style={{ float: 'left' }} >Delete</Button>}
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={() => {dispatch(isCreateDialogOpen ? processCreateMapkey(currentEditableRow) : processEditMapkey(currentEditableRow.id, currentEditableRow)); cancelDialog();}} >Save</Button>
    </>
  );

  const deleteDialogFooter = () => (
    <>
      <Button secondary={true} onClick={closeDeleteDialog} >Cancel</Button>
      <Button error={true} onClick={() => { closeDeleteDialog(); dispatch(processDeleteMapkey(currentEditableRow.id));}} >Delete</Button>
    </>
  );

  const onInputChange = (value, field) => {
    let _currentEditableRow = { ...currentEditableRow };
    _currentEditableRow[`${field}`] = value;
    setCurrentEditableRow(_currentEditableRow);
  };

  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => editRow(rowdata)} />
      </>
    );
  };

  useEffect(() => {
    dispatch(processFetchAllMapkeys());
  }, []);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={SynchronizeModuleMapkeysPath.defaultTitle}
          subtitle={`${reduxProps.data.length || 0} ${(!!reduxProps.data.length && reduxProps.data.length - 10 > 1) || !reduxProps.data.length ? 'Map Keys' : 'Map Key'} Total`}
        />
        <Options
          createEntity={() => {setIsCreateDialogOpen(true); setIsDialogOpen(true);}}
          isLoading={reduxProps.isLoading}
          canAdmin={reduxProps.canAdmin}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='keystroke' header='Keystroke' body={(rowdata) => keystrokeCell(rowdata.keystroke)} style={{ width: '15%' }} ></Column>
          <Column field='name' header='Name' style={{ width: '28%' }} ></Column>
          <Column field='description' header='Description' style={{ width: '52%' }} ></Column>
          <Column header='' body={actionBodyTemplate} style={{ width: '5%' }} ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '50%' }} header={isCreateDialogOpen ? 'Create Mapkey' : 'Edit Mapkey'} footer={dialogFooter} modal className="p-fluid" closable={false} >
        <div>
          <label htmlFor="keystroke" className="font-bold">Keystroke</label>
          <InputText id="keystroke" value={currentEditableRow.keystroke} onChange={(e) => onInputChange(e.target.value, 'keystroke')} required autoFocus={isCreateDialogOpen} />
        </div>
        <div>
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText id="name" value={currentEditableRow.name} onChange={(e) => onInputChange(e.target.value, 'name')} required />
        </div>
        <div>
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea id="description" value={currentEditableRow.description} onChange={(e) => onInputChange(e.target.value, 'description')} required rows={3} cols={20} />
        </div>
        <div>
          <label htmlFor="mapkeyAction" className="font-bold">Mapkey Action</label>
          <textarea
            style={mapkeyActionFieldStyle}
            value={currentEditableRow.mapkeyAction}
            onChange={(e) => onInputChange(e.target.value, 'mapkeyAction')}
            onPaste={(e) => { e.preventDefault(); onInputChange(sanitizeMapkeyAction(e.clipboardData.getData('text')), 'mapkeyAction'); }}
          ></textarea>
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Mapkey '${currentEditableRow.name}'`} footer={deleteDialogFooter} modal className='p-fluid' closable={false} >
        <div>
          <Text>Are you sure you want to delete &apos;{currentEditableRow.name}&apos;?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(MapkeyGrid);
