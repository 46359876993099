import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import selectDrafts from '../../../modules/drafts/selectors';

import LayerSchema from './schema';

export const selectNormalizedLayers = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.LAYERS.state)
  );
export const selectNormalizedLayersList = () =>
  createSelector(selectNormalizedLayers(), (layers) =>
    layers.toList()
  );

export const selectNormalizedDraftLayers = () =>
  createSelector(selectDrafts(), (drafts) => {
    const state = drafts.get(EK.LAYERS.state);
    const records = state.get('records');
    return state.get('ids').map((id) => records.get(id));
  });

export const selectLayers = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.LAYERS.state),
      [LayerSchema],
      entities
    )
  );
export const selectDraftLayers = () =>
  createSelector(
    selectEntities(),
    selectNormalizedDraftLayers(),
    (entities, drafts) =>
      denormalize(drafts, [LayerSchema], entities)
  );
