import { createAction } from 'redux-actions';

import {
  WATCH_BUTTON_ACTION,
  SET_BUTTON_ACTION_STATUS,
  UNWATCH_BUTTON_ACTION,
} from './constants';

export const watchButtonAction = createAction(WATCH_BUTTON_ACTION, (id, startActions, endActions) => ({ id, startActions, endActions }));
export const setButtonActionStatus = createAction(SET_BUTTON_ACTION_STATUS, (id, status) => ({ id, status }));
export const unwatchButtonAction = createAction(UNWATCH_BUTTON_ACTION, id => ({ id }));