/**
 * Based on a variation of Material Design Box Shadows from here:
 * https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
 *
 * Another option is the MaterializeCSS shadows, but they were a bit too fuzzy for my liking
 */

export default [
  'none',
  '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
];
