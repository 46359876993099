import { Map } from 'immutable';
import { toast } from 'react-toastify';

import { processLogout } from '../auth/constants';

import { SHOW_NOTICE, HIDE_NOTICE } from './constants';

const initialNoticeState = Map({});

const noticeReducer = (state = initialNoticeState, { type, payload }) => {
  if (type === SHOW_NOTICE) {
    const { id, toastId } = payload;

    return id && toastId ? state.set(id, toastId) : state;
  } else if (type === HIDE_NOTICE) {
    const id = payload.id;

    if (id && state.has(id)) {
      toast.dismiss(state.get(id));
      return state.delete(id);
    } else {
      return state;
    }
  } else if (type === processLogout.HANDLED) {
    state.forEach(toastId => toast.dismiss(toastId));

    return initialNoticeState;
  } else {
    return state;
  }
};

export default noticeReducer;