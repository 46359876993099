import { toast } from 'react-toastify';

import settingClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllSettingsTypes, saveSettingsTypes } from './constants';

export const processFetchAllSettings = () => settingClient(REQUEST_TYPES.GET, 'plural', fetchAllSettingsTypes, '/settings');

export const processSaveSettings = (_, data) => settingClient(REQUEST_TYPES.PATCH, 'plural', saveSettingsTypes, '/settings?diff_result=true', {
  data: { settings: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
