import React from 'react';

import theme from '../../../../assets/themes/theme';

const AccessMissing = ({
  id = 'access-missing-graphic',
  backgroundColor = theme.colors.primary[4],
  backgroundAccentColor = theme.colors.primary[3],
  iconColor = theme.colors.gray[4],
  iconAccentColor = theme.colors.gray[3],
}) => (
  <svg id={id} data-name={`data-${id}`} viewBox="0 0 106 105">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M53,104.35 C23.776,104.35 0,80.945 0,52.175 C0,23.406 23.776,0 53,0 C82.225,0 106,23.406 106,52.175 C106,80.945 82.225,104.35 53,104.35"
          fill={backgroundColor}
        ></path>
        <path
          d="M66.233,64.64 C62.315,68.573 57.119,70.732 51.591,70.736 C40.193,70.741 30.92,61.473 30.92,50.075 C30.92,44.576 33.062,39.398 36.954,35.493 C40.866,31.564 46.063,29.402 51.582,29.398 C57.098,29.398 62.286,31.545 66.198,35.461 C70.106,39.364 72.262,44.557 72.266,50.066 C72.266,55.559 70.129,60.735 66.233,64.64 M69.551,32.101 C64.742,27.298 58.362,24.655 51.582,24.656 C44.787,24.656 38.395,27.312 33.589,32.138 C28.806,36.944 26.171,43.317 26.171,50.075 C26.176,64.086 37.581,75.489 51.593,75.483 C58.395,75.481 64.787,72.817 69.596,67.992 C74.383,63.188 77.009,56.821 77.009,50.065 C77.006,43.284 74.36,36.905 69.551,32.101"
          fill={theme.colors.gray[8]}
        ></path>
        <path
          d="M51.577,70.665 C40.223,70.664 30.99,61.429 30.992,50.077 C30.992,44.594 33.125,39.433 37.005,35.542 C40.9,31.625 46.079,29.471 51.584,29.469 C57.077,29.468 62.25,31.61 66.148,35.506 C70.042,39.403 72.192,44.573 72.197,50.065 C72.192,55.541 70.068,60.698 66.186,64.59 C62.281,68.508 57.101,70.663 51.591,70.665 C51.586,70.665 51.582,70.665 51.577,70.665 M51.58,30.157 L51.555,30.159 C46.243,30.169 41.248,32.254 37.487,36.031 C33.743,39.789 31.679,44.78 31.679,50.079 C31.679,55.386 33.752,60.38 37.517,64.145 C39.819,66.448 42.584,68.116 45.6,69.061 C44.393,68.289 43.258,67.385 42.219,66.346 C38.454,62.583 36.38,57.585 36.38,52.279 C36.38,46.979 38.444,41.991 42.192,38.229 C45.952,34.455 50.947,32.37 56.252,32.361 L56.282,32.359 C56.286,32.359 56.289,32.359 56.292,32.359 C58.352,32.359 60.367,32.672 62.281,33.276 C59.109,31.247 55.43,30.157 51.58,30.157"
          fill={backgroundAccentColor}
        ></path>
        <path
          d="M45.6,69.061 C42.584,68.116 39.819,66.448 37.517,64.145 C33.752,60.38 31.679,55.386 31.679,50.079 C31.679,44.78 33.743,39.789 37.487,36.031 C41.248,32.254 46.243,30.169 51.555,30.159 L51.58,30.157 C55.43,30.157 59.109,31.247 62.281,33.276 C60.367,32.672 58.352,32.359 56.292,32.359 C56.289,32.359 56.286,32.359 56.282,32.359 L56.252,32.361 C50.947,32.37 45.952,34.455 42.192,38.229 C38.444,41.991 36.38,46.979 36.38,52.279 C36.38,57.585 38.454,62.583 42.219,66.346 C43.258,67.385 44.393,68.289 45.6,69.061"
          fill={backgroundColor}
        ></path>
        <path
          d="M51.591,71.913 C39.544,71.918 29.743,62.122 29.745,50.08 C29.745,44.264 32.01,38.789 36.121,34.664 C40.254,30.513 45.746,28.227 51.58,28.222 L51.584,26.092 L51.535,26.092 C45.141,26.106 39.13,28.612 34.611,33.152 C30.098,37.682 27.612,43.693 27.612,50.075 C27.614,63.291 38.369,74.048 51.593,74.042 L51.595,71.91 L51.591,71.913"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M74.922,68.855 C73.579,67.472 72.233,66.089 70.879,64.701 C70.25,64.072 69.426,63.743 68.614,63.749 C68.138,64.334 67.626,64.898 67.084,65.44 C66.524,66 65.939,66.534 65.327,67.031 C65.325,67.836 65.651,68.666 66.275,69.293 C67.626,70.681 68.98,72.073 70.337,73.468 C71.644,74.778 73.187,74.333 74.467,73.013 L74.476,73.006 C75.78,71.695 76.225,70.147 74.922,68.855"
          fill={theme.colors.gray[8]}
        ></path>
        <polyline
          fill="#484F59"
          points="98.994 90.13 91.655 97.47 68.748 74.564 76.086 67.225 98.994 90.13"
        ></polyline>
        <polyline
          fill={theme.colors.gray[8]}
          points="98.405 90.717 95.374 93.751 72.466 70.843 75.5 67.813 98.405 90.717"
        ></polyline>
        <polyline
          fill={theme.colors.gray[2]}
          points="77.848 68.985 70.509 76.327 68.748 74.564 76.086 67.225 77.848 68.985"
        ></polyline>
        <polyline
          fill={theme.colors.gray[3]}
          points="77.02 69.524 73.775 72.765 72.564 71.555 75.804 68.313 77.02 69.524"
        ></polyline>
        <path
          d="M100.754,91.892 C100.754,95.944 97.469,99.23 93.413,99.232 C92.827,98.643 92.24,98.057 91.655,97.47 C94.102,95.022 96.547,92.577 98.994,90.13 C99.579,90.717 100.165,91.305 100.754,91.892"
          fill={theme.colors.gray[2]}
        ></path>
        <polyline
          fill={theme.colors.gray[3]}
          points="100.044 92.551 96.802 95.792 95.287 94.276 98.53 91.036 100.044 92.551"
        ></polyline>
        <polygon
          fill={iconColor}
          points="18.091 60.223 22.831 60.223 22.831 46.891 18.091 46.891"
        ></polygon>
        <polygon
          fill={iconColor}
          points="7.869 51.632 44.008 51.632 44.008 46.891 7.869 46.891"
        ></polygon>
        <polygon
          fill={iconColor}
          points="10.094 56.965 20.462 56.965 20.462 47.855 10.094 47.855"
        ></polygon>
        <path
          d="M46.825,58.298 L56.008,58.298 L56.008,40.227 L46.825,40.227 L46.825,58.298 Z M56.599,35.485 L46.231,35.485 C43.94,35.485 42.083,37.343 42.083,39.633 L42.083,58.891 C42.083,61.181 43.94,63.038 46.231,63.038 L56.599,63.038 C58.892,63.038 60.748,61.181 60.748,58.891 L60.748,39.633 C60.748,37.343 58.892,35.485 56.599,35.485 Z"
          fill={iconColor}
        ></path>
        <path
          d="M45.047,58.891 L56.599,58.891 L56.599,38.449 L45.047,38.449 L45.047,58.891 Z M45.64,61.26 C44.006,61.26 42.676,59.93 42.676,58.298 L42.676,39.041 C42.676,37.407 44.006,36.077 45.64,36.077 L56.008,36.077 C57.642,36.077 58.97,37.407 58.97,39.041 L58.97,58.298 C58.97,59.93 57.642,61.26 56.008,61.26 L45.64,61.26 Z"
          fill={iconAccentColor}
        ></path>
        <polygon
          fill={iconColor}
          points="10.094 60.223 14.834 60.223 14.834 46.891 10.094 46.891"
        ></polygon>
        <polygon
          fill={iconAccentColor}
          points="10.094 60.223 12.463 60.223 12.463 51.632 10.094 51.632"
        ></polygon>
        <polygon
          fill={iconAccentColor}
          points="18.091 60.223 20.462 60.223 20.462 51.632 18.091 51.632"
        ></polygon>
        <polygon
          fill={iconAccentColor}
          points="7.869 49.26 44.008 49.26 44.008 46.891 7.869 46.891"
        ></polygon>
      </g>
    </g>
  </svg>
);

export default AccessMissing;
