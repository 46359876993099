import React from 'react';

import styled, { keyframes } from 'styled-components';
import space from '../../../assets/themes/base/space';

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const TinySpinnerBox = styled.svg`
  animation: ${spin} 2s linear infinite;
  width: ${props => `calc(2*${props.theme.space[3]})`};
  height: ${props => `calc(2*${props.theme.space[3]})`};
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -13;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -49;
  }
`;

const TinySpinnerCircle = styled.circle.attrs(({ color, theme }) => ({
  fill: 'none',
  stroke: color || theme.colors.gray[4],
}))`
  cx: ${space[3]};
  cy: ${space[3]};
  r: ${space[2]};
  strokeWidth: ${space[1]};
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

const TinySpinner = props => <TinySpinnerBox><TinySpinnerCircle {...props} /></TinySpinnerBox>;

export default TinySpinner;
