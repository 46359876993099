import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'ORGANIZATION_USER_INVITE';

export const fetchAllOrganizationUserInvitesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchOrganizationUserInviteByTokenTypes = generateAPHTypes(resource, 'FETCH');

export const createOrganizationUserInvitesTypes = generateAPHTypes(resource, 'CREATE');

export const deleteOrganizationUserInvitesTypes = generateAPHTypes(resource, 'DELETE');

export const acceptOrganizationUserInviteTypes = generateAPHTypes(resource, 'ACCEPT');

export const resendOrganizationUserInviteTypes = generateAPHTypes(resource, 'RESEND');