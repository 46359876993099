import React from 'react';

import theme from '../../../../assets/themes/theme';

const SettingsGraphic = ({
  id = 'settings-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <g>
      <circle fill={backgroundColor} cx="64" cy="64" r="64" />
      <g>
        <path
          fill={theme.colors.gray[7]}
          d="M62.5,81v-4.6l-3.4-0.8c-0.4-2.1-1.2-4-2.3-5.7l1.9-3.1l-3.2-3.2l-3,1.8c-1.7-1.2-3.6-2-5.7-2.4L46,59.5
			h-4.6L40.6,63c-2.1,0.4-4,1.2-5.7,2.3l-3.1-1.9l-3.2,3.2l1.8,3c-1.2,1.7-2,3.6-2.4,5.7l-3.5,0.8v4.6l3.4,0.8
			c0.4,2.1,1.2,4,2.3,5.7l-1.9,3.1l3.2,3.2l3-1.8c1.7,1.2,3.6,2,5.7,2.4l0.8,3.5h4.6l0.8-3.4c2.1-0.4,4-1.2,5.8-2.3l3.1,1.9l3.2-3.2
			l-1.8-3c1.2-1.7,2-3.6,2.4-5.7L62.5,81z M43.5,86.6c-4.4,0-8.1-3.6-8.1-8.1s3.6-8.1,8.1-8.1c4.4,0,8.1,3.6,8.1,8.1
			C51.5,83,47.9,86.6,43.5,86.6z"
        />
        <path
          fill={theme.colors.gray[4]}
          d="M88.2,91.6l0.8-2.9l-2.1-1.2c0.1-1.4,0-2.8-0.4-4.1l1.8-1.6l-1.5-2.7l-2.3,0.6c-0.9-1.1-2-2-3.2-2.6
			l0.1-2.4l-2.9-0.8l-1.2,2c-1.4-0.1-2.8,0-4.1,0.4l-1.6-1.8L69,76l0.6,2.3c-1.1,0.9-2,2-2.6,3.2l-2.4-0.1l-0.8,2.9l2.1,1.2
			c-0.1,1.4,0,2.8,0.4,4.1l-1.8,1.6l1.5,2.7l2.3-0.6c0.9,1.1,2,2,3.2,2.6l-0.1,2.4l2.9,0.8l1.2-2c1.4,0.1,2.8,0,4.1-0.4l1.6,1.8
			l2.7-1.5l-0.6-2.3c1.1-0.9,2-2,2.6-3.2L88.2,91.6z M74.9,91.6c-2.9-0.8-4.5-3.8-3.7-6.7c0.8-2.9,3.8-4.5,6.7-3.7
			c2.9,0.8,4.5,3.8,3.7,6.7C80.7,90.8,77.7,92.5,74.9,91.6z"
        />
        <g>
          <path
            fill={theme.colors.gray[0]}
            d="M96.5,57.5l1.9-5.7l-3.9-2.5c0.4-2.8,0.3-5.5-0.4-8.1l3.7-3l-2.6-5.4l-4.5,1c-1.6-2.2-3.6-4-6-5.5
				l0.5-4.7l-5.7-1.9l-2.5,4c-2.8-0.4-5.5-0.3-8.1,0.4l-3-3.7L60.5,25l1,4.5c-2.2,1.6-4,3.6-5.5,6L51.3,35l-1.9,5.7l3.9,2.5
				c-0.4,2.8-0.3,5.5,0.4,8.1l-3.7,3l2.6,5.4l4.5-1c1.6,2.2,3.6,4,6,5.5l-0.5,4.7l5.7,1.9l2.5-3.9c2.8,0.4,5.5,0.3,8.1-0.4l3,3.7
				l5.4-2.6l-1-4.5c2.2-1.6,4-3.6,5.5-6L96.5,57.5z M70.5,56.4C65,54.5,62,48.5,63.9,43s7.9-8.5,13.4-6.6s8.5,7.9,6.6,13.4
				S76,58.3,70.5,56.4z"
          />
          <circle fill={theme.colors.gray[0]} cx="73.9" cy="46.4" r="4.2" />
        </g>
      </g>
    </g>
  </svg>
);

export default SettingsGraphic;
