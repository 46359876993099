import React from 'react';

export default function FitBranchIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 115.92 28.08 28.08"
      enableBackground="new 0 115.92 28.08 28.08"
      xmlSpace="preserve"
      style={style}
    >
      <path d="M22.38,136.4H5.54v-8.984h5.393v-6.056h6.25v6.056h5.198V136.4z M6.54,135.4h14.84v-6.984h-5.198v-6.056h-4.25v6.056H6.54V135.4z" />
    </svg>
  );
}
