import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllTableRowTypes,
  createTableRowTypes,
  copyTableRowTypes,
  editTableRowTypes,
  deleteTableRowTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_TABLE_ROWS);

// # GET /api/framework/profile_tables/:profile_table_id/profile_table_rows
export const processFetchTableRows = (profileTableId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllTableRowTypes,
    `/framework/profile_tables/${profileTableId}/profile_table_rows?with_full_detail=true`,
    {
      mutateResponse: ({ profileTableRows = [] }) => {
        return({ profileTableRows: profileTableRows.map((row)=> ({ ...row, table: profileTableId, })), });
      },
    }
  );

// # GET /api/framework/profile_tables/:profile_table_id/profile_table_rows
export const processCreateTableRows = (TableId, newTableRows) =>
  /// newTableRows must be an array of table objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createTableRowTypes,
    `/framework/profile_tables/${TableId}/profile_table_rows`,
    {
      data: {
        profile_table_rows: newTableRows,
      },
      mutateResponse: ({ profileTableRows = [] }) => ({
        profileTableRows: profileTableRows.map(dim => ({ ...dim, table: TableId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Table Row created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Row',
      },
    }
  );

export const processEditTableRows = (profileTableId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editTableRowTypes,
    `/framework/profile_tables/${profileTableId}/profile_table_rows/${data.rowId}`,
    {
      data,
      mutateResponse: ({ profileTableRow = {} }) => ({
        profileTableRow: { ...profileTableRow, table: profileTableId, },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteTableRows = (TableId, rowId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteTableRowTypes,
    `/framework/profile_tables/${TableId}/profile_table_rows/${rowId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_TABLE_ROWS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Row deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Row',
      },
    }
  );