import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../../modules/entities/model';

// prettier-ignore
export default class ProfileTable extends EntityRecord({
  tableName: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  tableLabel: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING
  },
  profile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileId',
  },
  isSubtable: {
    value: false,
    handleCopy: false,
    handleCompare: true,
    handleSerialize: true,
  },
  parentTable: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  }
}) {}
