import { Map } from 'immutable';

import { 
  CELL_EDITING_STARTED, 
  CELL_EDITING_STOPPED,
} from './constants';

const initialGridState = Map({
  isEditingGrid: false,
});

const gridReducer = (state = initialGridState, { type }) => {
  if (type === CELL_EDITING_STARTED) {
    return state.set('isEditingGrid', true);
  } else if (type === CELL_EDITING_STOPPED) {
    return state.set('isEditingGrid', false);
  } else {
    return state;
  }
};

export default gridReducer;