import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXPasswordInput from '../../RDXPasswordInput';

import validateRules from '../../../../utils/validation/validateRules';
import mustMatchRDXField from '../../../../utils/validation/mustMatchRDXField';

const passwordRules = {
  minLength: {
    message: 'Must be at least 8 characters long',
    validator: value => value && value.length >= 8,
  },
  maxLength: {
    message: 'Must be shorter than 30 characters',
    validator: value => value && value.length <= 30,
  },
};

const passwordValidations = validateRules(passwordRules);

const passwordConfirmationRules = {
  matchPassword: {
    message: 'Must match the entered password exactly',
    validator: mustMatchRDXField('password'),
  },
};

const passwordConfirmationValidations = validateRules(passwordConfirmationRules);

const PasswordResetForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field 
          component={RDXPasswordInput} 
          name='password' 
          label='Password' 
          autoComplete='new-password' 
          required validate={passwordValidations} 
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field 
          component={RDXPasswordInput} 
          name='passwordConfirmation' 
          label='Confirm Password' 
          autoComplete='new-password' 
          required 
          validate={passwordConfirmationValidations}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button primary type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Reset Password</Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'passwordReset',
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

export default enhance(PasswordResetForm);
