import { toast } from 'react-toastify';

import EK from '../../keys';

import boltNutClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllBoltNutFamiliesTypes,
  fetchIndividualBoltNutFamilyTypes,
  createBoltNutFamilyTypes,
  copyBoltNutFamilyTypes,
  editBoltNutFamilyTypes,
  deleteBoltNutFamilyTypes,
  archiveBoltNutFamiliesTypes,
  unarchiveBoltNutFamiliesTypes,
} from './constants';

export const processFetchAllBoltNutFamilies = () => boltNutClient(REQUEST_TYPES.GET, 'plural', fetchAllBoltNutFamiliesTypes, '/piping/bolt_nut_families?all=true');

export const processFetchIndividualBoltNutFamily = boltNutId => boltNutClient(REQUEST_TYPES.GET, 'single', fetchIndividualBoltNutFamilyTypes, `/piping/bolt_nut_families/${boltNutId}?with_full_detail=true`, {
  mutateResponse: ({ boltNutFamily: { id, boltNutInstances = [], ...other } = {} }) => ({
    boltNutFamily: {
      ...other,
      id,
      boltNutInstances: boltNutInstances.map(instance => ({
        ...instance,
        boltNutFamily: `${id}`,
        boltNutFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateBoltNutFamily = data => boltNutClient(REQUEST_TYPES.POST, 'plural', createBoltNutFamilyTypes, '/piping/bolt_nut_families', {
  data: {
    boltNutFamilies: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bolt nut successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create bolt nut',
  },
});

export const processCopyBoltNutFamily = (data) =>
  boltNutClient(
    REQUEST_TYPES.POST,
    'plural',
    copyBoltNutFamilyTypes,
    '/piping/bolt_nut_families/copy',
    {
      data: {
        [EK.BOLT_NUTS.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Bolt nut successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy bolt nut',
      },
    }
  );

export const processEditBoltNutFamily = (boltNutId, data) => boltNutClient(REQUEST_TYPES.PATCH, 'single', editBoltNutFamilyTypes, `/piping/bolt_nut_families/${boltNutId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteBoltNutFamily = boltNutId => boltNutClient(REQUEST_TYPES.DELETE, 'single', deleteBoltNutFamilyTypes, `/piping/bolt_nut_families/${boltNutId}?with_full_detail=true`, {
  deleteKey: EK.BOLT_NUTS.state,
  dependentDeletionKeys: [EK.BOLT_NUT_INSTANCES.state],
  mutateResponse: ({ boltNutFamily: { id, ...other } = {}, boltNutInstances = [] }) => ({
    boltNutFamily: {
      ...other,
      id,
      boltNutInstances: boltNutInstances.map(instance => ({
        ...instance,
        boltNutFamily: `${id}`,
        boltNutFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bolt nut deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete bolt nut',
  },
});

export const processArchiveBoltNutFamilies = boltNutIds => boltNutClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveBoltNutFamiliesTypes, '/piping/bolt_nut_families/archive', {
  data: { ids: boltNutIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bolt nut archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive bolt nut',
  },
});

export const processUnarchiveBoltNutFamilies = boltNutIds => boltNutClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveBoltNutFamiliesTypes, '/piping/bolt_nut_families/unarchive', {
  data: { ids: boltNutIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bolt nut unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive bolt nut',
  },
});
