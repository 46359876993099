import { createBrowserHistory } from 'history';
import qhistory from 'qhistory';
import { stringify, parse } from 'qs';

const history = qhistory(
  createBrowserHistory(),
  stringify,
  parse
);

export default history;
