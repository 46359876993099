import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { SECTION_METHOD_TYPE_OPTIONS } from '../../../entities/Standardize/Sections/model';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXRadioSelect from '../../../containers/form/RDXRadioSelect';

const FilterForm = (props) => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field component={RDXTextInput} name="name" label="Name" type="text" />
    </BaseFormRow>
    {/* <BaseFormRow>
      <Field
        component={RDXRadioSelect}
        name="sectionMethod"
        label="Section Method"
        options={SECTION_METHOD_TYPE_OPTIONS}
      />
    </BaseFormRow> */}
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="datumFeatureName"
        label="Datum Feature Name"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="description"
        label="Description"
        type="text"
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.SECTIONS.state}` })
);

export default enhance(FilterForm);
