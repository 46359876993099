import validateRules from '../../../utils/validation/validateRules';

import { ALLOWANCES, DEFAULT_ACTION_OPTIONS } from './model';

export const rules = {
  name: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.NAME.test(value),
    },
  },
  creoAction: {
    mustExistIn: {
      message:
        'Must be either exclude, master rep, automatic rep, or default envelope rep',
      validator: (value) =>
        value &&
        !!DEFAULT_ACTION_OPTIONS.find((option) => option.value === value),
    },
  },
};

export const validations = {
  name: validateRules(rules.name),
  creoAction: validateRules(rules.creoAction),
};
