import invariant from 'invariant';
import { schema as schemaClass } from 'normalizr';
import conformsTo from 'lodash/conformsTo';
import isString from 'lodash/isString';

import APH, { NON_ENTITY_CALL, REQUEST_TYPES } from './constants';

const typesShape = {
  REQUEST: isString,
  SUCCESS: isString,
  FAILURE: isString,
};

const identity = (r) => r;

const generateStatusUp = (fetching) => ({
  ...(fetching && { isFetching: 1 }),
  ...(!fetching && { isPerforming: 1 }),
});

const generateStatusDown = (fetching, shouldPerform, shouldConsolidate) => ({
  ...(fetching && { isFetching: -1 }),
  ...(!fetching && { isPerforming: -1 }),
  ...(shouldPerform && fetching && shouldConsolidate &&
    { isConsolidatingFetch: 1 }),
  ...(shouldPerform && !fetching && shouldConsolidate &&
    { isConsolidatingPerform: 1 }),
});

const generateAPH = (
  schema,
  keys,
  method,
  entityKeyType,
  types,
  url,
  queryParams = {},
) => {
  const {
    data,
    mutateRequest = identity,
    mutateResponse = identity,
    resetCache,
    disableErrorRedirect,
    successNotification,
    errorNotification,
    // TODO: clean this up:
    deleteKey,
    dependentDeletionKeys = [],
    ...optionalParams
  } = queryParams;

  invariant(
    isString(method),
    '(modules/api/base): a string method type is required',
  );

  const shouldEntityExist = entityKeyType !== NON_ENTITY_CALL;

  if (schema) {
    invariant(
      isString(entityKeyType) && isString(keys[entityKeyType]) &&
        schema instanceof schemaClass.Entity,
      '(modules/api/base): valid schema class and entityKey are required',
    );
  }

  invariant(
    conformsTo(types, typesShape),
    '(modules/api/base): a valid REQUEST/SUCCESS/FAILURE types object is required',
  );

  const fetching = method === REQUEST_TYPES.GET;
  const deleting = method === REQUEST_TYPES.DELETE;

  const baseMeta = {
    query: {
      ...optionalParams,
      ...(keys && entityKeyType && { entityKey: keys[entityKeyType] }),
    },
    ...(disableErrorRedirect && { disableErrorRedirect: true }),
  };

  return {
    type: APH,
    payload: {
      endpoint: url,
      method,
      ...(!!data && { data: mutateRequest(data, baseMeta.query) }),
      types: [
        {
          type: types.REQUEST,
          meta: () => ({
            ...baseMeta,
            status: generateStatusUp(fetching),
          }),
        },
        {
          type: types.SUCCESS,
          payload: (action, state, response) =>
            mutateResponse(response, baseMeta.query),
          meta: () => ({
            ...baseMeta,
            status: generateStatusDown(fetching, true, shouldEntityExist),
            ...(schema && keys && entityKeyType && {
              schema,
              entityKey: keys[entityKeyType],
            }),
            method,
            resetCache,
            ...(fetching && { cache: url }),
            ...(successNotification && { notification: successNotification }),
            ...(deleting && { deleteKey, dependentDeletionKeys }),
          }),
        },
        {
          type: types.FAILURE,
          meta: () => ({
            ...baseMeta,
            status: generateStatusDown(fetching, false, shouldEntityExist),
            ...(errorNotification && { notification: errorNotification }),
          }),
        },
      ],
    },
  };
};

/**
 * The base api function to call any endpoint from the API
 * @param {Entity} schema - An Entity object to pass onto normalization function
 * @param {object} keys - An object of the keys for an entity (single, plural, state)
 * @param {string} method - The request method (i.e., get, post, patch, delete)
 * @param {string} entityKeyType - The entity key type (single or plural or state) to pass onto normalization function
 * @param {object} types - An object of action type strings (i.e., REQUEST/SUCCESS/FAILURE)
 * @param {string} url - The endpoint url for where to send the request
 * @param {object} params - The params of the request, including mutation functions + action params (includes data - The body of the request to be sent. Optional.)
 * @return {object} - Returns an FSA-compliant object that can be dispatched to Redux
 */
const base = (schema, keys, method, entityKeyType, types, url, params = {}) =>
  generateAPH(schema, keys, method, entityKeyType, types, url, params);

/**
 * The base non-entity api function to call any endpoint from the API
 * @param {string} method - The request method (i.e., get, post, patch, delete)
 * @param {object} types - An object of action type strings (i.e., REQUEST/SUCCESS/FAILURE)
 * @param {string} url - The endpoint url for where to send the request
 * @param {object} params - The params of the request, including mutation functions + action params. Also includes data (The body of the request to be sent. Optional.)
 * @return {object} - Returns an FSA-compliant object that can be dispatched to Redux
 */
const nonEntityBase = (method, types, url, params = {}) =>
  generateAPH(null, null, method, NON_ENTITY_CALL, types, url, params);

const api = (schema, keys) => base.bind(null, schema, keys);

export default api;

export { nonEntityBase as nonEntityApi };
