import { createAction } from 'redux-actions';

import { REQUEST_TYPES } from '../api/constants';

const generateStatusDown = (fetching) => ({
  ...(fetching && { isConsolidatingFetch: -1 }),
  ...(!fetching && { isConsolidatingPerform: -1 }),
});

export const receiveEntities = createAction('ENTITIES/RECEIVE', (entities, result, method) => ({ entities, result }), (entities, result, method) => ({ status: generateStatusDown(method === REQUEST_TYPES.GET) }));
export const removeEntities = createAction('ENTITIES/REMOVE', (entities, result) => ({ entities, result }), () => ({ status: generateStatusDown(false) }));
export const mergeEntities = createAction('ENTITIES/MERGE', (entities, method) => ({ entities }), (entities, method) => ({ status: generateStatusDown(method === REQUEST_TYPES.GET) }));
