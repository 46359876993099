import React, {useMemo} from 'react';

const mapRowStyles = (originalData, editedRowMap) => {
  const currentData = editedRowMap && editedRowMap[originalData.id] || originalData;
  return {
    'bg-deleted': currentData.isDeleted,
    'bg-edited': (currentData.isCreated || currentData.isEdited),
  };
};

// left for now because a lot of pages stuill use this
export const rowStyles = (data, rows) => {
  const currentData = rows && rows.find(row => row.id == data.id) || data;
  return {
    'bg-deleted': currentData.isDeleted,
    'bg-edited': (currentData.isCreated || currentData.isEdited),
  };
};

// Use me!
export default function useRowStyles (isMap=false) {
  const styleFunc = useMemo(() => isMap ? mapRowStyles : rowStyles, [isMap]);

  return styleFunc;
}