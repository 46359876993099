import React from 'react';

import Text from '../../common/Text';

import BaseSearchHighlighter from '../BaseSearchHighlighter';

const BaseDataItem = ({ primary, secondary, disabled, pb, searchWords, children }) => (
  <Text color={disabled ? 'gray.4' : (primary ? 'gray.7' : 'gray.6')} fontSize={secondary && 1 || undefined} pb={pb} $ellipsis>
    { searchWords && <BaseSearchHighlighter searchWords={searchWords} disabled={disabled}>{ children }</BaseSearchHighlighter> || children }
  </Text>
);

export default BaseDataItem;