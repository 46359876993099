import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';
import organizationUserInviteKeys from '../OrganizationUserInvites/keys';
import userKeys from '../Users/keys';

const userSchema = baseSchema(userKeys.state);

const organizationSchema = baseSchema(keys.state, {
  owner: { schema: userSchema },
});

const organizationUserInviteSchema = baseSchema(organizationUserInviteKeys.state, {
  organization: { schema: organizationSchema },
  invitee: { schema: userSchema },
  invitedBy: { schema: userSchema },
});

export {
  userSchema,
  organizationUserInviteSchema,
};

export default organizationSchema;
