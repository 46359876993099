import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';

import Flex from '../Flex';
import Text from '../Text';

const TabContainer = styled(Flex).attrs(_ => ({
  as: 'ul',
}))`
  display: flex;
  position: relative;
  list-style: none;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  backface-visibility: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const BaseTab = styled(Flex).attrs(({ as = 'li', active }) => ({
  as,
  active,
  className: active && 'active' || undefined,
}))`
  position: relative;
  z-index: 1;
  width: 8rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  color: ${colors.gray[6]};
  padding: 0;

  transition: color 0.2s;

  ${({ active }) => active && css`
    color: ${colors.primary[4]};
  ` || css`
    &:hover {
      color: ${colors.gray[7]};
    }
  `}
`;

const TabText = styled(Text).attrs(_ => ({
  $ellipsis: true,
}))`
  display: inline-block;
  text-decoration: none;
  outline: none;
  vertical-align: middle;
  color: inherit;
  padding: 0.25rem 0.5rem;

  ${({ active }) => active && css`
    background: ${colors.primary[1]};
    border-radius: ${radii[2]};
  `}
`;

const Tab = ({ id, onSelect, ...rest }) => {
  const onClick = useCallback(() => onSelect(id));

  return <BaseTab onClick={onClick} { ...rest } />;
};

const Tabs = ({ tabs, activeTabId, onChange }) => (
  <TabContainer>
    {
      tabs.map(({ id, label, ...rest }) => (
        <Tab key={id} id={id} active={id === activeTabId} onSelect={onChange} { ...rest }>
          <TabText fontSize={2} active={id === activeTabId} fontWeight='600'>
            { label }
          </TabText>
        </Tab>
      ))
    }
  </TabContainer>
);

export default Tabs;