import React from 'react';

import theme from '../../../../assets/themes/theme';

const DocumentationGraphic = ({
  id = 'documentation-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <path
        fill={backgroundColor}
        d="M3.5,256C3.5,116.5,116.5,3.5,256,3.5s252.5,113,252.5,252.5c0,139.5-113,252.5-252.5,252.5
		S3.5,395.5,3.5,256z"
      />
      <path
        fill={theme.colors.error[4]}
        d="M400.4,154.5h-36.5c-1.2,0-2.2-1-2.2-2.2v-35.7c0-1.2,1-2.2,2.2-2.2h36.5c1.2,0,2.2,1,2.2,2.2v35.7
		C402.5,153.6,401.6,154.5,400.4,154.5z"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M400.4,225.4h-36.5c-1.2,0-2.2-1-2.2-2.2v-35.7c0-1.2,1-2.2,2.2-2.2h36.5c1.2,0,2.2,1,2.2,2.2v35.7
		C402.5,224.5,401.6,225.4,400.4,225.4z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M400.4,296.3h-36.5c-1.2,0-2.2-1-2.2-2.2v-35.7c0-1.2,1-2.2,2.2-2.2h36.5c1.2,0,2.2,1,2.2,2.2v35.7
		C402.5,295.4,401.6,296.3,400.4,296.3z"
      />
      <path
        fill={theme.colors.gray[0]}
        d="M359.8,418.2H152.2c-12.2,0-22.1-9.9-22.1-22.1V115.8c0-12.2,9.9-22.1,22.1-22.1h207.5
		c12.2,0,22.1,9.9,22.1,22.1v280.3C381.9,408.4,372,418.2,359.8,418.2z"
      />
      <g>
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,132.4h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,128.4,147.8,132.4,142.9,132.4z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,185.4h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,181.4,147.8,185.4,142.9,185.4z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,238.5h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,234.4,147.8,238.5,142.9,238.5z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,291.5h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,287.4,147.8,291.5,142.9,291.5z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,344.5h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,340.5,147.8,344.5,142.9,344.5z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M142.9,397.5h-23.6c-4.9,0-9-4-9-9l0,0c0-4.9,4-9,9-9h23.6c4.9,0,9,4,9,9l0,0
			C151.8,393.5,147.8,397.5,142.9,397.5z"
        />
      </g>
      <rect
        x="172.6"
        y="114.5"
        fill={theme.colors.gray[1]}
        width="179.1"
        height="168"
      />
      <g>
        <rect
          x="190.7"
          y="143.5"
          fill={theme.colors.gray[4]}
          width="142.8"
          height="11"
        />
        <rect
          x="190.7"
          y="176.5"
          fill={theme.colors.gray[4]}
          width="142.8"
          height="11"
        />
        <rect
          x="190.7"
          y="209.5"
          fill={theme.colors.gray[4]}
          width="142.8"
          height="11"
        />
        <rect
          x="190.7"
          y="242.5"
          fill={theme.colors.gray[4]}
          width="142.8"
          height="11"
        />
      </g>
    </g>
  </svg>
);

export default DocumentationGraphic;
