import React from 'react';

import theme from '../../../../assets/themes/theme';

import BaseGraphicSVG, { fillTransitionClass } from '../BaseGraphicSVG';

const st1 = {
  fill: '#30465a',
  stroke: '#30465a',
  strokeMiterlimit: 10,
  strokeWidth: '3px',
};

const st2 = {
  fill: '#ff8787'
};

const st3 = {
  fill: '#f8f9fa',
  stroke: '#30465a',
  strokeMiterlimit: 10,
  strokeWidth: '2px',
};

const st4 = {
  fill: '#f1f3f5',
  stroke: '#30465a',
  strokeMiterlimit: 10,
  strokeWidth: '2px',
};

const st5 = {
  fill: '#d8d8d8',
  stroke: '#30465a',
  strokeMiterlimit: 10,
  strokeWidth: '2px',
};

const st6 = {
  fill: '#d8d8d8',
};

const st7 = {
  fill: '#ff8787',
  stroke: '#30465a',
  strokeMiterlimit: 10,
  strokeWidth: '2px',
};

const CableGraphic = ({
  id = 'cable-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <BaseGraphicSVG
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <circle
      className={fillTransitionClass}
      fill={disabled ? disabledBackgroundColor : backgroundColor}
      cx="64"
      cy="64"
      r="63.5"
    />
    <g>
      <path
        d="M313.7777,282.93686a15.14932,15.14932,0,0,1-15.1324-15.1324v-14.3131h2.1667v14.3131a12.96565,12.96565,0,1,0,25.9313,0v-56.3609a12.35765,12.35765,0,1,1,24.7153,0v38.3263h-2.1667v-38.3263a10.191,10.191,0,1,0-20.3819,0v56.3609A15.1493,15.1493,0,0,1,313.7777,282.93686Z"
        transform="translate(-262.7278 -179.18125)"
        style={st1}
      />
      <polygon
        points="91.416 67.378 83.848 67.378 85.077 60.544 90.187 60.544 91.416 67.378"
        style={st2}
      />
      <path
        d="M344.2912,261.68076h2.6785v10.1496a1.33925,1.33925,0,0,1-1.3393,1.3392h0a1.33923,1.33923,0,0,1-1.3392-1.3392Z"
        transform="translate(-262.7278 -179.18125)"
        style={st3}
      />
      <path
        d="M354.3602,261.68066h2.6785v10.1496a1.33925,1.33925,0,0,1-1.3393,1.3392h0a1.33923,1.33923,0,0,1-1.3392-1.3392Z"
        transform="translate(-262.7278 -179.18125)"
        style={st3}
      />
      <path
        d="M359.4817,259.84106v-10.6743a4.8672,4.8672,0,0,0-4.8672-4.8673l-8.4786.0001a4.8672,4.8672,0,0,0-4.8672,4.8673v10.6743Z"
        transform="translate(-262.7278 -179.18125)"
        style={st4}
      />
      <path
        d="M340.3524,258.48316h20.04551a1.55418,1.55418,0,0,1,1.55418,1.55418v1.8019a1.55422,1.55422,0,0,1-1.55422,1.55422h-20.0454a1.55418,1.55418,0,0,1-1.55418-1.55418v-1.802A1.5541,1.5541,0,0,1,340.3524,258.48316Z"
        transform="translate(438.0252 342.69188) rotate(179.99943)"
        style={st5}
      />
      <path
        d="M352.2004,251.53086a1.83155,1.83155,0,1,1-1.8316-1.8316A1.83155,1.83155,0,0,1,352.2004,251.53086Z"
        transform="translate(-262.7278 -179.18125)"
        style={st6}
      />
      <polygon
        points="33.184 72.128 40.734 72.128 39.508 78.931 34.41 78.931 33.184 72.128"
        style={st7}
      />
      <path
        d="M290.72785,238.02746v10.6743a4.8672,4.8672,0,0,0,4.8672,4.8673l8.4786-.0001a4.8672,4.8672,0,0,0,4.8672-4.8673v-10.6743Z"
        transform="translate(-262.7278 -179.18125)"
        style={st4}
      />
      <path
        d="M27.08375,55.29381h20.0456a1.5541,1.5541,0,0,1,1.5541,1.5541v1.802a1.55422,1.55422,0,0,1-1.55422,1.55422H27.08385a1.5542,1.5542,0,0,1-1.5542-1.5542v-1.802a1.5541,1.5541,0,0,1,1.5541-1.5541Z"
        style={st5}
      />
      <path
        d="M298.00915,246.33766a1.83155,1.83155,0,1,1,1.8316,1.8316A1.83155,1.83155,0,0,1,298.00915,246.33766Z"
        transform="translate(-262.7278 -179.18125)"
        style={st6}
      />
    </g>
  </BaseGraphicSVG>
);

export default CableGraphic;
