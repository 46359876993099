import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';

import profileTableRowSchema from '../ProfileTableRows/schema';
import profileTableColumnSchema from '../ProfileTableColumns/schema';
import profileTableSchema from '../ProfileTables/schema';

const profileTableItemSchema = baseSchema(EK.PROFILE_TABLE_ITEMS.state, {
  row: { schema: profileTableRowSchema, isId: true },
  column: { schema: profileTableColumnSchema, isId: true },
  profileSubtable: { schema: profileTableSchema, isId: true}
});

export default profileTableItemSchema;
