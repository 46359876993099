import { call, put, race, select, take } from 'redux-saga/effects';
import { denormalize } from 'normalizr';
import { List, Map } from 'immutable';

import { ENTITY_STATUS } from '../entities/constants';

import { selectIsPerforming } from '../utility/selectors';

import { addDrafts, mergeDrafts, moveDrafts } from './actions';

import generateUUID from '../../utils/reducers/generateUUID';

import { selectIsDoneFetchingInitialData } from '../../routes/Dashboard/selectors';

const defaultSelector = () => (state) => ({});
const defaultGenerateCreationProperties = (_) => ({});

// needs to include all ids from existing records + draft ids
export const generateUniqueIdForCreations = (
  creations,
  currentIdsSet,
  opts = {},
) => {
  return creations.reduce((creationsMap, creation) => {
    let isIdValid = false;
    let attempts = 0;

    while (!isIdValid && attempts < 30) {
      const uuid = generateUUID();

      if (!currentIdsSet.has(uuid) && !creationsMap.has(uuid)) {
        isIdValid = true;
        return creationsMap.set(uuid, {
          ...creation,
          id: uuid,
          isLocalDraft: true,
          ...(opts.creationProperties || {}),
        });
      }

      attempts++;
    }
  }, Map());
};

export const serializeRecords = (records, params) =>
  records.reduce((collection, record) => {
    if (record.status === ENTITY_STATUS.PENDING_VALID_SAVE) {
      return [
        ...collection,
        record.serialize(params),
      ];
    } else if (record.status === ENTITY_STATUS.PENDING_VALID_CHANGES) {
      return [
        ...collection,
        {
          id: record.id,
          ...record.serialize(params),
        },
      ];
    } else if (record.status === ENTITY_STATUS.PENDING_DELETE) {
      return [...collection, { id: record.id, _destroy: '1' }];
    } else {
      return collection;
    }
  }, []);

export function* waitWhilePerformingSaga() {
  let isPerformingEntities = yield select(selectIsPerforming());
  while (isPerformingEntities > 0) {
    yield take();
    isPerformingEntities = yield select(selectIsPerforming());
  }

  return isPerformingEntities;
}

export function* waitWhileInitialFetchingSaga() {
  let initialEntitiesFetched = yield select(selectIsDoneFetchingInitialData());
  while (!initialEntitiesFetched) {
    yield take();
    initialEntitiesFetched = yield select(selectIsDoneFetchingInitialData());
  }

  return initialEntitiesFetched;
}

export const generateWaitWhileBaseParamsSaga = ({
  baseParamsSelector = defaultSelector,
}) => {
  return function* waitWhileBaseParams() {
    let baseParams = yield select(baseParamsSelector());
    while (!baseParams) {
      yield take();
      baseParams = yield select(baseParamsSelector());
    }

    return baseParams;
  };
};

export const generateReviewSaga = ({
  keys,
  schema,
  draftDenormalizeSelector,
  originalDenormalizedDataSelector,
  postValidateProcessing,
}) => {
  return function* review(drafts, { ignoreValidate } = {}) {
    const denormalizationData = yield select(draftDenormalizeSelector());
    const originalData = yield select(originalDenormalizedDataSelector());

    let denormalizedDrafts;

    if (List.isList(drafts)) {
      denormalizedDrafts = denormalize(drafts.map((draft) => draft.tally), [
        schema,
      ], denormalizationData);
    } else {
      denormalizedDrafts = drafts.map((draft) =>
        denormalize(draft.tally, schema, denormalizationData)
      );
    }

    return {
      [keys.state]: denormalizedDrafts.map((draft) =>
        draft.withMutations((model) => {
          if (ignoreValidate) {
            return model.comparison(originalData.get(model.id)).renormalized
              .analyze;
          } else {
            return model.comparison(originalData.get(model.id)).validate
              .renormalized
              .analyze;
          }

          if (postValidateProcessing) return postValidateProcessing(model);
        })
      ),
    };
  };
};

export const generateMoveSaga = ({
  keys,
  model,
  baseParamsSelector = defaultSelector,
  originalNormalizedDataSelector,
  currentIdsSetSelector,
  generateCreationProperties = defaultGenerateCreationProperties,
}) => {
  return function* move() {
    const originalNormalizedData = yield select(
      originalNormalizedDataSelector(),
    );
    let drafts = List();

    if (model) {
      const baseParams = yield select(baseParamsSelector());
      const currentIdsSet = yield select(currentIdsSetSelector());

      const creationProperties = yield call(
        generateCreationProperties,
        baseParams,
      );

      drafts = generateUniqueIdForCreations(
        List(Array(25).fill({})),
        currentIdsSet,
        { creationProperties },
      ).map((m) => new model(m));
    }

    yield put(
      moveDrafts({ [keys.state]: originalNormalizedData.concat(drafts) }),
    );
  };
};

export const generateWatchCreateSaga = ({
  model,
  reviewSaga,
  currentIdsSetSelector,
}) => {
  return function* watchCreate(
    { payload: { creations, insertAt, ignoreValidate, creationProperties } },
  ) {
    const currentIdsSet = yield select(currentIdsSetSelector());

    let drafts = generateUniqueIdForCreations(creations, currentIdsSet, {
      creationProperties,
    }).map((m) => new model(m));

    const draftEntities = yield call(reviewSaga, drafts, { ignoreValidate });

    yield put(addDrafts(draftEntities, { insertAt }));
  };
};

export const generateWatchEditSaga = ({
  keys,
  model,
  reviewSaga,
  baseParamsSelector = defaultSelector,
  draftStateToGetLastEditedIndexSelector,
  currentIdsSetSelector,
  generateCreationProperties = defaultGenerateCreationProperties,
}) => {
  return function* watchEdit({ payload: { edits } }) {
    let draftEntities;

    if (reviewSaga) {
      draftEntities = yield call(reviewSaga, edits);
    } else {
      draftEntities = {
        [keys.state]: List.isList(edits)
          ? edits.reduce((map, edit) => map.set(edit.id, edit), Map())
          : edits,
      };
    }

    yield put(mergeDrafts(draftEntities));

    if (model) {
      const currentUnfilteredDraftData = yield select(
        draftStateToGetLastEditedIndexSelector(),
      );

      const lastEditedIndex = currentUnfilteredDraftData.findLastIndex(
        (instance) => instance.isEdited,
      );

      let newRowCount = 25;
      if (
        lastEditedIndex >= 0 &&
        lastEditedIndex !== currentUnfilteredDraftData.size - 1
      ) {
        newRowCount = 25 -
          (currentUnfilteredDraftData.size - 1 - lastEditedIndex);
      }

      if (newRowCount > 0) {
        const baseParams = yield select(baseParamsSelector());
        const currentIdsSet = yield select(currentIdsSetSelector());

        const creationProperties = yield call(
          generateCreationProperties,
          baseParams,
        );

        const drafts = generateUniqueIdForCreations(
          List(Array(newRowCount).fill({})),
          currentIdsSet,
          { creationProperties },
        ).map((m) => new model(m));

        yield put(addDrafts({ [keys.state]: drafts }));
      }
    }
  };
};

export const generateWatchCollectionUpdateSaga = ({
  baseParamsSelector = defaultSelector,
  currentDraftDataToSerializeSelector,
  serializationDataSelector,
  processUpdateAction,
  processUpdateTypes,
  moveSaga,
  ignoreSerialization,
}) => {
  return function* watchCollectionUpdate() {
    const baseParams = yield select(baseParamsSelector());
    const currentData = yield select(currentDraftDataToSerializeSelector());
    let serializationData;
    let serialized;

    if (!ignoreSerialization) {
      if (serializationDataSelector) {
        serializationData = yield select(serializationDataSelector());
      }

      serialized = serializeRecords(currentData, serializationData);
    } else {
      serialized = currentData;
    }

    yield put(processUpdateAction(baseParams, serialized));

    const { success } = yield race({
      success: take(processUpdateTypes.SUCCESS),
      failure: take(processUpdateTypes.FAILURE),
    });

    if (success) {
      yield call(waitWhilePerformingSaga);

      if (moveSaga) {
        yield call(moveSaga);
      }
    }
  };
};
