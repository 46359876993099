import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllSpecsTypes,
  fetchIndividualSpecTypes,
  createSpecTypes,
  copySpecTypes,
  editSpecTypes,
  deleteSpecTypes,
  archiveSpecsTypes,
  unarchiveSpecsTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.SPECS);

export const processFetchAllSpecs = () => client(REQUEST_TYPES.GET, 'plural', fetchAllSpecsTypes, '/piping/specs?all=true');

export const processFetchIndividualSpec = specId => client(REQUEST_TYPES.GET, 'single', fetchIndividualSpecTypes, `/piping/specs/${specId}`);

export const processCreateSpec = data => client(REQUEST_TYPES.POST, 'plural', createSpecTypes, '/piping/specs', {
  data: {
    specs: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Spec successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create spec',
  },
});

export const processCopySpec = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copySpecTypes,
    '/piping/specs/copy',
    {
      data: {
        [EK.SPECS.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Spec successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy spec',
      },
    }
  );

export const processEditSpec = (specId, data) => client(REQUEST_TYPES.PATCH, 'single', editSpecTypes, `/piping/specs/${specId}?with_instances=true`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteSpec = specId => client(REQUEST_TYPES.DELETE, 'single', deleteSpecTypes, `/piping/specs/${specId}?with_full_detail=true`, {
  deleteKey: EK.SPECS.state,
  dependentDeletionKeys: [EK.SPEC_FITTING_INSTANCES.state, EK.SPEC_PIPE_INSTANCES.state],
  mutateResponse: ({ spec: { id, ...other } = {}, specFittingInstances = [], specPipeInstances = [] }) => ({
    spec: {
      ...other,
      id,
      specFittingInstances: specFittingInstances.map(instance => ({
        ...instance,
        spec: specId,
      })),
      specPipeInstances: specPipeInstances.map(instance => ({
        ...instance,
        spec: specId,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Piping spec deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete piping spec',
  },
});

export const processArchiveSpecs = specIds => client(REQUEST_TYPES.ARCHIVE, 'plural', archiveSpecsTypes, '/piping/specs/archive', {
  data: { ids: specIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Spec archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive spec',
  },
});

export const processUnarchiveSpecs = specIds => client(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveSpecsTypes, '/piping/specs/unarchive', {
  data: { ids: specIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Spec unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive spec',
  },
});
