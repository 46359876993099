import { compose } from 'redux';
import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';
import hasMeasurements from '../../../modules/entities/decorators/hasMeasurements';

class BendSpringbackInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: entity => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size must be present',
    filter: FILTERS.STRING,
  },
  radius: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.radius),
    validateError: 'A numeric radius must be present',
  },
  angle: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_NUMERIC(entity.angle),
    validateError: 'A numeric angle must be present',
  },
  springBackLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.springBackLength),
    validateError: 'A numeric springback length must be present',
  },
  elongationLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.elongationLength),
    validateError: 'A numeric elongation length must be present',
  },
  bendSpringbackFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  bendSpringbackFamilyId: {
    value: null,
    handleCopy: true,
  },
}) {}

const enhance = compose(
  hasMeasurements
);

export default enhance(BendSpringbackInstance);
