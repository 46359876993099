import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllConfigSettingCategoriesTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.CONFIG_SETTING_CATEGORIES);

export const processFetchAllConfigSettingCategories = () => client(REQUEST_TYPES.GET, 'plural', fetchAllConfigSettingCategoriesTypes, '/synchronize/config_setting_categories');
