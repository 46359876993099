import React from 'react';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';

const ExternalPageButtons = ({ children }) => (
  <Box>
    <Flex flexDirection='row' alignItems='center' justifyContent='center'>
      { children }
    </Flex>
  </Box>
);

export default ExternalPageButtons;
