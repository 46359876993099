import { toast } from 'react-toastify';

import EK from '../../keys';

import bendSpringbackClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllBendSpringbackFamiliesForPipeFamilyTypes,
  fetchIndividualBendSpringbackFamilyForPipeFamilyTypes,
  createBendSpringbackFamilyForPipeFamilyTypes,
  copyBendSpringbackFamilyForPipeFamilyTypes,
  editBendSpringbackFamilyForPipeFamilyTypes,
  deleteBendSpringbackFamilyForPipeFamilyTypes,
  archiveBendSpringbackFamiliesForPipeFamilyTypes,
  unarchiveBendSpringbackFamiliesForPipeFamilyTypes,
} from './constants';

export const processFetchAllBendSpringbackFamiliesForPipeFamily = pipeId => bendSpringbackClient(REQUEST_TYPES.GET, 'plural', fetchAllBendSpringbackFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_springback_families?all=true`, {
  mutateResponse: ({ bendSpringbackFamilies = [] }) => ({
    bendSpringbackFamilies: bendSpringbackFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId })),
  }),
});

export const processFetchIndividualBendSpringbackFamilyForPipeFamily = (pipeId, familyId) => bendSpringbackClient(REQUEST_TYPES.GET, 'single', fetchIndividualBendSpringbackFamilyForPipeFamilyTypes, `/piping/bend_springback_families/${familyId}?with_full_detail=true`, {
  mutateResponse: ({ bendSpringbackFamily: { id, bendSpringbackInstances = [], ...other } = {} }) => ({
    bendSpringbackFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendSpringbackInstances: bendSpringbackInstances.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateBendSpringbackFamilyForPipeFamily = (pipeId, data) => bendSpringbackClient(REQUEST_TYPES.POST, 'plural', createBendSpringbackFamilyForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_springback_families`, {
  data: {
    bendSpringbackFamilies: [data],
  },
  mutateResponse: ({ bendSpringbackFamilies = [] }) => ({
    bendSpringbackFamilies: bendSpringbackFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend springback successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create bend springback',
  },
});

export const processCopyBendSpringbackFamilyForPipeFamily = (pipeId, data) =>
  bendSpringbackClient(
    REQUEST_TYPES.POST,
    'plural',
    copyBendSpringbackFamilyForPipeFamilyTypes,
    `/piping/pipe_families/${pipeId}/bend_springback_families/copy`,
    {
      data: {
        [EK.BEND_SPRINGBACKS.plural]: [data],
      },
      mutateResponse: ({ bendSpringbackFamilies = [] }) => ({
        bendSpringbackFamilies: bendSpringbackFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Bend springback successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy bend springback',
      },
    }
  );

export const processEditBendSpringbackFamilyForPipeFamily = (pipeId, bendSpringbackId, data) => bendSpringbackClient(REQUEST_TYPES.PATCH, 'single', editBendSpringbackFamilyForPipeFamilyTypes, `/piping/bend_springback_families/${bendSpringbackId}`, {
  data,
  mutateResponse: ({ bendSpringbackFamily: { id, ...other } = {} }) => ({
    bendSpringbackFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendSpringbackInstances: bendSpringbackInstances.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteBendSpringbackFamilyForPipeFamily = (pipeId, bendSpringbackId) => bendSpringbackClient(REQUEST_TYPES.DELETE, 'single', deleteBendSpringbackFamilyForPipeFamilyTypes, `/piping/bend_springback_families/${bendSpringbackId}?with_full_detail=true`, {
  deleteKey: EK.BEND_SPRINGBACKS.state,
  dependentDeletionKeys: [EK.BEND_SPRINGBACK_INSTANCES.state],
  mutateResponse: ({ bendSpringbackFamily: { id, ...other } = {}, bendSpringbackInstances = [] }) => ({
    bendSpringbackFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendSpringbackInstances: bendSpringbackInstances.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend springback deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete bend springback',
  },
});

export const processArchiveBendSpringbackFamiliesForPipeFamily = (pipeId, bendSpringbackIds) => bendSpringbackClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveBendSpringbackFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_springback_families/archive`, {
  mutateResponse: ({ bendSpringbackFamilies = [] }) => ({
    bendSpringbackFamilies: bendSpringbackFamilies.map(({ id, bendSpringbackInstances = [], ...other } = {}) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendSpringbackInstances: bendSpringbackInstances.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    }),
    ),
  }),
  data: { ids: bendSpringbackIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend springback archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive bend springback',
  },
});

export const processUnarchiveBendSpringbackFamiliesForPipeFamily = (pipeId, bendSpringbackIds) => bendSpringbackClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveBendSpringbackFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_springback_families/unarchive`, {
  mutateResponse: ({ bendSpringbackFamilies = [] }) => ({
    bendSpringbackFamilies: bendSpringbackFamilies.map(({ id, bendSpringbackInstances = [], ...other } = {}) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendSpringbackInstances: bendSpringbackInstances.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    }),
    ),
  }),
  data: { ids: bendSpringbackIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend springback unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive bend springback',
  },
});