import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import NoteProfileForm from '../../../form/templates/NoteProfileForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import NoteProfileModel from '../../../../entities/Standardize/NoteProfiles/model';
import {
  processCreateNoteProfile,
  processCopyNoteProfile,
  processEditNoteProfile,
  processDeleteNoteProfile,
} from '../../../../entities/Standardize/NoteProfiles/actions';

import { selectCurrentNoteProfile } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyNoteProfile = new NoteProfileModel();

const NoteProfileModal = ({ noteProfile, copyEntity, isPerforming, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (noteProfile) {
      if (copyEntity) {
        onCopy({ ...data, id: noteProfile.id });
      } else {
        onUpdate(noteProfile.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [noteProfile, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(noteProfile.id), [noteProfile, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={noteProfile && noteProfile.name} copy={copyEntity} create='Note Profile' />

      <NoteProfileForm
        initialValues={noteProfile || emptyNoteProfile}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        noteProfile && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={noteProfile.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  noteProfile: selectCurrentNoteProfile(),
});

const enhance = compose(
  injectSaga({ key: `${EK.NOTE_PROFILES.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateNoteProfile,
      onCopy: processCopyNoteProfile,
      onUpdate: processEditNoteProfile,
      onDelete: processDeleteNoteProfile,
    }
  ),
);

export default enhance(NoteProfileModal);
