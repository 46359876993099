/**
 * Handles login process and waits for logout
 */

import { call, delay, put, race, select, take } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode'; // eslint-disable-line camelcase

import { authenticateClient, deauthenticateClient } from '../api/client';
import { getToken, removeToken, setToken } from './utils/token';
import { processFetchCurrentUser } from './actions';

import { selectNormalizedCurrentUser } from '../../entities/CurrentUser/selectors';

import { processLogout } from './constants';
import { logoutHandled } from './actions';

export function* signout() {
  yield call(removeToken);
  yield call(deauthenticateClient);
  yield put(logoutHandled());
}

export default function* authorizationSaga() {
  while (true) {
    let token = yield call(getToken);

    // if no token is present, then wait for something to call handle token
    // the only places this can happen are:
    //  - login
    //  - register new user
    //  - accept invite + register (organization user invite endpoint)
    if (!token) {
      const { payload: { authenticationToken, rememberMe = true } } =
        yield take((action) =>
          !!((action || {}).payload || {}).authenticationToken
        );
      token = authenticationToken;

      if (!token) continue; // eslint-disable-line no-continue

      if (rememberMe) {
        yield call(setToken, token);
      }
    }

    yield call(authenticateClient, token);

    // if there is no current user at the moment because we registered or accepted an invite, we need to reload current user
    if (!(yield select(selectNormalizedCurrentUser()))) {
      yield put(processFetchCurrentUser());
    }

    try {
      token = yield call(jwtDecode, token);
    } catch (error) {
      yield call(signout);
      continue; // eslint-disable-line no-continue
    }

    let userSignedOut;
    while (!userSignedOut) {
      const { expired } = yield race({
        expired: delay(token.exp, true),
        signout: take(processLogout.HANDLE),
      });

      // token expired first
      // TODO: we will want to show an optional login form here instead of clearing out
      // all data for the user. maybe set a timer where the user has 60 seconds to
      // log back in or they will be logged out? auto logging a user out while they're
      // working would suck
      // additional logic will need to be added here
      if (expired) {
        yield call(signout);
        userSignedOut = true; // breaks the loop
      } else {
        // currently identical, won't be in the future
        // user signed out before token expiration
        yield call(signout);
        userSignedOut = true; // breaks the loop
      }
    }
  }
}
