import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectRouteLocationPathname } from '../../../modules/router/selectors';

import { FrameworkModuleProfilePath } from '../../../paths';

export const selectNormalizedProfiles = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILES.state)
);
export const selectNormalizedProfileForLibraryList = () => createSelector(
  selectNormalizedProfiles(),
  profiles => profiles.toList()
);

export const selectCurrentProfileId = () => createSelector(
  selectRouteLocationPathname(),
  pathname => {
    const match = matchPath(pathname, { path: FrameworkModuleProfilePath.url });
    return match && match.params.id || null;
  }
);
export const selectCurrentProfile = () => createSelector(
  selectCurrentProfileId(),
  selectNormalizedProfiles(),
  (ProfileId, Profiles) => Profiles.get(ProfileId)
);
export const selectCurrentProfileNameFromId = (profileId) => createSelector(
  selectNormalizedProfiles(),
  (Profiles) => {
    const profile = Profiles.get(profileId);
    return profile && profile.name || 'Profile';
  }
);