import { put, take, select, fork, takeLatest } from 'redux-saga/effects';
import { getFormValues } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import { selectIsFetchingInModal } from '../../../../modules/utility/selectors';

import {
  processFetchConfigProfile
} from '../../../../entities/Synchronize/ConfigProfiles/actions';

import {
  processSaveConfigProfileMapkeys,
} from '../../../../entities/Synchronize/ConfigProfileMapkeys/actions';
import ConfigProfileMapkeyModel from '../../../../entities/Synchronize/ConfigProfileMapkeys/model';
import { selectNormalizedConfigProfileMapkeys } from '../../../../entities/Synchronize/ConfigProfileMapkeys/selectors';

import { SAVE_CONFIG_PROFILE_MAPKEYS, PREPARE_CONFIG_PROFILE_MAPKEYS_MODAL } from './constants';
import { configProfileMapkeysModalPrepared } from './actions';

const generateWatchSaveConfigProfileMapkeysSaga = (id) =>
  function* () {
    yield takeLatest(
      SAVE_CONFIG_PROFILE_MAPKEYS,
      function* () {
        const profileMapkeys = yield select(state => selectNormalizedConfigProfileMapkeys()(state, id));

        // form is type {[mapkeyId: string]: doesConfigProfileContainMapkey: true}
        const formValues = yield select(getFormValues(EK.CONFIG_PROFILE_MAPKEYS.state));

        const serialized = formValues.reduce((serializedRecords, formValue, mapkeyId) => {
          const initialProfileMapkey = profileMapkeys.find(
            (profileMapkey) => profileMapkey.get(EK.MAPKEYS.single) === mapkeyId
          );
          const prevIsSelected = !!initialProfileMapkey;
          const isEdited = prevIsSelected !== formValue;
          if (isEdited) {
            if (formValue) {
              // this is a new value
              return [
                ...serializedRecords,
                new ConfigProfileMapkeyModel({
                  [EK.CONFIG_PROFILES.single]: id,
                  [EK.MAPKEYS.single]: mapkeyId,
                }).serialize(),
              ];
            } else {
              // this is a deleted value
              return [
                ...serializedRecords,
                { id: initialProfileMapkey.id, _destroy: '1' },
              ];
            }
          } else {
            return serializedRecords;
          }
        }, []);

        yield put(processSaveConfigProfileMapkeys(id, serialized));
      }
    );
  };

// final output saga
export default function* main() {
  try {
    const {
      payload: { id },
    } = yield take(PREPARE_CONFIG_PROFILE_MAPKEYS_MODAL);

    yield put(processFetchConfigProfile(id));
    let isFetchingInitialEntities = yield select(selectIsFetchingInModal());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetchingInModal());
    }

    yield put(configProfileMapkeysModalPrepared());

    const watchSaveConfigProfileMapkeysSaga = generateWatchSaveConfigProfileMapkeysSaga(id);
    yield fork(watchSaveConfigProfileMapkeysSaga);
  } catch(error) {
    console.log(error);
    yield put(hideModal());
  }
}
