import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModulePipesBendSpringbacksEditPath } from '../../../paths';
import { selectCurrentBendSpringBack } from '../../../entities/Piping/BendSpringbackFamilies/selectors';

function SpringBackEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModulePipesBendSpringbacksEditPath.url });
  const springBackId = (match && match.params.familyId) || null;
  const springBackFamily = useSelector(selectCurrentBendSpringBack(springBackId));

  return <BreadcrumbTemplate title={(springBackFamily && springBackFamily.name) || PipingModulePipesBendSpringbacksEditPath.defaultTitle} />;
}

export default withRouter(SpringBackEditBread);
