import { useCallback, useEffect, useRef } from 'react';

import useStateWithCallback from './useStateWithCallback';

const useIsHovering = (
  ref,
  { delayEnter = 0, delayLeave = 0, onHoverChanged } = {},
) => {
  const [isHovering, setIsHovering] = useStateWithCallback(
    false,
    (isHovering) => {
      onHoverChanged && onHoverChanged(isHovering);
    },
  );

  const timers = useRef([]);

  const handleHovering = useCallback((e) => {
    const ids = timers.current;
    while (ids.length) clearTimeout(ids.pop());

    const hId = setTimeout(() => {
      setIsHovering(true);
    }, delayEnter);

    timers.current.push(hId);
  }, [setIsHovering, delayEnter]);

  const handleUnhovering = useCallback((e) => {
    const ids = timers.current;
    while (ids.length) clearTimeout(ids.pop());

    const hId = setTimeout(() => {
      setIsHovering(false);
    }, delayLeave);

    timers.current.push(hId);
  }, [setIsHovering, delayLeave]);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.addEventListener('mouseenter', handleHovering);
    ref.current.addEventListener('mouseleave', handleUnhovering);

    return () => {
      const ids = timers.current;
      while (ids.length) clearTimeout(ids.pop());

      if (ref.current) {
        ref.current.removeEventListener('mouseenter', handleHovering);
        ref.current.removeEventListener('mouseleave', handleUnhovering);
      }
    };
  }, [ref]);

  return isHovering;
};

export default useIsHovering;
