import React from 'react';

import theme from '../../../../assets/themes/theme';

const CadActiveTextLogoGraphic = ({
  id = 'cadactive-text-logo-graphic',
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 585.24291 109.56795"
  >
    <g id="logo_item--business" data-name="logo  item--business">
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M142.06738,236.70361c0-16.46533,12.605-28.36132,29.543-28.36132,8.981,0,16.70166,3.23,21.82227,9.21728l-6.61768,6.22363a19.36578,19.36578,0,0,0-14.73193-6.46c-11.42334,0-19.69531,8.03565-19.69531,19.38037,0,11.34424,8.272,19.3794,19.69531,19.3794a19.17146,19.17146,0,0,0,14.73193-6.53906l6.61768,6.30273c-5.12061,5.9873-12.84131,9.21777-21.90088,9.21777C154.67236,265.06445,142.06738,253.168,142.06738,236.70361Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M239.75732,251.51367H212.18408l-5.436,12.7627H196.19141l24.8164-55.14649h10.084l24.89453,55.14649H245.272Zm-3.3872-8.03515L225.9707,219.37158l-10.32031,24.10694Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M262.44873,209.12988h24.10742c17.88281,0,30.09375,11.02979,30.09375,27.57373,0,16.54346-12.21094,27.57276-30.09375,27.57276H262.44873ZM286.083,255.61035c12.29053,0,20.24658-7.48437,20.24658-18.90674,0-11.42334-7.95605-18.90771-20.24658-18.90771H272.69043v37.81445Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M359.82617,251.51367H332.25293l-5.43555,12.7627H316.26123l24.81494-55.14649h10.084l24.8955,55.14649H365.34082Zm-3.38769-8.03515L346.04,219.37158l-10.32031,24.10694Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M378.57813,236.70361c0-16.46533,12.60449-28.36132,29.543-28.36132,8.98047,0,16.70118,3.23,21.82227,9.21728l-6.61816,6.22363a19.36476,19.36476,0,0,0-14.73145-6.46c-11.42383,0-19.69531,8.03565-19.69531,19.38037,0,11.34424,8.27148,19.3794,19.69531,19.3794A19.17046,19.17046,0,0,0,423.3252,249.544l6.61816,6.30273c-5.12109,5.9873-12.8418,9.21777-21.90137,9.21777C391.18262,265.06445,378.57813,253.168,378.57813,236.70361Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M451.68945,217.7959H433.41211v-8.666H480.208v8.666H461.93066v46.48047H451.68945Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M488.08691,209.12988H498.3291v55.14649H488.08691Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M563.08887,209.12988l-24.02832,55.14649h-10.084l-24.10644-55.14649h11.10742l18.35644,42.541,18.51368-42.541Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
      <g style={{ isolation: 'isolate' }}>
        <path
          d="M609.17871,255.68945v8.58692H567.81836V209.12988H608.0752v8.58741H578.05957v14.33789h26.62891v8.42968H578.05957v15.20459Z"
          transform="translate(-23.9358 -183.03196)"
          fill={theme.colors.gray[7]}
        />
      </g>
    </g>
    <path
      d="M50.28523,254.44806c-.25219.0419-.26043-.12967-.29992-.24919-.31222-.945-.61883-1.89186-.92649-2.83836q-1.53831-4.73256-3.07633-9.46522c-.01413-.04345-.03188-.08573-.04585-.12923-.16148-.50285-.15647-.49046.32486-.6808a15.86169,15.86169,0,0,0,10.4524-12.64221,15.47156,15.47156,0,0,0-2.57184-11.6488c-.34952-.5208-.33241-.40864.13714-.75025q5.2696-3.83388,10.54153-7.66455c.25023-.18257.37-.14419.54908.08433a15.64956,15.64956,0,0,0,9.56413,6.00081,15.93681,15.93681,0,0,0,14.4551-4.11251,11.869,11.869,0,0,0,1.6451-1.84541c.22525-.31352.37657-.28029.65167-.07934q4.97067,3.63076,9.955,7.24278c.22258.16175.439.33338.67061.48094.22226.14157.25215.27342.09211.49841a14.68161,14.68161,0,0,0-2.39283,5.20935,15.881,15.881,0,0,0,4.12144,15.32179,15.28611,15.28611,0,0,0,6.10455,3.91657c.32965.11726.39734.24705.28565.58834q-2.03373,6.21431-4.033,12.43986c-.09419.29262-.20687.36571-.513.27747a16.05583,16.05583,0,0,0-6.18915-.592,15.80148,15.80148,0,0,0-9.36463,4.18413,15.974,15.974,0,0,0-5.10973,9.824,12.57639,12.57639,0,0,0-.11585,1.71417c.00061.23942-.06273.3425-.3297.34207q-6.66006-.01105-13.32016-.00115c-.24375.00032-.30507-.08657-.31407-.32006a16.1862,16.1862,0,0,0-20.72923-15.16786C50.41583,254.41091,50.32783,254.436,50.28523,254.44806Zm27.94191-.22865a16.18369,16.18369,0,1,0-16.19142-16.172A16.12955,16.12955,0,0,0,78.22714,254.21941Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M133.48936,240.80729c-.04675,1.30585-.1844,2.60345-.34706,3.89912-.064.51019-.05912.52153-.54581.3367q-3.79891-1.4427-7.59423-2.8949c-.54448-.20777-1.08372-.43052-1.63427-.6206-.26117-.09016-.29947-.20121-.1938-.4497a13.46776,13.46776,0,0,1,6.25531-6.82043,13.83858,13.83858,0,0,1,3.61439-1.2673c.24861-.05331.33423.01117.3468.24788.02911.54866.06512,1.097.09833,1.64542a1.0318,1.0318,0,0,0,.06677.89637v4.13078A1.0411,1.0411,0,0,0,133.48936,240.80729Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M77.61533,183.03219c.53607-.00287.552.01372.43567.53829q-1.09287,4.92784-2.18216,9.85646c-.06268.28614-.21175.2411-.39935.19214a13.60015,13.60015,0,0,1-5.39274-2.77094,13.28163,13.28163,0,0,1-4.08087-5.92651c-.09628-.27858-.07207-.39722.24786-.46915A55.6296,55.6296,0,0,1,74.8698,183.176C75.78426,183.117,76.70011,183.0795,77.61533,183.03219Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M108.00459,191.55633c-1.08741,1.049-2.1812,2.09156-3.26061,3.14876-1.40107,1.37225-2.79088,2.756-4.18512,4.13523-.31063.30728-.31494.3127-.60222-.00649a13.31684,13.31684,0,0,1-3.5253-10.52216,12.86765,12.86765,0,0,1,.286-1.76171c.10423-.44747.10286-.44708.52209-.30036a51.17309,51.17309,0,0,1,9.98689,4.76857q.32152.19834.64228.39794a.25667.25667,0,0,1,.0463.05044Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M47.00047,203.13548a13.18546,13.18546,0,0,1-7.69086-2.88841c-.2173-.16788-.19862-.27-.02148-.4523a56.12571,56.12571,0,0,1,8.8977-7.45c.351-.23656.35825-.23486.50662.168q1.74366,4.73472,3.48971,9.46853c.10368.2794.02789.3796-.231.46575A15.40882,15.40882,0,0,1,47.00047,203.13548Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M117.56535,214.07877a13.4998,13.4998,0,0,1,4.26064-9.77183c.18933-.18378.25049-.07581.356.06132a54.68014,54.68014,0,0,1,6.1406,10.06988c.109.23111.04251.28052-.16993.325q-3.79814.79432-7.59414,1.59883c-.79494.16747-1.59554.31179-2.38317.50831-.28814.07189-.33066-.04037-.36893-.25894A12.86226,12.86226,0,0,1,117.56535,214.07877Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M26.35285,227.627c-.27545-.01649-.72142-.04438-1.16755-.06936-.22485-.01259-.28-.10458-.23342-.33575a55.55492,55.55492,0,0,1,3.47886-11.15653c.11934-.27524.20112-.2993.442-.115q3.98838,3.05085,7.99634,6.076c.26476.19958.24979.34007.06726.56488a13.40925,13.40925,0,0,1-4.94954,3.84336A13.56391,13.56391,0,0,1,26.35285,227.627Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M24.87861,246.45911c2.81955.22158,5.60521.44095,8.391.659.52525.04112,1.05129.0726,1.57624.117.43607.03693.44169.04319.38745.51724a13.18971,13.18971,0,0,1-1.71465,5.17428,13.66231,13.66231,0,0,1-5.38664,5.215c-.22021.12019-.30112.084-.38939-.14215a56.39226,56.39226,0,0,1-3.11672-11.255C24.57522,246.44077,24.671,246.40837,24.87861,246.45911Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M122.63513,262.19723a12.61293,12.61293,0,0,1,4.30672.86334c.43016.15913.43172.175.21758.57623a57.29237,57.29237,0,0,1-6.20411,9.221c-.44365.54408-.4449.54023-.86675-.03241-1.87532-2.5456-3.74605-5.09465-5.63554-7.62971-.22041-.29571-.17847-.42231.10432-.61709A13.58559,13.58559,0,0,1,122.63513,262.19723Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M48.76005,276.14379a13.5177,13.5177,0,0,1-1.65038,6.05168c-.13636.25585-.22244.29666-.468.1159a59.86629,59.86629,0,0,1-8.61129-7.72149c-.1799-.19578-.20084-.28262.06592-.42493q4.40574-2.35033,8.797-4.72786c.223-.12045.3356-.10044.45.138A14.46348,14.46348,0,0,1,48.76005,276.14379Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M95.38075,279.715a13.4274,13.4274,0,0,1,10.38564,5.43192c.19426.25149.13265.36021-.12933.50741a55.53711,55.53711,0,0,1-10.53794,4.54114c-.28721.09058-.363.01643-.37945-.25661-.108-1.79486-.224-3.58921-.33644-5.3838-.0931-1.48568-.17885-2.97185-.28256-4.45679-.02-.28569.057-.403.35148-.387C94.76084,279.728,95.07115,279.715,95.38075,279.715Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M76.00592,292.5979a4.62173,4.62173,0,0,0-1.17844-.05258c-1.95409-.15149-3.8986-.38037-5.82845-.726-1.60073-.28671-3.18733-.63471-4.75529-1.06965-.26773-.07426-.25815-.15486-.14281-.36274q2.40711-4.33848,4.79654-8.68673c.13432-.24494.22684-.295.48032-.145a13.37762,13.37762,0,0,1,5.08145,5.32925A12.84337,12.84337,0,0,1,76.00592,292.5979Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.gray[7]}
    />
    <path
      d="M61.14144,189.34234a13.60327,13.60327,0,0,1-3.45653,9.35c-.08228.09532-.17958.17771-.26137.2734a.21516.21516,0,0,1-.37337.0051c-.0383-.04256-.081-.08114-.1216-.12164q-3.04461-3.03753-6.08973-6.07453c-.34911-.34791-.6987-.69586-1.06094-1.02984-.22893-.21108-.18556-.34.06346-.49461a53.94749,53.94749,0,0,1,10.40337-5.03053c.42878-.1525.44782-.13884.5393.30431A14.92416,14.92416,0,0,1,61.14144,189.34234Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M80.296,183.03351a53.79088,53.79088,0,0,1,10.78545,1.382c.54814.12858.56464.12665.37042.63564a13.373,13.373,0,0,1-8.61056,8.322c-.2495.08349-.50867.13942-.75506.23056-.2942.10884-.42993.03158-.49857-.2857-.43085-1.99156-.87759-3.97967-1.31849-5.96906q-.43469-1.96137-.86814-3.923c-.0825-.372-.06992-.39008.31036-.39221C79.90625,183.03261,80.10112,183.03351,80.296,183.03351Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M109.799,203.15032a13.34614,13.34614,0,0,1-4.22245-.69751c-.27405-.08862-.36241-.19566-.25273-.49152q1.76043-4.74819,3.495-9.50594c.1187-.32572.24975-.30518.49412-.14035a54.27862,54.27862,0,0,1,8.87755,7.46549c.22783.23784.12812.36058-.07154.515a13.34977,13.34977,0,0,1-4.39542,2.284A13.6871,13.6871,0,0,1,109.799,203.15032Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M39.93464,214.09856a12.53717,12.53717,0,0,1-.21676,2.46171c-.04485.25858-.11674.36936-.42511.30368q-4.97167-1.05892-9.95033-2.08508c-.35275-.07305-.23843-.25434-.14746-.44663q1.00755-2.12966,2.20322-4.15975a58.54507,58.54507,0,0,1,3.83588-5.70371c.19177-.25307.30294-.32028.56409-.06214a13.5023,13.5023,0,0,1,4.07017,8.31919C39.9197,213.18359,39.92685,213.64045,39.93464,214.09856Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M131.0083,227.6546a13.57979,13.57979,0,0,1-10.48895-5.06485c-.19018-.23005-.16192-.34811.05955-.51539q4.04565-3.05592,8.08-6.12677c.23959-.18255.31689-.12549.42633.12588a54.88292,54.88292,0,0,1,3.36493,10.61506c.026.12307.05161.24626.07424.37.08413.45966.06.49293-.39353.52443C131.754,227.6091,131.37689,227.63122,131.0083,227.6546Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M24.355,232.96619a13.16712,13.16712,0,0,1,2.45573.71307,13.4261,13.4261,0,0,1,7.51521,7.38571c.12192.28363.04.37917-.21169.47462q-4.67122,1.77162-9.33712,3.5572c-.24905.09528-.39256.07316-.421-.22587-.0824-.86569-.18-1.73-.2566-2.59618a62.45928,62.45928,0,0,1-.13216-6.801c.01557-.755.08791-1.50882.13348-2.26322C24.11013,233.05657,24.14772,232.93371,24.355,232.96619Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M132.55258,246.44949c.38161-.0167.38551-.016.29963.48766a54.88551,54.88551,0,0,1-2.7168,10.05893c-.11647.31075-.26042.61315-.35156.93091-.08973.31281-.22154.33748-.49222.18969a13.84549,13.84549,0,0,1-3.87731-3.14482,13.49,13.49,0,0,1-3.122-6.95546c-.1151-.76462-.11109-.75216.64711-.81028,2.51166-.19255,5.02253-.3954,7.53372-.594C131.1468,246.55888,131.82049,246.50672,132.55258,246.44949Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M35.114,262.17552a13.56637,13.56637,0,0,1,7.85493,2.41712c.24916.173.30936.29591.10987.56454q-2.87016,3.8648-5.71855,7.74576c-.37881.51386-.38644.52-.78185.03184a60.12939,60.12939,0,0,1-4.78748-6.75546c-.51537-.85357-1.0052-1.72133-1.4869-2.59418-.1452-.2631-.11536-.38587.18435-.50232A13.67271,13.67271,0,0,1,35.114,262.17552Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M110.41824,269.32188c.0602.03116.13172.06686.202.10478q4.32652,2.33268,8.65272,4.66608c.4115.222.41853.24627.11073.58337a58.13474,58.13474,0,0,1-8.47956,7.62348c-.22834.1682-.333.17083-.48331-.09494a13.45253,13.45253,0,0,1-.25862-12.56927C110.29994,269.366,110.33125,269.32761,110.41824,269.32188Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M63.38615,280.4792c-.14032,2.05415-.29909,4.32845-.44941,6.60333-.06119.926-.10629,1.853-.1541,2.77985-.021.40742-.05691.43694-.45339.31564a53.89041,53.89041,0,0,1-10.4906-4.51045c-.27445-.153-.291-.26947-.10328-.51362a13.38563,13.38563,0,0,1,8.945-5.32457,13.60292,13.60292,0,0,1,2.19446-.1249C63.41478,279.71261,63.41474,279.715,63.38615,280.4792Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M82.82118,292.54444a6.93222,6.93222,0,0,0-1.32154.05358,11.00358,11.00358,0,0,1,.582-3.44028,13.413,13.413,0,0,1,5.8074-7.4616c.08717-.055.18081-.10043.26513-.15933.20005-.1397.318-.07752.43085.127q2.40726,4.36327,4.82693,8.71967c.11343.20429.08795.29617-.14969.36114a53.88725,53.88725,0,0,1-6.34342,1.35132C85.55849,292.29685,84.19309,292.44983,82.82118,292.54444Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
    <path
      d="M70.88023,238.00367a7.41869,7.41869,0,1,1,7.38646,7.44A7.45288,7.45288,0,0,1,70.88023,238.00367Z"
      transform="translate(-23.9358 -183.03196)"
      fill={theme.colors.primary[4]}
    />
  </svg>
);

export default CadActiveTextLogoGraphic;