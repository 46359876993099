import { Map } from 'immutable';

import resetOnLogout from '../../utils/reducers/resetOnLogout';
import createFilteredReducer from '../../utils/reducers/createFilteredReducer';

const initialStatusState = Map({
  isFetching: 0,
  isPerforming: 0,
  isConsolidatingFetch: 0,
  isConsolidatingPerform: 0,
});

const statusReducer = (state = initialStatusState, { meta = {} }) => {
  const { status = {} } = meta;
  const { isFetching = 0, isPerforming = 0, isConsolidatingFetch = 0, isConsolidatingPerform = 0 } = status;

  return state.withMutations(intermediateState => {
    intermediateState
      .set('isFetching', intermediateState.get('isFetching') + isFetching)
      .set('isPerforming', intermediateState.get('isPerforming') + isPerforming)
      .set('isConsolidatingFetch', intermediateState.get('isConsolidatingFetch') + isConsolidatingFetch)
      .set('isConsolidatingPerform', intermediateState.get('isConsolidatingPerform') + isConsolidatingPerform);
  });
};

export default resetOnLogout(createFilteredReducer(statusReducer, action => !!((action || {}).meta || {}).status));
