import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import { selectCurrentOrganizationId } from '../CurrentUser/selectors';
import OrganizationUserInviteSchema from './schema';

export const selectNormalizedOrganizationUserInvites = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.ORGANIZATION_USER_INVITES.state)
);

export const selectOrganizationUserInvites = () => createSelector(
  selectEntities(),
  selectCurrentOrganizationId(),
  (entities, currentOrganizationId) => denormalize(entities.get(EK.ORGANIZATION_USER_INVITES.state).filter(invite => invite.organization === currentOrganizationId), [OrganizationUserInviteSchema], entities)
);
export const selectOrganizationUserInvitesList = () => createSelector(
  selectOrganizationUserInvites(),
  invites => invites.toList()
);
