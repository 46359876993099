
// if I missed some grammar item here feel free to add it.
/**
 * returns the plural version of the word given
 */
function getPluralWord (word: string) {
  let result = `${word}s`;
  switch (word.slice(-1)) {
  case 's':
    result = `${word}'`;
    break;
  case 'y':
    result = `${word.slice(0, -1)}ies`;
    break;
  default:
    break;
  }

  return result;
}

/**
 * takes a word and the number of items,
 * returns the plural version of the word if necessary
 *
 * @param {string} word - the word to make plural
 * @param {integer} count - the number of items
 * @returns {string} - the plural version of the word (if necessary)
 */
export const pluralize = (word: string, count: number) => (count > 1) || !count ? getPluralWord(word) : word;