import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveConfigProfileValuesTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.CONFIG_PROFILE_VALUES);

export const processSaveConfigProfileValues = (profileId, data) => client(REQUEST_TYPES.PATCH, 'plural', saveConfigProfileValuesTypes,  `/synchronize/config_profiles/${profileId}/values/collection?diff_result=true`, {
  data: { [EK.CONFIG_PROFILE_VALUES.collection]: data },
  mutateResponse: ({ [EK.CONFIG_PROFILES.single]: { id } = {}, [EK.CONFIG_PROFILE_VALUES.plural]: { created = [], destroyed = [], updated = [] } = {} }) => ({
    [EK.CONFIG_PROFILE_VALUES.plural]: {
      created: created.map(value => ({
        ...value,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
      destroyed: destroyed.map(value => ({
        ...value,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
      updated: updated.map(value => ({
        ...value,
        [EK.CONFIG_PROFILES.single]: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});