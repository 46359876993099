import baseSchema from '../../../modules/entities/utils/schema';

import keys from './keys';

import UnitSchema from '../Units/schema';

const unitSystemSchema = baseSchema(keys.state, {
  unitLength: { schema: UnitSchema, isId: true },
  unitWeight: { schema: UnitSchema, isId: true },
  unitDensity: { schema: UnitSchema, isId: true },
});

export default unitSystemSchema;
