import React from 'react';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { selectCurrentTableNameFromId } from '../../../entities/Framework/ProfileTables/selectors';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { FrameworkModuleTablePath } from '../../../paths';

export default function () {
  const location = useLocation();
  const tableId = matchPath(location.pathname, { path: FrameworkModuleTablePath.url });
  const tableName = useSelector(selectCurrentTableNameFromId(tableId.params.tableId));

  return <BreadcrumbTemplate title={tableName} />;
}