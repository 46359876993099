import { useFloating, offset, shift, flip } from '@floating-ui/react';
import { useMemo } from 'react';

// WARNING: order matters in the middleware, flip must go before shift
export const DEFAULT_MIDDLEWARE = [offset(10), flip(), shift({ crossAxis: true, padding: 5 })];

const useFloatingState = ({
  middleware = DEFAULT_MIDDLEWARE,
  ...props
}) => {
  const { x, y, floating, reference, strategy } = useFloating({...props, middleware});
  const style = useMemo(() => ({
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
  }), [strategy, x, y]);

  return [reference, floating, style];
};

export default useFloatingState;
