import { createAction } from 'redux-actions';

import {
  PREPARE_CONFIG_PROFILE_MAPKEYS_MODAL,
  CONFIG_PROFILE_MAPKEYS_MODAL_PREPARED,
  SAVE_CONFIG_PROFILE_MAPKEYS,
} from './constants';

export const prepareConfigProfileMapkeysModal = createAction(PREPARE_CONFIG_PROFILE_MAPKEYS_MODAL, (id) => ({ id }));
export const configProfileMapkeysModalPrepared = createAction(CONFIG_PROFILE_MAPKEYS_MODAL_PREPARED);
export const saveConfigProfileMapkeys = createAction(SAVE_CONFIG_PROFILE_MAPKEYS);
