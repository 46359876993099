import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { List } from 'immutable';

import Flex from '../../common/Flex';

import BaseListCreator from '../../form/BaseListCreator';

const ListCreatorEditor = React.forwardRef(({
  value,
  placeholder,
  stopEditing,
  defaultValue,
  minListSize,
  maxListSize,
  allow,
  onChange,
  onDefaultValueChange,
}, ref) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    },
    getValue: () => currentValue === null || currentValue === undefined ? defaultValue : currentValue,
  }));

  const [isReady, setIsReady] = useState(false);

  const [currentValue, setCurrentValue] = useState(value || defaultValue);

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%' cursor='pointer' >
      <BaseListCreator
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onDefaultValueChange={onDefaultValueChange}
        itemHeight={56}
        minListSize={minListSize}
        maxListSize={maxListSize}
        allow={allow}
        defaultValue={defaultValue}
      />
    </Flex>
  );
});

export default ListCreatorEditor;