import { InsulationSpecsType } from '../../../entities/Piping/InsulationSpecs/types';

export const convertToInsulationSpecsType = ({
  id,
  insulationCode,
  appearance,
  insulationFamily,
  isEdited=false,
  isCreated=false,
  isDeleted=false
}: InsulationSpecsType) => ({
  id,
  insulationCode,
  appearance,
  insulationFamily,
  isEdited,
  isCreated,
  isDeleted
});

export const saveCurrentInsulationSpecChanges = (
  changes: [InsulationSpecsType],
  saveAction: any,
) => {
  const collectionsdata: any = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        insulationCode: item.insulationCode,
        appearanceId: item.appearance?.id,
        insulationFamilyId: item.insulationFamily?.id,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        insulationCode: item.insulationCode,
        appearanceId: item.appearance?.id,
        insulationFamilyId: item.insulationFamily?.id,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};