import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectNormalizedStyleStates = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.STYLE_STATES.state)
);
export const selectNormalizedStyleStatesList = () => createSelector(
  selectNormalizedStyleStates(),
  styleStates => styleStates.toList()
);
