export const ENTITY_STATUS = {
  LOCAL_DRAFT: 'cadactive-selected-unedited-local-draft',
  SAVED: 'cadactive-selected-unedited-saved-record',
  PENDING_VALID_SAVE: 'cadactive-selected-pending-valid-create',
  PENDING_VALID_CHANGES: 'cadactive-selected-pending-valid-changes',
  PENDING_INVALID_SAVE: 'cadactive-selected-pending-invalid-create',
  PENDING_INVALID_CHANGES: 'cadactive-selected-pending-invalid-changes',
  UNSELECTED: 'cadactive-unselected-local-draft',
  PENDING_DELETE: 'cadactive-unselected-saved-record',
};

export const FIELD_STATUS = {
  UNEDITED: 'cadactive-unedited-field',
  VALID_UNEDITED: 'cadactive-valid-unedited-field',
  INVALID_UNEDITED: 'cadactive-invalid-unedited-field',
  EDITED: 'cadactive-edited-field',
  VALID_EDITED: 'cadactive-valid-edited-field',
  INVALID_EDITED: 'cadactive-invalid-edited-field',
};