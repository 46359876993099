import React from 'react';
import styled from 'styled-components';
import space from '../../../../assets/themes/base/space';

import Flex from '../../../../components/common/Flex';

const Headers = styled(Flex)`
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin-right: ${space[3]};
`;

export default Headers;