import styled from 'styled-components';

import Flex from '../../common/Flex';

const BaseSelectInputData = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  flex: 1;
  min-width: 0;

  opacity: ${
  ({ invisible }) => invisible ? 0 : 1
};

  min-height: ${
  ({ itemHeight }) => itemHeight && `${itemHeight}px`
};
`;

export default BaseSelectInputData;