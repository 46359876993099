import React from 'react';

export default function FitGeneralIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <path fill="#3B3B3C" d="M8.826,21.98H5.02v-16h3.767l0.149,0.222c4.467,6.666,0.213,15.149,0.029,15.506L8.826,21.98z M6.02,20.98h2.184c0.664-1.461,3.536-8.493,0.045-14H6.02V20.98z" />
      </g>
      <g>
        <path fill="#3B3B3C" d="M15.98,19.939H13V8.002h2.98V19.939z M14,18.939h0.98V9.002H14V18.939z" />
        <path fill="#3B3B3C" d="M21.042,21.98H14.98v-16h6.062V21.98z M15.98,20.98h4.062v-14H15.98V20.98z" />
        <path fill="#3B3B3C" d="M23.082,19.939h-3.04V8.002h3.04V19.939z M21.042,18.939h1.04V9.002h-1.04V18.939z" />
      </g>
    </svg>
  );
}
