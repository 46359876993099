import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import SpecPipeInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectCurrentPipeInstances } from '../PipeInstances/selectors';
import { removeDuplicateFromObjectIdArray, selectCurrentSpec } from '../Specs/selectors';
import { convertToSpecPipeInstanceType } from '../../../routes/Piping/SpecsPipesEdit/actions';

export const selectNormalizedSpecPipeInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SPEC_PIPE_INSTANCES.state)
);

export const selectCurrentNormalizedSpecPipeInstances = (specId) =>
  createSelector(
    selectNormalizedSpecPipeInstances(),
    instances => instances.toList().filter(item => item.spec == specId)
  );

export const selectCurrentSpecPipeInstances = (specId) => createSelector(
  selectEntities(),
  selectCurrentNormalizedSpecPipeInstances(specId),
  (entities, instances) => denormalize(instances, [SpecPipeInstanceSchema], entities).toList()
);

const selectCurrentSpecPipeInstancesFromSpec = (specId) => createSelector(
  selectCurrentSpec(specId),
  (spec) => spec?.specPipeInstances || List()
);

const specFilterForm = formValueSelector(`filters.${EK.SPEC_PIPE_INSTANCES.state}`);

function removeDuplicateFromSpecPipeInstancesArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    if(v.pipeInstance) {
      return v?.pipeInstance.id && i == self.findIndex((item) => item?.pipeInstance?.id ? item.pipeInstance.id == v.pipeInstance.id : item.id == v.pipeInstance.id);
    }
    return v?.id && i == self.findIndex((item) => item?.pipeInstance?.id ? item.pipeInstance.id == v.id : item.id == v.id);
  });

  return outputArray;
}

// gets all of the instances for a pipe family, concatenates that list with the list of specpipeinstances, and then removes the duplicates
export const selectCurrentFilteredSpecPipeInstances = (specId, pipeId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentSpecPipeInstancesFromSpec(specId),
  selectCurrentPipeInstances(pipeId),
  state => specFilterForm(state, 'sizeProperty', 'schedule', 'stockno', 'mccsCode'),
  (isFiltering, instances, pipeInstances, query) => {
    console.log('instances', instances.filter(item => typeof(item.pipeFamilyId) == 'string' ? item.pipeFamilyId == pipeId : item.pipeFamilyId?.id == pipeId));
    const specPipeInstances = instances && instances.filter(item => typeof(item.pipeFamilyId) == 'string' ? item.pipeFamilyId == pipeId : item.pipeFamilyId?.id == pipeId);
    const denSPI = specPipeInstances && isFiltering ? specPipeInstances.filter(i => i.doesMatchQuery(query, { normalized: false, searchAll: true })).toArray() : specPipeInstances.toArray() || [];
    const filteredPIs = pipeInstances && pipeInstances.filter(item => item.doesMatchQuery(query, { normalized: false })).toArray() || [];
    return removeDuplicateFromSpecPipeInstancesArray(denSPI.concat(filteredPIs) || denSPI).map(item => convertToSpecPipeInstanceType(item));
  }
);