import React from 'react';
import { toast } from 'react-toastify';

/**
 * Creates a Redux middleware that looks for the `notification` property in an
 * action's `meta` property, and then displays and appropriate toast notification
 *
 * @returns {Function} A Redux middleware.
 */
export default function toastifyNotificationMiddleware() {
  return (store: any) =>
    (next: any) =>
      (action: any) => {
        const { notification } = (action || {}).meta || {};

        if (notification && notification.message) {
          const {
            message,
            type = toast.TYPE.DEFAULT,
            position = toast.POSITION.BOTTOM_CENTER,
            ...rest
          } = notification;

          let fullMessage = message;
          if(action.error){
            // sometimes the error message can look a little different depending on the call being made, this prevents new message structures from crashing the web app until we add handling for them here.
            try {
              const errorMessage: string = action.payload.response.data.errors.fullMessages[0].base && action.payload.response.data.errors.fullMessages[0].base[0] || action.payload.response.data.errors.fullMessages[0];
              fullMessage = typeof (errorMessage) == 'string' && <><div>{message}:</div><div>{errorMessage}</div></> || message;
            } catch (error) {
              console.warn('Toastify Middleware type error');
            }
          }

          toast(fullMessage, {
            type,
            position,
            ...rest,
          });
        }

        next(action);
      };
}
