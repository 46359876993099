import { useEffect, useLayoutEffect, useState } from 'react';

import useLatest from './useLatest';

const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useLatest(callback);

  useEffect(() => {
    if (callback) callbackRef.current(state);
  }, [state, !callback]);

  return [state, setState];
};

const useStateWithCallbackInstant = (initialState, callback) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useLatest(callback);

  useLayoutEffect(() => {
    if (callback) callbackRef.current(state);
  }, [state, !callback]);

  return [state, setState];
};

export { useStateWithCallbackInstant };

export default useStateWithCallback;
