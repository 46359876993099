import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Text from '../../../../components/common/Text';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXPasswordInput from '../../RDXPasswordInput';

import validateRules from '../../../../utils/validation/validateRules';
import isValidEmail from '../../../../utils/validation/isValidEmail';
import mustMatchRDXField from '../../../../utils/validation/mustMatchRDXField';

const nameRegex = /^([a-z0-9\u00C0-\u02AB'´`-]{1,}\.?\s?)([a-z0-9\u00C0-\u02AB'´`-]?\.?\s?)+$/i;

const nameRules = {
  alphaSpaceCharacters: {
    message: 'Can only contain alphabetic characters and spaces',
    validator: value => value && nameRegex.test(value),
  },
};

const nameValidations = validateRules(nameRules);

const emailRules = {
  validEmail: {
    message: 'Must be a valid email address',
    validator: value => value && isValidEmail(value),
  },
};

const emailValidations = validateRules(emailRules);

const passwordRules = {
  minLength: {
    message: 'Must be at least 8 characters long',
    validator: value => value && value.length >= 8,
  },
  maxLength: {
    message: 'Must be shorter than 30 characters',
    validator: value => value && value.length <= 30,
  },
};

const emailDescription = 'This will be used to log into your account. Don\'t worry - we hate spam as much as you do.';

const passwordValidations = validateRules(passwordRules);

const passwordConfirmationRules = {
  matchPassword: {
    message: 'Must match the entered password exactly',
    validator: mustMatchRDXField('password'),
  },
};

const passwordConfirmationValidations = validateRules(passwordConfirmationRules);

const BaseRegisterForm = ({ handleSubmit, isPerforming, valid, emailLocked }) => (
  <Box as='form' onSubmit={handleSubmit} autoComplete='off'>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='firstName'
          label='First Name'
          autoComplete='new-password'
          required validate={nameValidations}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='lastName'
          label='Last Name'
          autoComplete='new-password'
          required
          validate={nameValidations}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='phone'
          label='Phone Number'
          type='tel'
          autoComplete='new-password'
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='email'
          label='Email'
          type='email'
          autoComplete='new-password'
          required
          disabled={emailLocked}
          validate={emailValidations}
          description={emailDescription}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXPasswordInput}
          name='password'
          label='Password'
          autoComplete='new-password'
          required
          validate={passwordValidations}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXPasswordInput}
          name='passwordConfirmation'
          label='Confirm Password'
          autoComplete='new-password'
          required
          validate={passwordConfirmationValidations}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Text as='p' color='gray.6' fontSize={1} textAlign='center' mb={3} p={0}>
      By creating your account, you agree to our <Text as='a' target='_blank' href='//cadactive.com/legal/'>Terms of Use, Privacy Policy, and End User License Agreement</Text>.
    </Text>

    <Button primary type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Create Account</Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'register',
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

const RegisterForm = enhance(BaseRegisterForm);

export default (props) => {
  const initialValues = { email: props.email || undefined };

  return <RegisterForm initialValues={initialValues} emailLocked={!!props.email} {...props} />;
};
