/**
 * Colors pulled from OpenColor
 * https://yeun.github.io/open-color/ingredients.html
 */
export default {
  transparent: 'transparent',
  white: '#ffffff',
  gray: [
    /**
     * Gray 0
     * - Used for primary background color
     * - Used for regular text on inverted background
     */
    'rgb(248,249,250)',

    /**
     * Gray 1
     * - Used for emphasis background color
     */
    'rgb(241,243,245)',

    /**
     * Gray 2
     * - Used for primary background hover state
     * - Used for disabled borders
     */
    'rgb(233,236,239)',

    /**
     * Gray 3
     * - Used for emphasis background hover state
     * - Used for light borders for dividing components
     */
    'rgb(222,226,230)',

    /**
     * Gray 4
     * - Used for emphasis borders for boundary of forms or buttons
     */
    'rgb(206,212,218)',

    /**
     * Gray 5
     * - Used for disabled text in forms, buttons, links, etc.
     * - Used for additional info text below headlines or for metadata on inverted background
     */
    'rgb(173,181,189)',

    /**
     * Gray 6
     * - Used for additional info text below headlines or for metadata
     * - Used for disabled text in forms, buttons, links, etc. in inverted background
     */
    'rgb(134,142,150)',

    /**
     * Gray 7
     * - Used for regular body text on white/off-white background
     * - Used for base border color on inverted background
     */
    'rgb(73,80,87)',

    /**
     * Gray 8
     * - Used for emphasis body text on white/off-white background
     * - Used for emphasis background color in inverted background
     */
    'rgb(52,58,64)',

    /**
     * Gray 9
     * - Used for inverted background color when wanted
     */
    'rgb(33,37,41)',
  ],

  primary: [
    'rgb(231,245,255)',
    'rgb(208,235,255)',
    'rgb(165,216,255)',
    'rgb(116,192,252)',
    'rgb(77,171,247)',
    'rgb(51,154,240)',
    'rgb(34,139,230)',
    'rgb(28,126,214)',
    'rgb(25,113,194)',
    'rgb(24,100,171)',
  ],

  success: [
    'rgb(235,251,238)',
    'rgb(211,249,216)',
    'rgb(178,242,187)',
    'rgb(140,233,154)',
    'rgb(105,219,124)',
    'rgb(81,207,102)',
    'rgb(64,192,87)',
    'rgb(55,178,77)',
    'rgb(47,158,68)',
    'rgb(43,138,62)',
  ],

  error: [
    'rgb(255,245,245)',
    'rgb(255,227,227)',
    'rgb(255,201,201)',
    'rgb(255,168,168)',
    'rgb(255,135,135)',
    'rgb(255,107,107)',
    'rgb(250,82,82)',
    'rgb(240,62,62)',
    'rgb(224,49,49)',
    'rgb(201,42,42)',
  ],

  warning: [
    'rgb(255,244,230)',
    'rgb(255,232,204)',
    'rgb(255,216,168)',
    'rgb(255,192,120)',
    'rgb(255,169,77)',
    'rgb(255,146,43)',
    'rgb(253,126,20)',
    'rgb(247,103,7)',
    'rgb(232,89,12)',
    'rgb(217,72,15)',
  ],
};
