import React from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';
import useIsOverflowing from '../../../../hooks/useIsOverflowing';

import Box from '../../../../components/common/Box';

import Tooltip, { CONTAINERS } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

const Breadcrumb = ({ title }) => {
  document.title = `CadActive${ !!title && ` | ${title}` || '' }`;

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });
  const isHovering = useIsHovering(reference);
  const isOverflowing = useIsOverflowing(reference);

  return (
    <Box ref={reference} $ellipsis>
      { title }
      {
        isHovering && isOverflowing && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid'>
            <TooltipBody>
              <TooltipSection small inverse>{ title }</TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Box>
  );
};

export default Breadcrumb;