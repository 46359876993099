import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';

import PipeInstanceSchema from './schema';

import { formValueSelector } from 'redux-form/immutable';
import { newPipeInstancesArray } from './constants';
import { selectCurrentPipe } from '../../../routes/SpecsEditSwitch/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import EK from '../../keys';

export const selectDenormalizedPipeInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PIPE_INSTANCES.state), [PipeInstanceSchema], entities).toList().toArray()
);

const pipeInstanceFilterForm = formValueSelector(`filters.${EK.PIPE_INSTANCES.state}`);

export const selectCurrentNormalizedFilteredPipeInstances = (pipeId) => createSelector(
  selectEntities(),
  selectSidebarIsFiltering(),
  state => pipeInstanceFilterForm(state, 'sizeProperty', 'schedule', 'mccsCode', 'stockno'),
  (entities, isFiltering, query) => {
    let instances = !isFiltering ? entities.get(EK.PIPE_INSTANCES.state) : entities.get(EK.PIPE_INSTANCES.state).toList().filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [PipeInstanceSchema], entities.delete(EK.PIPES.state)).toList().filter(item => item.pipeFamilyId == pipeId);
  }
);

export const selectCurrentPipeInstances = (PipeId) => createSelector(
  selectEntities(),
  (entities) => {
    return denormalize(entities.get(EK.PIPE_INSTANCES.state), [PipeInstanceSchema], entities.delete(EK.PIPES.state)).toList().filter(item => item.pipeFamilyId == PipeId);
  }
);

export const selectPipeInstanceCount = (pipeId) => createSelector(
  selectCurrentPipeInstances(pipeId),
  (instances) => instances && instances.size || 0
);

export const selectCurrentFilteredPipeInstances = (PipeFamilyId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentNormalizedFilteredPipeInstances(PipeFamilyId),
  (isFiltering, instances) =>
    isFiltering ? instances.toArray() : instances.toArray().concat(newPipeInstancesArray)
);