import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import debounce from 'lodash/debounce';

import BaseInput from '../BaseInput';

const BaseDebounceInput = React.forwardRef(({
  wait = 250,
  ...props
}, ref) => {
  const [debounceFieldValue, setDebounceFieldValue] = useState(props.value || '');
  const [debouncing, setDebouncing] = useState(false);
  const lastInputValue = useRef(props.value);

  useEffect(() => {
    if (debouncing) {
      return;
    }
    if (props.value === lastInputValue.current) {
      return;
    }
    lastInputValue.current = props.value;
    setDebounceFieldValue(props.value);
  }, [debouncing, props.value]);

  const call = useMemo(() => debounce((onChange, e) => {
    setDebouncing(false);
    onChange(e);
  }, wait), [setDebouncing, wait]);

  const onChange = useCallback(e => {
    e.persist();
    setDebouncing(true);
    call(props.onChange, e);
    setDebounceFieldValue(e.target.value);
  }, [setDebouncing, call, setDebounceFieldValue]);

  const onBlur = useCallback(e => {
    call.cancel();
    setDebouncing(false);
    props.onChange(e);
    props.onBlur(e);
  }, [ call, setDebouncing, props.onChange, props.onBlur ]);

  const onKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      call.cancel();
      setDebouncing(false);
      props.onChange(e);
    }
  }, [call, setDebouncing, props.onChange]);

  return (
    <BaseInput
      {...props}
      ref={ref}
      value={debounceFieldValue}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
});

export default BaseDebounceInput;