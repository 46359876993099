import { createAction } from 'redux-actions';

import { 
  IS_INITIAL_LOAD_COMPLETE,
  SET_SIDEBAR_MINIMIZED,
  SET_SIDEBAR_FILTERING,
  TOGGLE_SIDEBAR_MINIMIZED,
  TOGGLE_SIDEBAR_FILTERING,
  TOGGLE_HIDDEN_GRID_COLUMNS,
} from './constants';

export const isInitialLoadComplete = createAction(IS_INITIAL_LOAD_COMPLETE);
export const setSidebarMinimized = createAction(SET_SIDEBAR_MINIMIZED, isMinimized => ({ isMinimized }));
export const setSidebarFiltering = createAction(SET_SIDEBAR_FILTERING, isFiltering => ({ isFiltering }));
export const toggleSidebarMinimized = createAction(TOGGLE_SIDEBAR_MINIMIZED);
export const toggleSidebarFiltering = createAction(TOGGLE_SIDEBAR_FILTERING);
export const toggleHiddenGridColumns = createAction(TOGGLE_HIDDEN_GRID_COLUMNS);
