import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// header imports
import Header from '../../templates/Structures/Header';
import Skeleton from 'react-loading-skeleton';
import { PipingModuleFittingsPath, PipingModuleFittingsEditPath } from '../../../paths';
import Options from '../../templates/Structures/Options';
import { pluralize } from '../../../components/common/Header';

// Data Grid imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Dialog
import FittingFamilyDialog from '../../../containers/dialog/templates/FittingFamilyDialog';
import { BlankFitting, VALIDATION_FIELDS, } from '../../../entities/Piping/FittingFamilies/model';

// grid cell bodies
import {
  nameBody,
  materialBody,
  codeBody,
  rowEndTools,
  doRequiredFieldsExist,
  areFieldsValid,
} from './components';
import FittingCategoryBody from '../../../components/grid/FittingCategoryBody';

// Misc. imports
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

// update saga
import injectSaga from '../../../utils/sagas/injectSaga';
import EK from '../../../entities/keys';
import saga from './sagas';

// selectors
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectCurrentFilteredFittings, selectFittingFamiliesCounts } from '../../../entities/Piping/FittingFamilies/selectors';
import { selectNormalizedMaterials } from '../../../entities/Piping/Materials/selectors';
import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';
import { selectNormalizedFittingCodes } from '../../../entities/Piping/FittingCodes/selectors';
import { selectNormalizedFittingCategories } from '../../../entities/Piping/FittingCategories/selectors';
import { selectNormalizedBoltNutFamilies } from '../../../entities/Piping/BoltNutFamilies/selectors';

// actions
import {
  processArchiveFittingFamilies,
  processUnarchiveFittingFamilies,
  processCreateFittingFamily,
  processDeleteFittingFamily,
  processEditFittingFamily,
  processCopyFittingFamily,
  processFetchAllFittingFamilies,
} from '../../../entities/Piping/FittingFamilies/actions';

// dialog imports
import { Dialog } from 'primereact/dialog';
import { deleteDialogFooter } from './components';
import { push } from 'connected-react-router';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredFittings(),
  selectNormalizedMaterials(),
  selectNormalizedFittingCategories(),
  selectNormalizedBoltNutFamilies(),
  selectNormalizedFittingCodes(),
  selectFittingFamiliesCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    materials,
    categories,
    boltNuts,
    fittingCodes,
    {total, unarchived},
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      materials: materials && materials.toList().toArray() || [],
      categories: categories && categories.toList().toArray() || [],
      boltNuts: boltNuts && boltNuts.toList().toArray() || [],
      fittingCodes: fittingCodes && fittingCodes.toList().toArray() || [],
      isFiltering,
      total,
      unarchived,
      canCollaborate,
      hasValidLicense,
    };
  },
);

function FittingsBody (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // dialog state variables
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [currentEditingRow, setCurrentEditingRow] = useState({
    name: ''
  });
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  // header actions
  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    setValidation(VALIDATION_FIELDS);
    setCurrentEditingRow(BlankFitting);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };

  const saveAction = () => {
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      name: currentEditingRow.name,
      selectionName: currentEditingRow.selectionName,
      materialId: currentEditingRow.material?.id || '',
      fittingCodeId: currentEditingRow.fittingCode.id,
      fittingCategoryId: currentEditingRow.fittingCategory.id,
      boltNutFamilyId: currentEditingRow.boltNutFamily?.id || '',
    };

    if(isCreateDialogOpen) {
      dispatch(processCreateFittingFamily(editedRow));
    } else if(isCopyDialogOpen) {
      dispatch(processCopyFittingFamily({id: currentEditingRow.id, ...editedRow}));
    } else {
      dispatch(processEditFittingFamily(currentEditingRow.id, { id: currentEditingRow.id, ...editedRow }));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    dispatch(processDeleteFittingFamily(currentEditingRow.id));
    setCurrentEditingRow(BlankFitting);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  // row end actions
  const editRowAction = (rowdata) => {
    setCurrentEditingRow({
      id: rowdata.id,
      name: rowdata.name,
      selectionName: rowdata.selectionName,
      material: rowdata.material,
      fittingCode: rowdata.fittingCode,
      fittingCategory: rowdata.fittingCategory,
      boltNutFamily: rowdata.boltNutFamily,
    });
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    setCurrentEditingRow({
      id: rowdata.id,
      name: rowdata.name,
      selectionName: rowdata.selectionName,
      material: rowdata.material,
      fittingCode: rowdata.fittingCode,
      fittingCategory: rowdata.fittingCategory,
      boltNutFamily: rowdata.boltNutFamily,
    });
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveFittingFamilies([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveFittingFamilies([rowdata.id]));
  };

  const gotoFittingInstances = (fittingId) => {
    const fittingLink = PipingModuleFittingsEditPath.generate({ id: fittingId });
    dispatch(push(fittingLink));
  };

  // on page load actions
  useEffect(() => {
    dispatch(processFetchAllFittingFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading || (reduxProps.isFetching && reduxProps.total === 0)}
          title={PipingModuleFittingsPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Fitting',
              reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.showArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Fitting', reduxProps.unarchived)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='name' header='Name // Instances' style={{ width: '14%' }} body={(rowdata) => nameBody(rowdata, gotoFittingInstances)} ></Column>
          <Column field='selectionName' header='Selection Name' style={{width: '14%'}} body={(rowdata) => <Text>{rowdata.selectionName}</Text>} ></Column>
          <Column field='material' header="Material" style={{ width: '14%' }} body={materialBody} ></Column>
          <Column field='fittingCode' header='Fitting Code' style={{ width: '14%' }} body={codeBody} ></Column>
          <Column field='fittingCategory' header='Fitting Category' style={{ width: '14%' }} body={(rowdata) => rowdata.fittingCategory && FittingCategoryBody(rowdata.fittingCategory)} ></Column>
          <Column header=''
            style={{ width: '15%', minWidth: '200px', textAlign: 'right' }}
            body={(rowData) => rowEndTools(
              rowData,
              editRowAction,
              copyRowAction,
              gotoFittingInstances,
              archiveAction,
              unarchiveAction
            )}
          ></Column>
        </DataTable>
      </Flex>
      <FittingFamilyDialog
        currentEditingRow={currentEditingRow}
        isDialogOpen={isDialogOpen}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedRowChange}
        saveAction={saveAction}
        deleteAction={openDeleteDialog}
        cancelAction={cancelDialogs}
        validation={validation}
      />
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Pipe Family '${currentEditingRow.name}'`} footer={() => deleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}

export default injectSaga({ key: `${EK.FITTINGS.state}Page`, saga })(withRouter(FittingsBody));