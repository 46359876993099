import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { selectIsFetching } from '../../modules/utility/selectors';

import selectEntities from '../../modules/entities/selectors';
import OrganizationUserInviteSchema from '../../entities/OrganizationUserInvites/schema';
import { selectNormalizedOrganizationUserInvites } from '../../entities/OrganizationUserInvites/selectors';

export const selectInviteFromToken = (token) => createSelector(
  selectEntities(),
  selectNormalizedOrganizationUserInvites(),
  (entities, invites) => {
    return token ? denormalize(invites.find(invite => invite.token === token), OrganizationUserInviteSchema, entities) : null;
  }
);
export const selectIsInviteValid = (token) => createSelector(
  selectIsFetching(),
  selectInviteFromToken(token),
  (isFetching, invite) => {
    return !!token && !isFetching && invite && invite.isPending;
  }
);
