import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

// prettier-ignore
const appearanceStateSchema = baseSchema(
  EK.APPEARANCE_STATES.state
);

export default appearanceStateSchema;
