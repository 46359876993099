import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllDimensionTypes,
  createDimensionTypes,
  copyDimensionTypes,
  editDimensionTypes,
  deleteDimensionTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.DIMENSIONS);

// /api/framework/profiles/:profile_id/profile_dimensions

export const processFetchDimensions = (profileId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllDimensionTypes,
    `/framework/profiles/${profileId}/profile_dimensions`,
    {
      mutateResponse: ( {profileDimensions = []} ) => ({
        profileDimensions: profileDimensions.map( dim => ({...dim, profile: profileId, })),
      })
    },
  );

export const processCreateDimension = (profileId, newDimensions) =>
  /// newDimensions must be an array of profiles objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createDimensionTypes,
    `/framework/profiles/${profileId}/profile_dimensions`,
    {
      data: {
        profile_dimensions: newDimensions,
      },
      mutateResponse: ({ profileDimensions = [] }) => ({
        profileDimensions: profileDimensions.map(dim => ({ ...dim, profile: profileId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Dimension successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Dimension',
      },
    }
  );

export const processEditDimension = (profileId, data) =>
  // data is a profile object containing any/all fields to be changed (not technically necessary to include all fields)
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editDimensionTypes,
    `/framework/profiles/${profileId}/profile_dimensions/${data.id}`,
    {
      data,
      mutateResponse: ({ profileDimension = {} }) => ({
        profileDimension: { ...profileDimension, profile: profileId, },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

//  # DELETE / api / framework / profiles /: profile_id / profile_dimensions /: id
export const processDeleteDimension = (ProfileId, DimensionId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteDimensionTypes,
    `/framework/profiles/${ProfileId}/profile_dimensions/${DimensionId}?with_full_detail=true`,
    {
      deleteKey: EK.DIMENSIONS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Dimension deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Dimension',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyDimension = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyDimensionTypes,
    `/framework/profiles/${ProfileId}/profile_dimensions/copy`,
    {
      data: {
        dimensions: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Dimension successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Dimension',
      },
    }
  );