import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  StandardizeModuleParameterProfilesPath,
  ParameterProfilesEditPath,
} from '../../../../paths';

import {
  createParameterProfileTypes,
  copyParameterProfileTypes,
  editParameterProfileTypes,
  deleteParameterProfileTypes,
} from '../../../../entities/Standardize/ParameterProfiles/constants';
import {
  selectNormalizedParameterProfiles
} from '../../../../entities/Standardize/ParameterProfiles/selectors';

export function* handleCreateParameterProfileSuccess({
  payload = {},
}) {
  const id = `${get(payload, `${EK.PARAMETER_PROFILES.plural}[0].id`)}`;
  if (id) {
    let parameterProfiles = yield select(selectNormalizedParameterProfiles());
    let copiedParameterProfile = parameterProfiles.get(id);

    while (!copiedParameterProfile) {
      yield take();
      parameterProfiles = yield select(selectNormalizedParameterProfiles());
      copiedParameterProfile = parameterProfiles.get(id);
    }

    yield put(push(ParameterProfilesEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteParameterProfileSuccess() {
  yield put(push(StandardizeModuleParameterProfilesPath.url));
}

export function* watchCreateParameterProfileSaga() {
  yield takeLatest([
    createParameterProfileTypes.SUCCESS,
    copyParameterProfileTypes.SUCCESS,
  ], handleCreateParameterProfileSuccess
  );
}

export function* watchDeleteParameterProfileSaga() {
  yield takeLatest(
    deleteParameterProfileTypes.SUCCESS,
    handleDeleteParameterProfileSuccess
  );
}

export function* handleCloseParameterProfileModal() {
  yield put(hideModal());
}

export function* watchCloseParameterProfileModalSaga() {
  yield takeLatest(
    [
      createParameterProfileTypes.SUCCESS,
      editParameterProfileTypes.SUCCESS,
      deleteParameterProfileTypes.SUCCESS,
    ],
    handleCloseParameterProfileModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateParameterProfileSaga),
    fork(watchDeleteParameterProfileSaga),
    fork(watchCloseParameterProfileModalSaga),
  ]);
}
