import { take, put, debounce, fork } from 'redux-saga/effects';

import {
  createNoteBlockTypes,
  editNoteBlockTypes,
  deleteNoteBlockTypes,
  sortNoteBlockNoteTypes,
} from '../../../entities/Standardize/NoteBlocks/constants';

import {
  createNoteBlockNoteTypes,
  editNoteBlockNoteTypes,
  deleteNoteBlockNoteTypes,
} from '../../../entities/Standardize/NoteBlockNotes/constants';

import { setNoteProfileEditStatus } from './actions';

export function* watchNoteProfilesEditSaga() {
  // and we leave this while-true loop here to make sure the saga doesnt end
  while (true) {
    yield take([
      createNoteBlockTypes.REQUEST,
      editNoteBlockTypes.REQUEST,
      deleteNoteBlockTypes.REQUEST,
      sortNoteBlockNoteTypes.REQUEST,
      createNoteBlockNoteTypes.REQUEST,
      editNoteBlockNoteTypes.REQUEST,
      deleteNoteBlockNoteTypes.REQUEST,
    ]);

    yield put(setNoteProfileEditStatus(true));

    yield debounce(
      1500,
      [
        createNoteBlockTypes.SUCCESS,
        createNoteBlockTypes.FAILURE,
        editNoteBlockTypes.SUCCESS,
        editNoteBlockTypes.FAILURE,
        deleteNoteBlockTypes.SUCCESS,
        deleteNoteBlockTypes.FAILURE,
        sortNoteBlockNoteTypes.SUCCESS,
        sortNoteBlockNoteTypes.FAILURE,
        createNoteBlockNoteTypes.SUCCESS,
        createNoteBlockNoteTypes.FAILURE,
        editNoteBlockNoteTypes.SUCCESS,
        editNoteBlockNoteTypes.FAILURE,
        deleteNoteBlockNoteTypes.SUCCESS,
        deleteNoteBlockNoteTypes.FAILURE,
      ],
      function* () {
        yield put(setNoteProfileEditStatus(false));
      }
    );
  }
}

// final output saga
export default function* main() {
  yield fork(watchNoteProfilesEditSaga);
}
