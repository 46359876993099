import React from 'react';

import theme from '../../../../assets/themes/theme';

import BaseGraphicSVG, { fillTransitionClass } from '../BaseGraphicSVG';

const EcadGraphic = ({
  id = 'ecad-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <BaseGraphicSVG
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 220 220"
  >
    <ellipse cx="110.79734" cy="110.68726" rx="110.4556" ry="110.68726" fill={`${disabled ? disabledBackgroundColor : backgroundColor}`} />
    <rect x="159.5212" y="68.76424" width="7.33096" height="6.74593" fill='#ecf6ff' />
    <rect x="159.5212" y="81.59341" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="159.5212" y="94.42258" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="159.5212" y="107.25175" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="159.5212" y="120.08093" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="159.5212" y="132.9101" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="159.5212" y="145.73927" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="358.18022" y="280.90772" width="7.33096" height="6.74576" transform="translate(433.82324 -199.09019) rotate(90)" fill='#ecf6ff' />
    <rect x="345.35104" y="280.90772" width="7.33096" height="6.74576" transform="translate(420.99407 -186.26102) rotate(90)" fill='#ecf6ff' />
    <rect x="332.52187" y="280.90772" width="7.33096" height="6.74576" transform="translate(408.1649 -173.43184) rotate(90)" fill='#ecf6ff' />
    <rect x="319.6927" y="280.90772" width="7.33096" height="6.74576" transform="translate(395.33573 -160.60267) rotate(90)" fill='#ecf6ff' />
    <rect x="306.86353" y="280.90772" width="7.33096" height="6.74576" transform="translate(382.50656 -147.7735) rotate(90)" fill='#ecf6ff' />
    <rect x="294.03427" y="280.90764" width="7.33096" height="6.74593" transform="translate(369.6773 -134.94424) rotate(90)" fill='#ecf6ff' />
    <rect x="281.20501" y="280.90772" width="7.33096" height="6.74576" transform="translate(356.84804 -122.11498) rotate(90)" fill='#ecf6ff' />
    <rect x="55.25844" y="68.76424" width="7.33096" height="6.74593" fill='#ecf6ff' />
    <rect x="55.25844" y="81.59341" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="55.25844" y="94.42258" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="56.31514" y="106.19505" width="7.33096" height="6.74593" fill='#ecf6ff' />
    <rect x="56.31514" y="119.02422" width="7.33096" height="6.74593" fill='#ecf6ff' />
    <rect x="56.31514" y="131.85339" width="7.33096" height="6.74593" fill='#ecf6ff' />
    <rect x="56.31514" y="144.68256" width="7.33096" height="6.74576" fill='#ecf6ff' />
    <rect x="358.18038" y="176.64547" width="7.33096" height="6.74576" transform="translate(329.56116 -303.35261) rotate(90)" fill='#ecf6ff' />
    <rect x="345.35121" y="176.64547" width="7.33096" height="6.74576" transform="translate(316.73199 -290.52344) rotate(90)" fill='#ecf6ff' />
    <rect x="332.52204" y="176.64547" width="7.33096" height="6.74576" transform="translate(303.90282 -277.69427) rotate(90)" fill='#ecf6ff' />
    <rect x="319.69287" y="176.64547" width="7.33096" height="6.74576" transform="translate(291.07364 -264.86509) rotate(90)" fill='#ecf6ff' />
    <rect x="306.86361" y="176.64538" width="7.33096" height="6.74593" transform="translate(278.24439 -252.03584) rotate(90)" fill='#ecf6ff' />
    <rect x="294.03435" y="176.64547" width="7.33096" height="6.74576" transform="translate(265.41513 -239.20658) rotate(90)" fill='#ecf6ff' />
    <rect x="281.20518" y="176.64547" width="7.33096" height="6.74576" transform="translate(252.58596 -226.37741) rotate(90)" fill='#ecf6ff' />
    <path
      d="M431.41085,232.15709c0,.2536,0,.49062-.01766.74423H376.87452V231.2095h54.51867C431.41085,231.532,431.41085,231.836,431.41085,232.15709Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M429.21149,210.31648h-27.5767l-6.97026,9.76108H376.833v-1.69181H393.785l6.9701-9.76108H428.8393C428.97464,209.18213,429.0929,209.75819,429.21149,210.31648Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M425.74311,197.49256H401.63479l-6.97026,9.74415H376.833V205.5449H393.785l6.9701-9.74415h24.3961C425.35416,196.359,425.557,196.9165,425.74311,197.49256Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M420.46466,184.66846H401.63479l-6.97026,9.74415H376.833V192.7208H393.785l6.9701-9.74415h18.86371C419.90653,183.53512,420.194,184.09341,420.46466,184.66846Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M429.21149,253.98128c-.11859.55864-.23685,1.13335-.37219,1.69181H400.75505l-6.9701-9.76073H376.833v-1.69181h17.83151l6.97026,9.76073Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M425.74311,266.80555c-.1861.57471-.389,1.13317-.592,1.69181h-24.3961l-6.9701-9.74449H376.833v-1.69182h17.83151l6.97026,9.7445Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path
      d="M420.46466,279.64589c-.27069.57606-.55813,1.13419-.8459,1.69181H400.75505L393.785,271.577H376.833v-1.69181h17.83151l6.97026,9.76074Z"
      transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }}
    />
    <path d="M269.81729,230.37715V232.069H214.32c-.01692-.305-.01692-.62614-.01692-.93118,0-.25343,0-.507.01692-.76063Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.52952,243.06225v1.69181H252.57758l-6.97043,9.76073H217.52342c-.13551-.55847-.25411-1.13317-.37237-1.69181h27.59327l6.97043-9.76073Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.62219,255.94909v1.69181H252.67024l-6.97043,9.74449H221.30406c-.203-.55864-.406-1.1171-.5923-1.69181H244.837l6.97043-9.74449Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.43686,269.88515V271.577h-16.952l-6.97043,9.76074h-18.8466c-.30453-.55762-.57539-1.11575-.84591-1.69181h18.82969l6.97043-9.76074Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.43686,218.38575v1.69181H251.62209l-6.97043-9.76108H217.05839c.11826-.55829.23685-1.13435.37237-1.69181h28.08372l6.97043,9.76108Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.43686,205.5449v1.69181H251.62209l-6.97043-9.74415H220.52643c.20319-.57606.38929-1.13352.60922-1.69181h24.37883l6.97043,9.74415Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M269.43686,192.7208v1.69181H251.62209l-6.97043-9.74415H225.80505c.28744-.57505.5583-1.13334.86283-1.69181h18.8466l6.97043,9.74415Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M324.08236,285.64636v55.187c-.32162.01658-.626.01658-.94742.01658-.25394,0-.49062,0-.7444-.01658v-55.187Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M346.73736,309.76063v28.101c-.55813.13552-1.13334.25445-1.69181.37254V310.64037l-9.76175-6.97043V285.85551h1.69181V302.7902Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M359.631,309.83013v24.39574c-.55847.21977-1.1166.40672-1.69181.60889V310.70987l-9.76175-6.97043V285.925h1.69181V302.8597Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M372.45488,309.76063v18.86352c-.55829.305-1.11676.57522-1.69181.8635V310.64037l-9.74483-6.97043V285.85551h1.69181V302.7902Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M311.35015,285.85551v17.81443l-9.76175,6.97043v27.59378c-.558-.11809-1.13334-.237-1.69181-.37254v-28.101l9.76175-6.97043V285.85551Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M298.50931,285.85551v17.81443l-9.7445,6.97043v24.12489c-.57538-.18593-1.13385-.38912-1.69181-.59129V309.76063l9.7445-6.97043V285.85551Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M285.616,285.85551v17.81443l-9.76175,6.97043v18.82969c-.57522-.27069-1.13351-.54087-1.69181-.84591V309.76063l9.76175-6.97043V285.85551Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M324.17425,123.96827v55.16946h-1.69181V123.96827c.30435-.01759.626-.01759.93049-.01759C323.66654,123.95068,323.92048,123.95068,324.17425,123.96827Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M311.35015,160.62783v17.81426h-1.69181V161.5074l-9.76175-6.97043V126.45273c.55847-.13551,1.13385-.2536,1.69181-.37152v27.57618Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M298.50931,160.69732v17.81427H296.8175V161.5769l-9.7445-6.97043V130.21089c.558-.20319,1.11643-.40637,1.69181-.5923v24.1083Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M285.68555,160.62783v17.81426h-1.69181V161.5074L274.232,154.537V135.67361c.55829-.28744,1.11659-.57487,1.69181-.8459v18.82968Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M346.66786,126.45273V154.537l-9.76175,6.97043v16.93469H335.2143V160.62783l9.76175-6.97044V126.08121C345.53452,126.19913,346.10974,126.31722,346.66786,126.45273Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M359.492,130.14139V154.537l-9.76175,6.97043v16.93469H348.0384V160.62783l9.76175-6.97044v-24.1083C358.37537,129.735,358.93349,129.9382,359.492,130.14139Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <path d="M372.31589,135.67361V154.537l-9.74483,6.97043v16.93469h-1.69181V160.62783l9.74483-6.97044V134.82771C371.19912,135.09874,371.75759,135.38617,372.31589,135.67361Z" transform="translate(-212.30305 -121.5251)" fill='#30456a' style={{ stroke: '#30465a', strokeMiterlimit: 10, strokeWidth: '4px' }} />
    <rect x="60.92769" y="60.71595" width="99.81736" height="99.81736" fill='#30465a' />
    <rect x="280.7621" y="189.77206" width="84.75483" height="84.75533" rx="2.7241" transform="translate(342.98619 -212.51488) rotate(90)" fill='#ecf6ff' />
  </BaseGraphicSVG>
);

export default EcadGraphic;
