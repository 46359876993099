import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { submit } from 'redux-form/immutable';
import { createSelector, createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import styled from 'styled-components';
import space from '../../../../assets/themes/base/space';
import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import useBoundingclientrectRef from '@rooks/use-boundingclientrect-ref';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Loading from '../../../../components/common/Loading';
import Text from '../../../../components/common/Text';

import ConfigProfileMapkeysForm from '../../../form/templates/ConfigProfileMapkeysForm';

import {
  selectIsFetchingInModal,
  selectIsPerformingInModal,
} from '../../../../modules/utility/selectors';

import { selectCurrentConfigProfile } from '../../../../entities/Synchronize/ConfigProfiles/selectors';

import { selectNormalizedConfigProfileMapkeys } from '../../../../entities/Synchronize/ConfigProfileMapkeys/selectors';

import { selectNormalizedMapkeys } from '../../../../entities/Synchronize/Mapkeys/selectors';

import injectReducer from '../../../../utils/reducers/injectReducer';
import injectSaga from '../../../../utils/sagas/injectSaga';

import { prepareConfigProfileMapkeysModal, saveConfigProfileMapkeys } from './actions';
import { selectConfigProfileMapkeysModalIsReady } from './selectors';
import reducer from './reducer';
import saga from './sagas';

const Modal = styled(Flex)`
  width: 95%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[1]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const Header = styled(Flex)`
  display: flex;
  flex-direction: row;
  margin-top: ${space[6]};
  padding: ${space[4]} ${space[6]} ${space[4]} calc(${space[6]} - ${space[3]});
  align-items: center;
  background: ${colors.gray[1]};
`;

const HeaderText = styled(Flex)`
  padding-left: ${space[3]};
  padding-right: ${space[3]};
  height: 4rem;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;

  border-top-left-radius: ${radii[2]};
  background: ${colors.white};
`;

const DynamicContentContainer = styled(Box)`
  flex-grow: 1;
`;

const DynamicContent = styled(Box)`
  position: absolute;
  bottom: 0;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

export const createSelectInitialValuesSelector = () => createSelector(
  selectNormalizedMapkeys(),
  selectNormalizedConfigProfileMapkeys(),
  (mapkeys, profileMapkeys) =>
    mapkeys.reduce((map, mapkey) => {
      const profileMapkey = profileMapkeys.find(
        (profileMapkey) => profileMapkey.get(EK.MAPKEYS.single) === mapkey.id
      );
      if (profileMapkey) {
        return map.set(mapkey.id, true);
      } else {
        return map.set(mapkey.id, false);
      }
    }, Map())
);

const ConfigProfileMapkeysModal = ({
  configProfileId,
  prepareConfigProfileMapkeys,
  isReady,
  isFetching,
  isPerforming,
  currentProfile,
  saveProfileMapkeys,
  onSaveProfileMapkeys,
}) => {
  useEffect(() => {
    prepareConfigProfileMapkeys(configProfileId);
  }, []);

  const selectInitialValuesSelector = useMemo(
    createSelectInitialValuesSelector,
    []
  );

  const initialValues = useSelector(state => selectInitialValuesSelector(state, configProfileId));

  const [dynamicContentRef, dynamicContentRect, updateContentRectHeight] = useBoundingclientrectRef();

  const currentProfileName = useMemo(() => currentProfile && currentProfile.name || undefined, [currentProfile]);

  return !isReady ? <Loading /> : (
    <Modal>
      <Flex flexDirection='column' width='100%' height='100%'>
        <Header>
          <HeaderText>
            <Text as='span' color='gray.7' fontSize={5} $ellipsis>
              {currentProfile && `${currentProfileName} - Mapkeys` || 'Loading...'}
            </Text>
          </HeaderText>
          <Button primary onClick={saveProfileMapkeys} disabled={isPerforming}>Save</Button>
        </Header>
        <Content>
          <DynamicContentContainer ref={dynamicContentRef} />
          <DynamicContent height={dynamicContentRect && dynamicContentRect.height} width={dynamicContentRect && dynamicContentRect.width} right='0'>
            {
              dynamicContentRect && dynamicContentRect.height && (
                <ConfigProfileMapkeysForm
                  height={dynamicContentRect.height}
                  enableReinitialize
                  isFetching={isFetching}
                  isPerforming={isPerforming}
                  initialValues={initialValues}
                  onSubmit={onSaveProfileMapkeys}
                />
              )
            }
          </DynamicContent>
        </Content>
      </Flex>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  isReady: selectConfigProfileMapkeysModalIsReady(),
  isFetching: selectIsFetchingInModal(),
  isPerforming: selectIsPerformingInModal(),
  currentProfile: selectCurrentConfigProfile(),
});

const mapDispatchToProps = dispatch => ({
  prepareConfigProfileMapkeys(configProfileId) { dispatch(prepareConfigProfileMapkeysModal(configProfileId)); },
  saveProfileMapkeys() { dispatch(submit(EK.CONFIG_PROFILE_MAPKEYS.state)); },
  onSaveProfileMapkeys() { dispatch(saveConfigProfileMapkeys()); },
});

const enhance = compose(
  injectReducer({ key: `${EK.CONFIG_PROFILE_MAPKEYS.state}Modal`, reducer }),
  injectSaga({ key: `${EK.CONFIG_PROFILE_MAPKEYS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(ConfigProfileMapkeysModal);
