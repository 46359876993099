import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const selectRouter = () => state => state.get('router');

export const selectRouteLocationPathname = () => createSelector(
  selectRouter(),
  router => router.getIn(['location', 'pathname'])
);

export const selectRouteLocationQuery = () => createSelector(
  selectRouter(),
  router => router.getIn(['location', 'query'], Map()).toObject()
);