import React, { useCallback, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { arrayUnshift, formValueSelector, submit } from 'redux-form/immutable';
import styled, { css } from 'styled-components';
import space from '../../../../assets/themes/base/space';
import fontSizes from '../../../../assets/themes/base/fontSizes';
import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import useBoundingclientrectRef from '@rooks/use-boundingclientrect-ref';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import BaseLabel from '../../../../components/form/BaseLabel';
import EmailParsingTextInput from '../../../../components/form/EmailParsingTextInput';

import OrganizationUserInvitesForm from '../../../form/templates/OrganizationUserInvitesForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { selectCurrentUser } from '../../../../entities/CurrentUser/selectors';

import { processCreateOrganizationUserInvites } from '../../../../entities/OrganizationUserInvites/actions';
import OrganizationUserInviteModel from '../../../../entities/OrganizationUserInvites/model';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const ModalContainer = styled(Flex)`
  width: 70%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(Flex)`
  margin: auto;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[1]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const Header = styled(Box)`
  margin-top: ${space[6]};
  padding: ${space[4]} ${space[6]};
  background: ${colors.gray[1]};
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;

  border-top-left-radius: ${radii[2]};
  background: ${colors.white};
`;

const ContentHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;

  padding: ${space[4]} ${space[6]};
  border-bottom: 1px solid ${colors.gray[0]};
`;

const ContentHeaderDescription = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ContentHeaderAction = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 30%;
`;

const ContentSection = styled(Flex)`\
  transition: background-color 0.2s;
  border-bottom: 1px solid ${colors.gray[0]};
`;

const DynamicContentContainer = styled(Box)`
  flex-grow: 1;
`;

const DynamicContent = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const InviteHeader = styled(Flex)`
  flex-direction: row;
  align-items: left;
  background: ${colors.gray[0]};
  padding: ${space[2]} ${space[6]};
`;

const InviteHeaderColumn = styled(Box)`
  ${({ expand }) => expand && css`
    width: calc( 100% - 45rem );
    min-width: fit-content;
  `}
  font-size: ${fontSizes[0]};
  font-weight: 600;
  color: ${colors.gray[6]};
  text-transform: uppercase;
  text-align: left;
`;

const OrganizationUserInvitesModal = ({
  currentUser,
  isPerforming,
  invites,
}) => {
  const emailRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useCallback(() => setIsFocused(true), [setIsFocused]);
  const onBlur = useCallback(() => setIsFocused(false), [setIsFocused]);
  const focusOnInput = useCallback(() => emailRef.current.focus(), [emailRef]);

  // after first render?
  useEffect(() => {
    focusOnInput();
  }, []);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    typingText: '',
    isEmailValid: true,
  });

  const onTypingTextChange = useCallback(typingText => { setState(prev => ({ ...prev, typingText, isEmailValid: typingText === '' || prev.isEmailValid })); }, [setState]);
  const onVallidEmailParsed = useCallback(email => {
    dispatch(arrayUnshift(
      EK.ORGANIZATION_USER_INVITES.state,
      EK.ORGANIZATION_USER_INVITES.plural,
      new OrganizationUserInviteModel({ email })
    ));
  }, [dispatch]);
  const onEmailParseError = useCallback(() => { setState(prev => ({ ...prev, isEmailValid: false })); }, [setState]);
  const sendInvites = useCallback(() => {
    dispatch(submit(EK.ORGANIZATION_USER_INVITES.state));
  }, [dispatch]);
  const onSendInvites = useCallback(invites => {
    dispatch(processCreateOrganizationUserInvites(currentUser.organization.id, invites));
  }, [dispatch, currentUser]);

  const isInvitesEmpty = !invites || invites.size === 0;

  const [dynamicContentRef, dynamicContentRect, updateContentRectHeight] = useBoundingclientrectRef();

  return (
    <ModalContainer style={{ width: '90%' }}>
      <Modal style={{ width: '100%' }} >
        <Flex flexDirection='column' width='5%' height='100%' />
        <Flex flexDirection='column' width='95%' height='100%'>
          <Header>
            <Text color='gray.7' fontSize={5}>Invite Users</Text>
          </Header>
          <Content>
            <ContentHeader p={6}>
              <ContentHeaderDescription>
                <Text color='gray.7' fontSize={1}>Get started by typing out users&apos; emails below. You will then be able to decide permissions and modules before you send the invite.</Text>
              </ContentHeaderDescription>
              <ContentHeaderAction>
                <Button primary onClick={sendInvites} disabled={isPerforming || isInvitesEmpty}>Send Invites</Button>
              </ContentHeaderAction>
            </ContentHeader>
            <ContentSection flexDirection='column' justifyContent='center' p={5} bg={isFocused && 'primary.0'}>
              <BaseLabel label='Add users by email' />
              <EmailParsingTextInput
                ref={emailRef}
                onValueChange={onTypingTextChange}
                onValidEmailParsed={onVallidEmailParsed}
                onEmailParseError={onEmailParseError}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </ContentSection>
            <InviteHeader>
              <InviteHeaderColumn expand>E-mail</InviteHeaderColumn>
              <InviteHeaderColumn width='50rem'>Permissions</InviteHeaderColumn>
              <InviteHeaderColumn width='3rem'></InviteHeaderColumn>
            </InviteHeader>
            <DynamicContentContainer ref={dynamicContentRef} />
            <DynamicContent height={dynamicContentRect && dynamicContentRect.height} width={dynamicContentRect && dynamicContentRect.width}>
              {
                state.typingText !== '' && state.typingText !== null && (

                  <Flex flexDirection='column' justifyContent='center' pl={5} pr={5} pt={3} pb={3}>
                    <Text color='gray.7' fontSize={2}>
                      { state.typingText }
                    </Text>
                    <Text color={ state.isEmailValid ? 'gray.6' : 'error.4' } fontSize={1}>
                      { state.isEmailValid ? 'Press enter, space, or comma to the email to the list' : 'Must be a valid email before it can be added' }
                    </Text>
                  </Flex>
                )
              }
              {
                isInvitesEmpty && state.typingText === '' && (
                  <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%' width='100%'>
                    <Text color='gray.4' fontSize={3}>Get started by adding emails</Text>
                    { !isFocused && (
                      <Box m={4}>
                        <Button primary small onClick={focusOnInput}>Get Started</Button>
                      </Box>
                    )
                    }
                  </Flex>
                )
              }
              <OrganizationUserInvitesForm isPerforming={isPerforming} allowedRoles={currentUser.allowedRoleAssignments} onRDXSubmit={onSendInvites} />
            </DynamicContent>
          </Content>
        </Flex>
      </Modal>
    </ModalContainer>
  );
};

const organizationUserInvitesFormSelector = formValueSelector(EK.ORGANIZATION_USER_INVITES.state);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isPerforming: selectIsPerformingInModal(),
  invites: state => organizationUserInvitesFormSelector(state, EK.ORGANIZATION_USER_INVITES.plural),
});

const enhance = compose(
  injectSaga({ key: `${EK.ORGANIZATION_USER_INVITES.state}Modal`, saga }),
  connect(
    mapStateToProps,
  ),
);

export default enhance(OrganizationUserInvitesModal);
