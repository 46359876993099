import  React, {useCallback} from 'react';
const useMergeRefs = (ref1, ref2) =>{
  const targetRef = useCallback((node) => {
    ref1(node);
    ref2(node);
  }, []);

  return targetRef;
};

export default useMergeRefs;