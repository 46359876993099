import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { Set, Map } from 'immutable';
import capitalize from 'lodash/capitalize';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';

import validateRules from '../../../../utils/validation/validateRules';
import isValidEmail from '../../../../utils/validation/isValidEmail';

const nameRegex = /^([a-z0-9\u00C0-\u02AB'´`-]{1,}\.?\s?)([a-z0-9\u00C0-\u02AB'´`-]?\.?\s?)+$/i;

const nameRules = {
  alphaSpaceCharacters: {
    message: 'Can only contain alphabetic characters and spaces',
    validator: value => value && nameRegex.test(value),
  },
};

const nameValidations = validateRules(nameRules);

const emailRules = {
  validEmail: {
    message: 'Must be a valid email address',
    validator: value => value && isValidEmail(value),
  },
};

const emailValidations = validateRules(emailRules);

const CreateOrganizationUserForm = ({ handleSubmit, isPerforming, valid, roles = Set() }) => {
  const roleOptions = useMemo(() => roles.toList().map(role => ({
    primary: capitalize(role),
    label: capitalize(role),
    value: role,
  })), [roles]);

  return (
    <Box as='form' onSubmit={handleSubmit}>
      <BaseFormSection>
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name='firstName'
            label='First Name'
            required validate={nameValidations}
          />
        </BaseFormRow>
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name='lastName'
            label='Last Name'
            required validate={nameValidations}
          />
        </BaseFormRow>
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name='email'
            label='Email'
            type='email'
            autoComplete='username'
            required validate={emailValidations}
          />
        </BaseFormRow>
        <BaseFormRow>
          <Field
            component={RDXRadioSelect}
            name='roleName'
            label='Role'
            options={roleOptions}
          />
        </BaseFormRow>
      </BaseFormSection>

      <Button primary type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Create User</Button>
    </Box>
  );
};

const enhance = compose(
  reduxForm({
    form: 'createOrganizationUser',
    initialValues: Map({ roleName: 'user' }),
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

export default enhance(CreateOrganizationUserForm);
