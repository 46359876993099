import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import InsulationFamilySchema from './schema';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

export const selectNormalizedInsulationFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.INSULATIONS.state)
);
export const selectNormalizedInsulationFamiliesList = () => createSelector(
  selectNormalizedInsulationFamilies(),
  insulationFamilies => insulationFamilies.toList()
);

export const selectInsulationFamiliesOptions = () => createSelector(
  selectNormalizedInsulationFamilies(),
  (fams) => fams.filter(insulation => !insulation.archivedFlag)
);

export const selectInsulationFamiliesCounts = () => createSelector(
  selectNormalizedInsulationFamilies(),
  insulations => insulations.reduce(({ unarchived, total }, insulation) => ({
    unarchived: !insulation.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);


export const selectInsulationFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.INSULATIONS.state), [InsulationFamilySchema], entities.delete(EK.INSULATION_INSTANCES.state))
);

const insulationFilterForm = formValueSelector(`filters.${EK.INSULATIONS.state}`);

export const selectCurrentFilteredInsulations = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectInsulationFamilies(),
  state => insulationFilterForm(state, 'name', 'description'),
  (isFiltering, isShowingArchived, insulations, query) => isFiltering ?
    insulations.filter(insulation => (isShowingArchived || !insulation.archivedFlag) && insulation.doesMatchQuery(query)) :
    insulations.filter(insulation => !insulation.archivedFlag)
);

export const selectCurrentInsulationFamily = (familyId) => createSelector(
  selectEntities(),
  entities => entities.hasIn([EK.INSULATIONS.state, familyId]) && denormalize(entities.getIn([EK.INSULATIONS.state, familyId]), InsulationFamilySchema, entities)
);