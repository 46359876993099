import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const miterFamilySchema = baseSchema(EK.MITERS.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  miterInstances: { isId: true, idField: 'miterInstanceIds', isList: true },
});

export const miterInstanceSchema = baseSchema(EK.MITER_INSTANCES.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  radius: { isComplexUnit: true },
});

miterFamilySchema.define({ miterInstances: [ miterInstanceSchema ] });

export default miterFamilySchema;
