import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_-]{0,31})$/,
};

// prettier-ignore
export default class LayerState extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.name) && ALLOWANCES.NAME.test(entity.name),
    validateError: 'A valid name must be present and no longer than 31 characters',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  }
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}
