import React from 'react';

export default function FitRedIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <path fill="#323434" d="M9.417,21.02H7.018V7l2.464,0.017l11.538,3.1v7.809L9.417,21.02z M8.018,20.02h1.269l10.733-2.861v-6.275L9.286,8H8.018V20.02z" />
    </svg>
  );
}
