import { takeEvery, fork, call, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';

import { REQUEST_TYPES } from '../api/constants';

import {
  receiveEntities as entitiesReceived,
  removeEntities as entitiesRemoved,
  mergeEntities as entitiesMerged,
} from './actions';

const defaultEmptyData = {
  entities: {},
  result: null,
};

export function* receiveEntities(data, schema, method) {
  if (schema) {
    const { entities, result } = !isEmpty(data)
      ? normalize(data, isArray(data) ? [schema] : schema)
      : defaultEmptyData;

    yield put(entitiesReceived(entities, result, method));
  }
}

export function* removeEntities(data, deleteKey) {
  if (deleteKey) {
    // const { entities, result } = !isEmpty(data) ? normalize(
    //   data,
    //   isArray(data) ? [schema] : schema
    // ) : defaultEmptyData;

    yield put(entitiesRemoved({ [deleteKey]: { [data.id]: data } }));
  }
}

export function* mergeEntities(data, schema, method, dependentDeletionKeys) {
  if (schema) {
    // the BatchRecordUpdater from the API endpoints returns an object
    // with these three keys when returning a diff_result
    const diff = reduce(
      ['updated', 'destroyed', 'created'],
      ({ inserting = {}, destroying = {} } = {}, key) => {
        const { entities, result } = normalize(
          data[key],
          isArray(data[key]) ? [schema] : schema
        );

        return {
          inserting: reduce(
            entities,
            (inserted, normalized, entity) => {
              if (
                key === 'destroyed' &&
                dependentDeletionKeys.indexOf(entity) > -1
              ) {
                return inserted;
              } else {
                return {
                  ...inserted,
                  [entity]: {
                    ...(inserted[entity] || {}),
                    ...(normalized || {}),
                  },
                };
              }
            },
            inserting || {}
          ),
          destroying:
            key === 'destroyed'
              ? reduce(
                entities,
                (destroyed, normalized, entity) => {
                  return {
                    ...destroyed,
                    [entity]: {
                      ...(destroyed[entity] || {}),
                      ...((dependentDeletionKeys.indexOf(entity) > -1 &&
                          (normalized || {})) ||
                          {}),
                    },
                  };
                },
                destroying || {}
              )
              : destroying,
        };
      },
      {}
    );

    yield put(entitiesMerged(diff, method));
  }
}

export function* handleNormalization({
  payload,
  meta: { method, schema, entityKey, deleteKey, dependentDeletionKeys = [] },
}) {
  try {
    const { [entityKey]: entity } = payload;

    if (every(['created', 'updated', 'destroyed'], (key) => !!entity[key])) {
      yield call(mergeEntities, entity, schema, method, [
        entityKey,
        ...dependentDeletionKeys,
      ]);
    } else {
      if (method === REQUEST_TYPES.DELETE && deleteKey) {
        yield call(removeEntities, entity, deleteKey);
      } else {
        yield call(receiveEntities, entity, schema, method);
      }
    }
  } catch (error) {
    console.log('Normalization error: ', { error, payload, method, schema, entityKey });
  }
}

export function* watchSchemasSaga() {
  yield takeEvery(
    (action) => !!((action || {}).meta || {}).schema,
    handleNormalization
  );
}

export default function* main() {
  yield fork(watchSchemasSaga);
}
