import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import MiterInstanceSchema from './schema';
import EK from '../../keys';
import { newMiterInstanceRows } from './constants';

import { formValueSelector } from 'redux-form/immutable';

export const selectNormalizedMiterInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.MITER_INSTANCES.state)
);

export const selectCurrentMiterInstances = (familyId) => createSelector(
  selectNormalizedMiterInstances(),
  (instances) => instances.toList().filter(item => item.miterFamilyId == familyId)
);
export const selectMiterInstanceCount = (familyId) => createSelector(
  selectCurrentMiterInstances(familyId),
  (instances) => instances.size
);

const miterInstanceFilterForm = formValueSelector(`filters.${EK.MITER_INSTANCES.state}`);

export const selectCurrentFilteredMiterInstances = (familyId) => createSelector(
  selectCurrentMiterInstances(familyId),
  selectSidebarIsFiltering(),
  state => miterInstanceFilterForm(state, 'sizeProperty', 'radius'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [MiterInstanceSchema], entities.delete(EK.MITER_INSTANCES.state)).toList();
  }
);
export const selectCurrentMiterInstancesForPage = (familyId) => createSelector(
  selectCurrentFilteredMiterInstances(familyId),
  selectSidebarIsFiltering(),
  (instances, isFiltering) => isFiltering ? instances.toArray() : instances.toArray().concat(newMiterInstanceRows)
);