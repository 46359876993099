import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'STYLE_STATES';

export const fetchAllStyleStateTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createStyleStateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyStyleStateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editStyleStateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteStyleStateTypes = generateAPHTypes(
  resource,
  'DELETE'
);