import { compose } from 'redux';
import { List } from 'immutable';

import EntityRecord, { FILTERS, VALIDATORS } from '../../../modules/entities/model';
import hasMeasurements from '../../../modules/entities/decorators/hasMeasurements';

export const ALLOWANCES = {
  STOCKNO: /^(.{0,255})$/,
};

class InsulationInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size is required',
    filter: FILTERS.ENTITY,
  },
  matlThickness: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.matlThickness),
    validateError: 'A numeric material thickness must be present',
  },
  insulationType: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.insulationType) &&
      !!INSULATION_TYPE_OPTIONS.find(
        (option) => option.value === entity.insulationType
      ),
    validateError:
      'An insulation type of pipe, bend, miter, or fitting must be present',
    filter: FILTERS.STRING,
  },
  material: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'materialId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.material),
    validateError: 'An insulation material is required',
    filter: FILTERS.ENTITY,
  },
  fittingCategory: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'fittingCategoryId',
    validate: (entity) =>
      (entity.insulationType !== 'fitting' &&
        !VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCategory)) ||
      (entity.insulationType === 'fitting' &&
        VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCategory)),
    validateError: (entity) => {
      if (entity.insulationType !== 'fitting') {
        return 'A fitting category cannot be present if the insulation type is not fitting';
      } else {
        return 'A fitting category must be present if the insulation type is fitting';
      }
    },
    filter: FILTERS.ENTITY,
  },
  stockno: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.stockno || ALLOWANCES.STOCKNO.test(entity.stockno),
    validateError:
      'Stockno must either be empty or be no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  insulationFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  insulationFamilyId: {
    value: null,
    handleCopy: true,
  },
}) {}

export const INSULATION_TYPE_OPTIONS = [
  { value: 'pipe', label: 'Pipe', primary: 'Pipe', id: 'pipe' },
  { value: 'bend', label: 'Bend', primary: 'Bend', id: 'bend' },
  { value: 'miter', label: 'Miter', primary: 'Miter', id: 'miter' },
  { value: 'fitting', label: 'Fitting', primary: 'Fitting', id: 'fitting' },
];

const enhance = compose(hasMeasurements);

export default enhance(InsulationInstance);
