import React from 'react';

import BaseDataItem from '../BaseDataItem';
import BaseSelectInputData from '../BaseSelectInputData';

// !MPORTANT: Always keep these styles consistent between BaseOption DefaultOptionTemplate
const BaseSelectDefaultEditableCurrentOptionTemplate = ({ isLoading, item, itemHeight, invisible, children }) => (
  <BaseSelectInputData itemHeight={itemHeight} invisible={invisible}>
    { React.Children.map(children, c => React.cloneElement(c, { mb: item && item.secondary && 1 || undefined })) }
    { item && item.secondary && <BaseDataItem secondary isLoading={isLoading}>{ item.secondary }</BaseDataItem> }
  </BaseSelectInputData>
);

export default BaseSelectDefaultEditableCurrentOptionTemplate;