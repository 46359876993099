import { schema } from 'normalizr';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

/**
 * A factory function that generates normalizr schema
 * @param {string} key - A string that indicates the key to be used to identify the Entity in (de)normalization
 * @param {Object} mapping - A mapping of options to let this function know how to handle the Entity's properties
 * @param {Object} mapping.schema - The specific schema mapping that specifies the structure of the response from the API
 * @param {boolean} mapping.isId - Optional. Only used for core elements fields, identifies if we should expect back the possibility of just an id
 * @param {string} mapping.idField - Optional. Defaults to `{mapping.field}Id` in case we're looking for the idField
 * @param {string} mapping.originalField - Optional. Used to re-map an Entity property to its original response field (e.g., size -> sizeProperty)
 * @param {boolean} mapping.isComplexUnit - Optional. Used to indicate that the field will have an array of units as the response
 */
const baseSchema = (key, mapping = {}) => new schema.Entity(
  key,
  reduce(mapping, (structure, { schema }, field) => schema ? { ...structure, [field]: schema } : structure, {}),
  {
    // using this function to make convert the id for every Entity to ensure consistency within the app
    idAttribute: (entity) => !isNil(entity.id) ? `${entity.id}` : entity.id,
    // processStrategy is used to perform actions before normalization. We're using the model schemas
    // to configure how this function will run for each model based on its properties
    processStrategy: (entity) => reduce(mapping, (output, { originalField, idField, isId, isList, isComplexUnit }, field) => {
      const {
        // if originalField is present, use that; otherwise, default to field
        // rename whichever one is present to entityField for internal use in function
        [originalField || field] : entityField,
        // if idField is present, use that; otherwise, default to the field used above + Id
        // rename this id field to entityIdField for internal use in function
        [idField || `${originalField || field}Id`] : entityIdField
      } = entity;

      if (isId) {
        // if the isId params is present, we're assuming we're getting back ids from the server
        output[field] =
          // if the entityField is empty and the entityIdField is present
          isNil(entityField) && !isNil(entityIdField) ?
            // if the isList params is present, then we're expecting a list of ids
            (isList ?
              // map the list of ids to a string of the same ids to ensure consistency in the app
              map(entityIdField, id => `${id}`) :
              // just return the entityIdField as a string to ensure id consistency in the app
              `${entityIdField}`
            ) :
            // there is no entityIdField and we're just using the entityField by default
            entityField;
      } else if (isComplexUnit) {
        // if the isComplexUnit is present, we're assuming that the server will send back an array of units
        // the output will either map the existing array of units with the ids converted to strings for consistency in the app
        // or it will return an empty array
        output[field] = isArray(entityField) ? map(entityField, ({ id, ...unit }) => ({ id: `${id}`, ...unit })) : [];
      }

      return output;
    },
    // instead of just having the initial value be the entity, we are also adding the _normalized field here
    // this field is used to keep track of whether or not the current Entity is normalized or not. since
    // every entity run through this function starts out normalized, we are initializing this as true
    // before tossing it into a Record in the store
    { ...entity, _normalized: true }),
  }
);

export default baseSchema;
