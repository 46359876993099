import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import fontSizes from '../../../assets/themes/base/fontSizes';

const Key = styled.kbd`
  display: inline-block;
  border: 1px solid ${colors.gray[4]};
  border-bottom: 3px solid ${colors.gray[5]};
  border-radius: ${radii[1]};
  background-color: ${colors.white};
  font-size: ${fontSizes[1]};
  color: ${colors.gray[5]};

  padding: 0.25em 0.6em;
  margin: 0 0.25em;
`;

export default Key;