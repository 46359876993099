import { toast } from 'react-toastify';

import EK from '../keys';

import organizationUserInviteClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchOrganizationUserInviteByTokenTypes,
  fetchAllOrganizationUserInvitesTypes,
  createOrganizationUserInvitesTypes,
  deleteOrganizationUserInvitesTypes,
  acceptOrganizationUserInviteTypes,
  resendOrganizationUserInviteTypes,
} from './constants';

export const processFetchAllOrganizationUserInvites = organizationId => organizationUserInviteClient(REQUEST_TYPES.GET, 'plural', fetchAllOrganizationUserInvitesTypes, `/organizations/${organizationId}/user_invites`, {
  mutateResponse: ({ organizationUserInvites: { invites, organization } = {} }) => ({
    organizationUserInvites: invites.map(invite => ({ ...invite, organization })),
  }),
});

export const processFetchOrganizationUserInviteByToken = token => organizationUserInviteClient(REQUEST_TYPES.GET, 'single', fetchOrganizationUserInviteByTokenTypes, `/organization_user_invites/${token}`);

export const processCreateOrganizationUserInvites = (organizationId, invites) => organizationUserInviteClient(REQUEST_TYPES.POST, 'plural', createOrganizationUserInvitesTypes, `/organizations/${organizationId}/user_invites`, {
  data: {
    invites,
  },
  mutateResponse: ({ organizationUserInvites: { invites, organization } = {} }) => ({
    organizationUserInvites: invites.map(invite => ({ ...invite, organization })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Invites sent',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to send invite',
  },
});

export const processDeleteOrganizationUserInvite = (organizationId, inviteId) => organizationUserInviteClient(REQUEST_TYPES.DELETE, 'single', deleteOrganizationUserInvitesTypes, `/organizations/${organizationId}/user_invites/${inviteId}`, {
  deleteKey: EK.ORGANIZATION_USER_INVITES.state,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Invite deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete invite',
  },
});

export const processResendOrganizationUserInvite = (organizationId, inviteId) => organizationUserInviteClient(REQUEST_TYPES.PATCH, 'single', resendOrganizationUserInviteTypes, `/organizations/${organizationId}/user_invites/${inviteId}`, {
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Invite resent',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to resend invite',
  },
});

export const processAcceptOrganizationUserInvite = token => organizationUserInviteClient(REQUEST_TYPES.PATCH, 'single', acceptOrganizationUserInviteTypes, `/organization_user_invites/${token}/accept`);