import baseSchema from '../../../modules/entities/utils/schema';

import keys from './keys';
import pipeInstanceKeys from '../PipeInstances/keys';

import EndTypeSchema from '../EndTypes/schema';
import SizeSchema from '../Sizes/schema';
import ScheduleSchema from '../Schedules/schema';
import MaterialSchema from '../Materials/schema';
import UnitSystemSchema from '../../Synchronize/UnitSystems/schema';

const pipeFamilySchema = baseSchema(keys.state, {
  pipeInstances: { isId: true, isList: true, idField: 'pipeInstanceIds' },
  minLength: { isComplexUnit: true },
  maxLength: { isComplexUnit: true },
  maxOverallLength: { isComplexUnit: true },
  material: { schema: MaterialSchema, isId: true },
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export const pipeInstanceSchema = baseSchema(pipeInstanceKeys.state, {
  endTypes: { schema: [EndTypeSchema], isId: true, idField: 'endTypeIds', isList: true },
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  schedule: { schema: ScheduleSchema, isId: true },
  diameter: { isComplexUnit: true },
  pipeHeight: { isComplexUnit: true },
  pipeWidth: { isComplexUnit: true },
  thickness: { isComplexUnit: true },
  pipeFamily: { schema: pipeFamilySchema, isId: true },
});

pipeFamilySchema.define({ pipeInstances: [ pipeInstanceSchema ] });

export default pipeFamilySchema;
