import APH from '../constants';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Is the given object a valid type descriptor?
 *
 * @function isValidTypeDescriptor
 * @access private
 * @param {object} obj - The object to check agains the type descriptor definition
 * @returns {boolean}
 */
function isValidTypeDescriptor(obj) {
  const validKeys = ['type', 'payload', 'meta'];

  if (!isPlainObject(obj)) {
    return false;
  }
  for (let key in obj) {
    if (!~validKeys.indexOf(key)) {
      return false;
    }
  }
  if (!('type' in obj)) {
    return false;
  } else if (typeof obj.type !== 'string' && typeof obj.type !== 'symbol') {
    return false;
  }

  return true;
}

/**
 * Checks an action against the APH definition, returning a (possibly empty)
 * array of validation errors.
 *
 * @function validateAPH
 * @access public
 * @param {object} action - The action to check against the APH definition
 * @returns {array}
 */
function validateAPH(action) {
  var validationErrors = [];
  const validCallAPIKeys = [
    'endpoint',
    'method',
    'data',
    'bailout',
    'types',
  ];
  const validMethods = [
    'GET',
    // 'HEAD',
    'POST',
    // 'PUT',
    'PATCH',
    'DELETE',
    // 'OPTIONS'
  ];

  const callAPI = action.payload;
  if (!isPlainObject(callAPI)) {
    validationErrors.push('[APH] payload property must be a plain JavaScript object');
  }
  for (let key in callAPI) {
    if (!~validCallAPIKeys.indexOf(key)) {
      validationErrors.push(`Invalid [APH] key: ${key}`);
    }
  }

  const {
    endpoint,
    method,
    types,
    bailout,
  } = callAPI;
  if (typeof endpoint === 'undefined') {
    validationErrors.push('[APH] must have an endpoint property');
  } else if (typeof endpoint !== 'string' && typeof endpoint !== 'function') {
    validationErrors.push(
      '[APH].endpoint property must be a string or a function'
    );
  }
  if (typeof method === 'undefined') {
    validationErrors.push('[APH] must have a method property');
  } else if (typeof method !== 'string') {
    validationErrors.push('[APH].method property must be a string');
  } else if (!~validMethods.indexOf(method.toUpperCase())) {
    validationErrors.push(`Invalid [APH].method: ${method.toUpperCase()}`);
  }

  if (
    typeof bailout !== 'undefined' &&
    typeof bailout !== 'boolean' &&
    typeof bailout !== 'function'
  ) {
    validationErrors.push(
      '[APH].bailout property must be undefined, a boolean, or a function'
    );
  }

  if (typeof types === 'undefined') {
    validationErrors.push('[APH] must have a types property');
  } else if (!isArray(types) || types.length !== 3) {
    validationErrors.push('[APH].types property must be an array of length 3');
  } else {
    const [requestType, successType, failureType] = types;
    if (
      typeof requestType !== 'string' &&
      typeof requestType !== 'symbol' &&
      !isValidTypeDescriptor(requestType)
    ) {
      validationErrors.push('Invalid request type');
    }
    if (
      typeof successType !== 'string' &&
      typeof successType !== 'symbol' &&
      !isValidTypeDescriptor(successType)
    ) {
      validationErrors.push('Invalid success type');
    }
    if (
      typeof failureType !== 'string' &&
      typeof failureType !== 'symbol' &&
      !isValidTypeDescriptor(failureType)
    ) {
      validationErrors.push('Invalid failure type');
    }
  }

  return validationErrors;
}

/**
 * Is the given action a valid APH?
 *
 * @function isValidAPH
 * @access public
 * @param {object} action - The action to check against the APH definition
 * @returns {boolean}
 */
function isValidAPH(action) {
  return !validateAPH(action).length;
}

export { isValidTypeDescriptor, validateAPH, isValidAPH };
