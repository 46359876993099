import React from 'react';

import { USER_ROLE_TYPES } from '../../../modules/permissions/constants';

import BaseFormCell from '../../../components/form/BaseFormCell';
import { Container as UserRoleRadioButtonContainer } from '../../../components/form/BaseRadioButton';

import UserRoleRadioButton from '../../../components/management/UserRoleRadioButton';

const RDXUserRoleRadioInput = ({
  width,
  height = '100%',
  bg,
  input,
  meta,
  allowed,
  disabled,
  ...rest
}) => {
  const { value, ...radio } = input;
  return (
    <BaseFormCell width={width} height={height} bg={bg} style={{ justifyContent: 'center', overflow: 'visible' }} >
      <UserRoleRadioButtonContainer>
        <UserRoleRadioButton
          type="radio"
          value={USER_ROLE_TYPES.USER}
          label="User"
          role={USER_ROLE_TYPES.USER}
          {...radio}
          checked={value === USER_ROLE_TYPES.USER}
          disabled={disabled}
        />
        <UserRoleRadioButton
          type="radio"
          value={USER_ROLE_TYPES.COLLABORATOR}
          label="Collaborator"
          role={USER_ROLE_TYPES.COLLABORATOR}
          {...radio}
          checked={value === USER_ROLE_TYPES.COLLABORATOR}
          disabled={disabled || !allowed.has(USER_ROLE_TYPES.COLLABORATOR)}
        />
        <UserRoleRadioButton
          type="radio"
          value={USER_ROLE_TYPES.ADMIN}
          label="Admin"
          role={USER_ROLE_TYPES.ADMIN}
          {...radio}
          checked={value === USER_ROLE_TYPES.ADMIN}
          disabled={disabled || !allowed.has(USER_ROLE_TYPES.ADMIN)}
        />
      </UserRoleRadioButtonContainer>
    </BaseFormCell>
  );
};

export default RDXUserRoleRadioInput;
