import { handleActions } from 'redux-actions';
import { OrderedMap, Map } from 'immutable';
import keys from 'lodash/keys';
import some from 'lodash/some';

import createFilteredReducer from '../../../utils/reducers/createFilteredReducer';
import mergeEntitiesWithDefaultedTracking from './mergeEntitiesWithDefaultedTracking';

import { receiveEntities, removeEntities, mergeEntities } from '../actions';

const createEntitiesReducer = (entityKey, entityModel) => handleActions(
  {
    [receiveEntities]: createFilteredReducer(
      (state, { payload: { entities: { [entityKey] : models } } }) => mergeEntitiesWithDefaultedTracking(state, Map(models).map(model => new entityModel(model))),
      ({ payload: { entities } }) => !!entities[entityKey]
    ),
    [removeEntities]: createFilteredReducer(
      (state, { payload: { entities: { [entityKey] : models } } }) => state.deleteAll(keys(models || {})),
      ({ payload: { entities } }) => !!entities[entityKey]
    ),
    [mergeEntities]: createFilteredReducer(
      (state, { payload: { entities: { inserting, destroying } } }) => mergeEntitiesWithDefaultedTracking(state.deleteAll(keys(destroying[entityKey] || {})), Map(inserting[entityKey]).map(model => new entityModel(model))),
      ({ payload: { entities } }) => some(['inserting', 'destroying'], key => !!entities[key][entityKey])
    ),
  },
  OrderedMap()
);

export default createEntitiesReducer;
