import { toast } from 'react-toastify';

import appearanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllAppearancesTypes, saveAppearancesTypes } from './constants';

export const processFetchAllAppearances = () => appearanceClient(REQUEST_TYPES.GET, 'plural', fetchAllAppearancesTypes, '/synchronize/appearances');

export const processSaveAppearances = (data) => appearanceClient(REQUEST_TYPES.PATCH, 'plural', saveAppearancesTypes, '/synchronize/appearances/collection?diff_result=true', {
  data: { appearancesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

