import styled from 'styled-components';
import transitions from '../../../../assets/themes/base/transitions';

export const fillTransitionClass = 'cadactive-graphic-fill-transition';

const BaseGraphicSVG = styled.svg`
  .${fillTransitionClass} {
    transition: fill ${transitions.speed.fast};
  }
`;

export default BaseGraphicSVG;