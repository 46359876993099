import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';
import { convertToMaterialsType, getMaterialOptions, getMaterialTypesArray } from './actions';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import { MultiSelect } from 'primereact/multiselect';
import { MATERIAL_OPTIONS } from '../../../entities/Piping/Materials/model';
import { InputNumber } from 'primereact/inputnumber';
import { Chip } from 'primereact/chip';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleMaterialsPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// Actions
import { processFetchAllMaterials, processSaveMaterials } from '../../../entities/Piping/Materials/actions';
import { saveCurrentMaterialChanges } from './actions';

// selectors
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectFilteredMaterials, selectMaterialCount } from '../../../entities/Piping/Materials/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectFilteredMaterials(),
  selectMaterialCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    totalMaterials,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length-15 == 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      isLoading,
      hasValidLicense,
      canCollaborate,
      totalMaterials,
    };
  },
);

function MaterialsGrid() {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction] = useOnCellEditComplete(reduxProps.data, convertToMaterialsType, reduxProps.editable);

  // Cell Editors
  const customMultiSelectorOnCellChange = (newValues) => {
    let _currentEditingRow = { ...currentEditingRow, isValidForPipe: false, isValidForFitting: false, isValidForInsulation: false };
    newValues.forEach(({value}) => {
      switch(value) {
      case 'pipe':
        _currentEditingRow.isValidForPipe = true;
        break;
      case 'fitting':
        _currentEditingRow.isValidForFitting = true;
        break;
      case 'insulation':
        _currentEditingRow.isValidForInsulation = true;
        break;
      }
    });

    return _currentEditingRow;
  };

  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} />;
  const densityCellEditor = (field) => <InputNumber value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.value, field)} minFractionDigits={1} maxFractionDigits={5} />;
  const multiSelector = () => {
    return <MultiSelect
      appendTo={'self'}
      value={getMaterialTypesArray(currentEditingRow) || []}
      optionLabel='label'
      placeholder='Select Types'
      display='comma'
      optionDisabled={false}
      options={getMaterialOptions(currentEditingRow) || MATERIAL_OPTIONS}
      onChange={(e) => onCellChange(e.value, '', customMultiSelectorOnCellChange)}
      style={{width: '100%'}}
      useOptionAsValue
    />;
  };

  //Cell displays
  const textValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToMaterialsType(rowdata);

    if (curRow.id && curRow.id.includes('NEW_ROW_') && curRow[field] == '') {
      return (
        <Text style={{ color: colors.gray[5] }} >new row</Text>
      );
    }

    return (
      <>
        <Text>{curRow && curRow[`${field}`]}</Text>
      </>
    );
  };

  const typeValueBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToMaterialsType(rowdata);

    if (curRow.id && curRow.id.includes('NEW_ROW_') && !curRow.isValidForFitting && !curRow.isValidForInsulation && !curRow.isValidForPipe) {
      return (
        <Text style={{ color: colors.gray[5] }} >new row</Text>
      );
    }

    return getMaterialTypesArray(curRow).map(item => <Chip label={item.primary} key={item.primary} />);
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchAllMaterials());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleMaterialsPath.defaultTitle}
          subtitle={reduxProps.isFiltering ? `Showing ${reduxProps.data.length}/${reduxProps.totalSizes} ${pluralize('filtered Material', reduxProps.data.length)}` : `Showing ${reduxProps.data.length - 15} ${pluralize('Material', reduxProps.data.length - 15)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentMaterialChanges(
            editedRows,
            (data) => { dispatch(processSaveMaterials(data)); resetEditedRows(); },
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='code' style={{ width: '19%' }} header='Code' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('code')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'code')} sortable ></Column>
          <Column field='density' style={{ width: '19%' }} header='Density' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => densityCellEditor('density')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'density')} sortable ></Column>
          <Column field='filename' style={{ width: '19%' }} header='Filename' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('filename')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'filename')} sortable ></Column>
          <Column field='type' style={{ width: '19%' }} header='Type' body={(rowdata) => typeValueBody(rowdata)} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => multiSelector()} onCellEditComplete={(e) => onCellEditComplete.current(e)} ></Column>
          <Column field='description' style={{ width: '19%' }} header='Description' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('description')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'description')} sortable ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(MaterialsGrid);
