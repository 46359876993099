import React, { forwardRef } from 'react';

// display cell for keystrokes in the grid
export const keystrokeCell = (value) => (
  <div style={{ color: 'rgb(77, 171, 247)' }} ><strong>{value}</strong></div>
);

const MAPKEY_STATEMENT_SEPARATOR = ';';
const MAPKEY_NEWLINE_ESCAPE_CHAR = '\\';
const MAPKEY_KEYSTROKE_START = 'mapkey ';
const MAPKEY_LABEL_START = '@MAPKEY_LABEL';
const MAPKEY_DESCRIPTION_START = '@MAPKEY_NAME';
const MAPKEY_NEWLINE_START = 'mapkey(continued) ';
// const MAPKEY_ACTION_SEPARATOR = '~ ' // or % to reference other mapkeys
const WIN_NEWLINE = '\r\n';
const UNIX_NEWLINE = '\n';

function splitByNewLine(value) {
  const maybeLines = value.split(WIN_NEWLINE);
  if (maybeLines.length > 0) return maybeLines;
  return value.split(UNIX_NEWLINE);
}

export function sanitizeMapkeyAction(mapkeyInputValue) {
  // first split by line and remove blank lines
  const lines = splitByNewLine(mapkeyInputValue).filter((line) => line !== '');

  // reduce into a single-line and re-split by semicolon, which delineate statements
  const mapkeyInputStatements = lines.join('').split(MAPKEY_STATEMENT_SEPARATOR);

  // remove all "continued" line starts and the newline escapes
  const reducedMapkeyInput = mapkeyInputStatements.map((statement) => {
    // newline escapes start most statements since we split at the statement end char
    const escapedText = statement.startsWith(MAPKEY_NEWLINE_ESCAPE_CHAR)
      ? statement.substring(1).trim()
      : statement.trim();
    if (escapedText.startsWith(MAPKEY_NEWLINE_START)) {
      return escapedText.replace(MAPKEY_NEWLINE_START, '').trim();
    }
    return escapedText;
  });

  // split by semicolon and filter out all lines for keystroke, label, and name
  const actionStatements = reducedMapkeyInput
    .filter((text) =>
      !text.startsWith(MAPKEY_KEYSTROKE_START) &&
      !text.startsWith(MAPKEY_LABEL_START) &&
      !text.startsWith(MAPKEY_DESCRIPTION_START)
    );
  return actionStatements.join(';\n');
}

export const mapkeyActionFieldStyle = { height: 'fit-content', minHeight: '100px', width: '100%', border: '1px solid lightgray', borderRadius: '5px', fontFamily: 'serif', padding: '10px' };
