import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Map } from 'immutable';

import selectEntities from '../../../modules/entities/selectors';
import selectDrafts from '../../../modules/drafts/selectors';

import EndTypeCompatibilitySchema from './schema';
import { selectEndTypesList } from '../EndTypes/selectors';
import { selectNormalizedSchedulesList } from '../Schedules/selectors';

export const selectNormalizedEndTypeCompatibilities = () => createSelector(
  selectEntities(),
  entities => entities.get('endTypeCompatibilities')
);

export const selectEndTypeCompatibilitiesList = () => createSelector(
  selectNormalizedEndTypeCompatibilities(),
  endTypeCompats => endTypeCompats.toList()
);

export const selectCombinedEndTypeCompatibilitiesArray = () => createSelector(
  selectNormalizedEndTypeCompatibilities(),
  selectEndTypesList(),
  selectNormalizedSchedulesList(),
  (endTypeCompats, endTypes, schedules) => endTypeCompats.toList().toArray().map(
    (row) => {
      const fullFirstEndType = endTypes.find( et => et.id == row.firstEndType );
      const fullSecondEndType = endTypes.find( et => et.id == row.secondEndType );
      const fullFirstSchedule = schedules.find( scd => scd.id == row.firstSchedule );
      const fullSecondSchedule = schedules.find( scd => scd.id == row.secondSchedule );
      return {
        id: row.id,
        firstEndTypeId: fullFirstEndType && fullFirstEndType.id,
        firstEndTypeName: fullFirstEndType && fullFirstEndType.name,
        firstEndTypeCode: fullFirstEndType && fullFirstEndType.code,
        secondEndTypeId: fullSecondEndType && fullSecondEndType.id,
        secondEndTypeName: fullSecondEndType && fullSecondEndType.name,
        secondEndTypeCode: fullSecondEndType && fullSecondEndType.code,
        firstScheduleId: fullFirstSchedule && fullFirstSchedule.id,
        firstScheduleName: fullFirstSchedule && fullFirstSchedule.value,
        secondScheduleId: fullSecondSchedule && fullSecondSchedule.id,
        secondScheduleName: fullSecondSchedule && fullSecondSchedule.value,
        isEdited: false,
        isCreated: false,
        isDeleted: false,
        canSafelyDelete: row.canSafelyDelete,
      };
    }
  )
);

export const selectEndTypeCompatibilities = () => createSelector(
  selectEntities(),
  entities => denormalize(
    entities.get('endTypeCompatibilities'),
    [EndTypeCompatibilitySchema],
    Map({ endTypes: entities.get('endTypes'), schedules: entities.get('schedules') })
  )
);

export const selectNormalizedDraftEndTypeCompatibilitiesRecords = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn(['endTypeCompatibilities', 'records'])
);
export const selectNormalizedDraftEndTypeCompatibilities = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('endTypeCompatibilities');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

