import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllSectionsTypes,
  saveSectionsTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.SECTIONS);

export const processFetchAllSections = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllSectionsTypes,
    '/standardize/sections'
  );

export const processSaveSections = (data) =>
  client(
    REQUEST_TYPES.PATCH,
    'plural',
    saveSectionsTypes,
    '/standardize/sections/collection?diff_result=true',
    {
      data: { sectionsAttributes: data },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );
