import React from 'react';

import CadactiveHelmet from '../../../components/common/CadactiveHelmet';

import Box from '../../../components/common/Box';
import Flex from '../../../components/common/Flex';

const ExternalPage = ({ title, children }) => (
  <Flex position='absolute' flexDirection='column' alignItems='center' justifyContent='center' top='0px' right='0px' bottom='0px' left='0px'>
    <CadactiveHelmet title={title} />

    <Box width='30rem' ml='auto' mr='auto'>
      { children }
    </Box>
  </Flex>
);

export default ExternalPage;
