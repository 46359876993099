import React, { useState } from 'react';

///used for the flag option in the nbotes/note blocks section
export function ChangeHistory({ size = '1em', style }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 37" style={style}>
      <path d="M3.333 33.333 20.042 6.667l16.625 26.666Zm4.959-2.791h23.416L20.042 11.958ZM20 21.25Z" />
    </svg>
  );
}

export function ChangeHistorySolid({ size = '1em', style }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 37" style={style}>
      <path d="M3.333 33.333 20.042 6.667l16.625 26.666Z" />
    </svg>
  );
}

export default function ToggleFlag({ size, style, value }) {
  return ( value ? <ChangeHistorySolid width={size} height={size} style={style} /> : <ChangeHistory width={size} height={size} style={style} />);
}