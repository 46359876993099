/**
 * Using standard easing functions for transitions and three speeds
 */

export default {
  speed: {
    fast: '.2s',
    default: '.3s',
    slow: '.5s',
  },
  type: {
    in: ' cubic-bezier(0.55,0.055,0.675,0.19)',
    out: 'cubic-bezier(0.215,0.61,0.355,1)',
    inOut: 'cubic-bezier(0.645,0.045,0.355,1)',
    linear: 'cubic-bezier(0,0,1,1)',
  },
};
