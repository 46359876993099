import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllLayerStateTypes,
  createLayerStateTypes,
  copyLayerStateTypes,
  editLayerStateTypes,
  deleteLayerStateTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.LAYER_STATES);

export const processFetchAllLayerStates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllLayerStateTypes,
    '/standardize/layer_states'
  );

export const processCreateLayerState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createLayerStateTypes,
    '/standardize/layer_states',
    {
      data: {
        layerStates: [{
          name: data.name,
          description: data.description
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Layer State successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Layer State',
      },
    }
  );

export const processEditLayerState = (LayerStateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editLayerStateTypes,
    `/standardize/layer_states/${LayerStateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteLayerState = (LayerStateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteLayerStateTypes,
    `/standardize/layer_states/${LayerStateId}?with_full_detail=true`,
    {
      deleteKey: EK.LAYER_STATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Layer State deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Layer State',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyLayerState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyLayerStateTypes,
    '/standardize/layer_states/copy',
    {
      data: {
        layerStates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Layer State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Layer State',
      },
    }
  );