import React from 'react';
import styled from 'styled-components';

import Flex from '../../../common/Flex';
import Icon from '../../../common/Icon';
import Key from '../../../common/Key';

import { DefaultOptionTemplate } from '../';

const RadioOption = styled(Flex).attrs(props => ({
  $ellipsis: true,
  flexDirection: props.flexDirection || 'column',
  justifyContent: props.justifyContent || 'center',
}))`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const renderOptionForRadio = ({ item, isCapitalized, isSelected, hotkey }) => (
  <Flex flexDirection='row' justifyContent='center' alignItems='center'>
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%' mr={3}>
      <Icon name={isSelected ? 'radio' : 'radio-outline'} color={item.disabled ? 'gray.4' : (isSelected ? 'primary.4' : 'gray.6')} />
    </Flex>

    <RadioOption>
      <DefaultOptionTemplate isCapitalized={isCapitalized} item={item} />
    </RadioOption>

    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%' ml={3}>
      <Key>{ hotkey }</Key>
    </Flex>
  </Flex>
);

export default renderOptionForRadio;
