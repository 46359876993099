import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';

import EK from '../../keys';

import parameterSimplifiedRepSchema from './schema';

import { denormalize } from 'normalizr';

export const selectNormalizedSimplifiedReps = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get('simplifiedReps')
  );
export const selectNormalizedSimplifiedRepsList = () =>
  createSelector(
    selectNormalizedSimplifiedReps(),
    (simplifiedReps) => simplifiedReps.toList()
  );
export const selectSimplifiedReps = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.SIMPLIFIED_REPS.state),
      [parameterSimplifiedRepSchema],
      entities
    )
  );