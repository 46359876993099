import React from 'react';

import Text from '../../../common/Text';

import TooltipBody from '../../TooltipBody';
import TooltipSection from '../../TooltipSection';

const GridIsEditingTooltip = React.memo(() => (
  <TooltipBody>
    <TooltipSection large inverse>
      <Text color='white' fontSize={1} fontWeight={500}>
        Please finish editing data below before doing anything else
      </Text>
    </TooltipSection>
  </TooltipBody>
));

export default GridIsEditingTooltip;