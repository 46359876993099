import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectCurrentPipeId } from '../../../routes/PipesEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const bendMachineFilterForm = formValueSelector(`filters.${EK.BEND_MACHINES.state}`);

export const selectNormalizedBendMachineFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendMachineFamilies')
);
export const selectNormalizedBendMachineFamiliesList = () => createSelector(
  selectNormalizedBendMachineFamilies(),
  bendMachineFamilies => bendMachineFamilies.toList()
);


export const selectCurrentPipesBendMachinesCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedBendMachineFamiliesList(),
  (pipeId, bendMachines) => bendMachines
    .filter(bendMachine => bendMachine.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, bendMachine) => ({
      unarchived: !bendMachine.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesBendMachines = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedBendMachineFamiliesList(),
  state => bendMachineFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, bendMachines, name) => isFiltering ?
    bendMachines.filter(bendMachine => (isShowingArchived || !bendMachine.archivedFlag) && bendMachine.pipeFamilyId === pipeId && bendMachine.doesMatchQuery({ name })) :
    bendMachines.filter(bendMachine => !bendMachine.archivedFlag && bendMachine.pipeFamilyId === pipeId)
);

export const selectCurrentBendMachine = (machineId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.BEND_MACHINES.state, machineId]) && entities.getIn([EK.BEND_MACHINES.state, machineId])
);