import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleSpecsPath } from '../../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModuleSpecsPath.defaultTitle}></BreadcrumbTemplate>
  );
}
