import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { SynchronizeModuleConfigProfilesPath } from '../../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={SynchronizeModuleConfigProfilesPath.defaultTitle}></BreadcrumbTemplate>
  );
}
