import React from 'react';
import styled from 'styled-components';
import {
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,
  flex,
  justifySelf,
  alignSelf
} from 'styled-system';

import Box from '../Box';

const BaseFlexBox = styled(Box)`
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${flexBasis}
  ${flexDirection}
  ${flex}
  ${justifySelf}
  ${alignSelf}
`;

const Flex = React.forwardRef((props, ref) => <BaseFlexBox ref={ref} display='flex' { ...props } />);

export default Flex;
