import React from 'react';

import ModalTitle from '../ModalTitle';

const CreateOrEditEntityTitle = ({ edit, create, copy }) => (
  <ModalTitle>
    {edit !== undefined ? (
      <React.Fragment>
        <strong>{ copy ? 'Copy' : 'Edit' }</strong> {edit}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <strong>Create new</strong> {create}
      </React.Fragment>
    )}
  </ModalTitle>
);

export default CreateOrEditEntityTitle;
