import { toast } from 'react-toastify';

import fittingInstanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveFittingInstancesTypes } from './constants';

export const processSaveFittingInstances = (fittingFamilyId, data) => fittingInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveFittingInstancesTypes, `/piping/fitting_families/${fittingFamilyId}?diff_result=true`, {
  data: { fittingInstancesAttributes: data },
  mutateResponse: ({ fittingFamily: { id, fittingCodeId } = {}, fittingInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    fittingInstances: {
      created: created.map(instance => ({
        ...instance,
        fittingCodeId,
        fittingFamily: `${id}`,
        fittingFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        fittingCodeId,
        fittingFamily: `${id}`,
        fittingFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        fittingCodeId,
        fittingFamily: `${id}`,
        fittingFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
