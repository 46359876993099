import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import colors from '../../../assets/themes/base/colors';

const Light = ({ theme, ...props }) => <SkeletonTheme baseColor={theme.colors.gray[4]} highlightColor={theme.colors.gray[3]} { ...props } />;

const StaticLight = ({ theme, ...props }) => <SkeletonTheme baseColor={theme.colors.gray[4]} highlightColor={theme.colors.gray[4]} { ...props } />;

const Dark = ({ theme, ...props }) => <SkeletonTheme baseColor={theme.colors.gray[7]} highlightColor={theme.colors.gray[6]} { ...props } />;

export default {
  Light,
  StaticLight,
  Dark,
};
