import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { space, fontSize, fontWeight, color, lineHeight, letterSpacing, textAlign } from 'styled-system';
import colors from '../../../assets/themes/base/colors';

const StyledInput = styled.input`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${lineHeight}
  ${letterSpacing}
  ${textAlign}

  border: none;
  outline: none;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'proxima-soft', sans-serif;
  width: 100%;
  height: 100%;
  autofocus: false;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${colors.primary[4]};
    color: ${colors.primary[4]};
    background-color: ${colors.white};
  }

  &::placeholder {
    color: ${colors.gray[5]};
  }
`;

const BaseInput = React.forwardRef(({ allow, onDisallow, onChange, customOnFocus, isOpen, ...props }, ref) => {
  const onShouldChange = useCallback(e => {
    if (allow) {
      if (allow instanceof RegExp) {
        allow.test(e.target.value) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      } else {
        allow(e) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      }
    } else {
      onChange && onChange(e);
    }
  }, [allow, onChange]);

  return <StyledInput ref={ref} tabIndex='0' onChange={onShouldChange} fontSize={2} color='gray.7' onFocus={customOnFocus} { ...props } />;
});

export default BaseInput;
