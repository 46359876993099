import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,255})$/,
};

export const ACTION_OPTIONS = List([
  { value: 'exclude', label: 'Exclude', primary: 'Exclude', id: 'exclude' },
  {
    value: 'master_rep',
    label: 'Master Rep',
    primary: 'Master Rep',
    id: 'master_rep',
  },
  {
    value: 'automatic_rep',
    label: 'Automatic Rep',
    primary: 'Automatic Rep',
    id: 'automatic_rep',
  },
  {
    value: 'default_envelope_rep',
    label: 'Default Envelope Rep',
    primary: 'Default Envelope Rep',
    id: 'default_envelope_rep',
  },
]);

// prettier-ignore
export default class SimplifiedRepRuleset extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.name) && ALLOWANCES.NAME.test(entity.name),
    validateError: 'A valid name must be present and no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  creoAction: {
    value: 'exclude',
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_REQUIRED_STRING(entity.creoAction) && !!ACTION_OPTIONS.find(option => option.value === entity.creoAction),
    validateError: 'A default action of either exclude, master rep, automatic rep, or default envelope rep',
    filter: FILTERS.STRING,
  },
  simplifiedRepId: {
    value: null,
    handleCopy: true,
  },
  simplifiedRepRulesetRules: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.creoAction;
  }
  get secondaryPlaceholder() {
    return 'No Action';
  }
}
