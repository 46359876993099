import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,255})$/,
  CODE: /^(.{0,255})$/,
};

export const VALIDATION_FIELDS = {
  name: true,
  code: true,
};

export const BlankSize = {
  name: '',
  code: '',
  id: '',
  description: '',
};

class Size extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      ALLOWANCES.NAME.test(entity.name),
    validateError:
      'A valid name must be present and no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  code: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.code) &&
      ALLOWANCES.CODE.test(entity.code),
    validateError:
      'A valid code must be present and no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return '--';
  }
}

export default Size;
