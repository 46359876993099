import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_]{0,31})$/,
};

class Layer extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      ALLOWANCES.NAME.test(entity.name),
    validateError:
      'Name can either be empty or be at most 31 characters in length, and can only contain letters, numbers, and \'_\'',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}

export default Layer;
