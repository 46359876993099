import Path from './utils/routing/Path';

const requiredStringSchema = 'true';

export const SupportRedirectPath = new Path('/support-redirect', {
  defaultTitle: 'Redirecting...',
});
export const LoginPath = new Path('/login', {
  defaultTitle: 'Log In',
  query: { passwordReset: true },
});
export const RegisterPath = new Path('/register', {
  defaultTitle: 'Create Account',
});
export const ForgotPasswordRequestPath = new Path('/password_reset', {
  defaultTitle: 'Forgot Password',
});
export const ResetPasswordPath = new Path('/password_reset/:token', {
  defaultTitle: 'Password Reset',
  params: { token: requiredStringSchema },
});
export const AccountDeactivatedPath = new Path('/account-deactivated', {
  defaultTitle: 'Account Deactivated',
});
export const NoLicensePath = new Path('/no-license', {
  defaultTitle: 'Valid Product License Not Found',
});
export const EmailNotVerifiedPath = new Path('/email-not-verified', {
  defaultTitle: 'Email Confirmation Required',
});
export const VerifyEmailPath = new Path('/verify_email/:token', {
  defaultTitle: 'Email Verification',
  params: { token: requiredStringSchema },
});
export const AccountSetupPath = new Path('/account-setup', {
  defaultTitle: 'Account Setup',
});
export const ServerErrorPath = new Path('/server-error', {
  defaultTitle: '500 | Server Error',
});
export const PageNotFoundPath = new Path('/lost-in-the-wilderness', {
  defaultTitle: '404 | Lost in the Wilderness',
});

// DASHBOARD
export const DashboardPath = new Path('/dashboard', {
  defaultTitle: 'Dashboard',
});
// PIPING
export const PipingModulePath = new Path('/piping', {
  base: DashboardPath,
  defaultTitle: 'Piping',
});
export const PipingModuleSizesPath = new Path('/sizes', {
  base: PipingModulePath,
  defaultTitle: 'Sizes',
});
export const PipingModuleSchedulesPath = new Path('/schedules', {
  base: PipingModulePath,
  defaultTitle: 'Schedules',
});
export const PipingModuleFittingCategoriesPath = new Path(
  '/fitting-categories',
  {
    base: PipingModulePath,
    defaultTitle: 'Fitting Categories',
  },
);
export const PipingModuleEndTypesPath = new Path('/end-types', {
  base: PipingModulePath,
  defaultTitle: 'End Types',
});
export const PipingModuleMaterialsPath = new Path('/materials', {
  base: PipingModulePath,
  defaultTitle: 'Materials',
});
export const PipingModuleBoltNutsPath = new Path('/bolt-nuts', {
  base: PipingModulePath,
  defaultTitle: 'Bolt Nuts',
});
export const PipingModuleBoltNutsEditPath = new Path('/:id', {
  base: PipingModuleBoltNutsPath,
  params: { id: requiredStringSchema },
});
export const PipingModulePipesPath = new Path('/pipes', {
  base: PipingModulePath,
  defaultTitle: 'Pipes',
});
export const PipingModulePipesEditPath = new Path('/:id', {
  base: PipingModulePipesPath,
  params: { id: requiredStringSchema },
});
export const PipingModulePipesBendsPath = new Path('/bends', {
  base: PipingModulePipesEditPath,
  defaultTitle: 'Bends',
});
export const PipingModulePipesBendsEditPath = new Path('/:familyId', {
  base: PipingModulePipesBendsPath,
  params: { familyId: requiredStringSchema },
});
export const PipingModulePipesBendMachinesPath = new Path('/bend-machines', {
  base: PipingModulePipesEditPath,
  defaultTitle: 'Bend Machines',
});
export const PipingModulePipesBendMachinesEditPath = new Path('/:familyId', {
  base: PipingModulePipesBendMachinesPath,
  params: { familyId: requiredStringSchema },
});
export const PipingModulePipesBendSpringbacksPath = new Path(
  '/bend-springbacks',
  {
    base: PipingModulePipesEditPath,
    defaultTitle: 'Bend Springbacks',
  },
);
export const PipingModulePipesBendSpringbacksEditPath = new Path('/:familyId', {
  base: PipingModulePipesBendSpringbacksPath,
  params: { familyId: requiredStringSchema },
});
export const PipingModulePipesMitersPath = new Path('/miters', {
  base: PipingModulePipesEditPath,
  defaultTitle: 'Miters',
});
export const PipingModulePipesMitersEditPath = new Path('/:familyId', {
  base: PipingModulePipesMitersPath,
  params: { familyId: requiredStringSchema },
});
export const PipingModuleFittingsPath = new Path('/fittings', {
  base: PipingModulePath,
  defaultTitle: 'Fittings',
});
export const PipingModuleFittingsEditPath = new Path('/:id', {
  base: PipingModuleFittingsPath,
  params: { id: requiredStringSchema },
});
export const PipingModuleInsulationsPath = new Path('/insulations', {
  base: PipingModulePath,
  defaultTitle: 'Insulations',
});
export const PipingModuleInsulationsEditPath = new Path('/:id', {
  base: PipingModuleInsulationsPath,
  params: { id: requiredStringSchema },
});
export const PipingModuleInsulationSpecsPath = new Path('/insulation-specs', {
  base: PipingModulePath,
  defaultTitle: 'Insulation Specs',
});
export const PipingModuleProjectsPath = new Path('/projects', {
  base: PipingModulePath,
  defaultTitle: 'Projects',
});
export const PipingModuleProjectsEditPath = new Path('/:id', {
  base: PipingModuleProjectsPath,
  params: { id: requiredStringSchema },
});
export const PipingModuleSpecsPath = new Path('/piping-specs', {
  base: PipingModulePath,
  defaultTitle: 'Piping Specs',
});
export const PipingModuleSpecsEditPath = new Path('/:id', {
  base: PipingModuleSpecsPath,
  params: { id: requiredStringSchema },
});
export const PipingModuleSpecsFittingsEditPath = new Path('/fittings', {
  base: PipingModuleSpecsEditPath,
  defaultTitle: 'Fittings',
});
export const PipingModuleSpecsFittingInstancesEditPath = new Path(
  '/:familyId',
  {
    base: PipingModuleSpecsFittingsEditPath,
    params: { familyId: requiredStringSchema },
  },
);
export const PipingModuleSpecsPipesEditPath = new Path('/pipes', {
  base: PipingModuleSpecsEditPath,
  defaultTitle: 'Pipes',
});
export const PipingModuleSpecsPipeInstancesEditPath = new Path(
  '/:familyId',
  {
    base: PipingModuleSpecsPipesEditPath,
    params: { familyId: requiredStringSchema },
  },
);

// Standardize
export const StandardizeModulePath = new Path('/standardize', {
  base: DashboardPath,
  defaultTitle: 'Standardize',
});
export const StandardizeModuleModelTemplatesPath = new Path(
  '/model-templates',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Model Templates',
  },
);
export const StandardizeModuleNoteProfilesPath = new Path('/note-profiles', {
  base: StandardizeModulePath,
  defaultTitle: 'Note Profiles',
});
export const NoteProfilesEditPath = new Path('/:id', {
  base: StandardizeModuleNoteProfilesPath,
  params: { id: requiredStringSchema },
});
export const StandardizeModuleLayersPath = new Path('/layers', {
  base: StandardizeModulePath,
  defaultTitle: 'Layers',
});
export const StandardizeModuleNotesPath = new Path('/notes', {
  base: StandardizeModulePath,
  defaultTitle: 'Notes',
});
export const StandardizeModuleOrientationsPath = new Path('/orientations', {
  base: StandardizeModulePath,
  defaultTitle: 'Orientations',
});
export const StandardizeModuleParameterProfilesPath = new Path(
  '/parameter-profiles',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Parameter Profiles',
  },
);
export const ParameterProfilesEditPath = new Path('/:id', {
  base: StandardizeModuleParameterProfilesPath,
  params: { id: requiredStringSchema },
});
export const StandardizeModuleParametersPath = new Path('/parameters', {
  base: StandardizeModulePath,
  defaultTitle: 'Parameters',
});
export const StandardizeModuleSectionsPath = new Path('/sections', {
  base: StandardizeModulePath,
  defaultTitle: 'Sections',
});
export const StandardizeModuleSimplifiedRepsPath = new Path(
  '/simplified-reps',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Simplified Reps',
  },
);
export const StandardizeModuleExplodeStatesPath = new Path(
  '/explode-states',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Explode States'
  }
);
export const StandardizeModuleCombinedStatesPath = new Path(
  '/combined-states',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Combined States'
  }
);
export const StandardizeModuleLayerStatesPath = new Path(
  '/layer-states',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Layer States'
  }
);
export const StandardizeModuleAppearanceStatesPath = new Path(
  '/appearance-states',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Appearance States'
  }
);
export const StandardizeModuleStyleStatesPath = new Path(
  '/style-states',
  {
    base: StandardizeModulePath,
    defaultTitle: 'Style States'
  }
);

// Synchronize
export const SynchronizeModulePath = new Path('/synchronize', {
  base: DashboardPath,
  defaultTitle: 'Synchronize',
});
export const SynchronizeModuleAppearancesPath = new Path('/appearances', {
  base: SynchronizeModulePath,
  defaultTitle: 'Appearances',
});
export const SynchronizeModuleConfigProfilesPath = new Path(
  '/config-profiles',
  {
    base: SynchronizeModulePath,
    defaultTitle: 'Config Profiles',
  },
);
export const SynchronizeModuleMapkeysPath = new Path('/mapkeys', {
  base: SynchronizeModulePath,
  defaultTitle: 'Mapkeys',
});

// Framework
export const FrameworkModulePath = new Path(
  '/framework',
  {
    base: DashboardPath,
    defaultTitle: 'Framework',
  }
);
export const FrameworkModuleProfileLibrariesPath = new Path(
  '/profile-libraries',
  {
    base: FrameworkModulePath,
    defaultTitle: 'Profile Libraries'
  }
);
export const FrameworkModuleProfileLibrariesBasePath = new Path(
  '/:libraryId',
  {
    base: FrameworkModuleProfileLibrariesPath,
    defaultTitle: 'Profile Libraries',
    params: { libraryId: requiredStringSchema },
  }
);
export const FrameworkModuleProfilePath = new Path(
  '/:id',
  {
    base: FrameworkModuleProfileLibrariesBasePath,
    params: { id: requiredStringSchema },
  }
);
export const FrameworkModuleProfileTablesPath = new Path(
  '/tables',
  {
    base: FrameworkModuleProfilePath,
    params: { id: requiredStringSchema },
  }
);
export const FrameworkModuleTablePath = new Path(
  '/:tableId',
  {
    base: FrameworkModuleProfileTablesPath,
    params: { tableId: requiredStringSchema }
  }
);