import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_LIBRARIES';

export const fetchAllProfileLibraryTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createProfileLibraryTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyProfileLibraryTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editProfileLibraryTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteProfileLibraryTypes = generateAPHTypes(
  resource,
  'DELETE'
);