import React from 'react';

import theme from '../../../../assets/themes/theme';

const LegalGraphic = ({
  id = 'legal-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <circle fill={backgroundColor} cx="256" cy="256" r="252.5" />

      <g>
        <path
          fill={theme.colors.gray[0]}
          d="M330.3,425.2v-45.9c0-12.2,9.9-22,22-22h45.9V108.8c0-12.2-9.9-22-22-22H135.8c-12.2,0-22,9.9-22,22v294.3
			c0,12.2,9.9,22,22,22H330.3z"
        />
        <path
          fill={theme.colors.gray[4]}
          d="M330.3,425.2l67.9-67.9h-45.9c-12.2,0-22,9.9-22,22V425.2z"
        />
        <rect
          x="149.6"
          y="145.5"
          fill={theme.colors.gray[4]}
          width="214.7"
          height="13.2"
        />
        <rect
          x="149.6"
          y="181.4"
          fill={theme.colors.gray[4]}
          width="214.7"
          height="13.2"
        />
        <rect
          x="149.6"
          y="217.4"
          fill={theme.colors.gray[4]}
          width="214.7"
          height="13.2"
        />
        <rect
          x="149.6"
          y="253.4"
          fill={theme.colors.gray[4]}
          width="135.3"
          height="13.2"
        />
        <rect
          x="185.1"
          y="314.1"
          fill={theme.colors.gray[4]}
          width="76.5"
          height="13.2"
        />
        <g>
          <path
            fill={theme.colors.gray[0]}
            d="M415,254.7c-0.6-0.6-0.9-1.3-1-2.1c-1.1-13.4,5.9-26.2,17.8-32.6l17.7-9.5c4.5-2.4,5.7-6.8,5.4-10.1
				c-0.3-3.3-2.4-7.4-7.3-8.9c-1.8-0.5-2.8-2.4-2.3-4.2c0.5-1.8,2.4-2.8,4.3-2.3c6.8,2,11.4,7.7,12.1,14.7c0.7,7-2.8,13.5-9,16.8
				l-17.7,9.5c-9.5,5.1-15.1,15.3-14.2,26c0.1,1.9-1.3,3.5-3.1,3.7C416.7,255.7,415.7,255.4,415,254.7z"
          />
        </g>
        <path
          fill={theme.colors.gray[6]}
          d="M243.3,363.1L243.3,363.1c-1.2-1.2-1.2-3,0-4.2l16.7-16.7l4.2,4.2l-16.7,16.7
			C246.4,364.3,244.5,364.3,243.3,363.1z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M257.3,359.7c31-14.7,107.3-79,154.5-126.2c57-57,49.9-67.4,39.2-78.1c-10.8-10.8-21.1-17.8-78.1,39.2
			C325.6,241.8,261.3,318.1,246.6,349L257.3,359.7z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M397.8,247.1c4.9-4.7,9.5-9.2,13.9-13.6c57-57,49.9-67.4,39.2-78.1c-10.8-10.8-21.1-17.8-78.1,39.2
			c-4.4,4.4-9,9.1-13.6,13.9L397.8,247.1z"
        />
        <path
          fill={theme.colors.error[4]}
          d="M392.8,251.8c2.6-2.4,5.1-4.9,7.6-7.3l-38.7-38.7c-2.4,2.5-4.8,5-7.3,7.6L392.8,251.8z"
        />
      </g>
    </g>
  </svg>
);

export default LegalGraphic;
