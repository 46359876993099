import { toast } from 'react-toastify';

import specFittingInstanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveSpecFittingInstancesTypes } from './constants';

export const processSaveSpecFittingInstances = (specId, data) => specFittingInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveSpecFittingInstancesTypes, `/piping/specs/${specId}?`, {
  data: { specFittingInstancesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});