import React from 'react';

import theme from '../../../../assets/themes/theme';

const st0 = {
  fill: '#4DABF7',
};
const st1 = {
  fill: '#646464',
  stroke: '#646464',
  strokeWidth: 4,
  strokeMiterlimit: '10',
};
const st2 = {
  fill: '#E6E6E6',
  stroke: '#324A5E',
  strokeWidth: 4,
  strokeMiterlimit: 10,
};
const st3 = {
  fill: '#E6E6E6',
  stroke: '#324A5E',
  strokeWidth: 3,
  strokeMiterlimit: 10,
};
const st4 = {
  fill: '#9FBDE7',
  stroke: '#324A5E',
  strokeWidth: 3,
  strokeMiterlimit: 10,
};
const st5 = {
  fill: '#324A5E'
};
const st6 = {
  fill: 'none',
  stroke: '#324A5E',
  strokeWidth: 27,
  strokeMiterlimit: 10,
};

const SynchronizeGraphic = ({
  id = 'synchronize-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <circle
      fill={disabled ? disabledBackgroundColor : backgroundColor}
      cx="256"
      cy="256"
      r="254"
    />
    <g>
      <polygon
        style={st1}
        points="211.9,246.5 243.4,274.9 255.2,261.3 223.6,232.9 	"
      />
      <path
        style={st1}
        d="M219.2,228.9l40.3,36.4c1.1,1,2.8,0.9,3.9-0.2c0,0,0,0,0.1-0.1l6.8-7.9c1-1.2,0.9-2.9-0.2-4l-40.3-36.4
        c-1.1-1-2.8-0.9-3.9,0.2c0,0,0,0-0.1,0.1l-6.8,7.9C218,226.1,218.1,227.9,219.2,228.9z"
      />
      <path
        style={st2}
        d="M230.5,201.4l80,74.2c18.3-7.2,40.5-3.3,56.8,11.8c13.5,12.4,20.4,30.5,18.4,48.8l-30.5-28.3l-31.3,10.9
        l-7.6,33l30.5,28.3c-17.7,3.7-36.2-1.6-49.3-14.1c-16.3-15.1-22.2-37.3-17-56.6l-80-74.2c-18.3,7.2-40.5,3.3-56.8-11.8
        c-13.5-12.4-20.4-30.5-18.4-48.8l30.5,28.3l31.3-10.9l7.6-33l-30.5-28.3c17.7-3.7,36.2,1.6,49.3,14.1
        C229.8,159.9,235.7,182.1,230.5,201.4z"
      />
      <polygon
        style={st2}
        points="354.9,126.5 370.9,141 362.9,159.4 340.6,171.5 278.6,243.1 267,232.7 329,161 338,136.9 	"
      />
      <path
        style={st3}
        d="M143.5,353.8l32.6,29.4c3.2,2.9,8.2,2.7,11.1-0.5c0.1-0.1,0.1-0.1,0.2-0.2l82.6-95.4c2.9-3.4,2.6-8.5-0.7-11.5
          l-32.6-29.4c-3.2-2.9-8.2-2.7-11.1,0.5c-0.1,0.1-0.1,0.1-0.2,0.2l-82.6,95.4C139.9,345.7,140.2,350.8,143.5,353.8z"
      />
      <polygon
        style={st4}
        points="237.1,246.7 268.6,275.1 280.4,261.6 248.9,233.1 	"
      />
      <path
        style={st4}
        d="M267,290.1c-0.5,0.8-1.1,1.5-1.7,2.2l-73.6,85c-0.6,0.7-1.2,1.3-1.9,1.9c-1.6-1.6-0.9-5.1,1.5-7.9l68.2-78.8
          C261.9,289.8,265.2,288.7,267,290.1z"
      />
      <path
        style={st4}
        d="M181,365.6l71.7-82.8c1.5-1.8,1.4-4.4-0.4-6l0,0c-1.7-1.5-4.3-1.4-5.8,0.3l-0.1,0.1L174.8,360
          c-1.5,1.8-1.4,4.4,0.4,6l0,0C176.8,367.5,179.4,367.4,181,365.6C180.9,365.7,181,365.6,181,365.6z"
      />
      <path
        style={st4}
        d="M166.2,352.2l71.7-82.8c1.5-1.8,1.4-4.4-0.4-6l0,0c-1.7-1.5-4.3-1.4-5.8,0.3l-0.1,0.1l-71.7,82.8
          c-1.5,1.8-1.4,4.4,0.4,6l0,0C162,354.1,164.6,354,166.2,352.2C166.1,352.3,166.1,352.2,166.2,352.2z"
      />
      <path
        style={st4}
        d="M221.2,258L153,336.8c-2.4,2.8-5.6,3.8-7.4,2.5c0.5-0.8,1.1-1.5,1.7-2.2l73.6-85c0.6-0.7,1.2-1.3,1.9-1.9
          C224.2,251.8,223.6,255.2,221.2,258z"
      />
      <path
        style={st2}
        d="M244.4,229.1l40.3,36.4c1.1,1,2.8,0.9,3.9-0.2c0,0,0,0,0.1-0.1l6.8-7.9c1-1.2,0.9-2.9-0.2-4L254.9,217
          c-1.1-1-2.8-0.9-3.9,0.2c0,0,0,0-0.1,0.1l-6.8,7.9C243.2,226.3,243.3,228.1,244.4,229.1z"
      />
      <path
        style={st5}
        d="M202.6,149.7c-0.8,1.5-0.8,1.5,0.7,2.3c13.5,7.4,21.9,26.1,17,40.2c-0.4,1.4-1,2.7-1.7,4
          c-0.2,0.5-0.2,0.7,0.3,0.9c0.3,0.1,0.6,0.3,0.8,0.4c1.9,1,1.9,1,2.8-1c6.5-14.9,0.3-33.9-12.6-44.3c-1.7-1.3-3.5-2.5-5.4-3.6
          c-0.8-0.5-1.4-0.6-1.7,0.5C202.9,149.3,202.8,149.5,202.6,149.7z"
      />
      <polyline
        style={st5}
        points="399.9,311.2 441,269 482.5,309.3 	"
      />
      <polyline
        style={st5}
        points="112.1,198 70.5,239.7 29.5,198.8 	"
      />
      <path
        style={st6}
        d="M444.1,289.5C427.2,392.9,329.8,463,226.4,446.2c-90.1-14.7-156.9-91.5-159-182.7"
      />
      <path
        style={st6}
        d="M68.3,218.1C87.6,115.2,186.6,47.4,289.6,66.6c85.5,16,149.2,88.1,154.4,175"
      />
    </g>
  </svg>
);

export default SynchronizeGraphic;
