import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import UnitSchema from './schema';

export const selectUnits = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.UNITS.state), [UnitSchema], entities)
);