import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import GlobalCadSearchRuleCriterionSchema from '../GlobalCadSearchRuleCriteria/schema';

const globalCadSearchRuleSchema = baseSchema(EK.GLOBAL_CAD_SEARCH_RULES.state, {
  globalCadSearchRuleCriteria: {
    schema: [GlobalCadSearchRuleCriterionSchema],
    isId: true,
    idField: 'globalCadSearchRuleCriterionIds',
    isList: true,
  },
});

export default globalCadSearchRuleSchema;
