import { List } from 'immutable';
import capitalize from 'lodash/capitalize';

import EntityRecord from '../../modules/entities/model';

import { USER_ROLE_TYPES } from '../../modules/permissions/constants';

export default class OrganizationUserInvite extends EntityRecord({
  token: {
    value: null,
  },
  email: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  firstName: {
    value: null,
    handleSerialize: true,
  },
  lastName: {
    value: null,
    handleSerialize: true,
  },
  state: {
    value: null,
  },
  createdAt: {
    value: null,
  },
  role: {
    value: USER_ROLE_TYPES.USER,
    serializeOutputField: 'roleName',
    handleSerialize: true,
  },
  organization: {
    value: null,
    isEntity: true,
    handleCompare: true,
  },
  invitee: {
    value: null,
    isEntity: true,
  },
  invitedBy: {
    value: null,
    isEntity: true,
  },
  hasModuleCabling: {
    value: false,
    handleSerialize: true,
  },
  hasModulePiping: {
    value: false,
    handleSerialize: true,
  },
  hasModuleFramework: {
    value: false,
    handleSerialize: true,
  },
  hasModuleEcad: {
    value: false,
    handleSerialize: true,
  },
  hasModuleReporting: {
    value: false,
    handleSerialize: true,
  },
}) {
  get isNamePresent() {
    return this.firstName !== null || this.lastName !== null;
  }

  get fullName() {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  get isAccepted() {
    return this.state === 'accepted';
  }

  get isPending() {
    return this.state === 'pending';
  }
}
