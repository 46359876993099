import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../../templates/Structures/Options';

import { selectNormalizedNoteProfilesList } from '../../../entities/Standardize/NoteProfiles/selectors';

import { selectStandardizeModulePermissionsAndState } from '../../Dashboard/selectors';

import { showModal } from '../../../modules/modal/actions';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectNormalizedNoteProfilesList(),
  ({ isLoadingInitialData, isFetching, ...rest }, data) => ({
    ...rest,
    isLoading: isLoadingInitialData ||
      (isFetching && (!data || data.size === 0)),
  }),
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  createEntity() {
    dispatch(
      showModal('NOTE_BLOCK_MODAL', {
        noteProfileId: ownProps.match.params.id,
      }),
    );
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(Options);
