import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'DIMENSIONS';

export const fetchAllDimensionTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createDimensionTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyDimensionTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editDimensionTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteDimensionTypes = generateAPHTypes(
  resource,
  'DELETE'
);