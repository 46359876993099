import { toast } from 'react-toastify';

import userClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllUsersTypes, adminCreateUserTypes, editUserTypes, forceResetPasswordTypes } from './constants';

export const processFetchAllUsers = organizationId => userClient(REQUEST_TYPES.GET, 'plural', fetchAllUsersTypes, `/organizations/${organizationId}/users`, {
  mutateResponse: ({ organizationUserMemberships }) => ({
    users: organizationUserMemberships.users.map(user => ({ ...user, organization: organizationUserMemberships.organization })),
  }),
});

export const processAdminCreateUser = (organizationId, data) => userClient(REQUEST_TYPES.POST, 'plural', adminCreateUserTypes, `/organizations/${organizationId}/users`, {
  data,
  mutateResponse: ({ organizationUserMemberships }) => ({
    users: organizationUserMemberships.users.map(user => ({ ...user, organization: organizationUserMemberships.organization })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'User successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create user',
  },
});

export const processEditUser = (organizationId, userId, data) => userClient(REQUEST_TYPES.PATCH, 'plural', editUserTypes, `/organizations/${organizationId}/users/${userId}`, {
  data,
  mutateResponse: ({ organizationUserMemberships }) => ({
    users: organizationUserMemberships.users.map(user => ({ ...user, organization: organizationUserMemberships.organization })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

// import { put, select } from 'redux-saga/effects';

// import UserSchema from './schema';
// import keys from './keys';
// import ENTITIES from '../../modules/entities/constants';
// import * as ActionTypes from '../../utils/actions';

// import { selectUsers } from './selectors';
// import { selectCurrentOrganizationId } from '../../modules/CurrentUser/selectors';

// export const forceResetPassword = ActionTypes.createAction(ENTITIES.CREATE, UserSchema, {
//   url: '/password_resets',
//   dataFn: ({ email }) => ({ email, forced: true }),
//   errorNotification: {
//     title: 'Uh oh',
//     message: 'We had trouble resetting the password. Please try again in a moment or contact our support team if the issue persists.',
//   },
//   successNotification: {
//     title: 'Got it',
//     message: 'The password was successfully reset.',
//   },
// });
