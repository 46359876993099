// validation
export function areFieldsValid({ name }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name }) {
  // takes the VALIDATION_FIELDS type, so all values are booleans
  return !!name;
}
