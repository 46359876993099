export const convertToBoltNutInstanceType = ({
  id,
  sizeProperty,
  schedule,
  mccsCode,
  bulkItemName,
  quantity,
  boltType,
  materialDescription,
  isEdited=false,
  isDeleted=false,
  isCreated=false,
}) => ({
  id,
  sizeProperty,
  schedule,
  mccsCode,
  bulkItemName,
  quantity,
  boltType,
  materialDescription,
  isEdited,
  isDeleted,
  isCreated
});

export const saveCurrentBoltNutInstanceChanges = (
  changes,
  saveAction,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty?.id,
        scheduleId: item.schedule?.id,
        mccsCode: item.mccsCode,
        bulkItemName: item.bulkItemName,
        quantity: item.quantity,
        boltType: item.boltType,
        materialDescription: item.materialDescription,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty?.id,
        scheduleId: item.schedule?.id,
        mccsCode: item.mccsCode,
        bulkItemName: item.bulkItemName,
        quantity: item.quantity,
        boltType: item.boltType,
        materialDescription: item.materialDescription,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};