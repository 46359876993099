import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

import { selectRouteLocationPathname } from '../../../modules/router/selectors';
import { matchPath } from 'react-router-dom';
import { FrameworkModuleProfileLibrariesBasePath } from '../../../paths';

export const selectNormalizedProfileLibraries = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_LIBRARIES.state)
);
export const selectNormalizedProfileLibrariesList = () => createSelector(
  selectNormalizedProfileLibraries(),
  profileLibraries => profileLibraries.toList()
);

export const selectCurrentProfileLibraryName = () => createSelector(
  selectNormalizedProfileLibrariesList(),
  selectRouteLocationPathname(),
  (libs, path) => {
    const match = matchPath(path, { path: FrameworkModuleProfileLibrariesBasePath.url });
    const lib = libs && match.params && libs.find(lib => lib.id == match.params.libraryId);
    return lib && lib.name || '';
  }
);
