import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectSortedNormalizedConfigSettingCategories = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CONFIG_SETTING_CATEGORIES.state)
);
export const selectNormalizedNonCustomConfigSettingCategoriesList = () => createSelector(
  selectSortedNormalizedConfigSettingCategories(),
  categories => categories.filter(category => !category.isCustom).toList().sortBy(category => category.name)
);
export const selectNormalizedNonCustomConfigSettingCategoriesOptions = () => createSelector(
  selectNormalizedNonCustomConfigSettingCategoriesList(),
  categories => categories.map(category => category.toOption())
);
