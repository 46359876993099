import React, { useCallback } from 'react';

import useStateWithCallback from '../../../hooks/useStateWithCallback';

import BaseInput from '../BaseInput';

// references e.key values
const DEFAULT_EMAIL_PARSING_KEY_DELIMITERS = [
  ',',
  ' ',
  'Enter',
];

// email validation regex
const isEmail = email => {
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/.test(email);
};

// email parsing function that returns cleaned email or false if error
const parseEmail = emailString => {
  return typeof emailString === 'string' && isEmail(emailString) && emailString || false;
};

const EmailParsingTextInput = React.forwardRef(({
  onValueChange,
  onValidEmailParsed,
  onEmailParseError,
  emailParsingDelimiters = DEFAULT_EMAIL_PARSING_KEY_DELIMITERS,
  clearInputAfterParsing = true,
  ...input
}, ref) => {
  const [currentEmailString, setCurrentEmailString] = useStateWithCallback('', onValueChange);

  const onChange = useCallback(e => {
    setCurrentEmailString(e.target.value);
  });

  const onKeyDown = useCallback(e => {
    e.persist();

    if (emailParsingDelimiters.includes(e.key)) {
      // one of the delimiters was triggered
      e.preventDefault();
      const email = parseEmail(e.target.value);
      if (email) {
        onValidEmailParsed && onValidEmailParsed(email);
        if (clearInputAfterParsing) {
          setCurrentEmailString('');
        }
      } else {
        onEmailParseError && onEmailParseError();
      }
    }
  }, [emailParsingDelimiters, onValidEmailParsed, onEmailParseError, clearInputAfterParsing]);

  return (
    <BaseInput
      { ...input }
      ref={ref}
      value={currentEmailString}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
});

export default EmailParsingTextInput;