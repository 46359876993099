import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import ProjectSchema, { specSchema } from './schema';
import { removeDuplicateFromObjectIdArray, selectNormalizedSpecs } from '../Specs/selectors';
import { convertToProjectSpecType } from '../../../routes/Piping/ProjectsEdit/actions';
import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { List } from 'immutable';
import { selectIsShowingArchived } from '../../../modules/query/selectors';

export const selectNormalizedProjects = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.PROJECTS.state)
);

export const selectProjectsList = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PROJECTS.state).toList(), [ProjectSchema], entities.delete(EK.SPECS.state))
);

export const selectProjectsCounts = () => createSelector(
  selectNormalizedProjects(),
  projects => projects.reduce(({ unarchived, total }, project) => ({
    unarchived: !project.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectCurrentProject = (projectId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.PROJECTS.state, projectId]) && denormalize(entities.getIn([EK.PROJECTS.state, projectId]), ProjectSchema, entities)
);

const projectSpecFilterForm = formValueSelector(`filters.${EK.SPECS.state}`);

const selectFilteredProjectSpecs = (projectId) => createSelector(
  selectEntities(),
  selectSidebarIsFiltering(),
  state => projectSpecFilterForm(state, 'specName', 'mnemonic'),
  (entities, isFiltering, query) => {
    const rawProjectSpecs = entities.hasIn([EK.PROJECTS.state, projectId]) && entities.getIn([EK.PROJECTS.state, projectId]).specs;
    const projectSpecs = rawProjectSpecs && denormalize(rawProjectSpecs, [specSchema], entities).toList() || List();
    return isFiltering ? projectSpecs.filter(item => item.doesMatchQuery(query)) : projectSpecs;
  }
);

const selectFilteredSpecs = () => createSelector(
  selectEntities(),
  selectNormalizedSpecs(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  state => projectSpecFilterForm(state, 'specName', 'mnemonic'),
  (entities, specs, isFiltering, isShowingArchived, query) =>
    denormalize(isFiltering ?
      specs.filter(item => (isShowingArchived || !item.archivedFlag) && item.doesMatchQuery(query)).toList() :
      specs.filter(item => !item.archivedFlag).toList(), [specSchema], entities)
);

export const selectCurrentFilteredProjectSpecInstances = (projectId) => createSelector(
  selectFilteredSpecs(),
  selectFilteredProjectSpecs(projectId),
  (specs, projectSpecs) => {
    const pspecs = projectSpecs && projectSpecs.toArray().map( item => convertToProjectSpecType({
      id: item.id,
      specName: item.specName,
      mnemonic: item.mnemonic,
      instancesCount: item.instancesCount,
      specPipeInstances: item.specPipeInstances,
      specFittingInstances: item.specFittingInstances,
      isSelectedForProject: true,
    }));
    return pspecs && removeDuplicateFromObjectIdArray(pspecs.concat(specs.toArray())) || specs.toArray();
  }
);

export const selectProjectSpecInstanceCount = (projectId) => createSelector(
  selectCurrentProject(projectId),
  project => project?.specs?.count() || 0
);