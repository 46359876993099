import { Map } from 'immutable';

import { LOCATION_CHANGE } from 'connected-react-router';

import { isTokenPresent } from './utils/token';

import {
  processAcceptInviteTypes,
  processFetchCurrentUserTypes,
  processLoginTypes,
  processLogout,
  processRegisterTypes,
  processRequestPasswordResetTypes,
  processResetPasswordTypes,
  processVerifyEmailTypes,
  samlLoginTypes,
} from './constants';

const generateInitialState = () =>
  Map({
    isLoggingIn: isTokenPresent(),
    isAuthError: false,
    isAuthSuccess: false,
    passwordResetRequestSubmitted: false,
    passwordResetError: false,
  });

const authReducer = (state = generateInitialState(), { type }) => {
  switch (type) {
  case processLoginTypes.REQUEST:
  case processRegisterTypes.REQUEST:
  case processVerifyEmailTypes.REQUEST:
  case processAcceptInviteTypes.REQUEST:
  case processFetchCurrentUserTypes.REQUEST:
    return state.withMutations((intermediateState) => {
      intermediateState.set('isAuthError', false).set('isAuthSuccess', false).set('isLoggingIn', true);
    });

  case processLoginTypes.SUCCESS:
  case processRegisterTypes.SUCCESS:
  case processVerifyEmailTypes.SUCCESS:
  case processAcceptInviteTypes.SUCCESS:
  case processFetchCurrentUserTypes.SUCCESS:
  case samlLoginTypes.SUCCESS:
    return state.withMutations((intermediateState) => {
      intermediateState.set('isAuthSuccess', true).set('isLoggingIn', false);
    });

  case processLoginTypes.FAILURE:
  case processRegisterTypes.FAILURE:
  case processVerifyEmailTypes.FAILURE:
  case processAcceptInviteTypes.FAILURE:
  case processFetchCurrentUserTypes.FAILURE:
  case samlLoginTypes.FAILURE:
    return state.withMutations((intermediateState) => {
      intermediateState.set('isAuthError', true).set('isLoggingIn', false);
    });

  case processLogout.HANDLED:
    return state.withMutations((intermediateState) => {
      intermediateState
        .set('isAuthError', false)
        .set('isAuthSuccess', false)
        .set('isLoggingIn', false)
        .set('passwordResetRequestSubmitted', false)
        .set('passwordResetError', false);
    });

  case processRequestPasswordResetTypes.SUCCESS:
    return state.set('passwordResetRequestSubmitted', true);

  case processResetPasswordTypes.SUCCESS:
    return state.set('passwordResetError', false);

  case processResetPasswordTypes.FAILURE:
    return state.set('passwordResetError', true);

  case LOCATION_CHANGE:
    return state.withMutations((intermediateState) => {
      intermediateState
        .set('isAuthError', false)
        .set('isAuthSuccess', false)
        .set('passwordResetRequestSubmitted', false)
        .set('passwordResetError', false);
    });

  default:
    return state;
  }
};

export default authReducer;
