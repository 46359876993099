import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../../../../assets/themes/base/colors';
import radii from '../../../../../../assets/themes/base/radii';
import space from '../../../../../../assets/themes/base/space';
import borders from '../../../../../../assets/themes/base/borders';

import Box from '../../../../../../components/common/Box';
import Flex from '../../../../../../components/common/Flex';
import Icon from '../../../../../../components/common/Icon';
import Text from '../../../../../../components/common/Text';

import MinimizableListItem from '../MinimizableListItem';

const MinimizableListContainer = styled(Box)`
  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-left: ${space[4]};
      padding-right: ${space[4]};
      padding-top: ${space[4]};
    `}
  ${({ isOpen }) =>
    !isOpen &&
    css`
      padding: ${space[4]};
      background-color: ${colors.gray[1]};
      padding: ${space[3]};

      border-radius: ${radii[2]};
      border: ${borders[2]};
    `}
`;

const MinimizableListItemsEmpty = styled(Flex)`
  background-color: ${colors.white};

  border-radius: ${radii[2]};
  border: ${borders[2]};

  height: 25rem;
`;

const MinimizableListItems = styled(Box)`
  background-color: ${colors.white};

  border-radius: ${radii[2]};
  border: ${borders[2]};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      overflow: auto;
      min-height: 25rem;
      max-height: 35rem;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    `}
`;

const MinimizableListShowToggle = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray[5]};
  border-radius: ${radii[1]};
  margin-top: ${space[4]};
`;

const MinimizableList = ({
  items,
  minimizable,
  noItemsText,
  noItemsSubtext,
  currentSelectedItemId,
  sortable,
  onToggleSelect,
  onToggleShowForm,
  onEdit,
  onDelete,
}) => {
  return (
    <MinimizableListContainer isOpen={minimizable && !!currentSelectedItemId}>
      {items.size > 0 ? (
        <MinimizableListItems isOpen={minimizable && !!currentSelectedItemId}>
          {items.map((item) => (
            <MinimizableListItem
              key={item.id}
              item={item}
              isSelected={item.id === currentSelectedItemId}
              isHidden={
                minimizable &&
                currentSelectedItemId &&
                item.id !== currentSelectedItemId
              }
              sortable={sortable}
              onToggleSelect={onToggleSelect}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </MinimizableListItems>
      ) : (
        <MinimizableListItemsEmpty
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize={3} color="gray.6" mb={4}>
            {noItemsText}
          </Text>
          <Text fontSize={2} color="gray.6">
            {noItemsSubtext}
          </Text>
        </MinimizableListItemsEmpty>
      )}

      {onToggleShowForm && !currentSelectedItemId && (
        <MinimizableListShowToggle onClick={onToggleShowForm}>
          <Text>
            <Icon color="gray.5" name="add" />
          </Text>
        </MinimizableListShowToggle>
      )}
    </MinimizableListContainer>
  );
};

export default MinimizableList;
