import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';

import UnitSystemSchema from '../../Synchronize/UnitSystems/schema';
import profileLibrarySchema from '../ProfileLibraries/schema';

const profileSchema = baseSchema(EK.PROFILES.state, {
  unitSystem: { schema: UnitSystemSchema, isId: true },
  profileLibrary: { schema: profileLibrarySchema, isId: true},
});

export default profileSchema;
