import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import OrganizationSchema from '../Organizations/schema';
import OrganizationUserInviteSchema from '../OrganizationUserInvites/schema';

const currentUserSchema = baseSchema(EK.CURRENT_USER.state, {
  organization: { schema: OrganizationSchema },
  organizationInvites: { schema: [OrganizationUserInviteSchema] },
});

export default currentUserSchema;
