import { Map } from 'immutable';

import resetOnLogout from '../../../utils/reducers/resetOnLogout';

import { SET_NOTE_PROFILE_EDIT_STATUS } from './constants';

const initialModalState = Map({ isSaving: false });

const isSavingReducer = (state = initialModalState, { type, payload }) => {
  if (type === SET_NOTE_PROFILE_EDIT_STATUS) {
    return state.set('isSaving', payload.isSaving);
  } else {
    return state;
  }
};

export default resetOnLogout(isSavingReducer);
