import React from 'react';

import ComplexCellRenderer from '../ComplexCellRenderer';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import { DEFAULT_SYMBOL_FILE_ICONS } from '../../../entities/Piping/FittingCategories/model';

export default function (option) {
  if (option.symbolFile) {
    if (DEFAULT_SYMBOL_FILE_ICONS.includes(option.symbolFile)) {
      return (
        <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
          <Flex flexDirection='column' justifyContent='center' style={{ float: 'left', height: '100%' }} ><Icon style='ptc' name={option.symbolFile} iconStyle={{ height: '30px', width: '30px' }} /></Flex>
          <ComplexCellRenderer
            value={{ primary: option.categoryName, secondary: option.symbolFile || '--' }}
            placeholder={''}
            isCapitalized={false}
            paddingLeft='10px'
            style={{ float: 'right' }}
          />
        </div>
      );
    }
  }
  return (
    <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
      <Flex flexDirection='column' justifyContent='center' style={{ float: 'left', height: '100%' }} ></Flex>
      <ComplexCellRenderer
        value={{ primary: option.categoryName, secondary: option.symbolFile || '--' }}
        placeholder={''}
        isCapitalized={false}
        paddingLeft='10px'
        style={{ float: 'right' }}
      />
    </div>
  );
}
