import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'END_TYPE_COMPATIBILITIES';

export const fetchAllEndTypeCompatibilitiesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const createEndTypeCompatibilitiesTypes = generateAPHTypes(resource, 'CREATE');

export const updateEndTypeCompatibilityTypes = generateAPHTypes(resource, 'UPDATE');

export const removeEndTypeCompatibilityTypes = generateAPHTypes(resource, 'REMOVE');