import { call, takeLatest, put, fork } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { LoginPath } from '../../paths';

import { samlLoginTypes } from '../../modules/auth/constants';

import { setToken } from '../../modules/auth/utils/token';
import { DashboardPath } from '../../paths';

export function* handleSAMLLoginSuccess({ payload = {} }) {
  const { authenticationToken } = payload;
  if (!authenticationToken) {
    // TODO: handle error here
    yield put(push(LoginPath.url));
  }
  const token = authenticationToken;
  yield call(setToken, token);
  // yield put(push('/dashboard'));
  // TODO: handle redirect to dashboard better, this feels hacky
  window.location = DashboardPath.url;
}

export function* watchLoginSaga() {
  yield takeLatest(samlLoginTypes.SUCCESS, handleSAMLLoginSuccess);
}

// final output saga
export default function* main() {
  yield fork(watchLoginSaga);
}
