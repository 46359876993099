import React from 'react';

export default function CheckboxIndeterminateIcon({ size = '1em', style }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" style={style}>
      <path
        d="M38,6H10c-2.2,0-4,1.8-4,4v28c0,2.199,1.8,4,4,4h28c2.199,0,4-1.801,4-4V10C42,7.8,40.199,6,38,6z M34,26H14v-4h20V26z" />
    </svg>
  );
}
