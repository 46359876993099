import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.LAYERS.state;

export const fetchAllLayersTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createLayerTypes = generateAPHTypes(resource, 'CREATE');

export const saveLayersTypes = generateAPHTypes(
  resource,
  'COLLECTION'
);
