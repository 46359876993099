import React from 'react';

import Box from '../../../../../components/common/Box';

const ExternalPageGraphic = ({ children }) => (
  <Box width='75%' mb={7} ml='auto' mr='auto'>
    { children }
  </Box>
);

export default ExternalPageGraphic;
