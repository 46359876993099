import { List, Set } from 'immutable';
import isArray from 'lodash/isArray';

import EK from '../../keys';

import EntityRecord, { COMPARATORS, FILTERS, VALIDATORS, UTILS } from '../../../modules/entities/model';

class ConfigSetting extends EntityRecord({
  name: {
    value: null,
    filter: FILTERS.STRING,
  },
  settingName: {
    value: null,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    filter: FILTERS.STRING,
  },
  defaultValue: {
    value: null,
  },
  valueType: {
    value: null,
  },
  listValues: {
    value: List(),
    isList: true,
  },
  validationText: {
    value: null,
  },
  multipleAllowed: {
    value: false,
  },
  [EK.CONFIG_SETTING_CATEGORIES.single]: {
    value: null,
    isEntity: true,
  },
  searchTerms: {
    value: Set(),
  },
}) {
  constructor({ valueType, listValues, defaultValue, incomingSearchTerm, ...other } = {}) {
    super({
      ...other,
      valueType,
      listValues: valueType === 'list' && isArray(listValues) ? List(listValues.map(value => { return { value, label: value, primary: value }; })) : List(),
      defaultValue: valueType === 'bool' ? (
        defaultValue === 'true'
      ) : (valueType === 'color' ? UTILS.OBJECTIZE_COLOR(defaultValue) : defaultValue),
      // this needs to be added later purely for merging, not for constructor
      // searchTerms: !!incomingSearchTerm ? searchTerms.add(incomingSearchTerm) : searchTerms,
      searchTerms: incomingSearchTerm ? Set([incomingSearchTerm]) : undefined,
    });
  }

  compareIfValuesEqual(a, b) {
    if (this.valueType === 'color') {
      return COMPARATORS.COLOR(a, b);
    } else {
      return a === b;
    }
  }

  isValueEditedAgainst(value, originalValue) {
    if (originalValue === undefined) {
      return !this.isValueDefaultOrUndefined(value);
    } else {
      return !this.compareIfValuesEqual(value, originalValue);
    }
  }

  isValueDefaultOrUndefined(value) {
    return value === undefined || this.compareIfValuesEqual(value, this.defaultValue);
  }

  doesMatchSearchTerm(searchTerm) {
    return this.searchTerms && this.searchTerms.has(searchTerm);
  }
}

export default ConfigSetting;