import React from 'react';

import theme from '../../../../assets/themes/theme';

import BaseGraphicSVG, { fillTransitionClass } from '../BaseGraphicSVG';

const PipeGraphic = ({
  id = 'pipe-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) => (
  <BaseGraphicSVG
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <circle
      className={fillTransitionClass}
      fill={disabled ? disabledBackgroundColor : backgroundColor}
      cx="64"
      cy="64"
      r="64"
    />
    <g>
      <path
        className={fillTransitionClass}
        fill={theme.colors.gray[0]}
        d="M128,61.84H110.08A15.49,15.49,0,0,0,94.61,77.32v6a27.48,27.48,0,1,1-54.95,0V56.63A15.5,15.5,0,0,0,24.19,41.15h-20a63.31,63.31,0,0,1,6.11-12H24.19A27.51,27.51,0,0,1,51.66,56.63V83.27a15.48,15.48,0,1,0,31,0v-6a27.51,27.51,0,0,1,27.47-27.48h16.34A63.24,63.24,0,0,1,128,61.84Z"
      />
      <rect
        className={fillTransitionClass}
        fill={disabled ? theme.colors.gray[1] : theme.colors.gray[4]}
        x="108.44"
        y="47.6"
        width="8.86"
        height="16.11"
        rx="2.27"
      />
      <rect
        className={fillTransitionClass}
        fill={disabled ? theme.colors.gray[1] : theme.colors.gray[4]}
        x="18"
        y="27.24"
        width="8.86"
        height="16.11"
        rx="2.27"
      />
      <rect
        className={fillTransitionClass}
        fill={disabled ? theme.colors.gray[1] : theme.colors.gray[4]}
        x="84.19"
        y="74.3"
        width="8.86"
        height="16.11"
        rx="2.27"
        transform="translate(170.97 -6.27) rotate(90)"
      />
      <rect
        className={fillTransitionClass}
        fill={disabled ? theme.colors.gray[1] : theme.colors.gray[4]}
        x="40.83"
        y="74.3"
        width="8.86"
        height="16.11"
        rx="2.27"
        transform="translate(127.61 37.09) rotate(90)"
      />
      <rect
        className={fillTransitionClass}
        fill={disabled ? theme.colors.gray[1] : theme.colors.gray[4]}
        x="41.11"
        y="52.03"
        width="8.86"
        height="16.11"
        rx="2.27"
        transform="translate(105.62 14.54) rotate(90)"
      />
    </g>
  </BaseGraphicSVG>
);

export default PipeGraphic;
