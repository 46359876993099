import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';

const PasswordResetRequestForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field 
          component={RDXTextInput} 
          name='email' 
          label='Email' 
          type='email' 
          autoComplete='username' 
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button primary type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Request Password Reset</Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'passwordResetRequest',
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

export default enhance(PasswordResetRequestForm);
