import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  getFormValues,
  getFormInitialValues,
  formValueSelector,
  change,
  clearFields,
  reset,
} from 'redux-form/immutable';
import { List, Map, is } from 'immutable';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import styled from 'styled-components';

import fontSizes from '../../../../assets/themes/base/fontSizes';
import borders from '../../../../assets/themes/base/borders';
import space from '../../../../assets/themes/base/space';
import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import pluralize from 'pluralize';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import EK from '../../../../entities/keys';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Label from '../../../../components/common/Label';
import Text from '../../../../components/common/Text';
import VariableItemSizeList from '../../../../components/common/VariableItemSizeList';

import BaseSearchHighlighter from '../../../../components/form/BaseSearchHighlighter';

import Tooltip, { CONTAINERS, LAYER_PRIORITY } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';
import RDXCheckboxInput from '../../RDXCheckboxInput';
import RDXColorInput from '../../RDXColorInput';

import { ALLOWANCES } from '../../../../entities/Settings/model';

import {
  selectSortedNormalizedConfigSettingCategories,
} from '../../../../entities/Synchronize/ConfigSettingCategories/selectors';

import {
  selectNormalizedConfigSettings,
} from '../../../../entities/Synchronize/ConfigSettings/selectors';
import { processFetchAllConfigSettingsForCategory } from '../../../../entities/Synchronize/ConfigSettings/actions';

const SettingField = styled(Box)`
  flex-shrink: 0;
  height: 4.75rem;
  width: 100%;
  // border: ${borders[2]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const SettingDetails = styled(Flex)`
  flex-direction: column;
  padding: ${space[3]};
  margin-right: ${space[4]};
  height: 100%;
  width: 60%;
  justify-content: center;
`;

const SettingName = styled(Text)`
  font-size: ${fontSizes[2]};
  font-weight: 500;
  margin-bottom: ${space[1]};
  text-align: right;
`;

const SettingCreoName = styled(Box)`
  margin-bottom: ${space[1]};
  text-align: right;
`;

const SettingCreoNameLabel = styled(Label)`
  font-family: monospace;
`;

const SettingDescription = styled(Text)`
  font-size: ${fontSizes[1]};
  color: ${colors.gray[6]};
  text-align: right;
`;

const createSelectSettingForForm = () => createSelector(
  selectNormalizedConfigSettings(),
  (_, settingId) => settingId,
  (settings, settingId) => settings.get(settingId)
);

const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const SkeletonSettingFieldRow = ({ height }) => {
  const randomFieldType = generateRandomNumber(1, 3);
  return (
    <Flex flexDirection='column' height={height}>
      <Flex flexDirection='row' p={4}>
        <Flex flexDirection='row' alignItems='center' width='7rem' justifyContent='space-between' mr={4} />
        <SettingDetails>
          <SettingName color='gray.7'>
            <Skeleton width={`${generateRandomNumber(30, 80)}%`} />
          </SettingName>
          <SettingCreoName>
            <SettingCreoNameLabel secondary>
              <Skeleton width={`${generateRandomNumber(4, 7)}rem`} />
            </SettingCreoNameLabel>
          </SettingCreoName>
          <SettingDescription>
            <Skeleton count={2} />
          </SettingDescription>
        </SettingDetails>
        <Flex flexDirection='column' justifyContent='center' width='40%' mr={4}>
          <SettingField>
            {
              randomFieldType === 1 && <Skeleton height='4rem' />
            }
            {
              randomFieldType === 2 && <Skeleton height='1.25rem' width='1.25rem' />
            }
            {
              randomFieldType === 3 && <Skeleton height='2rem' width='4rem' />
            }
          </SettingField>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SettingFormField = ({ setting, name, isPerforming }) => (
  <SettingField>
    {
      (setting.valueType === 'string' || setting.valueType === 'integer' || setting.valueType === 'double') && (
        <Field
          component={RDXTextInput}
          name={name || setting.id}
          bg='gray.0'
          height='100%'
          allow={setting.valueType === 'integer' ? ALLOWANCES.INTEGER : (setting.valueType === 'double' ? ALLOWANCES.DOUBLE : null)}
          defaultValue={setting.defaultValue}
          disabled={isPerforming}
          wait={250}
        />
      ) || null
    }
    {
      setting.valueType === 'list' && (
        <Field
          component={RDXRadioSelect}
          name={name || setting.id}
          bg='gray.0'
          height='100%'
          defaultValue={setting.defaultValue}
          options={setting.listValues}
          disabled={isPerforming}
        />
      ) || null
    }
    {
      setting.valueType === 'bool' && (
        <Field
          component={RDXCheckboxInput}
          name={name || setting.id}
          disabled={isPerforming}
          defaultValue={setting.defaultValue}
        />
      ) || null
    }
    {
      setting.valueType === 'color' && (
        <Field
          component={RDXColorInput}
          name={name || setting.id}
          disabled={isPerforming}
          defaultValue={setting.defaultValue}
          tooltipProps={{
            priority: LAYER_PRIORITY.MODAL_DROPDOWN
          }}
        />
      ) || null
    }
  </SettingField>
);

const FlexibleFormFieldContainer = styled(Box)`
  flex-grow: 1;
`;

const renderMultipleAllowedSettingFormFields = ({ fields, setting, isPerforming }) => (
  <Flex flexDirection='column' justifyContent='center'>
    {
      fields.map((field, index) => (
        <Flex key={index} flexDirection='row' alignItems='center' mb={2}>
          <FlexibleFormFieldContainer>
            <SettingFormField name={`${field}.value`} setting={setting} isPerforming={isPerforming} />
          </FlexibleFormFieldContainer>
          <Field component={RDXTextInput} name={`${field}.id`} type='hidden' />
          <Button
            transparent
            subtle
            large
            primary
            icon='close'
            onClick={() => fields.remove(index)}
            type='button'
          />
        </Flex>
      ))
    }
    <Button secondary type="button" onClick={() => fields.push({})}>
      Add Value
    </Button>
  </Flex>
);

const SettingFieldItem = ({  index, id, initialValue, isPerforming, isVisible, searchWords, setSize  }) => {
  const rowRoot = useRef();

  const selectSettingForForm = useMemo(createSelectSettingForForm, []);
  const setting = useSelector(state => selectSettingForForm(state, id));

  const selectFormValueForSetting = useMemo(() => formValueSelector(EK.CONFIG_SETTINGS.state), []);
  const formValue = useSelector(state => selectFormValueForSetting(state, `${setting.id}`));

  const isValueDefaultOrUndefined = useMemo(() =>  {
    if (setting.multipleAllowed) {
      return !formValue || formValue.every(fv => setting.isValueDefaultOrUndefined(fv.value));
    } else {
      return setting.isValueDefaultOrUndefined(formValue);
    }
  }, [setting, formValue]);
  const isEdited = useMemo(() => {
    if (setting.multipleAllowed) {
      if (initialValue === undefined) {
        return formValue && formValue.size > 0;
      } else {
        return !formValue || initialValue.size !== formValue.size || initialValue.some(iv => {
          const found = formValue.find(fv => fv.id === iv.id);
          return !found || found.value !== iv.value;
        });
      }
    } else {
      return setting.isValueEditedAgainst(formValue, initialValue);
    }
  }, [setting, formValue, initialValue]);

  const dispatch = useDispatch();
  const resetToDefaultValue = useCallback(() => {
    if (initialValue !== undefined) {
      if (setting.multipleAllowed) {
        dispatch(change(EK.CONFIG_SETTINGS.state, setting.id, null));
      } else {
        dispatch(change(EK.CONFIG_SETTINGS.state, setting.id, setting.defaultValue));
      }
    } else {
      dispatch(clearFields(EK.CONFIG_SETTINGS.state, true, true, setting.id));
    }
  }, [dispatch, setting, initialValue]);
  const undoFormValueChange = useCallback(() => {
    dispatch(clearFields(EK.CONFIG_SETTINGS.state, true, true, setting.id));
  }, [dispatch, setting]);

  const [resetButtonReference, resetButtonFloating, resetButtonFloatingStyle] = useFloatingState({
    placement: 'bottom'
  });
  const isHoveringResetButton = useIsHovering(resetButtonReference, { delayEnter: 500 });

  const [undoButtonReference, undoButtonFloating, undoButtonFloatingStyle] = useFloatingState({
    placement: 'bottom'
  });
  const isHoveringUndoButton = useIsHovering(undoButtonReference, { delayEnter: 500 });


  useEffect(() => {
    if (rowRoot.current) {
      setSize && setSize(index, rowRoot.current.getBoundingClientRect().height);
    }
  }, [index, setSize]);

  return (
    <Flex ref={rowRoot} flexDirection='row' p={4}  bg={isEdited && 'primary.0' || undefined}>
      <Flex flexDirection='row' alignItems='center' width='7rem' justifyContent='space-between' mr={4}>
        {
          !isValueDefaultOrUndefined && !setting.multipleAllowed &&
          <Button
            ref={el => resetButtonReference.current = el}
            transparent
            subtle
            large
            error
            icon='reset'
            onClick={resetToDefaultValue}
            type='button'
          /> || null
        }
        {
          isHoveringResetButton && ReactDOM.createPortal(
            <Tooltip ref={resetButtonFloating} style={resetButtonFloatingStyle} size='small' priority={LAYER_PRIORITY.MODAL_DROPDOWN}>
              <TooltipBody>
                <TooltipSection small inverse>Clear the saved value and reset it to the default value</TooltipSection>
              </TooltipBody>
            </Tooltip>,
            document.querySelector(CONTAINERS.TOOLTIP)
          )
        }

        {
          isEdited && !setting.multipleAllowed &&
          <Button
            ref={el => undoButtonReference.current = el}
            transparent
            subtle
            large
            primary
            icon='history'
            onClick={undoFormValueChange}
            type='button'
          /> || null
        }
        {
          isHoveringUndoButton && ReactDOM.createPortal(
            <Tooltip ref={undoButtonFloating} style={undoButtonFloatingStyle} size='small' priority={LAYER_PRIORITY.MODAL_DROPDOWN}>
              <TooltipBody>
                <TooltipSection small inverse>Undo any unsaved changes and reset the value to the last saved value</TooltipSection>
              </TooltipBody>
            </Tooltip>,
            document.querySelector(CONTAINERS.TOOLTIP)
          )
        }
      </Flex>
      <SettingDetails>
        <SettingName color='gray.7'>
          { searchWords && <BaseSearchHighlighter searchWords={searchWords}>{ setting.name }</BaseSearchHighlighter> || setting.name }
        </SettingName>
        <SettingCreoName>
          {
            isEdited && <Label success mr={2}>Edited</Label>
          }
          {
            initialValue !== undefined &&
            <Label primary mr={2}>In Profile</Label>
          }
          <SettingCreoNameLabel secondary>
            { searchWords && <BaseSearchHighlighter searchWords={searchWords}>{ setting.settingName }</BaseSearchHighlighter> || setting.settingName }
          </SettingCreoNameLabel>
        </SettingCreoName>
        <SettingDescription>
          { searchWords && <BaseSearchHighlighter searchWords={searchWords}>{ setting.description }</BaseSearchHighlighter> || setting.description }
        </SettingDescription>
      </SettingDetails>
      <Flex flexDirection='column' justifyContent='center' width='40%' mr={4}>
        {
          isVisible && (
            setting.multipleAllowed ?
              <FieldArray name={setting.id} setting={setting} isPerforming={isPerforming} component={renderMultipleAllowedSettingFormFields} /> :
              <SettingFormField setting={setting} isPerforming={isPerforming} />
          ) || null
        }
      </Flex>
    </Flex>
  );
};

const SettingFieldRow = ({ style, ...props }) => (
  <Flex flexDirection='column' style={style}>
    <SettingFieldItem { ...props } />
  </Flex>
);

const createSelectCategoryNameSelector = () => createSelector(
  selectSortedNormalizedConfigSettingCategories(),
  (_, categoryId) => categoryId,
  (categories, categoryId) => categories.getIn([categoryId, 'name'])
);

const SettingsCounts = ({ categoryId, searchTerm, total, filtered }) => {
  const selectCategoryName = useMemo(
    createSelectCategoryNameSelector,
    []
  );

  const categoryName = useSelector(state => selectCategoryName(state, categoryId));

  const dispatch = useDispatch();
  const clearFilters = useCallback(() => {
    dispatch(reset(`filters.${EK.CONFIG_SETTINGS.state}`));
  }, [dispatch]);

  let header;
  let subheader;

  if (categoryId) {
    header = categoryName;
    subheader = `${pluralize('setting', total)} in category`;
  } else if (searchTerm) {
    header = <React.Fragment>Showing results for <Text as='span' color='primary.4' fontWeight='600'>{ searchTerm }</Text></React.Fragment>;
    subheader = `matching ${pluralize('setting', total)}`;
  } else {
    header = 'Current Profile Values';
    subheader = `saved or edited ${pluralize('setting', total)} in profile`;
  }

  return (
    <Flex flexDirection='row' alignItems='center' height={80} p={4} bg='gray.0'>
      <Flex flexDirection='column' justifyContent='center' style={{ flexGrow: 1 }}>
        <Text fontSize={3} color='gray.7'>{ header }</Text>
        <Text fontSize={1} color='gray.6'>
          {
            total !== filtered &&
            <React.Fragment>Filtered <Text as='span' color='primary.4' fontWeight='600'>{ filtered }</Text> {pluralize('setting', filtered)} out of </React.Fragment>
          }
          <strong>{ total }</strong> { subheader }
        </Text>
      </Flex>
      {
        total !== filtered &&
        <Flex flexDirection='column' justifyContent='center'>
          <Button
            transparent
            // subtle
            error
            small
            type="button"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </Flex>
      }
    </Flex>
  );
};

const createSelectSettingIdsForSearchTermSelector = () => createSelector(
  selectNormalizedConfigSettings(),
  (_, searchTerm) => searchTerm,
  (settings, searchTerm) => {
    return settings.reduce((output, setting) => setting.doesMatchSearchTerm(searchTerm) ? output.push(setting.id) : output, List());
  }
);

const createSelectSettingIdsForCategorySelector = () => createSelector(
  selectNormalizedConfigSettings(),
  (_, categoryId) => categoryId,
  (settings, categoryId) => {
    return settings.reduce((output, setting) => setting.get(EK.CONFIG_SETTING_CATEGORIES.single) === categoryId ? output.push(setting.id) : output, List());
  }
);

function isKeysEqual(prev, next) {
  return is(prev.keySeq(), next.keySeq());
}

const createImmutableKeysEqualSelector = createSelectorCreator(
  defaultMemoize,
  isKeysEqual
);

const createSelectFormValuesKeys = () => createImmutableKeysEqualSelector(
  getFormValues(EK.CONFIG_SETTINGS.state),
  formValues => formValues.keySeq()
);

const createSelectFilteredSettings = () => createSelector(
  getFormValues(`filters.${EK.CONFIG_SETTINGS.state}`),
  selectNormalizedConfigSettings(),
  getFormValues(EK.CONFIG_SETTINGS.state),
  getFormInitialValues(EK.CONFIG_SETTINGS.state),
  (_, settingIds) => settingIds,
  (filters, settings, formValues, initialValues, settingIds) => {
    const showSaved = filters.get('showSaved');
    const showEdited = filters.get('showEdited');
    const showUntouched = filters.get('showUntouched');
    const settingMatchQuery = {
      name: filters.get('filterTerm'),
      description: filters.get('filterTerm'),
      settingName: filters.get('filterTerm'),
    };

    if (
      (filters.get('filterTerm') === '' || !filters.get('filterTerm')) &&
      (showSaved && showEdited && showUntouched)
    ) {
      return settingIds;
    } else {
      return settingIds.filter(id => {
        const setting = settings.get(id);
        return setting.doesMatchQuery(settingMatchQuery) && (
          (showSaved && showEdited && showUntouched) ||
          (showSaved && initialValues.has(id)) ||
          (showEdited && setting.isValueEditedAgainst(formValues.get(id), initialValues.get(id))) ||
          (showUntouched && !initialValues.has(id) && setting.isValueDefaultOrUndefined(formValues.get(id)))
        );
      });
    }
  }
);

const ConfigProfileSettingsForm = ({ initialValues, categoryId, searchTerm, fetchSettingsForCategory, handleSubmit, isFetching, isPerforming, height }) => {
  const listRef = useRef();
  const sizeList = useRef(List());

  const renderedItemsRef = useRef({
    // default from the function
    overscanStartIndex: -1,
    overscanStopIndex: -1,
    visibleStartIndex: -1,
    visibleStopIndex: -1,
  });

  const setRenderedItems = useCallback(state => renderedItemsRef.current = state, []);

  const setSize = useCallback((index, size) => {
    // only update sizeList and reset cache if an actual value changed
    if (sizeList.current.get(index) !== size) {
      sizeList.current = sizeList.current.set(index, size);
      if (listRef.current) {
        // clear cache and re-render
        listRef.current.resetAfterIndex(index);
      }
    }
  }, []);

  const getSize = useCallback(index => sizeList.current.get(index) || 120, []);

  // Increases accuracy by calculating an average row height
  // Fixes the scrollbar behaviour described here: https://github.com/bvaughn/react-window/issues/408
  const calcEstimatedSize = useCallback(() => {
    return sizeList.current.reduce((estimatedHeight, itemHeight) => estimatedHeight + itemHeight, 0) / sizeList.current.size;
  }, []);

  useEffect(() => {
    if (categoryId) {
      fetchSettingsForCategory(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    sizeList.current = List();
    if (listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [categoryId, searchTerm]);

  const selectSettingIdsForSearchTerm = useMemo(
    createSelectSettingIdsForSearchTermSelector,
    []
  );

  const selectSettingIdsForCategory = useMemo(
    createSelectSettingIdsForCategorySelector,
    []
  );

  const selectFormValuesKeys = useMemo(
    createSelectFormValuesKeys,
    []
  );

  const matchingSettingIds = useSelector(state => {
    if (searchTerm) {
      return selectSettingIdsForSearchTerm(state, searchTerm);
    } else if (categoryId) {
      return selectSettingIdsForCategory(state, categoryId);
    } else {
      return null;
    }
  });

  const formValuesSettingIds = useSelector(state => selectFormValuesKeys(state));

  const searchWords = useMemo(() => [searchTerm], [searchTerm]);
  const settingIds = useMemo(() => {
    if (matchingSettingIds) {
      const ids = matchingSettingIds.reduce(({ included, excluded }, id) => ({
        included: formValuesSettingIds.includes(id) ? [ ...included, id ] : included,
        excluded: !formValuesSettingIds.includes(id) ? [ ...excluded, id ] : excluded,
      }), { included: [], excluded: [] });
      return List([ ...ids.included, ...ids.excluded ]);
    } else {
      // should we sort this as well?
      return formValuesSettingIds.toList();
    }
  }, [matchingSettingIds]);

  const selectFilteredSettings = useMemo(
    createSelectFilteredSettings,
    []
  );

  const finalSettingIds = useSelector(state => selectFilteredSettings(state, settingIds));

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <SettingsCounts
        categoryId={categoryId}
        searchTerm={searchTerm}
        total={settingIds.size}
        filtered={finalSettingIds.size}
      />
      {
        isFetching ? (
          [...Array(5)].map((_, index) => <SkeletonSettingFieldRow key={index} index={index} height={120} />)
        ) : (
          finalSettingIds.size > 0 ? (
            <VariableItemSizeList
              ref={listRef}
              height={height - 80}
              itemCount={finalSettingIds.size}
              itemSize={getSize}
              overscanCount={4}
              estimatedItemSize={calcEstimatedSize()}
              onItemsRendered={setRenderedItems}
            >
              {
                ({ index, ...props }) => (
                  <SettingFieldRow
                    { ...props }
                    index={index}
                    id={finalSettingIds.get(index)}
                    initialValue={initialValues.get(finalSettingIds.get(index))}
                    isPerforming={isPerforming}
                    isVisible={index >= renderedItemsRef.current.overscanStartIndex && index <= renderedItemsRef.current.overscanStopIndex}
                    searchWords={searchWords}
                    setSize={setSize}
                  />
                )
              }
            </VariableItemSizeList>
          ) : (
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              height={`${height - 80}px`}
            >
              {
                settingIds.size === 0 ? (
                  searchTerm ? (
                    <React.Fragment>
                      <Text fontSize={3} color='gray.6' mb={2}>
                        No matching settings found for <Text as='span' fontWeight='600' color='primary.4'>{ searchTerm }</Text>
                      </Text>
                      <Text fontSize={2} color='gray.6'>
                        Please try searching for something else
                      </Text>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Text fontSize={3} color='gray.6' mb={2}>
                        No settings currently in profile
                      </Text>
                      <Text fontSize={2} color='gray.6'>
                        Start building your profile by saving values for settings
                      </Text>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <Text fontSize={3} color='gray.6' mb={2}>
                      No matching settings found for your filters
                    </Text>
                    <Text fontSize={2} color='gray.6'>
                      Try changing your filters to find what you&apos;re looking for
                    </Text>
                  </React.Fragment>
                )
              }
            </Flex>
          )
        )
      }
    </Box>
  );
};

const enhance = compose(
  reduxForm({
    form: `${EK.CONFIG_SETTINGS.state}`,
  }),
  connect(
    undefined, // mapStateToProps
    dispatch => ({
      fetchSettingsForCategory(categoryId) { dispatch(processFetchAllConfigSettingsForCategory(categoryId)); },
    })
  )
);

export default enhance(ConfigProfileSettingsForm);
