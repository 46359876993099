import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';

import selectEntities from '../../../modules/entities/selectors';

import MultiSearchSelect from '../../../components/form/MultiSearchSelect';

const createSelectEntityFromEntityKey = () => createSelector(
  selectEntities(),
  (_, ek) => ek.state,
  (_, ek, eFilter) => eFilter,
  (entities, eks, eFilter) => eFilter && entities.get(eks).filter(eFilter) || entities.get(eks)
);

const BaseMultiEntitySearchSelect = ({ forwardRef, ek, eFilter, ...props }) => {
  const selectEntityFromEntityKey = useMemo(
    createSelectEntityFromEntityKey,
    []
  );

  const entities = useSelector(state => selectEntityFromEntityKey(state, ek, eFilter));

  const options = useMemo(() => entities.reduce((output, entity) => output.push(entity.toOption()), List()), [entities]);

  return <MultiSearchSelect { ...props } ref={forwardRef} options={options} />;
};

const MultiEntitySearchSelect = React.forwardRef((props, ref) => <BaseMultiEntitySearchSelect forwardRef={ref} { ...props } />);

export default MultiEntitySearchSelect;