import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { PipingModulePipesBendsEditPath } from '../../../paths';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import TextCell from '../../../components/grid/TextCell';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processArchiveBendFamiliesForPipeFamily,
  processFetchAllBendFamiliesForPipeFamily,
  processUnarchiveBendFamiliesForPipeFamily,
  processCreateBendFamilyForPipeFamily,
  processCopyBendFamilyForPipeFamily,
  processEditBendFamilyForPipeFamily,
  processDeleteBendFamilyForPipeFamily,
} from '../../../entities/Piping/BendFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';

import { Dialog } from 'primereact/dialog';

import { InputText } from 'primereact/inputtext';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';
import Header from './Header';

import {
  nameBody,
  dialogFooter,
  deleteDialogFooter,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import { selectCurrentPipeId } from '../../PipesEditSwitch/selectors';

import { selectCurrentFilteredPipesBends } from '../../../entities/Piping/BendFamilies/selectors';
import { BendDialog, DeleteBendDialog } from '../../../containers/dialog/templates/BendDialog';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredPipesBends(),
  selectCurrentPipeId(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    pipeId,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      pipeId,
    };
  },
);

const BLANK_BEND = {
  id: '',
  name: '',
};

export const VALIDATION_FIELDS = {
  name: true
};

function PipesBendsGrid(ownProps) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_BEND);


  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_BEND);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_BEND);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_BEND);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateBendFamilyForPipeFamily(ownProps.match.params.id, editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyBendFamilyForPipeFamily(ownProps.match.params.id, editedRow));
    } else {
      dispatch(processEditBendFamilyForPipeFamily(ownProps.match.params.id, editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveBendFamiliesForPipeFamily(ownProps.match.params.id, [rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveBendFamiliesForPipeFamily(ownProps.match.params.id, [rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, always use a modal to warn the user
    dispatch(processDeleteBendFamilyForPipeFamily(ownProps.match.params.id, currentEditingRow.id));
    setCurrentEditingRow(BLANK_BEND);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };


  const gotoBendsInstance = (bendId) => {
    const link = PipingModulePipesBendsEditPath.generate({id: ownProps.match.params.id, familyId: bendId,});
    dispatch(push(link));
  };

  useEffect( () => {
    dispatch(processFetchAllBendFamiliesForPipeFamily(ownProps.match.params.id));
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header/>
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex
        style={{
          border: '1px solid #DEE2E6',
          borderRadius: '5px',
          height: '86%',
        }}
        flexDirection='column'
      >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          scrollable
          scrollHeight='flex'
        >
          <Column field='name' header='Name' body={(rowData) => nameBody(rowData, gotoBendsInstance)}></Column>
          <Column field='instancesCount' header='Instances' bodyStyle={{fontSize: '14px',}} style={{ width: '33%'}} body={(rowData) => TextCell(rowData.instancesCount)} ></Column>
          <Column header=''
            style={{textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => gotoBendsInstance(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <BendDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
      />
      <DeleteBendDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default withRouter(PipesBendsGrid);
