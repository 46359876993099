import { take, put, fork, select } from 'redux-saga/effects';

import { processFetchAllAppearances } from '../../entities/Synchronize/Appearances/actions';
import { processFetchAllMapkeys } from '../../entities/Synchronize/Mapkeys/actions';

import { selectIsFetching } from '../../modules/utility/selectors';

import { signout } from '../../modules/auth/sagas';

import { isInitialLoadComplete } from '../Dashboard/actions';

export function* watchLoadSynchronizeModuleSaga() {
  try {
    yield put(processFetchAllAppearances());
    yield put(processFetchAllMapkeys());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadSynchronizeModuleSaga);
}
