import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';

export const convertToInsulationInstanceType = ({
  id,
  sizeProperty,
  fittingCategory,
  material,
  matlThickness,
  stockno,
  insulationType,
  isCreated=false,
  isEdited=false,
  isDeleted=false,
}) => ({
  id,
  sizeProperty,
  fittingCategory,
  material,
  matlThickness,
  stockno,
  insulationType,
  isCreated,
  isEdited,
  isDeleted
});

export const saveCurrentIsulationInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty?.id,
        fittingCategoryId: item.fittingCategory?.id,
        materialId: item.material?.id,
        matlThickness: getUnitLength(item.matlThickness, currentUnitSystem),
        stockno: item.stockno,
        insulationType: item.insulationType,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty?.id,
        fittingCategoryId: item.fittingCategory?.id,
        materialId: item.material?.id,
        matlThickness: getUnitLength(item.matlThickness, currentUnitSystem),
        stockno: item.stockno,
        insulationType: item.insulationType,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};