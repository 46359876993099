const APH = 'API/PROCESS_HANDLER';

export const NON_ENTITY_CALL = 'NON_ENTITY_CALL';

export const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  ARCHIVE: 'PATCH',
  UNARCHIVE: 'PATCH',
};

export default APH;
