import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';

import UnitSystemSchema from '../../Synchronize/UnitSystems/schema';

const profileLibrarySchema = baseSchema(EK.PROFILE_LIBRARIES.state, {
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export default profileLibrarySchema;
