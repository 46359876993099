import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';
import RDXTextInput from '../../../containers/form/RDXTextInput';

const FilterForm = props => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='sizeProperty'
        label='Size'
        ek={EK.SIZES}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='schedule'
        label='Schedule'
        ek={EK.SCHEDULES}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='stockno'
        label='Stock no'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='mccsCode'
        label='MCCS Code'
        type='text'
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.SPEC_PIPE_INSTANCES.state}` })
);

export default enhance(FilterForm);
