import React, { useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import Box from '../../../../components/common/Box';
import Tabs from '../../../../components/common/Tabs';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import OrganizationSettingsForm from '../../../form/templates/OrganizationSettingsForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import {
  SETTING_PRODUCTS,
  SETTING_PRODUCTS_LIST,
} from '../../../../entities/Settings/model';
import { selectNormalizedSettings } from '../../../../entities/Settings/selectors';

import { MODAL_SIZES } from '../../Modal';

const createSelectInitialValues = () => createSelector(
  selectNormalizedSettings(),
  (_, product) => product,
  (settings, product = null,) => ({
    settings: settings.filter(setting => setting.product === product)
  })
);

const OrganizationSettingsModal = ({ isPerforming, product : initialProduct }) => {
  const selectInitialValues = useMemo(
    createSelectInitialValues,
    []
  );

  const [product, setProduct] = useState(initialProduct || SETTING_PRODUCTS.SYNCHRONIZE);

  const initialValues = useSelector(state => selectInitialValues(state, product));

  return (
    <Box width={MODAL_SIZES.XLARGE} height='100%'>
      <ModalTitle><strong>Product &amp; Module</strong> Settings</ModalTitle>
      <Tabs tabs={SETTING_PRODUCTS_LIST} activeTabId={product} onChange={setProduct} />
      <OrganizationSettingsForm
        height='33rem'
        enableReinitialize
        initialValues={initialValues}
        active={product}
        isPerforming={isPerforming}
        // onRDXSubmit={onSave}
      />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
});

const enhance = compose(
  connect(
    mapStateToProps,
    // {
    //   prepareModal: prepareBendMachineModal,
    //   onCreate: processCreateBendMachineFamilyForPipeFamily,
    //   onUpdate: processEditBendMachineFamilyForPipeFamily,
    //   onDelete: processDeleteBendMachineFamilyForPipeFamily,
    // }
  ),
);

export default enhance(OrganizationSettingsModal);
