import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import BendInstanceSchema from './schema';
import EK from '../../keys';
import { newBendInstancesArray } from './constants';

import { formValueSelector } from 'redux-form/immutable';

export const selectNormalizedBendInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.BEND_INSTANCES.state)
);

export const selectBendInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.BEND_INSTANCES.state), [BendInstanceSchema], entities.delete('bendFamilies'))
);

const bendInstanceFilterForm = formValueSelector(`filters.${EK.BEND_INSTANCES.state}`);

export const selectCurrentDenormalizedFilteredBendInstances = (bendFamilyId) => createSelector(
  selectNormalizedBendInstances(),
  selectSidebarIsFiltering(),
  state => bendInstanceFilterForm(state, 'sizeProperty', 'ratio', 'minAngle', 'maxAngle'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.toList().filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [BendInstanceSchema], entities.delete(EK.BENDS.state)).toList().filter(item => item.bendFamilyId == bendFamilyId);
  }
);

const selectCurrentBendInstances = (bendFamilyId) => createSelector(
  selectNormalizedBendInstances(),
  (bends) => bends.toList().filter(item => item.bendFamilyId == bendFamilyId)
);

export const selectBendInstanceCount = (bendFamilyId) => createSelector(
  selectCurrentBendInstances(bendFamilyId),
  (instances) => instances && instances.size || 0
);

export const selectCurrentFilteredBendInstances = (bendFamilyId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentDenormalizedFilteredBendInstances(bendFamilyId),
  (isFiltering, instances) =>
    isFiltering ? instances.toArray() : instances.toArray().concat(newBendInstancesArray)
);