import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllCombinedStateTypes,
  createCombinedStateTypes,
  copyCombinedStateTypes,
  editCombinedStateTypes,
  deleteCombinedStateTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.COMBINED_STATES);

export const processFetchAllCombinedStates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllCombinedStateTypes,
    '/standardize/combined_states'
  );

export const processCreateCombinedState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createCombinedStateTypes,
    '/standardize/combined_states',
    {
      data: {
        combinedStates: [{
          name: data.name,
          description: data.description
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Combined State successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Combined State',
      },
    }
  );

export const processEditCombinedState = (CombinedStateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editCombinedStateTypes,
    `/standardize/combined_states/${CombinedStateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteCombinedState = (CombinedStateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteCombinedStateTypes,
    `/standardize/combined_states/${CombinedStateId}?with_full_detail=true`,
    {
      deleteKey: EK.COMBINED_STATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Combined State deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Combined State',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyCombinedState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyCombinedStateTypes,
    '/standardize/combined_states/copy',
    {
      data: {
        combinedStates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Explode State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Explode State',
      },
    }
  );