import React from 'react';
import { connect } from 'react-redux';

import { showNotice, hideNotice } from '../../../modules/notices/actions';

class Notice extends React.Component {
  componentDidMount = () => {
    const { id, message, showNotice, hideNotice, ...rest } = this.props;

    showNotice(id, message, rest);
  }

  componentWillUnmount = () => {
    this.props.hideNotice(this.props.id);
  }
  
  render() { return null; }
}

const mapDispatchToProps = dispatch => ({
  showNotice(id, message, options) { dispatch(showNotice(id, message, options)); },
  hideNotice(id) { dispatch(hideNotice(id)); },
});

export default connect(
  null,
  mapDispatchToProps,
)(Notice);
