import { Set } from 'immutable';

const mergeEntitiesWithDefaultedTracking = (state, incomingState) => state.mergeWith((oldModel, newModel) => {
  const defaulted = oldModel.defaulted.merge(newModel.defaulted);
  return defaulted.reduce((mergingModel, key) => {
    if (mergingModel.get(key) !== undefined) {
      // if the new incoming model has a value that is not undefined, take the new value
      return mergingModel;
    } else if (oldModel.get(key) !== undefined) {
      // if the new incoming model has no defined value, use the existing model's value
      return mergingModel.set(key, oldModel.get(key));
    } else {
      // if the old model also has no value defined, then we need to add this key to 
      // the Set of values that were defaulted
      return mergingModel.set('defaulted', mergingModel.get('defaulted').add(key));
    }
  }, newModel.set('defaulted', Set()));
}, incomingState);

export default mergeEntitiesWithDefaultedTracking;

/**
 * Example one:
 * Existing object: { a: 4, b: 7, c: 8, d: undefined } - Set('d')
 * Incoming object: { a: 3, b: 7, c: 1, d: 7 } - Set()
 * Result: Set()
 */

/**
  * Example two:
  * Existing object: { a: undefined, b: 2, c: undefined, d: 9 } - Set('a', 'c')
  * Incoming object: { a: 8, b: 4, c: undefined, d: 6 } - Set('c')
  * Result: Set('c')
  */

/**
   * Example three:
   * Existing object: { a: undefined, b: undefined, c: undefined, d: 4 } - Set('a', 'b', 'c')
   * Incoming object: { a: undefined, b: 2, c: undefined, d: undefined } - Set('a', 'c', 'd')
   * Result: Set('a', 'c')
   */