import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectNormalizedPipeFamilies } from '../../entities/Piping/PipeFamilies/selectors';

import { selectMasterCatalogUnitSystem } from '../../entities/Piping/PipingCatalogs/selectors';

import UnitSystemSchema from '../../entities/Synchronize/UnitSystems/schema';

import { PipingModulePipesEditPath } from '../../paths';
import PipeFamilySchema from '../../entities/Piping/PipeFamilies/schema';

export const selectCurrentPipeId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, { path: PipingModulePipesEditPath.url });
    return (match && match.params.id) || null;
  });

export const selectCurrentPipe = (pipeId) =>
  createSelector(
    selectNormalizedPipeFamilies(),
    selectEntities(),
    (pipes, entities) => {
      return denormalize(pipes.get(pipeId), PipeFamilySchema, entities);
    }
  );

export const selectCurrentPipeUnitSystem = (pipeId) =>
  createSelector(
    selectCurrentPipe(pipeId),
    selectEntities(),
    selectMasterCatalogUnitSystem(),
    (pipe, defaultMasterCatalogUnitSystem) =>
      pipe?.unitSystem || defaultMasterCatalogUnitSystem
  );

export const selectCurrentPipeInstanceAndChildrenSerializationSelector = () =>
  createSelector(selectCurrentPipeUnitSystem(), (unitSystem) => ({
    unitSystem,
  }));
