import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BackTemplate from '../../templates/Structures/Back';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../../Dashboard/selectors';

import { selectCurrentSpec } from '../../SpecsEditSwitch/selectors';

import { PipingModuleSpecsPipesEditPath } from '../../../paths';

const mapStateToProps = createSelector(
  selectIsFetching(),
  selectIsLoadingInitialData(),
  selectCurrentSpec(),
  (isFetching, isLoadingInitialData, spec) => ({
    isLoading: isFetching || isLoadingInitialData,
    to:
      spec &&
      spec.id &&
      PipingModuleSpecsPipesEditPath.generate({ id: spec.id }),
    label:
      (spec &&
        spec.specName &&
        `${spec.specName} // Pipes`) ||
      undefined,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BackTemplate);
