import { List, Set } from 'immutable';
import capitalize from 'lodash/capitalize';

import EntityRecord from '../../modules/entities/model';

import { USER_ROLE_TYPES } from '../../modules/permissions/constants';

import { PRODUCT_TIERS } from '../Organizations/model';

function generateRoleOption(role) {
  const capitalized = capitalize(role);
  return { value: role, label: capitalized, primary: capitalized };
}

// TODO: add function to check if org owner (ADD THE SAME THING FOR THE USER MODEL)
export default class CurrentUser extends EntityRecord({
  email: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  firstName: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  lastName: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  phoneNumber: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  role: {
    value: USER_ROLE_TYPES.USER,
    handleCompare: true,
  },
  activeFlag: {
    value: true,
    handleCompare: true,
  },
  hiddenFlag: {
    value: false,
    handleCompare: true,
  },
  hasModuleCabling: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModulePiping: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModuleFramework: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasAcceptedEula: {
    value: false,
  },
  passwordResetRequired: {
    value: false,
  },
  passwordResetToken: {
    value: null,
  },
  organization: {
    value: null,
    isEntity: true,
    handleCompare: true,
  },
  organizationInvites: {
    value: List(),
    isEntity: true,
    handleCompare: true,
  },
  emailVerified: {
    value: false,
    handleCompare: true,
  },
}) {
  get fullName() {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  get isAdmin() {
    return this.role === USER_ROLE_TYPES.ADMIN;
  }

  get isCollaborator() {
    return (
      this.role === USER_ROLE_TYPES.ADMIN ||
      this.role === USER_ROLE_TYPES.COLLABORATOR
    );
  }

  hasPipingModule() {
    return (
      this.getIn(['organization', 'modulePipingUserCount'], 0) > 0 &&
      this.hasModulePiping
    );
  }

  hasStandardizeModule() {
    return (
      this.getIn(['organization', 'productTier'], PRODUCT_TIERS.SYNCHRONIZE) !== PRODUCT_TIERS.SYNCHRONIZE
    );
  }

  getRoleAssignmentOptions() {
    if (this.isAdmin) {
      return List([
        USER_ROLE_TYPES.ADMIN,
        USER_ROLE_TYPES.COLLABORATOR,
        USER_ROLE_TYPES.USER,
      ]).map(generateRoleOption);
    } else if (this.isCollaborator) {
      return List([USER_ROLE_TYPES.COLLABORATOR, USER_ROLE_TYPES.USER]).map(
        generateRoleOption
      );
    } else {
      return List([USER_ROLE_TYPES.USER]).map(generateRoleOption);
    }
  }

  get allowedRoleAssignments() {
    if (this.isAdmin) {
      return Set([
        USER_ROLE_TYPES.ADMIN,
        USER_ROLE_TYPES.COLLABORATOR,
        USER_ROLE_TYPES.USER,
      ]);
    } else if (this.isCollaborator) {
      return Set([USER_ROLE_TYPES.COLLABORATOR, USER_ROLE_TYPES.USER]);
    } else {
      return Set([USER_ROLE_TYPES.USER]);
    }
  }
}
