import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import space from '../../../../assets/themes/base/space';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

import RDXCheckboxInput from '../../RDXCheckboxInput';
import RDXTextInput from '../../RDXTextInput';
import RDXUserRoleRadioInput from '../../RDXUserRoleRadioInput';

import ModuleLabel, { PRODUCT_MODULES } from '../../../../entities/Organizations/components/ModuleLabel';

const InviteFieldFormSectionContainer = styled(Flex)`
  align-items: center;
  flex-grow: 1;
`;

const Invite = styled(Flex)`
  flex-direction: row;
  align-items: center;
  height: 4rem;
  padding-left: ${space[4]};
  padding-right: ${space[4]};
  border-bottom: 1px solid ${colors.gray[0]};

  &:last-child {
    border-bottom: none;
  }
`;

const FlexibleInputContainer = styled(Box)`
  flex-grow: 1;
  justify-content: center;
  height: 100%;
`;

const InviteFieldModulePipingToggle = ({ checked }) => (
  <ModuleLabel module={PRODUCT_MODULES.PIPING} disabled={!checked} />
);

const InviteFieldModuleCablingToggle = ({ checked }) => (
  <ModuleLabel module={PRODUCT_MODULES.CABLING} disabled={!checked} />
);

const InviteFieldModuleFrameworkToggle = ({ checked }) => (
  <ModuleLabel module={PRODUCT_MODULES.FRAMEWORK} disabled={!checked} />
);

const InviteFieldModuleEcadToggle = ({ checked }) => (
  <ModuleLabel module={PRODUCT_MODULES.ECAD} disabled={!checked} />
);

const InviteFieldModuleReportingToggle = ({ checked }) => (
  <ModuleLabel module={PRODUCT_MODULES.REPORTING} disabled={!checked} />
);

// can't use form section because of this STUPID WEBPACK
const InviteFieldEmailFormSection = ({
  invite,
  isPerforming,
  ...rest
}) => (
  <InviteFieldFormSectionContainer flexDirection='row' style={{ justifyContent: 'center' }}  { ...rest }>
    <FlexibleInputContainer>
      <Field
        component={RDXTextInput}
        name={invite ? `${invite}.email` : 'email'}
        height='100%'
        placeholder='E-mail address'
        disabled={isPerforming}
        style={{ justifyContent: 'center' }}
      />
    </FlexibleInputContainer>
  </InviteFieldFormSectionContainer>
);

const InviteFieldPermissionsFormSection = ({
  invite,
  isPerforming,
  allowedRoles,
  ...rest
}) => {
  return (
    <Flex flexDirection='row' { ...rest }>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='33rem'>
        <Field
          component={RDXUserRoleRadioInput}
          name={invite ? `${invite}.role` : 'role'}
          allowed={allowedRoles}
          disabled={isPerforming}
        />
      </Flex>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='6rem'>
        <Field
          component={RDXCheckboxInput}
          name={invite ? `${invite}.hasModulePiping` : 'hasModulePiping'}
          toggleComponent={InviteFieldModulePipingToggle}
          disabled={isPerforming}
        />
      </Flex>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='6rem'>
        <Field
          component={RDXCheckboxInput}
          name={invite ? `${invite}.hasModuleCabling` : 'hasModuleCabling'}
          toggleComponent={InviteFieldModuleCablingToggle}
          disabled={isPerforming}
        />
      </Flex>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='6rem'>
        <Field
          component={RDXCheckboxInput}
          name={invite ? `${invite}.hasModuleFramework` : 'hasModuleFramework'}
          toggleComponent={InviteFieldModuleFrameworkToggle}
          disabled={isPerforming}
        />
      </Flex>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='6rem'>
        <Field
          component={RDXCheckboxInput}
          name={invite ? `${invite}.hasModuleEcad` : 'hasModuleEcad'}
          toggleComponent={InviteFieldModuleEcadToggle}
          disabled={isPerforming}
        />
      </Flex>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' width='6rem' pr={2}>
        <Field
          component={RDXCheckboxInput}
          name={invite ? `${invite}.hasModuleReporting` : 'hasModuleReporting'}
          toggleComponent={InviteFieldModuleReportingToggle}
          disabled={isPerforming}
        />
      </Flex>
    </Flex>
  );
};

const OrganizationUserInviteForm = ({ handleSubmit, isPerforming, allowedRoles }) => (
  <Box as="form" onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto' }}>
    <Invite>
      <InviteFieldEmailFormSection isPerforming={isPerforming} allowedRoles={allowedRoles} />
    </Invite>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: `${EK.ORGANIZATION_USER_INVITES.state}Edit`,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(OrganizationUserInviteForm);

export { InviteFieldEmailFormSection, InviteFieldPermissionsFormSection };
