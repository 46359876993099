import React, { useCallback } from 'react';

import Flex from '../../../common/Flex';
import Text from '../../../common/Text';

import BaseInput from '../../BaseInput';
import BaseSelectRelativableContainer from '../../BaseSelectRelativableContainer';
import BaseSelectDefaultEditableCurrentOptionTemplate from '../../BaseSelectDefaultEditableCurrentOptionTemplate';

const BaseCurrentHiddenValue = ({ forwardRef, isLoading, getInputProps, inputValue, name, placeholder, currentHighlightedItem, isOpen, setSelectState, itemHeight }) => {
  const onFocus = useCallback(() => {
    setSelectState(prev => ({ ...prev, isOpen: true }));
  }, [setSelectState]);
  const onClick = useCallback(e => {
    e.stopPropagation();

    if (forwardRef && forwardRef.current) {
      if (isOpen) {
        setSelectState(prev => ({ ...prev, isOpen: false }));
      } else {
        forwardRef && forwardRef.current && forwardRef.current.focus();
      }
    }
  }, [forwardRef, setSelectState, isOpen]);

  return (
    <Flex flexDirection='row' alignItems='center' height='100%' cursor='pointer'>
      <BaseSelectRelativableContainer.Parent onClick={onClick}>
        <BaseSelectDefaultEditableCurrentOptionTemplate isLoading={isLoading} item={currentHighlightedItem} itemHeight={itemHeight} invisible>
          <BaseInput 
            { 
              ...getInputProps({ 
                placeholder: isOpen && currentHighlightedItem && currentHighlightedItem.primary || placeholder,
                name,
                value: inputValue,
                onFocus,
                ref: forwardRef,
              }) 
            } 
          />
        </BaseSelectDefaultEditableCurrentOptionTemplate>
        {
          <BaseSelectRelativableContainer.Child>
            <BaseSelectDefaultEditableCurrentOptionTemplate isLoading={isLoading} item={currentHighlightedItem}>
              <Text color='gray.7' fontSize={2}>{ inputValue }</Text>
            </BaseSelectDefaultEditableCurrentOptionTemplate>
          </BaseSelectRelativableContainer.Child>
        }
      </BaseSelectRelativableContainer.Parent>
    </Flex>
  );
};

const CurrentHiddenValue = React.forwardRef((props, ref) => <BaseCurrentHiddenValue forwardRef={ref} { ...props } />);

export default CurrentHiddenValue;
