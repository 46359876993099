import { toast } from 'react-toastify';

import scheduleClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllSchedulesTypes, saveSchedulesTypes } from './constants';

export const processFetchAllSchedules = () => scheduleClient(REQUEST_TYPES.GET, 'plural', fetchAllSchedulesTypes, '/piping/schedules');

export const processSaveSchedules = (data) => scheduleClient(REQUEST_TYPES.PATCH, 'plural', saveSchedulesTypes, '/piping/schedules/collection?diff_result=true', {
  data: { schedulesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
