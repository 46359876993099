import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';
import fontSizes from '../../../assets/themes/base/fontSizes';

import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import Text from '../../common/Text';

const Selection = styled(Flex).attrs(_ => ({
  borderRadii: 1,
  bg: 'gray.0',
  mr: 2,
}))`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.gray[4]};
  color: ${colors.gray[7]};
  padding: ${space[1]} ${space[2]};
  font-size: ${fontSizes[2]};
  font-weight: 600;
  vertical-align: middle;

  ${
  ({ isCapitalized }) => isCapitalized && css`
      text-transform: capitalize;
    `
}
`;

const DefaultText = styled(Text)`
  color: ${colors.gray[4]};
  font-size: ${fontSizes[2]};
  ${
  ({ isCapitalized }) => isCapitalized && css`
      text-transform: capitalize;
    `
}
`;

const BaseMultiOptionValue = ({ selectedItems, selectItem, isCapitalized, ...rest }) => (
  <Box { ...rest }>
    {
      selectedItems && selectedItems.size > 0 && selectedItems.map(item => (
        <Selection key={item.primary} isCapitalized={isCapitalized}>
          <Flex flexDirection='column' justifyContent='center' alignItems='center' p={1}>{ item.primary }</Flex>
          { selectItem && !item.disabled && <Flex cursor='pointer' flexDirection='column' justifyContent='center' alignItems='center' p={1} onClick={() => selectItem(item)}><Icon name='close' /></Flex> || null}
        </Selection>
      )) || <DefaultText isCapitalized={isCapitalized}>No items selected</DefaultText>
    }
  </Box>
);

export default BaseMultiOptionValue;

