import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';

export const convertToMiterInstanceType = ({
  id,
  sizeProperty,
  radius,
  minAngle,
  maxAngle,
  cutNumber,
  isEdited=false,
  isDeleted=false,
  isCreated=false,
}) => ({
  id,
  sizeProperty,
  radius,
  minAngle,
  maxAngle,
  cutNumber,
  isEdited,
  isDeleted,
  isCreated,
});

export const saveCurrentMiterInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty?.id,
        radius: item.radius,
        cutNumber: item.cutNumber,
        minAngle: item.minAngle,
        maxAngle: item.maxAngle,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty?.id,
        radius: getUnitLength(item.radius, currentUnitSystem),
        cutNumber: item.cutNumber,
        minAngle: item.minAngle,
        maxAngle: item.maxAngle,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};