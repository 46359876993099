import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { selectCurrentFitting } from '../../SpecsEditSwitch/selectors';

import { PipingModuleSpecsFittingInstancesEditPath } from '../../../paths';

const mapStateToProps = createSelector(selectCurrentFitting(), (fitting) => ({
  title:
    (fitting && fitting.name) ||
    PipingModuleSpecsFittingInstancesEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
