import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import space from '../../../assets/themes/base/space';
import fontSizes from '../../../assets/themes/base/fontSizes';

import Flex from '../../common/Flex';

const StyledAppend = styled(Flex).attrs(({active}) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? 'primary.5' : 'gray.5',
}))`
  padding: 5px;
  height: 100%;
  padding-left: ${space[2]};
  font-size: ${fontSizes[2]};
  width: 3rem;

  float: right;

  ${
  ({ onClick }) => !!onClick && css`
      cursor: pointer;
    `
}

  ${
  ({ isCapitalized }) => isCapitalized && css`
      text-transform: capitalize;
    `
}
`;

const BaseAppend = ({ onClick, ...props }) => {
  const onClickPreventPropagationHandler = useCallback(e => {
    if (onClick) {
      e.stopPropagation();
      onClick(e);
    }
  }, [onClick]);

  return <StyledAppend { ...props } onClick={onClickPreventPropagationHandler} />;
};

export default BaseAppend;