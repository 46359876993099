import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';
import dimensionSchema from '../ProfileParameters/schema';
import parameterSchema from '../ProfileParameters/schema';

const profileTableColumnSchema = baseSchema(EK.PROFILE_TABLE_COLUMNS.state, {
  profileDimensionId: { schema: dimensionSchema, isId: true},
  profileParameterId: { schema: parameterSchema, isId: true},
});

export default profileTableColumnSchema;
