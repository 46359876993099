import React from 'react';

import theme from '../../../../assets/themes/theme';

const SecurityGraphic = ({
  id = 'security-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <circle fill={backgroundColor} cx="256" cy="256" r="252.5" />
      <g>
        <rect x="122.9" y="177.8" fill="#ACB3BA" width="268.8" height="176.8" />
        <path
          fill={theme.colors.gray[0]}
          d="M398.7,107H113.3c-7,0-12.7,5.7-12.7,12.7v60.2c0,7,5.7,12.7,12.7,12.7h285.3c7,0,12.7-5.7,12.7-12.7
			v-60.2C411.4,112.7,405.7,107,398.7,107z"
        />
        <path
          fill={theme.colors.gray[0]}
          d="M398.7,213.2H113.3c-7,0-12.7,5.7-12.7,12.7v60.2c0,7,5.7,12.7,12.7,12.7h285.3c7,0,12.7-5.7,12.7-12.7
			v-60.2C411.4,218.9,405.7,213.2,398.7,213.2z"
        />
        <path
          fill={theme.colors.gray[0]}
          d="M398.7,319.4H113.3c-7,0-12.7,5.7-12.7,12.7v60.2c0,7,5.7,12.7,12.7,12.7h285.3c7,0,12.7-5.7,12.7-12.7
			v-60.2C411.4,325.1,405.7,319.4,398.7,319.4z"
        />
      </g>
      <g>
        <g>
          <path
            fill={theme.colors.gray[7]}
            d="M347.5,342.1h-19.7v-42.4c0-13.6-11-24.6-24.6-24.6c-13.6,0-24.6,11-24.6,24.6v42.4h-19.7v-42.4
				c0-24.5,19.9-44.3,44.3-44.3c24.5,0,44.3,19.9,44.3,44.3V342.1z"
          />
        </g>
        <path
          fill={theme.colors.gray[4]}
          d="M355.5,330.8H250.8c-4.5,0-8.2,3.6-8.2,7.9v90.9c0,4.4,3.7,7.9,8.2,7.9h104.8c4.5,0,8.2-3.6,8.2-7.9v-90.9
			C363.7,334.4,360.1,330.8,355.5,330.8z"
        />
        <path
          fill={theme.colors.gray[7]}
          d="M312.9,374c0-5.4-4.4-9.7-9.7-9.7c-5.4,0-9.7,4.4-9.7,9.7c0,3.3,1.7,6,4.2,7.8l-2.5,22.3h16.2l-2.5-22.3
			C311.2,380,312.9,377.3,312.9,374z"
        />
      </g>
      <g>
        <circle fill={theme.colors.error[4]} cx="134.2" cy="148.1" r="14.5" />
        <circle fill={backgroundColor} cx="180.8" cy="148.1" r="14.5" />
      </g>
      <g>
        <circle fill={theme.colors.error[4]} cx="134.2" cy="256" r="14.5" />
        <circle fill={backgroundColor} cx="180.8" cy="256" r="14.5" />
      </g>
      <g>
        <circle fill={theme.colors.error[4]} cx="134.2" cy="363.9" r="14.5" />
        <circle fill={backgroundColor} cx="180.8" cy="363.9" r="14.5" />
      </g>
    </g>
  </svg>
);

export default SecurityGraphic;
