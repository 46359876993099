import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import selectEntities from '../../../modules/entities/selectors';
import BendMachineInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import EK from '../../keys';
import { newBendMachineInstanceRows } from './constants';

export const selectNormalizedBendMachineInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.BEND_MACHINE_INSTANCES.state)
);

export const selectCurrentBendMachineInstances = (familyId) => createSelector(
  selectNormalizedBendMachineInstances(),
  (instances) => instances.toList().filter(item => item.bendMachineFamilyId == familyId)
);
export const selectBendMachineInstanceCount = (familyId) => createSelector(
  selectCurrentBendMachineInstances(familyId),
  (instances) => instances.size
);

const bendMachineInstanceFilterForm = formValueSelector(`filters.${EK.BEND_MACHINE_INSTANCES.state}`);

export const selectCurrentFilteredBendMachineInstances = (familyId) => createSelector(
  selectCurrentBendMachineInstances(familyId),
  selectSidebarIsFiltering(),
  state => bendMachineInstanceFilterForm(state, 'sizeProperty'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [BendMachineInstanceSchema], entities.delete(EK.BEND_MACHINE_INSTANCES.state)).toList();
  }
);
export const selectCurrentBendMachineInstancesForPage = (familyId) => createSelector(
  selectCurrentFilteredBendMachineInstances(familyId),
  selectSidebarIsFiltering(),
  (instances, isFiltering) => isFiltering ? instances.toArray() : instances.toArray().concat(newBendMachineInstanceRows)
);
