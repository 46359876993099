import React from 'react';

import theme from '../../../../assets/themes/theme';

const MegaphoneGraphic = ({
  id = 'megaphone-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <circle fill={backgroundColor} cx="256" cy="256" r="252.5" />
      <path
        fill={theme.colors.gray[0]}
        d="M178.2,204.9v38.8v38.8c0,0,84.3,0,143.2,68.8V243.7V136C262.5,204.9,178.2,204.9,178.2,204.9z"
      />
      <path
        fill={theme.colors.error[4]}
        d="M331.2,124.2c-5.5,0-9.9,4.4-9.9,9.9v219.3c0,5.5,4.4,9.9,9.9,9.9c5.5,0,9.9-4.4,9.9-9.9V134
		C341.1,128.6,336.7,124.2,331.2,124.2z"
      />
      <path
        fill={theme.colors.error[4]}
        d="M93.8,204.8c-7.2,0-13,5.8-13,13v51.3c0,7.2,5.8,13,13,13h84.4v-77.3H93.8z"
      />
      <polygon
        fill={theme.colors.gray[0]}
        points="410.7,170.4 365.4,205.8 360.1,200.5 395.6,155.3 	"
      />
      <polygon
        fill={theme.colors.gray[0]}
        points="410.7,317 365.4,281.5 360.1,286.8 395.6,332.1 	"
      />
      <polygon
        fill={theme.colors.gray[0]}
        points="431.2,254.3 374.1,247.4 374.1,239.9 431.2,233 	"
      />
      <path
        fill={theme.colors.gray[7]}
        d="M180.7,380.6l-24.2-98.4h-41.1l24.9,101.2c0.6,2.6,3,4.5,5.7,4.5h29.1C178.8,387.8,181.6,384.3,180.7,380.6
		z"
      />
    </g>
  </svg>
);

export default MegaphoneGraphic;
