import { createSelector } from 'reselect';
import { List } from 'immutable';

import EK from '../../../../entities/keys';

import selectModal from '../../../../modules/modal/selectors';

import { selectNormalizedUsers } from '../../../../entities/Users/selectors';
import { selectNormalizedOrganizationUserInvites } from '../../../../entities/OrganizationUserInvites/selectors';

const selectOrganizationDirectory = () => createSelector(
  selectNormalizedOrganizationUserInvites(),
  selectNormalizedUsers(),
  (invitesMap, usersMap) => {
    const invites = invitesMap.toList().filter(invite => invite.isPending).sortBy(invite => invite.createdAt);
    let activeUsers = 0;
    let modulePipingUsers = 0;
    let moduleCablingUsers = 0;
    let moduleFrameworkUsers = 0;
    let moduleEcadUsers = 0;
    let moduleReportingUsers = 0;

    const users = usersMap.reduce(({ active, inactive }, user) => {
      activeUsers = user.activeFlag ? activeUsers + 1 : activeUsers;
      modulePipingUsers = user.hasModulePiping ? modulePipingUsers + 1 : modulePipingUsers;
      moduleCablingUsers = user.hasModuleCabling ? moduleCablingUsers + 1 : moduleCablingUsers;
      moduleFrameworkUsers = user.hasModuleFramework ? moduleFrameworkUsers + 1 : moduleFrameworkUsers;
      moduleEcadUsers = user.hasModuleEcad ? moduleEcadUsers + 1 : moduleEcadUsers;
      moduleReportingUsers = user.hasModuleReporting ? moduleReportingUsers + 1 : moduleReportingUsers;

      return {
        active: user.activeFlag ? active.push(user) : active,
        inactive: !user.activeFlag ? inactive.push(user) : inactive,
      };
    }, { active: List(), inactive: List() });

    return {
      accounts: invites.concat(users.active.sortBy(user => user.role)).concat(users.inactive.sortBy(user => user.role)),
      inviteModulePipingCount: invites.reduce((total, invite) => invite.hasModulePiping ? total + 1 : total, 0),
      inviteModuleCablingCount: invites.reduce((total, invite) => invite.hasModuleCabling ? total + 1 : total, 0),
      activeUserCount: activeUsers,
      activeUserModulePipingCount: modulePipingUsers,
      activeUserModuleCablingCount: moduleCablingUsers,
      activeUserModuleFrameworkCount: moduleFrameworkUsers,
      activeUserModuleReportingCount: moduleReportingUsers,
      activeUserModuleEcadCount: moduleEcadUsers,
    };
  }
);

const selectIsCurrentlyActiveModal = () => createSelector(
  selectModal(),
  modals => {
    const currentModal = modals.last();

    return currentModal.modalType && currentModal.modalType === 'ORGANIZATION_MODAL';
  }
);

export {
  selectOrganizationDirectory,
  selectIsCurrentlyActiveModal,
};
