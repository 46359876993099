
const PARAMETER_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;

export const areParamFieldsValid = (param) => {
  let validFields = {
    areFieldsValid: true,
    parameterName: '',
    valueType: '',
  };

  if (param.parameterName == '' || !PARAMETER_NAME_REGEX.test(param.parameterName)) { validFields.parameterName = 'p-invalid'; validFields.areFieldsValid = false; }
  if (param.valueType == '') { validFields.valueType == 'p-invalid'; validFields.areFieldsValid = false; }

  return validFields;
};

export const areDimFieldsValid = (dim) => {
  let validFields = {
    areFieldsValid: true,
    dimensionName: '',
  };

  if (dim.dimensionName == '' || !PARAMETER_NAME_REGEX.test(dim.dimensionName)) { validFields.dimensionName = 'p-invalid'; validFields.areFieldsValid = false; }

  return validFields;
};