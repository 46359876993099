import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

// prettier-ignore
const combinedStateSchema = baseSchema(
  EK.COMBINED_STATES.state,
);

export default combinedStateSchema;
