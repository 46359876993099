import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import UnitSchema from '../../Synchronize/Units/schema';

const parameterSchema = baseSchema(EK.PARAMETERS.state, {
  unit: { schema: UnitSchema, isId: true },
});

export default parameterSchema;