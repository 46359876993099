import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';

import Text from '../../common/Text';

const BaseCellDataItem = styled(Text).attrs(({ primary, secondary, variable }) => ({
  fontSize: secondary && 1 || undefined,
  $ellipsis: !variable,
}))`
  padding-bottom: ${space[1]};

  ${
  ({ variable }) => variable && css`
      /* Need to add these styles to specifically overwrite ag-grid defaults */
      white-space: normal;
    `
}

  ${
  ({ secondary }) => !secondary && css`
      padding-top: ${space[1]};
    `
}

  ${
  ({ isCapitalized }) => isCapitalized && css`
      text-transform: capitalize;
    `
}

  ${
  ({ clickable }) => clickable && css`
      color: ${colors.primary[4]} !important;
    `
}

  color: inherit;

  ${
  ({ disabled }) => disabled && css`
      color: ${colors.gray[4]};
    `
}
`;

export default BaseCellDataItem;