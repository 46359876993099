import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { hideModal } from '../../../../modules/modal/actions';

import {
  createSimplifiedRepTypes,
  copySimplifiedRepTypes,
  editSimplifiedRepTypes,
  deleteSimplifiedRepTypes,
} from '../../../../entities/Standardize/SimplifiedReps/constants';

export function* handleCloseSimplifiedRepModal() {
  yield put(hideModal());
}

export function* watchCloseSimplifiedRepModalSaga() {
  yield takeLatest(
    [
      createSimplifiedRepTypes.SUCCESS,
      copySimplifiedRepTypes.SUCCESS,
      editSimplifiedRepTypes.SUCCESS,
      deleteSimplifiedRepTypes.SUCCESS,
    ],
    handleCloseSimplifiedRepModal
  );
}

// final output saga
export default function* main() {
  yield all([fork(watchCloseSimplifiedRepModalSaga)]);
}
