/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Unclear why this is happening
// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

import history from './utils/routing/history';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// variables for Sentry
const SENTRY_WEB_APP_DSN = process.env.REACT_APP_SENTRY_WEB_APP_DSN;
const WEB_ENV = process.env.REACT_APP_WEB_ENV;
if (WEB_ENV){
  try {
    Sentry.init({
      dsn: SENTRY_WEB_APP_DSN,
      environment: WEB_ENV,
      integrations: [new BrowserTracing()],
      sampleRate: 1.0,
      tracesSampleRate: 1.0,
    });
  } catch (e) {
    //prevents incorrect key error from breaking application
    console.error(`${e.name}: ${e.message}`);
  }
}


// Import the CSS reset
// Sanitize resets the css based on developer experience
// Normalize resets the css based on the CSS specification
// They're both maintained in tandem
import 'sanitize.css';

import setup from './configuration/setup';
import configureStore from './store';
import App from './routes/App';

// Custom function to set up all the fun options shenanigans
// we'll be adding to this as we start testing more on staging
setup();

// Redux functions modified to configure the store
const store = configureStore();

// Set up the router, wrapping all Routes in the App component
// // Sync history and store, as the react-router-redux reducer
// // is under the non-default key ("routing"), selectLocationState
// // must be provided for resolving how to retrieve the "route" in the state
// import { selectLocationState } from './modules/utility/selectors';
// const history = syncHistoryWithStore(browserHistory, store, {
//   selectLocationState: selectLocationState(),
// });

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
  );
};


// Hot reloadable translation json files
// if (module.hot) {
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept('./i18n', () => {
//     render(translationMessages);
//   });
// }
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept();
}

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   Promise.all([
//     System.import('intl'),
//     System.import('intl/locale-data/jsonp/en.js'),
//   ]).then(() => render(translationMessages));
// } else {
//   render(translationMessages);
// }

// render the app
render();

if (window && window.navigator && window.navigator.serviceWorker) {
  window.navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}
