import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import BoltNutFamilySchema from '../BoltNutFamilies/schema';
import FittingCategorySchema from '../FittingCategories/schema';
import FittingCodeSchema from '../FittingCodes/schema';
import MaterialSchema from '../Materials/schema';
import EndTypeSchema from '../EndTypes/schema';
import SizeSchema from '../Sizes/schema';
import ScheduleSchema from '../Schedules/schema';
import UnitSystemSchema from '../../Synchronize/UnitSystems/schema';
import UnitSchema from '../../Synchronize/Units/schema';

const fittingFamilySchema = baseSchema(EK.FITTING_FAMILIES.state, {
  fittingInstances: { isId: true, idField: 'fittingInstanceIds', isList: true },
  boltNutFamily: { schema: BoltNutFamilySchema, isId: true },
  fittingCategory: { schema: FittingCategorySchema, isId: true },
  fittingCode: { schema: FittingCodeSchema, isId: true },
  material: { schema: MaterialSchema, isId: true },
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export const fittingInstanceSchema = baseSchema(EK.FITTING_INSTANCES.state, {
  schedule: { schema: ScheduleSchema, isId: true },
  weightUnit: { schema: UnitSchema, isId: true },
  fittingCode: { schema: FittingCodeSchema, isId: true },
  port0Size: { schema: SizeSchema, isId: true },
  port0EndType: { schema: EndTypeSchema, isId: true },
  port1Size: { schema: SizeSchema, isId: true },
  port1EndType: { schema: EndTypeSchema, isId: true },
  port2Size: { schema: SizeSchema, isId: true },
  port2EndType: { schema: EndTypeSchema, isId: true },
  fittingFamily: { schema: fittingFamilySchema, isId: true },
});

fittingFamilySchema.define({ fittingInstances: [ fittingInstanceSchema ] });

export default fittingFamilySchema;
