import { createSelector } from 'reselect';

import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import modelTemplateSchema from './schema';

export const selectNormalizedModelTemplates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get('modelTemplates')
  );
export const selectNormalizedModelTemplatesList = () =>
  createSelector(
    selectNormalizedModelTemplates(),
    (modelTemplates) => modelTemplates.toList()
  );

export const selectDenormalizedModelTemplatesList = () =>
  createSelector(
    selectEntities(),
    selectNormalizedModelTemplates(),
    (entities, modelTemplates) => denormalize(modelTemplates, [modelTemplateSchema], entities).toList().toArray()
  );