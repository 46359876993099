import { InternalError } from './errors';

/**
 * Evaluate a type descriptor to an FSA
 *
 * @function actionWith
 * @access private
 * @param {object} descriptor - A type descriptor
 * @param {array} args - The array of arguments for `payload` and `meta` function properties
 * @returns {object}
 */
function actionWith(descriptor, args) {
  try {
    descriptor.payload = typeof descriptor.payload === 'function' ?
      descriptor.payload(...args) :
      descriptor.payload;
  } catch (e) {
    descriptor.payload = new InternalError(e.message);
    descriptor.error = true;
  }

  try {
    descriptor.meta = typeof descriptor.meta === 'function' ?
      descriptor.meta(...args) :
      descriptor.meta;
  } catch (e) {
    delete descriptor.meta;
    descriptor.payload = new InternalError(e.message);
    descriptor.error = true;
  }

  return descriptor;
}

export default actionWith;
