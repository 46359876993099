import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectCurrentTable } from '../ProfileTables/selectors';
import { selectCurrentTableItems } from '../ProfileTableItems/selectors';

export const selectNormalizedRows = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_TABLE_ROWS.state)
);

export const selectCurrentTableRows = () => createSelector(
  selectCurrentTable(),
  selectNormalizedRows(),
  (tableId, rows) => rows.filter(item => item.table == tableId).toList().toArray()
);

export const selectCurrentTableRowsById = () => createSelector(
  selectCurrentTableRows(),
  selectCurrentTableItems(),
  (rows, items) => {
    const newRow = {
      selectionString: '',
      rowId: 'NEW_ROW',
      isTable: false,
      isCreated: false,
      isInvalid: false,
      isDeleted: false,
    };
    return rows.map(row => (
      {
        rowId: row.id,
        selectionString: row.selectionString,
        ...(items.filter(item =>
          item.row == row.id
        )).reduce((obj, colitem) =>
          (obj[colitem.column] = colitem.valueType == 'subtable' ? colitem.profileSubtable : colitem.value,
          {
            ...obj,
            isTable: colitem.valueType == 'subtable',
            isCreated: false,
            isInvalid: false,
            isDeleted: false,
            profileSubtableId: colitem.profileSubtableId,
          }),
        {})
      })).concat([newRow]);
  }
);