import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'APPEARANCES';

export const fetchAllAppearancesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const saveAppearancesTypes = generateAPHTypes(resource, 'COLLECTION');

export const BlankRow = { id: '', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, };

export const newAppearanceRows = [
  { id: 'NEW_ROW_1', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_2', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_3', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_4', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_5', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_6', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_7', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_8', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_9', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, },
  { id: 'NEW_ROW_10', color: { r: '255', g: '255', b: '255' }, name: '', isCreo: false, isDeleted: false, isCreated: false, isEdited: false, }
];