import React from 'react';

export default function MIcon({ size = '1em', style }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" style={style}>
      <path d="M28.5,18h-9c-1.1,0-2,0.9-2,2v10h3v-9h2v6h3v-6h2v9h3V20C30.5,18.9,29.6,18,28.5,18z" />
      <path
        d="M38,6H10c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28c2.2,0,4-1.8,4-4V10C42,7.8,40.2,6,38,6z M10,38V10h28v28H10z" />
    </svg>
  );
}
