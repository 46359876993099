import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';

import { processFetchIndividualPipeFamily } from '../../entities/Piping/PipeFamilies/actions';
import { processFetchAllPipeInstances } from '../../entities/Piping/PipeInstances/actions';

function SpecsEditSwitch (props) {
  const dispatch = useDispatch();

  useEffect( () => {
    // dispatch(processFetchAllPipeInstances(props.match.params.id));
    dispatch(processFetchIndividualPipeFamily(props.match.params.id));
  }, []);

  return <Switch {...props} pipeId={props.match.params.id} />;
}

export default withRouter(SpecsEditSwitch);