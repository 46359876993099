import truncate from 'lodash/truncate';

import EntityRecord from '../../../modules/entities/model';

export default class ConfigSettingCategory extends EntityRecord({
  name: {
    value: null,
  },
  description: {
    value: null,
  },
  isCustom: {
    value: false,
  },

  // placeholder for number of settings in category
  // set in selector, not set in database
  valuesCount: {
    value: 0,
  },
  editedValuesCount: {
    value: 0,
  },
}) {
  toOption() {
    return {
      id: this.id,
      value: this.id,
      label: this.name,
      primary: this.name,
      secondary: truncate(this.description),
    };
  }
}