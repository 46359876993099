import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Dialog
import { ParameterDialog, DeleteParameterDialog } from '../../../containers/dialog/templates/ParameterDialog';

// cell bodies
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import Text from '../../../components/common/Text';
import { convertToParametersType } from './actions';

// Header
import Flex from '../../../components/common/Flex';
import { StandardizeModuleParametersPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// validation
import { VALIDATION_FIELDS } from '../../../entities/Standardize/Parameters/model';
import { areFieldsValid, doRequiredFieldsExist } from './actions';

// Actions
import { processCreateParameter, processFetchAllParameters, processSaveParameters } from '../../../entities/Standardize/Parameters/actions';
import { convertListToString } from '../../../entities/Standardize/Parameters/model';

// selectors
import { selectStandardizeModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectCurrentParametersCount, selectCurrentFilteredParameters } from '../../../entities/Standardize/Parameters/selectors';
import MultiTypeCellRenderer from '../../../components/grid/MultiTypeCellRenderer';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredParameters(),
  selectCurrentParametersCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    totalMaterials,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length - 15 == 0));
    return {
      ...rest,
      data: (!isLoading && data?.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isLoading,
      hasValidLicense,
      canCollaborate,
      totalMaterials,
    };
  },
);

function MaterialsGrid() {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [currentEditingRow, setCurrentEditingRow] = useState({ name: '', valueType: '', defaultValue: undefined, description: '', });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const cancelDialogs = () => {
    setIsDialogOpen(false);
    setIsDeleteDialogOpen(false);
    setIsCreateDialogOpen(false);
  };

  const editParameter = (row) => {
    const editableRow = convertToParametersType(row);
    setCurrentEditingRow(editableRow);
    setIsDialogOpen(true);
  };

  const openDeleteDialog = (rowdata) => {
    setCurrentEditingRow(convertToParametersType(rowdata));
    setIsDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const openCreateDialog = () => {
    setCurrentEditingRow({ name: '', valueType: '' });
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit of a field in the dialog
    setCurrentEditingRow((prev) => {
      let _editedRow = { ...prev };
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  const saveAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    if (isCreateDialogOpen) {
      dispatch(processCreateParameter({
        name: currentEditingRow.name,
        description: currentEditingRow.description,
        valueType: currentEditingRow.valueType,
        defaultValue: currentEditingRow.defaultValue,
        listValues: currentEditingRow.valueType == 'list' && convertListToString(currentEditingRow.listValues),
        unitId: currentEditingRow.valueType == 'double' && currentEditingRow.unit?.id || undefined,
      }));
    } else {
      dispatch(processSaveParameters([{
        id: currentEditingRow.id,
        name: currentEditingRow.name,
        description: currentEditingRow.description,
        valueType: currentEditingRow.valueType,
        defaultValue: currentEditingRow.defaultValue,
        listValues: currentEditingRow.valueType == 'list' && convertListToString(currentEditingRow.listValues) || '',
        unitId: currentEditingRow.valueType == 'double' && currentEditingRow.unit?.id || undefined,
      }]));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    cancelDialogs();
    dispatch(processSaveParameters([{ id: currentEditingRow.id, _destroy: '1' }]));
  };

  useEffect(() => {
    dispatch(processFetchAllParameters());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleParametersPath.defaultTitle}
          subtitle={reduxProps.isFiltering ? `Showing ${reduxProps.data.length}/${reduxProps.totalSizes} ${pluralize('filtered Parameter', reduxProps.data.length)}` : `Showing ${reduxProps.data.length} ${pluralize('Parameter', reduxProps.data.length)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='name' style={{ width: '19%' }} header='Name' ></Column>
          <Column field='valueType' style={{ width: '19%' }} header='Value Type' ></Column>
          <Column field='defaultValue' style={{ width: '19%' }} body={(rowdata) => MultiTypeCellRenderer(rowdata)} header='Default Value' ></Column>
          <Column field='description' style={{ width: '19%' }} header='Description' ></Column>
          <Column header=''
            style={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editParameter}
                deleteAction={openDeleteDialog}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <ParameterDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
      />
      <DeleteParameterDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default withRouter(MaterialsGrid);
