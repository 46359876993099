import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectNormalizedCombinedStates = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.COMBINED_STATES.state)
);
export const selectNormalizedCombinedStatesList = () => createSelector(
  selectNormalizedCombinedStates(),
  (combinedStates) => {
    return combinedStates ? combinedStates.toList() : combinedStates;
  }
);