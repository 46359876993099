import React from 'react';
import Text from '../../common/Text';
import { ParametersType } from '../../../entities/Standardize/Parameters/types';

/**
 * Renders the default value body based on the parameter.valueType.
 *
 * @param {ParametersType} rowdata - a parameter object
 * @returns {React.ReactNode} - The rendered default value body.
 */
const MultiTypeCellRenderer = (rowdata: ParametersType): React.ReactNode => {
  switch (rowdata.valueType) {
  case 'double':
    return <><Text style={{ float: 'left', width: '50%' }} >{rowdata.defaultValue}</Text><Text style={{ float: 'right', width: '50%' }} >{rowdata.unit?.abbreviation || '--'}</Text></>;
  case 'bool':
    return typeof(rowdata.defaultValue) == 'string' ? <Text>{rowdata.defaultValue}</Text> : <Text>{rowdata.defaultValue == true ? 'true' : rowdata.defaultValue == false ? 'false' : 'undefined'}</Text>;
  default:
    return <Text>{rowdata.defaultValue}</Text>;
  }
};

export default MultiTypeCellRenderer;