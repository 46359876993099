import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import selectEntities from '../../../modules/entities/selectors';

import SearchSelect from '../../../components/form/SearchSelect';

const createSelectEntityFromEntityKey = () => createSelector(
  selectEntities(),
  (_, ek) => ek.state,
  (_, ek, eFilter) => eFilter,
  (_, ek, eFilter, schema) => schema,
  (entities, eks, eFilter, schema) => {
    const e = schema && denormalize(entities.get(eks), [schema], entities) || entities.get(eks);
    return eFilter && e.filter(eFilter) || e;
  }
);

const BaseEntitySearchSelect = ({ forwardRef, ek, eFilter, schema, ...props }) => {
  const selectEntityFromEntityKey = useMemo(
    createSelectEntityFromEntityKey,
    []
  );

  const entities = useSelector(state => selectEntityFromEntityKey(state, ek, eFilter, schema));

  const options = useMemo(() => entities.reduce((output, entity) => output.push(entity.toOption()), List()), [entities]);

  return <SearchSelect { ...props } ref={forwardRef} options={options} />;
};

const EntitySearchSelect = React.forwardRef((props, ref) => <BaseEntitySearchSelect forwardRef={ref} { ...props } />);

export default EntitySearchSelect;