import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import BoltNutInstanceSchema from './schema';
import EK from '../../keys';
import { newBoltNutInstanceRows } from './constants';

import { formValueSelector } from 'redux-form/immutable';

export const selectNormalizedBoltNutInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.BOLT_NUT_INSTANCES.state)
);

export const selectCurrentBoltNutInstances = (familyId) => createSelector(
  selectNormalizedBoltNutInstances(),
  (instances) => instances.toList().filter(item => item.boltNutFamilyId == familyId)
);
export const selectBoltNutInstanceCount = (familyId) => createSelector(
  selectCurrentBoltNutInstances(familyId),
  (instances) => instances.size
);

const boltNutInstanceFilterForm = formValueSelector(`filters.${EK.BOLT_NUT_INSTANCES.state}`);

export const selectCurrentFilteredBoltNutInstances = (familyId) => createSelector(
  selectCurrentBoltNutInstances(familyId),
  selectSidebarIsFiltering(),
  state => boltNutInstanceFilterForm(state, 'sizeProperty', 'schedule', 'mccsCode', 'bulkItemName', 'quantity', 'boltType', 'materialDescription'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [BoltNutInstanceSchema], entities.delete(EK.BOLT_NUT_INSTANCES.state)).toList();
  }
);
export const selectCurrentBoltNutInstancesForPage = (familyId) => createSelector(
  selectCurrentFilteredBoltNutInstances(familyId),
  selectSidebarIsFiltering(),
  (instances, isFiltering) => isFiltering ? instances.toArray() : instances.toArray().concat(newBoltNutInstanceRows)
);