import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectNormalizedLayerStates = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.LAYER_STATES.state)
);
export const selectNormalizedLayerStatesList = () => createSelector(
  selectNormalizedLayerStates(),
  layerStates => layerStates.toList()
);
