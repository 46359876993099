import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import ParameterSchema from '../Parameters/schema';
//import ParameterProfileParameterSchema from './schema';

const parameterProfileSchema = baseSchema(EK.PARAMETER_PROFILES.state, {
  parameterProfileParameters: {
    isId: true,
    idField: 'parameterProfileParameterIds',
    isList: true,
  },
});

export const parameterProfileParameterSchema = baseSchema(
  EK.PARAMETER_PROFILE_PARAMETERS.state,
  {
    parameterProfileParameter: { schema: parameterProfileSchema, isId: true },
    parameter: { schema: ParameterSchema, isId: true },
  },
);

parameterProfileSchema.define({
  parameterProfileParameters: [parameterProfileParameterSchema],
});

export default parameterProfileSchema;
