import { List } from 'immutable';
import capitalize from 'lodash/capitalize';

import EntityRecord from '../../modules/entities/model';

import { USER_ROLE_TYPES } from '../../modules/permissions/constants';

export default class User extends EntityRecord({
  email: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  firstName: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  lastName: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  phoneNumber: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  role: {
    value: USER_ROLE_TYPES.USER,
    handleCompare: true,
    handleSerialize: true,
  },
  passwordResetToken: {
    value: null,
    hamdleCompare: true,
    handleSerialize: true,
  },
  activeFlag: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModuleCabling: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModulePiping: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModuleFramework: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModuleEcad: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  hasModuleReporting: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  emailVerified: {
    value: false,
    handleCompare: true,
  },
  hasAcceptedEula: {
    value: false,
  },
}) {
  get isNamePresent() {
    return this.firstName !== null || this.lastName !== null;
  }

  get fullName() {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  get isAdmin() {
    return this.role === USER_ROLE_TYPES.ADMIN;
  }

  get isCollaborator() {
    return (
      this.role === USER_ROLE_TYPES.ADMIN ||
      this.role === USER_ROLE_TYPES.COLLABORATOR
    );
  }
}
