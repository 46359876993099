import React from 'react';

import theme from '../../../../assets/themes/theme';

const AstronautGraphic = ({
  id = 'astronaut-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <g>
      <circle fill={backgroundColor} cx="64" cy="64" r="63.5" />
      <path
        fill={theme.colors.gray[4]}
        d="M102.5,97.2H25.5c-3.8,0-6.8-3-6.8-6.8V57.7c0-3.8,3-6.8,6.8-6.8h77.1c3.8,0,6.8,3,6.8,6.8v32.7
		C109.3,94.2,106.3,97.2,102.5,97.2z"
      />
      <path
        fill={theme.colors.gray[2]}
        d="M114.8,102.3c-4.1,5.5-9.1,10.2-14.8,14.1c-10.2,7-22.6,11.1-35.9,11.1h-0.3c-13.3,0-25.7-4.1-35.9-11.1
		c-5.7-3.9-10.7-8.6-14.8-14.1c0-10.2,4.7-16.2,10.5-19.7c9-5.5,20.5-5.1,20.5-5.1c2.6,3.6,10.4,6.3,19.6,6.3h0.3
		c9.2,0,17-2.6,19.6-6.3c0,0,11.5-0.4,20.5,5.1C110.1,86.1,114.8,92.1,114.8,102.3z"
      />
      <path
        fill={theme.colors.gray[2]}
        d="M87.7,95.5V123c-7.3,2.9-15.3,4.5-23.6,4.5h-0.3c-8.3,0-16.3-1.6-23.6-4.5V95.5H87.7z"
      />
      <path
        fill={theme.colors.gray[0]}
        d="M114.8,102.3c-4.1,5.5-9.1,10.2-14.8,14.1c-1.1-16.8,1.9-28,4.3-33.8C110.1,86.1,114.8,92.1,114.8,102.3z"
      />
      <path
        fill={theme.colors.gray[0]}
        d="M28,116.4c-5.7-3.9-10.7-8.6-14.8-14.1c0-10.2,4.7-16.2,10.5-19.7C26,88.4,29.1,99.6,28,116.4z"
      />
      <path
        fill={theme.colors.gray[5]}
        d="M64,76.9c0,0-1.7,0.1-4.1,0c-0.2,0-0.4,0-0.5,0c-0.5,0-1,0-1.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0-0.3,0c-0.4,0-0.5,0,0,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.4,0,0.9,0,1.4,0c0.2,0,0.3,0,0.5,0
		c-4.7-0.2-11.9-1.3-14.6-5.4l-1.1,6c0,0,4.5,6.7,19.9,6.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.3,0,0.9,0,1.6,0c0.2,0,0.4,0,0.6,0
		c0.1,0,0.1,0,0.2,0c4.5-0.2,13.3-1.4,16.9-6.2l-1.2-6.1C82.6,71.4,80.6,77,64,76.9z"
      />
      <path
        fill={theme.colors.gray[0]}
        d="M72.7,8.7H55.3c-14.9,0-26.9,14.1-26.9,31.4V47c0,17.3,12,31.4,26.9,31.4h17.4c14.9,0,26.9-14.1,26.9-31.4
		v-6.9C99.6,22.8,87.6,8.7,72.7,8.7z"
      />
      <path
        fill={theme.colors.primary[3]}
        d="M38,33.9c-3,0-5.5,2.1-6,5c-0.2,1.4-0.4,2.8-0.4,4.2v5.9c0,13.1,10.6,23.7,23.7,23.7h17.4
		c13.1,0,23.7-10.6,23.7-23.7v-5.9c0-1.4-0.1-2.8-0.4-4.2c-0.5-2.9-3.1-5-6-5H38z"
      />
      <path
        fill={theme.colors.primary[4]}
        d="M96,38.9c-0.5-2.9-3.1-5-6-5H69.8L56.6,72.7h16.1c13.1,0,23.7-10.6,23.7-23.7v-5.9
		C96.4,41.7,96.3,40.3,96,38.9z"
      />
      <path
        fill={theme.colors.gray[4]}
        d="M87.7,95.5V123c-7.3,2.9-15.3,4.5-23.6,4.5h-0.3c-8.3,0-16.3-1.6-23.6-4.5V95.5H87.7z"
      />
      <rect
        x="40.3"
        y="103.2"
        fill={theme.colors.error[4]}
        width="47.5"
        height="3.3"
      />
      <rect
        x="56.8"
        y="101.6"
        fill={theme.colors.error[4]}
        width="14.5"
        height="6.1"
      />
    </g>
  </svg>
);

export default AstronautGraphic;
