import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'USERS';

export const fetchAllUsersTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const adminCreateUserTypes = generateAPHTypes(resource, 'ADMIN_POST');

export const editUserTypes = generateAPHTypes(resource, 'EDIT');

export const forceResetPasswordTypes = generateAPHTypes(resource, 'FORCE_RESET_PASSWORD');