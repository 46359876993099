import React from 'react';

import theme from '../../../../assets/themes/theme';

const CadActiveLogoGraphic = ({
  id = 'cadactive-logo-graphic',
  backgroundColor = theme.colors.gray[0],
}) => (
  <svg
    id={id}
    data-name={`data-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
  >
    <circle fill={backgroundColor} cx="100" cy="100" r="100" />
    <path
      fill={theme.colors.gray[7]}
      d="M395,206.09c-1.25,5.49-2.62,11-3.69,16.5-.48,2.48-1.42,3-3.71,2.12a25.59,25.59,0,0,1-14-12.79c-1.24-2.43-.18-3.1,2-3.76,3.09-1,6.52-.32,9.43-2.07Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M407,206.09c2.75,1.71,6,1.39,9,1.93,3.16.57,3.3,1.84,2.12,4.36-2.83,6.06-7.61,9.88-13.63,12.4-2.31,1-3.25.12-3.73-2.23-1.12-5.51-2.46-11-3.71-16.46Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M495.91,319c-6.41-.43-11.74-4.11-17.71-5.9-1.8-.54-.91-1.65-.48-2.73,2.65-6.69,10.92-12.33,18.19-12.35Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M382,405.91c-2.93-2.07-6.53-1.88-9.8-2.67-3.08-.75-2.89-1.61-1.52-3.85a122.47,122.47,0,0,0,5.95-10.91c1.25-2.59,2.41-3,4.8-1.21,6.16,4.71,10.23,13.3,8.56,18.64Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M296.08,298c7.48.06,15.29,5.24,18.05,12,.49,1.19,1.45,2.4-.6,3.18-5.11,1.92-10.17,4-15.3,5.8-.53.19-1.43-.62-2.15-1Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M401,405.91c.39-7.77,3.93-13.85,9.91-18.67,1.84-1.48,3.07-1.43,4.23.8,2.07,4,4.26,7.87,6.51,11.75,1.31,2.26.54,2.8-1.78,3.48-3.6,1-7.52.69-10.87,2.64Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M453.79,313.42c-2.39,7.45-4.62,14.58-7,21.65-.62,1.85-2.3.84-3.46.66-18.42-2.82-32.27,7.11-35.3,25.66-.45,2.75-1.55,3.19-3.84,3.17q-9.22-.12-18.46,0c-2.37,0-3.37-.53-3.7-3.26-2.09-17.41-17.1-28.56-34.39-25.63-2.75.47-4.23.29-5.07-2.76-1.64-5.92-3.52-11.79-5.68-17.52-1-2.59-.05-3.38,2-4.4,16.49-8.15,22-25.32,13.28-41.48-1-1.87-1.36-3,.76-4.4,5.39-3.61,10.64-7.42,15.79-11.34,1.9-1.44,2.8-1.2,4.45.49,12.86,13.21,30.54,13.24,43.64.11,1.69-1.7,2.61-2.29,4.73-.6,4.95,3.91,10.09,7.6,15.28,11.18,2,1.41,2.39,2.34,1.06,4.72-8.56,15.41-3.07,33,12.56,41.09C451.75,311.44,453.9,311.41,453.79,313.42ZM395.46,277a29.47,29.47,0,0,0-.81,58.94,29.47,29.47,0,0,0,.81-58.94Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M340.35,243a29,29,0,0,1-14.26-4c-2.14-1.27-2.32-2.38-.4-4.07a156,156,0,0,1,13-10.76c1.9-1.34,2.59-.74,3.23.94,1.74,4.64,3.43,9.31,5.17,14,.71,1.9,0,2.79-1.92,3.07S341.6,242.79,340.35,243Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M300.93,287.53c-1.86.06-3.23-.22-2.55-2.77a136.51,136.51,0,0,1,5.16-16.54c.75-1.86,1.39-2.37,3.08-1,3.77,3,7.55,5.92,11.44,8.71,2,1.46,1.94,2.65.29,4.27A25,25,0,0,1,300.93,287.53Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M449.43,221.86c-4.29,4.11-8.36,8-12.38,11.9-1.24,1.21-2.16,1.84-3.52,0a25.43,25.43,0,0,1-5.25-19.08c.25-2,.81-3.17,3.15-2.15C437.44,215.16,443.51,217.64,449.43,221.86Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M466.68,262.73a25.48,25.48,0,0,1,5.64-15.45c1.42-1.73,2.6-2.78,4.46-.16a136.09,136.09,0,0,1,8.52,14c1.11,2.07.7,2.93-1.53,3.37-4.71.95-9.37,2.2-14.11,3C464.74,268.26,468.06,263.9,466.68,262.73Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M452.34,242.82a18.8,18.8,0,0,1-2.49,0c-5.54-.78-5.8-1.22-3.84-6.63,1.29-3.58,2.68-7.12,3.9-10.72.71-2.13,1.67-2.52,3.54-1.19a116.33,116.33,0,0,1,12.93,10.82c1.69,1.63,1.41,2.55-.44,3.76A25.57,25.57,0,0,1,452.34,242.82Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M302.9,322.08c3.81.33,7.61.7,11.42,1,1.75.12,2.76.37,2.28,2.65a25.78,25.78,0,0,1-11.15,16.47c-2,1.41-2.66.25-3.13-1.29-1.36-4.45-2.78-8.88-3.87-13.39C297.07,321.79,297.2,321.76,302.9,322.08Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M364,221.21c-.11,2.75-1.93,7.45-4.83,11.83-1.3,2-2.5,2.42-4.28.61-3.25-3.3-6.52-6.58-9.86-9.8-1.84-1.77-1.6-3,.63-4.12,3.88-1.89,7.68-3.92,11.61-5.66C363.51,211.29,364,211.68,364,221.21Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M425.25,401.71c-.42-5.8-.7-11.1-1.22-16.37-.26-2.55.71-3.23,3.06-3a26.05,26.05,0,0,1,16,7.3c2.1,2,2.62,3.6-.6,5.13A130.4,130.4,0,0,1,425.25,401.71Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M490.93,287.41a26.6,26.6,0,0,1-17.27-7.27c-1.79-1.63-1.83-2.76.27-4.22,3.79-2.64,7.46-5.46,11.08-8.33,2.1-1.66,3.07-1,3.81,1.27,1.52,4.7,3.1,9.37,4.44,14.13C494.38,287,493.06,287.75,490.93,287.41Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M325.38,263.89c.56,3.06-.95,4.48-5.24,3.2-3.79-1.14-7.72-1.85-11.61-2.63-2-.39-3-.74-1.81-3.16a119.64,119.64,0,0,1,8.78-14.43c1.19-1.71,2.26-2,3.8-.13C323.2,251.39,325.09,256.71,325.38,263.89Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M474.82,350.35c3,.41,7.22-.33,8.72,2.06s-1.83,5.15-3.41,7.54c-7.66,11.57-7.68,11.55-15.28,1.19-4.72-6.43-4.38-7.44,3.49-9.67A61.93,61.93,0,0,1,474.82,350.35Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.gray[7]}
      d="M321.64,372.46c5.07-2.73,9.5-4.95,13.76-7.47,2.44-1.45,3.75-1.35,4.63,1.58,1.86,6.24,2,12.35-.71,18.4-.68,1.53-1.34,2.72-3.21,1.2C331,382,325.91,377.85,321.64,372.46Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M494.44,323.66a132.66,132.66,0,0,1-4.52,16.84c-.87,2.41-1.82,2.77-3.9,1.31-5.4-3.8-9-8.71-10.22-15.29-.42-2.25,0-3.47,2.53-3.6,4.47-.24,8.93-.6,13.39-1C493.89,321.75,494.91,322.38,494.44,323.66Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M367.87,387.69c-.25,3-1,6-.66,8.87.62,5.54-1.94,5.07-5.52,3.46-4.07-1.82-8.09-3.74-12.23-5.38-3.17-1.26-2.57-2.92-.74-4.67,3.88-3.74,8.31-6.52,13.82-7.13S368.16,382.23,367.87,387.69Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M450.67,375.13c0-.66.07-1.32.11-2,.63-9.13,2-10,9.72-6,10.93,5.59,10.93,5.59,1.83,13.76a6.39,6.39,0,0,1-1.14,1c-2.52,1.39-4.5,5.73-6.92,4.55-2.95-1.44-3.23-5.79-3.45-9.29C450.78,376.45,450.72,375.79,450.67,375.13Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M315.36,350.63c5.87-.06,10,.86,13.76,3.24,1.41.9,2.52,1.49,1.05,3.37-3,3.77-5.81,7.64-8.59,11.55-1.33,1.88-2.37,1.38-3.42-.12-2.94-4.21-5.83-8.45-8.78-12.65-2.31-3.3-.89-4.65,2.53-5Z"
      transform="translate(-296 -206)"
    />
    <path
      fill={theme.colors.primary[4]}
      d="M395.1,320a13.63,13.63,0,0,1,.5-27.25c6.94.18,13.19,6.62,13.25,13.65S402.3,320.12,395.1,320Z"
      transform="translate(-296 -206)"
    />
  </svg>
);

export default CadActiveLogoGraphic;
