import React from 'react';

import theme from '../../../../assets/themes/theme';

const RocketGraphic = ({
  id = 'rocket-graphic',
  backgroundColor = theme.colors.primary[4],
}) => (
  <svg id={id} data-name={`data-${id}`} viewBox="0 0 107 116">
    <defs>
      <path
        d="M53.306,104.857 C24.081,104.857 0.306,81.452 0.306,52.683 C0.306,23.913 24.081,0.508 53.306,0.508 C82.53,0.508 106.306,23.913 106.306,52.683 C106.306,81.452 82.53,104.857 53.306,104.857"
        id={`${id}-path-1`}
      ></path>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M53.306,115.139 C24.081,115.139 0.306,91.734 0.306,62.965 C0.306,34.195 24.081,10.79 53.306,10.79 C82.53,10.79 106.306,34.195 106.306,62.965 C106.306,91.734 82.53,115.139 53.306,115.139"
          fill={backgroundColor}
        ></path>
        <g transform="translate(0.000000, 10.282000)">
          <mask id={`${id}-mask-2`} fill="white">
            <use href={`#${id}-path-1`}></use>
          </mask>

          <path
            d="M43.724,108.372 C43.724,114.202 39.001,118.928 33.172,118.928 C27.341,118.928 22.614,114.202 22.614,108.372 C22.614,102.548 27.341,97.82 33.172,97.82 C39.001,97.82 43.724,102.548 43.724,108.372"
            fill={theme.colors.gray[4]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <path
            d="M83.482,109.546 C84.257,107.944 84.704,106.152 84.704,104.254 C84.704,97.522 79.246,92.062 72.512,92.062 C65.778,92.062 60.316,97.522 60.316,104.254 C60.316,106.152 60.762,107.944 61.541,109.546 L83.482,109.546"
            fill={theme.colors.gray[4]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <polyline
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
            points="51.154 39.124 56.523 63.996 61.896 110.054 40.414 110.054 45.783 63.996 51.154 39.124"
          ></polyline>
          <polyline
            fill={theme.colors.gray[4]}
            mask={`url(#${id}-mask-2)`}
            points="51.154 39.124 54.896 63.996 58.639 110.054 43.668 110.054 47.411 63.996 51.154 39.124"
          ></polyline>
          <polyline
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
            points="51.154 39.124 52.262 63.996 53.368 110.054 48.936 110.054 50.044 63.996 51.154 39.124"
          ></polyline>
          <path
            d="M77.469,110.232 C77.517,109.822 77.548,109.403 77.548,108.978 C77.548,103.152 72.827,98.426 66.998,98.426 C65.349,98.426 63.795,98.813 62.403,99.489 C60.8,93.991 55.73,89.971 49.713,89.971 C43.136,89.971 37.699,94.772 36.671,101.054 C33.29,101.647 30.316,103.428 28.216,105.967 C27.524,105.742 26.784,105.616 26.015,105.616 C22.075,105.616 18.881,108.81 18.881,112.749 C18.881,116.639 21.998,119.795 25.869,119.874 L25.872,119.88 L76.907,119.88 L76.922,119.842 C77.057,119.852 77.183,119.88 77.318,119.88 C79.989,119.88 82.15,117.718 82.15,115.05 C82.15,112.432 80.067,110.313 77.469,110.232"
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <path
            d="M37.404,93.163 C35.716,93.163 34.141,93.652 32.807,94.486 C32.739,87.24 26.85,81.384 19.586,81.384 C13.885,81.384 9.046,84.992 7.183,90.041 C5.775,89.53 4.26,89.236 2.675,89.236 C-2.96,89.236 -7.758,92.763 -9.667,97.723 C-8.499,98.623 -7.047,99.176 -5.464,99.207 L-5.462,99.213 L45.573,99.213 L45.588,99.174 L45.677,99.183 C44.55,95.691 41.276,93.163 37.404,93.163"
            fill={theme.colors.gray[4]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <path
            d="M81.967,95.206 C82.804,97.326 81.765,99.722 79.643,100.56 C77.525,101.396 75.127,100.356 74.29,98.236 C73.454,96.117 74.495,93.72 76.612,92.884 C78.734,92.046 81.13,93.086 81.967,95.206"
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <path
            d="M93.835,94.957 C93.813,94.013 93.639,93.057 93.272,92.126 C91.612,87.923 86.858,85.859 82.652,87.52 C78.448,89.179 76.385,93.935 78.044,98.139 C78.412,99.069 78.936,99.887 79.566,100.59 L93.835,94.957"
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
          ></path>
          <path
            d="M30.488,95.739 C30.488,100.524 26.607,104.405 21.822,104.405 C17.036,104.405 13.156,100.524 13.156,95.739 C13.156,90.956 17.036,87.076 21.822,87.076 C26.607,87.076 30.488,90.956 30.488,95.739"
            fill={theme.colors.gray[0]}
            mask={`url(#${id}-mask-2)`}
          ></path>
        </g>
        <path
          d="M51.838,60.953 C47.429,60.953 43.854,66.89 43.854,74.212 C43.854,75.14 43.913,76.047 44.022,76.92 L59.654,76.92 C59.764,76.047 59.821,75.14 59.821,74.212 C59.821,66.89 56.248,60.953 51.838,60.953"
          fill={theme.colors.gray[8]}
        ></path>
        <path
          d="M49.76,60.953 C46.499,60.953 43.854,66.89 43.854,74.212 C43.854,75.14 43.898,76.047 43.979,76.92 L55.541,76.92 C55.621,76.047 55.664,75.14 55.664,74.212 C55.664,66.89 53.021,60.953 49.76,60.953"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M44.301,29.187 C44.301,43.313 37.064,55.605 26.386,61.974 C26.066,62.271 25.75,62.563 25.443,62.844 C23.471,64.645 24.113,73.155 24.113,73.155 L44.404,73.155 C44.404,73.155 44.404,28.865 44.404,23.925 C44.404,24.687 44.339,25.648 44.221,26.751 C44.269,27.557 44.301,28.367 44.301,29.187"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M44.404,45.671 C41.168,53.912 35.059,60.743 27.283,64.967 C26.932,65.265 26.585,65.557 26.249,65.838 C24.931,66.936 24.677,70.52 24.6789883,73.155 L44.404,73.155 C44.404,73.155 44.404,58.907 44.404,45.671"
          fill={theme.colors.gray[4]}
        ></path>
        <path
          d="M26.461,72.157 C26.146,72.389 25.886,72.736 25.669,73.155 L44.404,73.155 C44.404,73.155 44.404,65.86 44.404,56.862 C40.467,62.916 34.651,67.928 27.634,71.287 C27.236,71.586 26.843,71.877 26.461,72.157"
          fill={theme.colors.gray[0]}
        ></path>
        <path
          d="M59.376,29.187 C59.376,43.313 66.613,55.605 77.292,61.974 C77.612,62.271 77.928,62.563 78.234,62.844 C80.206,64.645 79.564,73.155 79.564,73.155 L59.273,73.155 C59.273,73.155 59.273,28.865 59.273,23.925 C59.273,24.687 59.339,25.648 59.457,26.751 C59.409,27.557 59.376,28.367 59.376,29.187"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M59.273,45.671 C62.509,53.912 68.619,60.743 76.395,64.967 C76.746,65.265 77.093,65.557 77.428,65.838 C78.747,66.936 79,70.52 78.9980117,73.155 L59.273,73.155 C59.273,73.155 59.273,58.907 59.273,45.671"
          fill={theme.colors.gray[4]}
        ></path>
        <path
          d="M77.216,72.157 C77.531,72.389 77.791,72.736 78.008,73.155 L59.273,73.155 C59.273,73.155 59.273,65.86 59.273,56.862 C63.21,62.916 69.026,67.928 76.043,71.287 C76.441,71.586 76.835,71.877 77.216,72.157"
          fill={theme.colors.gray[0]}
        ></path>
        <path
          d="M59.79,73.155 L59.79,22.262 C59.79,17.604 56.329,2.489 51.838,0 C47.349,2.489 43.887,17.604 43.887,22.262 L43.887,73.155 L59.79,73.155"
          fill={theme.colors.gray[0]}
        ></path>
        <path
          d="M51.838,0 L51.704,0.087 L51.704,73.155 L59.79,73.155 L59.79,22.262 C59.79,17.604 56.329,2.489 51.838,0"
          fill={theme.colors.gray[4]}
        ></path>
        <path
          d="M46.156,10.393 L57.521,10.393 C56.092,5.692 54.096,1.251 51.838,0 C49.581,1.251 47.585,5.692 46.156,10.393"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M51.704,10.393 L57.521,10.393 C56.092,5.692 54.096,1.251 51.838,0 L51.704,0.087 L51.704,10.393"
          fill={theme.colors.gray[8]}
        ></path>
        <path
          d="M56.732,18.823 C56.405,19.566 55.324,19.285 54.054,18.812 C52.604,18.243 50.983,18.243 49.533,18.812 C48.265,19.283 47.183,19.566 46.856,18.823 C46.521,18.097 47.312,16.341 48.963,15.701 C50.779,14.991 52.81,14.991 54.626,15.701 C56.276,16.336 57.066,18.095 56.732,18.823"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M35.623,60.953 C33.693,60.953 32.13,66.89 32.13,74.212 C32.13,75.14 32.155,76.047 32.203,76.92 L39.042,76.92 C39.09,76.047 39.115,75.14 39.115,74.212 C39.115,66.89 37.551,60.953 35.623,60.953"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M35.623,60.953 C35.119,60.953 34.643,61.366 34.209,62.093 C35.794,62.958 37.016,68.122 37.016,74.381 C37.016,75.252 36.991,76.1 36.947,76.92 L39.042,76.92 C39.09,76.047 39.115,75.14 39.115,74.212 C39.115,66.89 37.551,60.953 35.623,60.953"
          fill={theme.colors.gray[8]}
        ></path>
        <path
          d="M68.054,60.953 C66.126,60.953 64.562,66.89 64.562,74.212 C64.562,75.14 64.587,76.047 64.635,76.92 L71.475,76.92 C71.522,76.047 71.547,75.14 71.547,74.212 C71.547,66.89 69.984,60.953 68.054,60.953"
          fill={theme.colors.gray[7]}
        ></path>
        <path
          d="M68.054,60.953 C67.551,60.953 67.073,61.366 66.642,62.093 C68.228,62.958 69.447,68.122 69.447,74.381 C69.447,75.252 69.424,76.1 69.379,76.92 L71.475,76.92 C71.522,76.047 71.547,75.14 71.547,74.212 C71.547,66.89 69.984,60.953 68.054,60.953"
          fill={theme.colors.gray[8]}
        ></path>
      </g>
    </g>
  </svg>
);

export default RocketGraphic;
