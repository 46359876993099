import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Button from '../../../components/common/Button';
import colors from '../../../assets/themes/base/colors';

import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

//popup styling
const contentStyle = { width: 'fit-content' };
export const addCompatibilityButton = (action, name = '', id = '') => (
  <>
    <Popup trigger={() => <Button icon='add' secondary={true} onClick={() => action(id)} />} position='bottom center' on={['hover']} {...{contentStyle}} >
      Add new EndType Compatibility to {name != '' && `'${name}'` || 'End Type'}
    </Popup>
  </>
);

export const rowExpansionTemplate = (data, parent, endTypeCellBody, scheduleCellBody, getRowTool, scheduleSelector, endTypeSelector, addNewCompatAction, onBeforeCellEditShow, onCellEditComplete, editedRows ) => {

  return (
    <div style={{ marginLeft: '5rem', marginBottom: '2rem' }}>
      <DataTable
        value={data}
        sortMode="multiple"
        removableSort

        rowClassName={(data) => rowStyles(data, editedRows)}

        editMode='cell'
      >
        <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
        <Column field='firstEndTypeCode' header='First End Type' sortable body={(rowdata) => endTypeCellBody(rowdata, 'firstEndTypeId')} ></Column>
        <Column field='firstSchedule' header='First Schedule' sortable editor={() => scheduleSelector('firstScheduleId')} body={(rowdata) => scheduleCellBody(rowdata, 'firstScheduleId')} onBeforeCellEditShow={onBeforeCellEditShow} onCellEditComplete={onCellEditComplete} ></Column>
        <Column field='secondEndTypeCode' header='Second End Type' sortable editor={() => endTypeSelector('secondEndTypeId')} body={(rowdata) => endTypeCellBody(rowdata, 'secondEndTypeId')} onBeforeCellEditShow={onBeforeCellEditShow} onCellEditComplete={onCellEditComplete} ></Column>
        <Column field='secondSchedule' header='Second Schedule' sortable editor={() => scheduleSelector('secondScheduleId')} body={(rowdata) => scheduleCellBody(rowdata, 'secondScheduleId')} onBeforeCellEditShow={onBeforeCellEditShow} onCellEditComplete={onCellEditComplete} ></Column>
        <Column
          header={() => addCompatibilityButton(addNewCompatAction, parent.code, parent.id)}
          style={{ width: '5%', textAlign: 'right' }}
          alignHeader='right'
        />
      </DataTable>
    </div>
  );
};