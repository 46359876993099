import React from 'react';

import Text from '../../../common/Text';

import TooltipBody from '../../TooltipBody';
import TooltipSection from '../../TooltipSection';

const InvalidLicenseTooltip = React.memo(({ product }) => (
  <TooltipBody>
    <TooltipSection large primary>
      <Text fontSize={3} fontWeight={500}>No Valid Product License</Text>
    </TooltipSection>
    <TooltipSection large>
      <Text color='gray.7' fontSize={1}>
        Your license for { product && <strong>{ product }</strong> || 'this product' } either does not exist or is no longer valid. One of your organization&apos;s administrators can assign you a product license if one is available.
      </Text>
    </TooltipSection>
  </TooltipBody>
));

export default InvalidLicenseTooltip;