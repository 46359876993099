import { toast } from 'react-toastify';

import EK from '../../keys';

import bendMachineClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import bendMachineFamilyKeys from './keys';
import bendMachineInstanceKeys from '../BendMachineInstances/keys';

import {
  fetchAllBendMachineFamiliesForPipeFamilyTypes,
  fetchIndividualBendMachineFamilyForPipeFamilyTypes,
  createBendMachineFamilyForPipeFamilyTypes,
  copyBendMachineFamilyForPipeFamilyTypes,
  editBendMachineFamilyForPipeFamilyTypes,
  deleteBendMachineFamilyForPipeFamilyTypes,
  archiveBendMachineFamiliesForPipeFamilyTypes,
  unarchiveBendMachineFamiliesForPipeFamilyTypes,
} from './constants';

export const processFetchAllBendMachineFamiliesForPipeFamily = pipeId => bendMachineClient(REQUEST_TYPES.GET, 'plural', fetchAllBendMachineFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_machine_families?all=true`, {
  mutateResponse: ({ bendMachineFamilies = [] }) => ({
    bendMachineFamilies: bendMachineFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId })),
  }),
});

export const processFetchIndividualBendMachineFamilyForPipeFamily = (pipeId, familyId) => bendMachineClient(REQUEST_TYPES.GET, 'single', fetchIndividualBendMachineFamilyForPipeFamilyTypes, `/piping/bend_machine_families/${familyId}?with_full_detail=true`, {
  mutateResponse: ({ bendMachineFamily: { id, bendMachineInstances = [], ...other } = {} }) => ({
    bendMachineFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendMachineInstances: bendMachineInstances.map(instance => ({
        ...instance,
        bendMachineFamily: `${id}`,
        bendMachineFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateBendMachineFamilyForPipeFamily = (pipeId, data) => bendMachineClient(REQUEST_TYPES.POST, 'plural', createBendMachineFamilyForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_machine_families`, {
  data: {
    bendMachineFamilies: [data],
  },
  mutateResponse: ({ bendMachineFamilies = [] }) => ({
    bendMachineFamilies: bendMachineFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend machine successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create bend machine',
  },
});

export const processCopyBendMachineFamilyForPipeFamily = (pipeId, data) =>
  bendMachineClient(
    REQUEST_TYPES.POST,
    'plural',
    copyBendMachineFamilyForPipeFamilyTypes,
    `/piping/pipe_families/${pipeId}/bend_machine_families/copy`,
    {
      data: {
        [EK.BEND_MACHINES.plural]: [data],
      },
      mutateResponse: ({ bendMachineFamilies = [] }) => ({
        bendMachineFamilies: bendMachineFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Bend machine successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy bend machine',
      },
    }
  );

export const processEditBendMachineFamilyForPipeFamily = (pipeId, bendMachineId, data) => bendMachineClient(REQUEST_TYPES.PATCH, 'single', editBendMachineFamilyForPipeFamilyTypes, `/piping/bend_machine_families/${bendMachineId}`, {
  data,
  mutateResponse: ({ bendMachineFamilies = [] }) => ({
    bendMachineFamilies: bendMachineFamilies.map(({ id, bendMachineInstances = [], ...other }) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendMachineInstances: bendMachineInstances.map(instance => ({
        ...instance,
        bendMachineFamily: `${id}`,
        bendMachineFamilyId: `${id}`,
      })),
    })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteBendMachineFamilyForPipeFamily = (pipeId, bendMachineId) => bendMachineClient(REQUEST_TYPES.DELETE, 'single', deleteBendMachineFamilyForPipeFamilyTypes, `/piping/bend_machine_families/${bendMachineId}?with_full_detail=true`, {
  deleteKey: bendMachineFamilyKeys.state,
  dependentDeletionKeys: [bendMachineInstanceKeys.state],
  mutateResponse: ({ bendMachineFamily: { id, ...other } = {}, bendMachineInstances = [] }) => ({
    bendMachineFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendMachineInstances: bendMachineInstances.map(instance => ({
        ...instance,
        bendMachineFamily: `${id}`,
        bendMachineFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend machine deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete bend machine',
  },
});

export const processArchiveBendMachineFamiliesForPipeFamily = (pipeId, bendMachineIds) => bendMachineClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveBendMachineFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_machine_families/archive`, {
  mutateResponse: ({ bendMachineFamilies = [] }) => ({
    bendMachineFamilies: bendMachineFamilies.map(({ id, bendMachineInstances = [], ...other }) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendMachineInstances: bendMachineInstances.map(instance => ({
        ...instance,
        bendMachineFamily: `${id}`,
        bendMachineFamilyId: `${id}`,
      })),
    })),
  }),
  data: { ids: bendMachineIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend machine archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive bend machine',
  },
});

export const processUnarchiveBendMachineFamiliesForPipeFamily = (pipeId, bendMachineIds) => bendMachineClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveBendMachineFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_machine_families/unarchive`, {
  mutateResponse: ({ bendMachineFamilies = [] }) => ({
    bendMachineFamilies: bendMachineFamilies.map(({ id, bendMachineInstances = [], ...other }) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendMachineInstances: bendMachineInstances.map(instance => ({
        ...instance,
        bendMachineFamily: `${id}`,
        bendMachineFamilyId: `${id}`,
      })),
    })),
  }),
  data: { ids: bendMachineIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend machine unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive bend machine',
  },
});