import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_TABLE_ROWS';

export const fetchAllTableRowTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createTableRowTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyTableRowTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editTableRowTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteTableRowTypes = generateAPHTypes(
  resource,
  'DELETE'
);