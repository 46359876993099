import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import transitions from '../../../../assets/themes/base/transitions';
import space from '../../../../assets/themes/base/space';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

const BaseContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 20rem;
  top: 0;
  bottom: 0;
  left: -20rem;

  transition: left ${transitions.speed.fast} ${transitions.type.inOut};

  ${
  ({ isFiltering }) => isFiltering && css`
      left: 0;
    `
}
`;

const Inner = styled(Flex)`
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;

  transition: padding-top ${transitions.speed.fast} ${transitions.type.inOut},
    padding-bottom ${transitions.speed.fast} ${transitions.type.inOut},
    height ${transitions.speed.fast} ${transitions.type.inOut};

  ${
  ({ isMinimized }) => isMinimized && css`
      padding-top: ${space[4]};
      padding-bottom: ${space[4]};
    `
}
`;

const FormWrapper = styled(Flex).attrs(_ => ({
  bg: 'gray.2',
}))`
  display: flex;
  flex-direction: column;
  width: 20rem;

  transition: border-top ${transitions.speed.fast} ${transitions.type.inOut},
    border-top-right-radius ${transitions.speed.fast} ${transitions.type.inOut},
    border-bottom ${transitions.speed.fast} ${transitions.type.inOut},
    border-bottom-right-radius ${transitions.speed.fast} ${transitions.type.inOut};

  border-right: 1px solid ${colors.gray[4]};

  ${
  ({ isMinimized }) => isMinimized && css`
      overflow: hidden;

      border-top: 1px solid ${colors.gray[4]};
      border-bottom: 1px solid ${colors.gray[4]};

      border-top-right-radius: ${radii[2]};
      border-bottom-right-radius: ${radii[2]};
    `
}
`;

const Container = React.forwardRef((props, ref) => <BaseContainer ref={ref} { ...props } />);

const FilterSidebar = {
  Container,
  Inner,
  FormWrapper,
};

export default FilterSidebar;
