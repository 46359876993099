import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.PARAMETER_PROFILES.state;

export const fetchAllParameterProfilesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualParameterProfileTypes = generateAPHTypes(resource, 'FETCH');

export const createParameterProfileTypes = generateAPHTypes(resource, 'CREATE');

export const copyParameterProfileTypes = generateAPHTypes(resource, 'COPY');

export const editParameterProfileTypes = generateAPHTypes(resource, 'EDIT');

export const deleteParameterProfileTypes = generateAPHTypes(resource, 'DELETE');

export const archiveParameterProfilesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveParameterProfilesTypes = generateAPHTypes(resource, 'UNARCHIVE');
