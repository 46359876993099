import React from 'react';

export default function FitElbowIcon({ size = 16, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <g>
        <path d="M11.441,21.917h1c0-5.124,4.144-9.292,9.239-9.292v-1C16.034,11.625,11.441,16.242,11.441,21.917z" />
        <path d="M4.441,21.917h1c0-8.983,7.285-16.292,16.239-16.292v-1C12.174,4.625,4.441,12.382,4.441,21.917z" />
      </g>
      <rect x="4.441" y="21.417" fill="#231F20" width="8" height="1" />
      <rect x="21.18" y="4.625" fill="#231F20" width="1" height="8" />
    </svg>
  );
}
