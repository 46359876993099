import { toast } from 'react-toastify';

import endTypeCompatibilityClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import endTypeCompatibilityKeys from './keys';

import {
  fetchAllEndTypeCompatibilitiesTypes,
  createEndTypeCompatibilitiesTypes,
  updateEndTypeCompatibilityTypes,
  removeEndTypeCompatibilityTypes,
} from './constants';

export const processFetchAllEndTypeCompatibilities = () => endTypeCompatibilityClient(REQUEST_TYPES.GET, 'plural', fetchAllEndTypeCompatibilitiesTypes, '/piping/end_type_compatibilities');

export const processCreateEndTypeCompatibilities = data => endTypeCompatibilityClient(REQUEST_TYPES.POST, 'plural', createEndTypeCompatibilitiesTypes, '/piping/end_type_compatibilities', {
  data: {
    endTypeCompatibilities: data,
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'End type compatibilities saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save end type compatibilities',
  },
});

export const processUpdateEndTypeCompatibility = (endTypeCompatibilityId, data) => endTypeCompatibilityClient(REQUEST_TYPES.PATCH, 'single', updateEndTypeCompatibilityTypes, `/piping/end_type_compatibilities/${endTypeCompatibilityId}`, {
  data,
  // successNotification: {
  //   type: toast.TYPE.SUCCESS,
  //   message: 'End type compatibilities saved',
  // }, // removed to prevent mass updates from covering the screen
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save end type compatibilities',
  },
});

export const processRemoveEndTypeCompatibility = endTypeCompatibilityId => endTypeCompatibilityClient(REQUEST_TYPES.DELETE, 'single', removeEndTypeCompatibilityTypes, `/piping/end_type_compatibilities/${endTypeCompatibilityId}`, {
  deleteKey: endTypeCompatibilityKeys.state,
  // successNotification: {
  //   type: toast.TYPE.SUCCESS,
  //   message: 'End type compatibilities saved',
  // },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save end type compatibilities',
  },
});