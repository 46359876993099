import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';

import Notice from '../../../containers/common/Notice';

import { selectIsSavingCurrentNoteProfile } from './selectors';

const mapStateToProps = createStructuredSelector({
  isSaving: selectIsSavingCurrentNoteProfile(),
});

const enhance = compose(
  connect(mapStateToProps)
);

const NoteProfilesSavingNotices = ({ isSaving }) => isSaving && <Notice id='noteProfilesEditSaving' message='Saving data in the background...' position={toast.POSITION.BOTTOM_RIGHT} /> || null;

export default enhance(NoteProfilesSavingNotices);