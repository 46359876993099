import React from 'react';

import useToggle from '../../../hooks/useToggle';

import Icon from '../../../components/common/Icon';

import RDXTextInput from '../RDXTextInput';

const RDXPasswordInput = props => {
  const [isVisible, toggleVisibility] = useToggle();

  return (
    <RDXTextInput 
      type={isVisible ? 'text' : 'password'}
      append={<Icon color='gray.6' name={ isVisible ? 'visible-off' : 'visible' } />} 
      onAppendClick={toggleVisibility}
      { ...props }
    />
  );
};

export default RDXPasswordInput;