import EK from '../../keys';

import baseSchema from '../../../modules/entities/utils/schema';

import profileSchema from '../Profiles/schema';

const profileDimensionSchema = baseSchema(EK.DIMENSIONS.state, {
  profile: { schema: profileSchema, isId: true}
});

export default profileDimensionSchema;
