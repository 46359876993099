import React from 'react';

import styled, { keyframes } from 'styled-components';
import colors from '../../../assets/themes/base/colors';

import Box from '../Box';
import Flex from '../Flex';

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningGearContainer = styled(Box)`
  animation: ${spin} 1.5s linear infinite;
`;

const spinReverse = keyframes`
  100% {
    transform: rotate(-360deg);
  }
`;

const ReverseSpinningGearContainer = styled(Box)`
  animation: ${spinReverse} 1.5s linear infinite;
`;

const GrayGear = styled.svg`
  fill: ${colors.gray[4]};
`;

const BlueGear = styled.svg`
  fill: ${colors.primary[4]};
`;

const DarkGear = styled.svg`
  fill: ${colors.gray[7]};
`;

const Loading = React.memo(() => (
  <Flex height='100%' width='100%' flexDirection='column' justifyContent='center' alignItems='center'>

    <Box bg='white' border={1} borderRadius={2} boxShadow={4}>
      <Box position='relative' height='170px' width='225px'>
        <SpinningGearContainer position='absolute' top='25px' left='70px' width='40px' height='40px'>
          <GrayGear viewBox='0 0 100 100'>
            <path d='M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z' />
          </GrayGear>
        </SpinningGearContainer>

        <ReverseSpinningGearContainer position='absolute' top='38px' left='100px' width='80px' height='80px'>
          <BlueGear viewBox='0 0 100 100'>
            <path d='M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z' />
          </BlueGear>
        </ReverseSpinningGearContainer>

        <SpinningGearContainer position='absolute' top='85px' left='52px' width='60px' height='60px'>
          <DarkGear viewBox='0 0 100 100'>
            <path d='M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z' />
          </DarkGear>
        </SpinningGearContainer>
      </Box>
    </Box>

  </Flex>
));

export default Loading;
