import borders from './base/borders';
import colors from './base/colors';
import fontSizes from './base/fontSizes';
import letterSpacings from './base/letterSpacings';
import lineHeights from './base/lineHeights';
import radii from './base/radii';
import shadows from './base/shadows';
import space from './base/space';
import transitions from './base/transitions';

export const baseHtmlFontSize = 16;

export default {
  borders,
  colors,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  transitions,
};
