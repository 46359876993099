import React from 'react';

import Text from '../../../common/Text';

import TooltipBody from '../../TooltipBody';
import TooltipSection from '../../TooltipSection';

const ArchivedEntityTooltip = React.memo(() => (
  <TooltipBody>
    <TooltipSection large error>
      <Text fontSize={3} fontWeight={500}>Archived</Text>
    </TooltipSection>
    <TooltipSection large>
      <Text color='error.6' fontSize={1}>
        This entity is currently <strong>archived</strong> and cannot be modified in any way until it is unarchived.
      </Text>
    </TooltipSection>
  </TooltipBody>
));

export default ArchivedEntityTooltip;