import { toast } from 'react-toastify';

import organizationClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { createOrganizationTypes, editOrganizationTypes } from './constants';

export const processCreateOrganization = data => organizationClient(REQUEST_TYPES.POST, 'single', createOrganizationTypes, '/organizations', { data });

export const processEditOrganization = (organizationId, data) => organizationClient(REQUEST_TYPES.PATCH, 'single', editOrganizationTypes, `/organizations/${organizationId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});


// import { put, select } from 'redux-saga/effects';
//
// import OrganizationSchema from './schema';
// import keys from './keys';
// import ENTITIES from '../../modules/entities/constants';
// import * as ActionTypes from '../../utils/actions';
// import { reload as reloadCurrentUser } from '../../modules/CurrentUser/actions';
//
// import { selectCurrentOrganizationId } from '../../modules/CurrentUser/selectors';
//
// export const create = ActionTypes.createAction(ENTITIES.CREATE, OrganizationSchema, {
//   url: '/organizations',
//   dataFn: ({ organization }) => organization,
//   transformDataFn: ({ organization }) => ({ [keys.plural]: [organization] }),
//   successFn: function* createOrganizationSuccessFn() {
//     yield put(reloadCurrentUser.request());
//   },
// });
//
// export const update = ActionTypes.updateAction(ENTITIES.UPDATE, OrganizationSchema, {
//   urlFn: function* updateOrganizationUrlFn(action) {
//     const organizationId = yield select(selectCurrentOrganizationId());
//     return `/organization/${organizationId}`;
//   },
//   dataFn: ({ organization }) => organization,
// });
