import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'NOTE_BLOCKS';

export const createNoteBlockTypes = generateAPHTypes(resource, 'CREATE');

export const editNoteBlockTypes = generateAPHTypes(resource, 'EDIT');

export const deleteNoteBlockTypes = generateAPHTypes(resource, 'DELETE');

export const sortNoteBlockNoteTypes = generateAPHTypes(resource, 'SORT_CHILDREN');