import React from 'react';

export default function FitHoseStrIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <path d="M10.063,15.75h-5.25V4.625h5.25V15.75z M5.813,14.75h3.25V5.625h-3.25V14.75z" />
      <path d="M24.625,14.688H13.5V5.844h11.125V14.688z M14.5,13.688h9.125V6.844H14.5V13.688z" />
      <rect x="3.98" y="4.625" width="1.332" height="1" />
      <rect x="3.98" y="14.75" width="1.332" height="1" />
      <rect x="9.563" y="7.917" width="4.438" height="1" />
      <rect x="9.563" y="11.375" width="4.438" height="1" />
    </svg>
  );
}
