import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';

import Button from '../../components/common/Button';

import ExternalPage from '../templates/ExternalPage';
import ExternalPageHeader from '../templates/ExternalPage/components/ExternalPageHeader';
import ExternalPageText from '../templates/ExternalPage/components/ExternalPageText';
import ExternalPageButtons from '../templates/ExternalPage/components/ExternalPageButtons';

import { UserIsNotAuthenticated } from '../../modules/auth/utils/routes';

import { selectIsPerforming } from '../../modules/utility/selectors';

import { selectIsAuthError } from '../../modules/auth/selectors';
import { processVerifyEmail } from '../../modules/auth/actions';

import {
  LoginPath,
  VerifyEmailPath,
} from '../../paths';

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerforming(),
  isError: selectIsAuthError(),
});

const enhance = compose(
  withRouter,
  UserIsNotAuthenticated,
);

function EmailVerification(props){
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.token) { dispatch(processVerifyEmail(props.match.params.token)); }
  }, []);
  return (
    <ExternalPage title={VerifyEmailPath.title}>
      <ExternalPageHeader>{ reduxProps.isPerforming ? 'Verifying Email...' : (reduxProps.isError ? 'Email Verification Error' : 'Logging you in...') }</ExternalPageHeader>
      <ExternalPageText>
        { reduxProps.isPerforming ? 'This should only take a moment.' : (reduxProps.isError ? 'There was an error verifying your email. Please make sure that the url is the same as the one we sent you in the confirmation email. If this error persists, feel free to contact our support team.' : 'Everything is good to go - just give us a moment to set everything up.') }
      </ExternalPageText>
      <ExternalPageButtons>
        { !reduxProps.isPerforming && reduxProps.isError && <Button primary as={Link} to={LoginPath.url}>Back to Log In</Button> }
      </ExternalPageButtons>
    </ExternalPage>
  );
}

export default enhance(EmailVerification);
