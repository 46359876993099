import { call, cancel, fork, put, select, takeEvery } from 'redux-saga/effects';
import isArray from 'lodash/isArray';

import { isNodeEnvDevelopment } from '../../configuration/environmentCheck';

import APH from './constants';
import { validateAPH } from './utils/validation';
import { ApiError, InvalidAPH, RequestError } from './utils/errors';
import actionWith from './utils/actionWith';

import client from './client';

export function* handleAPH(action) {
  // Try to dispatch an error request FSA for invalid APHs
  const validationErrors = yield call(validateAPH, action);

  if (validationErrors.length) {
    const callAPI = action.payload;
    if (callAPI.types && isArray(callAPI.types)) {
      let requestType = callAPI.types[0];
      if (requestType && requestType.type) {
        requestType = requestType.type;
      }
      yield put({
        type: requestType,
        payload: new InvalidAPH(validationErrors),
        error: true,
      });
    }
    yield cancel();
  }

  // Parse the validated APH action
  const callAPI = action.payload;
  let {
    endpoint,
    data,
  } = callAPI;
  const { method, bailout, types } = callAPI;
  const [requestType, successType, failureType] = types;

  // get the current state and hold it
  const state = yield select();

  // // Should we bail out?
  // try {
  //   if (
  //     (typeof bailout === 'boolean' && bailout) ||
  //     (typeof bailout === 'function' && bailout(getState()))
  //   ) {
  //     return;
  //   }
  // } catch (e) {
  //   return next(
  //     await actionWith(
  //       {
  //         ...requestType,
  //         payload: new RequestError('[APH].bailout function failed'),
  //         error: true
  //       },
  //       [action, getState()]
  //     )
  //   );
  // }

  // Process [APH].endpoint function
  if (typeof endpoint === 'function') {
    try {
      endpoint = yield select(endpoint);
    } catch (e) {
      yield put(actionWith(
        {
          ...failureType,
          payload: new RequestError('[APH].endpoint function failed'),
          error: true,
        },
        [action, state],
      ));
      yield cancel();
    }
  }

  // Process [APH].data function
  if (typeof data === 'function') {
    try {
      data = yield select(data);
    } catch (e) {
      yield put(actionWith(
        {
          ...failureType,
          payload: new RequestError('[APH].data function failed'),
          error: true,
        },
        [action, state],
      ));
      yield cancel();
    }
  }

  // We can now dispatch the request FSA
  const request = (
    typeof requestType.payload === 'function' ||
      typeof requestType.meta === 'function'
  )
    ? actionWith(requestType, [action, state])
    : requestType;

  try {
    yield put(request);

    const { data: response, error } = yield call(client, {
      method,
      url: `/api_proxy/api${endpoint}`,
      data,
    });

    if (response) {
      // this action dispatches entity normalization, status management, and cache management
      // refer to api/actions to see this in action - it's pretty slick boyos
      yield put(actionWith(successType, [action, state, response]));
    } else {
      yield put(actionWith(
        {
          ...failureType,
          payload: new ApiError(error.status, error.statusText, error),
          error: true,
        },
        [action, state, error],
      ));
    }
  } catch (e) {
    yield put(actionWith(
      {
        ...failureType,
        payload: e && e.response
          ? new ApiError(e.response.status, e.response.statusText, e.response)
          : new ApiError('CONN_REFUSED', 'Network Error', e),
        error: true,
      },
      [action, state],
    ));
    yield cancel();
  }
}

export function* watchAPHSaga() {
  yield takeEvery(APH, handleAPH);
}

export default function* main() {
  yield fork(watchAPHSaga);
}
