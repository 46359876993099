import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.BEND_SPRINGBACKS.state;

export const fetchAllBendSpringbackFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualBendSpringbackFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createBendSpringbackFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyBendSpringbackFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editBendSpringbackFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteBendSpringbackFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveBendSpringbackFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveBendSpringbackFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'UNARCHIVE');
