import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'connected-react-router';
import EK from '../../../entities/keys';
import { PipingModuleInsulationsEditPath } from '../../../paths';
import {
  createInsulationFamilyTypes,
  copyInsulationFamilyTypes,
} from '../../../entities/Piping/InsulationFamilies/constants';
import { selectNormalizedInsulationFamilies } from '../../../entities/Piping/InsulationFamilies/selectors';

export function* handleCreateInsulationFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.INSULATIONS.plural}[0].id`)}`;
  if (id) {
    let insulations = yield select(selectNormalizedInsulationFamilies());
    let copiedInsulation = insulations.get(id);

    while (!copiedInsulation) {
      yield take();
      insulations = yield select(selectNormalizedInsulationFamilies());
      copiedInsulation = insulations.get(id);
    }

    yield put(push(PipingModuleInsulationsEditPath.generate({ id })));
  }
}

export function* watchCreateInsulationSaga() {
  yield takeLatest([
    createInsulationFamilyTypes.SUCCESS,
    copyInsulationFamilyTypes.SUCCESS,
  ], handleCreateInsulationFamilySuccess
  );
}
// final output saga
export default function* main() {
  yield all([
    fork(watchCreateInsulationSaga),
  ]);
}
