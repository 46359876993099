import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectCurrentTable } from '../ProfileTables/selectors';

export const selectNormalizedItems = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_TABLE_ITEMS.state)
);

export const selectCurrentTableItems = () => createSelector(
  selectCurrentTable(),
  selectNormalizedItems(),
  (tableId, items) => items.filter(item => item.table == tableId).toList().toArray() || []
);

export const selectItemsFromTableId = (tableId) => createSelector(
  selectNormalizedItems(),
  (items) => {
    return items.filter( item => item.table == tableId ).toList().toArray();
  }
);