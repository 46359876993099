import React from 'react';

const AccessRestricted = ({ id = 'access-restricted-graphic', backgroundColor='#01C6DA', iconColor = '#F4DA80', iconAccentColor = '#F3B840', primaryColor = '#01BACC', errorColor = '#FF876C' }) => (
  <svg viewBox='0 0 116 105'>
    <defs>
      <path d='M53.142,104.909 C23.917,104.909 0.142,81.504 0.142,52.735 C0.142,23.965 23.917,0.56 53.142,0.56 C82.367,0.56 106.142,23.965 106.142,52.735 C106.142,81.504 82.367,104.909 53.142,104.909 Z' id={`${id}-path-1`}></path>
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g>
        <path d='M62.856,104.909 C33.631,104.909 9.856,81.504 9.856,52.735 C9.856,23.965 33.631,0.56 62.856,0.56 C92.081,0.56 115.856,23.965 115.856,52.735 C115.856,81.504 92.081,104.909 62.856,104.909' fill={backgroundColor}></path>
        <g transform='translate(9.714000, 0.000000)'>
          <mask id={`${id}-mask-2`} fill='white'>
            <use href={`#${id}-path-1`}></use>
          </mask>
          <polygon fill='#E5E8EC' mask={`url(#${id}-mask-2)`} points='39.175 84.607 106.142 84.607 106.142 20.631 39.175 20.631'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='39.175 26.743 106.142 26.743 106.142 20.631 39.175 20.631'></polygon>
          <polygon fill='#FFFFFF' mask={`url(#${id}-mask-2)`} points='70.146 25.215 106.142 25.215 106.142 22.159 70.146 22.159'></polygon>
          <path d='M43.658,23.687 C43.658,24.361 43.111,24.91 42.435,24.91 C41.759,24.91 41.212,24.361 41.212,23.687 C41.212,23.01 41.759,22.465 42.435,22.465 C43.111,22.465 43.658,23.01 43.658,23.687' fill='#424953' mask={`url(#${id}-mask-2)`}></path>
          <path d='M47.529,23.687 C47.529,24.361 46.981,24.91 46.306,24.91 C45.632,24.91 45.085,24.361 45.085,23.687 C45.085,23.01 45.632,22.465 46.306,22.465 C46.981,22.465 47.529,23.01 47.529,23.687' fill={errorColor} mask={`url(#${id}-mask-2)`}></path>
          <path d='M51.4,23.687 C51.4,24.361 50.853,24.91 50.177,24.91 C49.503,24.91 48.955,24.361 48.955,23.687 C48.955,23.01 49.503,22.465 50.177,22.465 C50.853,22.465 51.4,23.01 51.4,23.687' fill={primaryColor} mask={`url(#${id}-mask-2)`}></path>
          <polygon fill='#F7F8FC' mask={`url(#${id}-mask-2)`} points='46.861 84.107 106.142 84.107 106.142 34.715 46.861 34.715'></polygon>
          <path d='M64.791,48.607 C64.791,51.461 62.476,53.774 59.623,53.774 C56.769,53.774 54.457,51.461 54.457,48.607 C54.457,45.753 56.769,43.441 59.623,43.441 C62.476,43.441 64.791,45.753 64.791,48.607' fill='#B7BEC0' mask={`url(#${id}-mask-2)`}></path>
          <polyline fill='#B7BEC0' mask={`url(#${id}-mask-2)`} points='55.802 65.774 63.443 65.774 61.938 49.441 57.306 49.441 55.802 65.774'></polyline>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 47.035 97.207 47.035 97.207 44.801 68.207 44.801'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 56.042 76.384 56.042 76.384 55.125 68.207 55.125'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 60.405 106.142 60.405 106.142 59.489 68.207 59.489'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 62.578 106.142 62.578 106.142 61.66 68.207 61.66'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 64.749 81.449 64.749 81.449 63.832 68.207 63.832'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='55.81 69.934 106.142 69.934 106.142 69.018 55.81 69.018'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='55.81 72.107 106.142 72.107 106.142 71.191 55.81 71.191'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='55.81 74.279 106.142 74.279 106.142 73.363 55.81 73.363'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='55.81 76.451 106.142 76.451 106.142 75.535 55.81 75.535'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='55.81 78.622 74.156 78.622 74.156 77.704 55.81 77.704'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 51.697 106.142 51.697 106.142 50.781 68.207 50.781'></polygon>
          <polygon fill='#CDD5D7' mask={`url(#${id}-mask-2)`} points='68.207 53.869 99.845 53.869 99.845 52.952 68.207 52.952'></polygon>
        </g>
        <polygon fill={iconAccentColor} points='42.642 62.934 48.476 62.934 48.476 46.529 42.642 46.529'></polygon>
        <polygon fill={iconAccentColor} points='20.601 52.362 61.056 52.362 61.056 46.529 20.601 46.529'></polygon>
        <polygon fill={iconAccentColor} points='45.558 58.925 58.319 58.925 58.319 47.715 45.558 47.715'></polygon>
        <path d='M5.834,60.565 L17.134,60.565 L17.134,38.328 L5.834,38.328 L5.834,60.565 Z M5.105,32.494 L17.865,32.494 C20.683,32.494 22.968,34.779 22.968,37.598 L22.968,61.295 C22.968,64.113 20.683,66.399 17.865,66.399 L5.105,66.399 C2.285,66.399 0,64.113 0,61.295 L0,37.598 C0,34.779 2.285,32.494 5.105,32.494 Z' fill={iconAccentColor}></path>
        <path d='M5.105,61.295 L19.322,61.295 L19.322,36.14 L5.105,36.14 L5.105,61.295 Z M18.593,64.211 C20.603,64.211 22.238,62.575 22.238,60.565 L22.238,36.869 C22.238,34.859 20.603,33.223 18.593,33.223 L5.834,33.223 C3.824,33.223 2.187,34.859 2.187,36.869 L2.187,60.565 C2.187,62.575 3.824,64.211 5.834,64.211 L18.593,64.211 Z' fill={iconColor}></path>
        <polygon fill={iconAccentColor} points='52.487 62.934 58.319 62.934 58.319 46.529 52.487 46.529'></polygon>
        <polygon fill={iconColor} points='55.403 62.934 58.319 62.934 58.319 52.362 55.403 52.362'></polygon>
        <polygon fill={iconColor} points='45.558 62.934 48.476 62.934 48.476 52.362 45.558 52.362'></polygon>
        <polygon fill={iconColor} points='20.601 49.445 61.056 49.445 61.056 46.529 20.601 46.529'></polygon>
      </g>
    </g>
  </svg>
);

export default AccessRestricted;
