import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

// prettier-ignore
const layerStateSchema = baseSchema(
  EK.LAYER_STATES.state
);

export default layerStateSchema;
