import qs from 'qs';
import pathToRegexp from 'path-to-regexp';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

// TODO: pass in validations for url PARAMS and url QUERY
// this way, we can validate route and the generate() method
// can handle one object and split out properties as needed
class Path {
  constructor(path, { defaultTitle = 'Loading...', generateDefaultTitle, base = undefined, params = {}, query = {} } = {}) {
    this.path = base ? `${base.url}${path}` : path;
    this.defaultTitle = defaultTitle;
    if (generateDefaultTitle) {
      this.generateDefaultTitle = generateDefaultTitle.bind(this);
    } else {
      this.generateDefaultTitle = () => this.defaultTitle;
    }

    this.regexPath = pathToRegexp.compile(this.path);

    this.params = {
      ...(base ? base.params : {}),
      ...params,
    };

    this.query = {
      ...(base ? base.query : {}),
      ...query,
    };
  }

  generate = (opts = {}) => {
    const p = pick(opts, keys(this.params));
    const q = pick(opts, keys(this.query));

    return `${this.regexPath(p)}${!isEmpty(q) ? `?${qs.stringify(q)}` : ''}`;
  }

  get url() { return this.path; }
}

export default Path;
