import { createSelector } from 'reselect';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import selectDrafts from '../../../modules/drafts/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { formValueSelector } from 'redux-form/immutable';
import { newMaterialRows } from './constants';

import MaterialSchema from './schema';
import { MATERIAL_FILTERS } from './model';

import EK from '../../keys';

export const selectNormalizedMaterials = () => createSelector(
  selectEntities(),
  entities => entities.get('materials')
);
export const selectPipeMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.filter(({ isValidForPipe }) => isValidForPipe)
);
export const selectFittingMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.filter(({ isValidForFitting }) => isValidForFitting)
);
export const selectInsulationMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.filter(({ isValidForInsulation }) => isValidForInsulation)
);

export const selectMaterials = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('materials'), [MaterialSchema], entities)
);

const materialsFilterForm = formValueSelector(`filters.${EK.MATERIALS.state}`);

export const materialFilterSelector = () => createSelector(
  state => materialsFilterForm(state, 'code', 'description', 'density', 'filename', 'isValidForPipe', 'isValidForFitting', 'isValidForInsulation'),
  (filterVals) => filterVals
);

export const selectFilteredMaterials = () => createSelector(
  selectNormalizedMaterials(),
  materialFilterSelector(),
  selectSidebarIsFiltering(),
  (materials, query, isFiltering) => {
    return isFiltering ?
      materials.filter(item => item.doesMatchQuery(query)).toList().toArray() :
      materials.toList().toArray().concat(newMaterialRows);
  }
);

export const selectMaterialCount = () => createSelector(
  selectNormalizedMaterials(),
  (materials) => materials.toList().size
);