import React from 'react';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';

const CustomCircle = styled.div`
  height: ${({$size}) => $size || '25'}px !important;
  width: ${({ $size }) => $size || '25'}px !important;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'rgb(114 186 255)'};
  border-radius: 50%;
  display: inline-block;

  color: ${({$textColor}) => $textColor || 'white'};
  text-align: center;
  align-content: center;
`;

export default function ({size, content, backgroundColor, textColor}) {
  return (
    <CustomCircle $size={size} $backgroundColor={backgroundColor} $textColor={textColor} >
      {content}
    </CustomCircle>
  );
}