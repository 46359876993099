import { useEffect } from 'react';

import useLatest from './useLatest';

const useOnClickOutside = (ref, handler) => {
  // saving the latest ref of the handler here
  // which means we only ever run the latest one
  // after all renders are complete
  const handlerRef = useLatest(handler);

  useEffect(() => {
    if (!handler || !ref.current) return;

    const listener = (e) => {
      const isOutside = ref.current !== null && !ref.current.contains(e.target);

      if (!handlerRef.current || !isOutside) return;

      handlerRef.current(e);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };

    // make the dependency for this handler a boolean
    // based on the presence of the handler - the handlerRef
    // inside the function will always be the correct one
  }, [ref, !handler]);
};

export default useOnClickOutside;
