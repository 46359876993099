import { toast } from 'react-toastify';

import boltNutInstanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveBoltNutInstancesTypes } from './constants';

export const processSaveBoltNutInstances = (boltNutFamilyId, data) => boltNutInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveBoltNutInstancesTypes, `/piping/bolt_nut_families/${boltNutFamilyId}?diff_result=true`, {
  data: { boltNutInstancesAttributes: data },
  mutateResponse: ({ boltNutFamily: { id } = {}, boltNutInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    boltNutInstances: {
      created: created.map(instance => ({
        ...instance,
        boltNutFamily: `${id}`,
        boltNutFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        boltNutFamily: `${id}`,
        boltNutFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        boltNutFamily: `${id}`,
        boltNutFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
