import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.BEND_MACHINES.state;

export const fetchAllBendMachineFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualBendMachineFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createBendMachineFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyBendMachineFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editBendMachineFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteBendMachineFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveBendMachineFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveBendMachineFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'UNARCHIVE');
