import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '../../components/common/Button';

import theme from '../../assets/themes/theme';

import EmailOpenGraphic from '../../components/common/graphics/EmailOpen';

import ExternalPage from '../templates/ExternalPage';
import ExternalPageHeader from '../templates/ExternalPage/components/ExternalPageHeader';
import ExternalPageGraphic from '../templates/ExternalPage/components/ExternalPageGraphic';
import ExternalPageText from '../templates/ExternalPage/components/ExternalPageText';
import ExternalPageButtons from '../templates/ExternalPage/components/ExternalPageButtons';

import {
  LoginPath,
  EmailNotVerifiedPath,
} from '../../paths';

const NotVerified = () => (
  <ExternalPage title={EmailNotVerifiedPath.title}>
    <ExternalPageHeader>Email Confirmation Required</ExternalPageHeader>
    <ExternalPageGraphic>
      <EmailOpenGraphic backgroundColor={theme.colors.error[4]} iconColor={theme.colors.primary[4]} iconAccentColor={theme.colors.primary[6]} />
    </ExternalPageGraphic>
    <ExternalPageText>
      You need to confirm your email before you can continue. Please click on the confirmation link in the email we sent you.
    </ExternalPageText>
    <ExternalPageButtons>
      <Button primary as={Link} to={LoginPath.url}>Back to Log In</Button>
    </ExternalPageButtons>
  </ExternalPage>
);

export default withRouter(NotVerified);
