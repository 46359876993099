import React from 'react';
import Text from '../../common/Text';

/**
 * Renders the header for a port column in a grid.
 *
 * @param {string} hyperscript - The hyperscript value for the header.
 * @param {string} header - The header text.
 * @returns {JSX.Element} The rendered header component.
 */
function PortColumnHeader (hyperscript: string, header: string): JSX.Element {
  return (
    <>
      <Text style={{ fontSize: '.7rem' }} >{hyperscript}</Text>
      <Text >{header}</Text>
    </>
  );
}

export default PortColumnHeader;