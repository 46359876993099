import { List } from 'immutable';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import EntityRecord, { FILTERS } from '../../../modules/entities/model';

class SpecPipeInstance extends EntityRecord({
  pipeInstance: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleSerialize: true,
    serializeOutputField: 'pipeInstanceId',
    filter: FILTERS.DEEP_ENTITY,
  },
  // this is not saved in the schema to just save us a ton of hassle
  pipeFamilyId: {
    value: null,
    isEntity: true,
  },
  spec: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'specId',
  },
  stockno: {
    value: null,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  mccsCode: {
    value: null,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  constructor({ pipeFamilyId, ...other } = {}) {
    super({
      pipeFamilyId: !isNil(pipeFamilyId) ? `${pipeFamilyId}` : null,
      ...other,
    });
  }

  get isSelectedForSpec() {
    return true;
  }

  get summary() {
    return {
      primary: `${get(this, 'pipeInstance.sizeProperty.name', 'No Size')} // ${get(this, 'pipeInstance.schedule.value', 'No Schedule')}`,
      secondary: `${get(this, 'pipeInstance.endTypes', List()).reduce((endTypes, endType) => `${endTypes}${endType.name} `, '')}`
    };
  }
}

export default SpecPipeInstance;