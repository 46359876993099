export const processLogout = {
  HANDLE: 'LOGOUT/HANDLE',
  HANDLED: 'LOGOUT/HANDLED',
};

export const processLoginTypes = {
  REQUEST: 'AUTH/LOGIN/REQUEST',
  SUCCESS: 'AUTH/LOGIN/SUCCESS',
  FAILURE: 'AUTH/LOGIN/FAILURE',
};

export const samlLoginTypes = {
  SUCCESS: 'AUTH/SAML_LOGIN/SUCCESS',
  FAILURE: 'AUTH/SAML_LOGIN/FAILURE',
};

export const processRegisterTypes = {
  REQUEST: 'AUTH/REGISTER/REQUEST',
  SUCCESS: 'AUTH/REGISTER/SUCCESS',
  FAILURE: 'AUTH/REGISTER/FAILURE',
};

export const processVerifyEmailTypes = {
  REQUEST: 'AUTH/VERIFY_EMAIL/REQUEST',
  SUCCESS: 'AUTH/VERIFY_EMAIL/SUCCESS',
  FAILURE: 'AUTH/VERIFY_EMAIL/FAILURE',
};

export const processAcceptInviteTypes = {
  REQUEST: 'AUTH/ACCEPT_INVITE/REQUEST',
  SUCCESS: 'AUTH/ACCEPT_INVITE/SUCCESS',
  FAILURE: 'AUTH/ACCEPT_INVITE/FAILURE',
};

export const processFetchCurrentUserTypes = {
  REQUEST: 'AUTH/FETCH_CURRENT_USER/REQUEST',
  SUCCESS: 'AUTH/FETCH_CURRENT_USER/SUCCESS',
  FAILURE: 'AUTH/FETCH_CURRENT_USER/FAILURE',
};

export const processRequestPasswordResetTypes = {
  REQUEST: 'AUTH/REQUEST_PASSWORD_RESET/REQUEST',
  SUCCESS: 'AUTH/REQUEST_PASSWORD_RESET/SUCCESS',
  FAILURE: 'AUTH/REQUEST_PASSWORD_RESET/FAILURE',
};

export const processResetPasswordTypes = {
  REQUEST: 'AUTH/RESET_PASSWORD/REQUEST',
  SUCCESS: 'AUTH/RESET_PASSWORD/SUCCESS',
  FAILURE: 'AUTH/RESET_PASSWORD/FAILURE',
};

export const processForceResetPasswordTypes = {
  REQUEST: 'AUTH/FORCE_RESET_PASSWORD/REQUEST',
  SUCCESS: 'AUTH/FORCE_RESET_PASSWORD/SUCCESS',
  FAILURE: 'AUTH/FORCE_RESET_PASSWORD/FAILURE',
};