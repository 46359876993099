import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectNormalizedConfigProfileValues = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CONFIG_PROFILE_VALUES.state)
);
