import { toast } from 'react-toastify';

import miterInstanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { saveMiterInstancesTypes } from './constants';

export const processSaveMiterInstances = (familyId, data) => miterInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveMiterInstancesTypes, `/piping/miter_families/${familyId}?diff_result=true`, {
  data: { miterInstancesAttributes: data },
  mutateResponse: ({ miterFamily: { id } = {}, miterInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    miterInstances: {
      created: created.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
