import React from 'react';

import Button from '../../Button';

//actions for each row
const RowEndEntityTools = ({ rowdata, editAction, listAction, deleteAction, copyAction, archiveAction, unarchiveAction}) => {
  const hasArchiveButton = archiveAction && unarchiveAction;

  return (
    <>
      {editAction && !rowdata.archivedFlag && <Button icon="edit" onClick={() => editAction(rowdata)} />}
      {copyAction && <Button icon="copy" onClick={() => copyAction(rowdata)} />}
      {listAction && <Button icon="list" onClick={() => listAction(rowdata)} />}
      {hasArchiveButton && (rowdata.archivedFlag ? <Button icon="unarchive" iconFillColor='error.4' onClick={() => unarchiveAction(rowdata)} /> : <Button icon="archive" onClick={() => archiveAction(rowdata)} />)}
      {deleteAction && <Button icon="delete" onClick={() => deleteAction(rowdata)} />}
    </>
  );
};

export default RowEndEntityTools;