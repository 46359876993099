import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_TABLE_COLUMNS';

export const fetchAllTableColumnTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createTableColumnTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyTableColumnTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editTableColumnTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteTableColumnTypes = generateAPHTypes(
  resource,
  'DELETE'
);