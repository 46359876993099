import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'SIMPLIFIED_REPS';

export const fetchAllSimplifiedRepsTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createSimplifiedRepTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copySimplifiedRepTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editSimplifiedRepTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteSimplifiedRepTypes = generateAPHTypes(
  resource,
  'DELETE'
);
