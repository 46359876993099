import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { reloadModal } from '../../../../modules/modal/actions';

import { updateMasterCatalogTypes } from '../../../../entities/Piping/PipingCatalogs/constants';

import {
  SETTING_PRODUCTS,
} from '../../../../entities/Settings/model';

import {
  ORGANIZATION_SECTIONS,
} from '../OrganizationModal';

export function* handleCloseMasterCatalogUnitSystemModal() {
  yield put(reloadModal('ORGANIZATION_MODAL', { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.MODULE }, true));
}

export function* watchCloseMasterCatalogUnitSystemModalSaga() {
  yield takeLatest(updateMasterCatalogTypes.SUCCESS, handleCloseMasterCatalogUnitSystemModal);
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCloseMasterCatalogUnitSystemModalSaga),
  ]);
}
