/* eslint-disable */

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import styled, { css } from "styled-components";
import transitions from "../../assets/themes/base/transitions";
import space from "../../assets/themes/base/space";

import Box from "../../components/common/Box";
import Flex from "../../components/common/Flex";

import Breadcrumbs from "../templates/Structures/Breadcrumbs";
import Headers from "../templates/Structures/Headers";

import GettingStarted from "../templates/Structures/GettingStarted";

import PageNotFoundRedirect from "../../containers/common/PageNotFoundRedirect";

import Sidebar from "./Sidebar";
import FilterSidebar from "./FilterSidebar";

import {
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending,
} from "../../modules/auth/utils/routes";

import { processFetchAllUnits } from "../../entities/Synchronize/Units/actions";
import { processFetchAllUnitSystems } from "../../entities/Synchronize/UnitSystems/actions";
import { processFetchAllAppearances } from "../../entities/Synchronize/Appearances/actions";
import { processFetchAllSettings } from "../../entities/Settings/actions";

import { handleLogout } from "../../modules/auth/actions";

import { selectCurrentUser } from "../../entities/CurrentUser/selectors";

import { showModal } from "../../modules/modal/actions";
import { MODAL_SIZES } from "../../containers/modal/Modal";

import {
  DashboardPath,
  PipingModulePath,
  OrganizationPath,
  StandardizeModulePath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleEndTypesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleBoltNutsPath,
  PipingModuleBoltNutsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModuleMaterialsPath,
  PipingModuleInsulationSpecsPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  SynchronizeModuleAppearancesPath,
  StandardizeModuleLayersPath,
  StandardizeModuleNoteProfilesPath,
  NoteProfilesEditPath,
  StandardizeModuleNotesPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleParameterProfilesPath,
  ParameterProfilesEditPath,
  StandardizeModuleParametersPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleModelTemplatesPath,
  SynchronizeModulePath,
  SynchronizeModuleConfigProfilesPath,
  SynchronizeModuleMapkeysPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  FrameworkModuleProfileLibrariesBasePath,
  FrameworkModuleProfilePath,
  FrameworkModuleProfileTablesPath,
  FrameworkModuleTablePath,
} from "../../paths";

import injectReducer from "../../utils/reducers/injectReducer";

import dashboardReducer from "./reducer";

import {
  selectIsLoadingInitialData,
  selectSidebarIsMinimized,
  selectSidebarIsFiltering,
} from "./selectors";

import breadcrumbs from "./breadcrumbs";

import PipingModuleBody from "../PipingModule/Body";

import SchedulesBody from "../Piping/Schedules/Body";

import SizesBody from "../Piping/Sizes/Body";

import EndTypesBody from "../Piping/EndTypes/Body";

import FittingCategoriesBody from "../Piping/FittingCategories/Body";

import MaterialsBody from "../Piping/Materials/Body";

import BoltNutsBody from "../Piping/BoltNuts/Body";

import BoltNutsEditBack from "../Piping/BoltNutsEdit/Back";
import BoltNutsEditBody from "../Piping/BoltNutsEdit/Body";

import InsulationsBody from "../Piping/Insulations/Body";
import InsulationsNotices from "../Piping/Insulations/Notices";

import InsulationsEditBack from "../Piping/InsulationsEdit/Back";
import InsulationsEditBody from "../Piping/InsulationsEdit/Body";

import FittingsBody from "../Piping/Fittings/Body";
import FittingsNotices from "../Piping/Fittings/Notices";

import FittingsEditBack from "../Piping/FittingsEdit/Back";
import FittingsEditBody from "../Piping/FittingsEdit/Body";

import PipesBody from "../Piping/Pipes/Body";
import PipesNotices from "../Piping/Pipes/Notices";

import PipesEditSwitch from "../PipesEditSwitch/Body";

import PipesEditBack from "../Piping/PipesEdit/Back";
import PipesEditBody from "../Piping/PipesEdit/Body";

import PipesBendsBack from "../Piping/PipesBends/Back";
import PipesBendsBody from "../Piping/PipesBends/Body";

import PipesBendsEditBack from "../Piping/PipesBendsEdit/Back";
import PipesBendsEditBody from "../Piping/PipesBendsEdit/Body";

import PipesBendMachinesBack from "../Piping/PipesBendMachines/Back";
import PipesBendMachinesBody from "../Piping/PipesBendMachines/Body";

import PipesBendMachinesEditBack from "../Piping/PipesBendMachinesEdit/Back";
import PipesBendMachinesEditBody from "../Piping/PipesBendMachinesEdit/Body";

import PipesBendSpringbacksBack from "../Piping/PipesBendSpringbacks/Back";
import PipesBendSpringbacksBody from "../Piping/PipesBendSpringbacks/Body";

import PipesBendSpringbacksEditBack from "../Piping/PipesBendSpringbacksEdit/Back";
import PipesBendSpringbacksEditBody from "../Piping/PipesBendSpringbacksEdit/Body";

import PipesMitersBack from "../Piping/PipesMiters/Back";
import PipesMitersBody from "../Piping/PipesMiters/Body";

import PipesMitersEditBack from "../Piping/PipesMitersEdit/Back";
import PipesMitersEditBody from "../Piping/PipesMitersEdit/Body";

import SpecsSwitch from "../SpecsSwitch/Body";
import SpecsBody from "../Piping/Specs/Body";
import SpecsNotices from "../Piping/Specs/Notices";

import SpecsEditSwitch from "../SpecsEditSwitch/Body";

import SpecsFittingsEditBack from "../Piping/SpecsFittingsEdit/Back";
import SpecsFittingsEditBody from "../Piping/SpecsFittingsEdit/Body";
import SpecsFittingsEditNotices from "../Piping/SpecsFittingsEdit/Notices";

import SpecsFittingInstancesEditBack from "../Piping/SpecsFittingInstancesEdit/Back";
import SpecsFittingInstancesEditBody from "../Piping/SpecsFittingInstancesEdit/Body";
import SpecsFittingInstancesEditNotices from "../Piping/SpecsFittingInstancesEdit/Notices";

import SpecsPipesEditBack from "../Piping/SpecsPipesEdit/Back";
import SpecsPipesEditBody from "../Piping/SpecsPipesEdit/Body";
import SpecsPipesEditNotices from "../Piping/SpecsPipesEdit/Notices";

import SpecsPipeInstancesEditBack from "../Piping/SpecsPipeInstancesEdit/Back";
import SpecsPipeInstancesEditBody from "../Piping/SpecsPipeInstancesEdit/Body";
import SpecsPipeInstancesEditNotices from "../Piping/SpecsPipeInstancesEdit/Notices";

import ProjectsBody from "../Piping/Projects/Body";
import ProjectsNotices from "../Piping/Projects/Notices";

import ProjectsEditBack from "../Piping/ProjectsEdit/Back";
import ProjectsEditBody from "../Piping/ProjectsEdit/Body";

import InsulationSpecsBody from "../Piping/InsulationSpecs/Body";

// Synchronize Components
import SynchronizeModuleBody from "../SynchronizeModule/Body";

import AppearancesBody from "../Synchronize/Appearances/Body";

import StandardizeModuleBody from "../StandardizeModule/Body";

import ExplodeStatesBody from '../Standardize/ExplodeStates/Body';

import CombinedStateBody from '../Standardize/CombinedStates/Body';

import LayerStateBody from '../Standardize/LayerStates/Body';

import AppearanceStateBody from '../Standardize/AppearanceStates/Body';

import StyleStatesBody from '../Standardize/StyleStates/Body';

import LayersBody from "../Standardize/Layers/Body";

import NoteProfilesBody from "../Standardize/NoteProfiles/Body";
import NoteProfilesNotices from "../Standardize/NoteProfiles/Notices";

import NoteProfilesEditBack from "../Standardize/NoteProfilesEdit/Back";
import NoteProfilesEditHeader from "../Standardize/NoteProfilesEdit/Header";
import NoteProfilesEditOptions from "../Standardize/NoteProfilesEdit/Options";
import NoteProfilesEditBody from "../Standardize/NoteProfilesEdit/Body";
import NoteProfilesEditNotices from "../Standardize/NoteProfilesEdit/Notices";

import NotesBody from "../Standardize/Notes/Body";

import OrientationsBody from "../Standardize/Orientations/Body";

import ParameterProfilesBody from "../Standardize/ParameterProfiles/Body";
import ParameterProfilesNotices from "../Standardize/ParameterProfiles/Notices";

import ParameterProfilesEditBack from "../Standardize/ParameterProfilesEdit/Back";
import ParameterProfilesEditBody from "../Standardize/ParameterProfilesEdit/Body";

import ParametersBody from "../Standardize/Parameters/Body";

import SectionsBody from "../Standardize/Sections/Body";

import SimplifiedRepsBody from "../Standardize/SimplifiedReps/Body";
import SimplifiedRepsNotices from "../Standardize/SimplifiedReps/Notices";

import ModelTemplatesBody from "../Standardize/ModelTemplates/Body";
import ModelTemplatesNotices from "../Standardize/ModelTemplates/Notices";

import MapkeysBody from "../Synchronize/Mapkeys/Body";

import ConfigProfilesBody from "../Synchronize/ConfigProfiles/Body";

// framework components
import FrameworkModuleSwitch from '../Framework/Base/Switch';

import ProfileLibrariesBody from '../Framework/ProfileLibraries/Body';
import ProfileBody from '../Framework/Profile/Body';
import ProfileTablesBody from '../Framework/ProfileTables/Body';
import TableBody from '../Framework/Table/Body';

// <span className={cx('raptorInstructions')}>Up, Up, Down, Down, Left, Right, Left, Right, B, A</span>

const DashboardPage = styled(Box)`
  height: 100vh;
  position: absolute;

  transition: left ${transitions.speed.fast}
      ${transitions.type.inOut},
    right ${transitions.speed.fast}
      ${transitions.type.inOut};

  will-change: left, right;

  left: 20rem;
  right: 0;

  ${({ $isFullPage }) =>
    $isFullPage &&
    css`
      left: 2rem;
      right: 1rem;
    `}
`;

const DashboardSuperHeader = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: ${space[5]};
  padding-right: ${space[6]};
  padding-left: ${space[6]};
  margin-bottom: ${space[2]};
`;

const DashboardBody = styled(Flex)`
  height: calc(100% - 6rem);
`;

const Options = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
`;

const CadactiveBreadcrumbs = withBreadcrumbs(breadcrumbs, {
  excludePaths: ["/"],
})(Breadcrumbs);

const Dashboard = ({
  currentUser,
  isLoading,
  isMinimized,
  isFiltering,
  showCurrentUserModal,
  showPrivacyPolicyModal,
  logout,

  fetchUnits,
  fetchUnitSystems,
  fetchAppearances,
  fetchSettings,
  showLegalTermsUpdatedModal,
}) => {
  useEffect(() => {
    fetchUnits();
    fetchUnitSystems();
    fetchAppearances();
    fetchSettings();

    if (!currentUser.hasAcceptedEula) {
      showLegalTermsUpdatedModal();
    }
  }, []);

  return (
    <Flex height="100vh" width="100vw" flexDirection="row">
      <Sidebar
        currentUser={currentUser}
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
        showCurrentUserModal={showCurrentUserModal}
        showPrivacyPolicyModal={showPrivacyPolicyModal}
        logout={logout}
      />

      <FilterSidebar
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
      />

      <DashboardPage $isFullPage={isMinimized && !isFiltering}>
        <DashboardSuperHeader>
          <Switch>
            <Route
              exact
              path={PipingModuleBoltNutsEditPath.url}
              component={BoltNutsEditBack}
            />
            <Route
              exact
              path={PipingModuleInsulationsEditPath.url}
              component={InsulationsEditBack}
            />
            <Route
              exact
              path={PipingModuleFittingsEditPath.url}
              component={FittingsEditBack}
            />
            <Route
              exact
              path={PipingModulePipesEditPath.url}
              component={PipesEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendsPath.url}
              component={PipesBendsBack}
            />
            <Route
              exact
              path={PipingModulePipesBendsEditPath.url}
              component={PipesBendsEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesPath.url}
              component={PipesBendMachinesBack}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesEditPath.url}
              component={PipesBendMachinesEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksPath.url}
              component={PipesBendSpringbacksBack}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksEditPath.url}
              component={PipesBendSpringbacksEditBack}
            />
            <Route
              exact
              path={PipingModulePipesMitersPath.url}
              component={PipesMitersBack}
            />
            <Route
              exact
              path={PipingModulePipesMitersEditPath.url}
              component={PipesMitersEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditBack}
            />
            <Route
              exact
              path={PipingModuleProjectsEditPath.url}
              component={ProjectsEditBack}
            />
            <Route
              exact
              path={NoteProfilesEditPath.url}
              component={NoteProfilesEditBack}
            />
            <Route
              exact
              path={ParameterProfilesEditPath.url}
              component={ParameterProfilesEditBack}
            />
          </Switch>
          <CadactiveBreadcrumbs isLoading={isLoading} />
        </DashboardSuperHeader>

        <DashboardBody flexDirection="column" mr={6} ml={6}>
          <Flex flexDirection="row" mb={4}>
            <Headers>
              <Switch>
                <Route
                  exact
                  path={NoteProfilesEditPath.url}
                  component={NoteProfilesEditHeader}
                />
              </Switch>
            </Headers>

            <Options>
              <Switch>
                <Route
                  exact
                  path={NoteProfilesEditPath.url}
                  component={NoteProfilesEditOptions}
                />
              </Switch>
            </Options>
          </Flex>

          <Switch>
            <Route exact path={DashboardPath.url} component={GettingStarted} />
            <Route
              path={PipingModulePath.url}
              render={() => (
                <PipingModuleBody>
                  <Route
                    exact
                    path={PipingModuleSchedulesPath.url}
                    component={SchedulesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleSizesPath.url}
                    component={SizesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleEndTypesPath.url}
                    component={EndTypesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingCategoriesPath.url}
                    component={FittingCategoriesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleMaterialsPath.url}
                    component={MaterialsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleBoltNutsPath.url}
                    component={BoltNutsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleBoltNutsEditPath.url}
                    component={BoltNutsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationsPath.url}
                    component={InsulationsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationsEditPath.url}
                    component={InsulationsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingsPath.url}
                    component={FittingsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingsEditPath.url}
                    component={FittingsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModulePipesPath.url}
                    component={PipesBody}
                  />
                  <Route
                    path={PipingModulePipesEditPath.url}
                    render={() => (
                      <PipesEditSwitch>
                        <Route
                          exact
                          path={PipingModulePipesEditPath.url}
                          component={PipesEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendsPath.url}
                          component={PipesBendsBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendsEditPath.url}
                          component={PipesBendsEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendMachinesPath.url}
                          component={PipesBendMachinesBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendMachinesEditPath.url}
                          component={PipesBendMachinesEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendSpringbacksPath.url}
                          component={PipesBendSpringbacksBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendSpringbacksEditPath.url}
                          component={PipesBendSpringbacksEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesMitersPath.url}
                          component={PipesMitersBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesMitersEditPath.url}
                          component={PipesMitersEditBody}
                        />
                        <PageNotFoundRedirect />
                      </PipesEditSwitch>
                    )}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationSpecsPath.url}
                    component={InsulationSpecsBody}
                  />
                  <Route
                    path={PipingModuleSpecsPath.url}
                    render={() => (
                      <SpecsSwitch>
                        <Route
                          exact
                          path={PipingModuleSpecsPath.url}
                          component={SpecsBody}
                        />
                        <Route
                          path={PipingModuleSpecsEditPath.url}
                          render={() => (
                            <SpecsEditSwitch>
                              <Redirect
                                exact
                                from={PipingModuleSpecsEditPath.url}
                                to={PipingModuleSpecsPipesEditPath.url}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsFittingsEditPath.url
                                }
                                component={SpecsFittingsEditBody}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsFittingInstancesEditPath.url
                                }
                                component={SpecsFittingInstancesEditBody}
                              />
                              <Route
                                exact
                                path={PipingModuleSpecsPipesEditPath.url}
                                component={SpecsPipesEditBody}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsPipeInstancesEditPath.url
                                }
                                component={SpecsPipeInstancesEditBody}
                              />
                              <PageNotFoundRedirect />
                            </SpecsEditSwitch>
                          )}
                        />
                        <PageNotFoundRedirect />
                      </SpecsSwitch>
                    )}
                  />
                  <Route
                    exact
                    path={PipingModuleProjectsPath.url}
                    component={ProjectsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleProjectsEditPath.url}
                    component={ProjectsEditBody}
                  />
                  <Redirect
                    exact
                    from={PipingModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </PipingModuleBody>
              )}
            />

            <Route path={SynchronizeModulePath.url}
              render={() => (
                <SynchronizeModuleBody>
                  <Route
                    exact
                    path={SynchronizeModuleAppearancesPath.url}
                    component={AppearancesBody}
                  />
                  <Route
                    exact
                    path={SynchronizeModuleConfigProfilesPath.url}
                    component={ConfigProfilesBody}
                  />
                  <Route
                    exact
                    path={SynchronizeModuleMapkeysPath.url}
                    component={MapkeysBody}
                  />
                  <Redirect
                    exact
                    from={SynchronizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </SynchronizeModuleBody>
              )}
            />

            <Route
              path={StandardizeModulePath.url}
              render={() => (
                <StandardizeModuleBody>
                  <Route
                    exact
                    path={StandardizeModuleLayersPath.url}
                    component={LayersBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleNoteProfilesPath.url}
                    component={NoteProfilesBody}
                  />
                  <Route
                    exact
                    path={NoteProfilesEditPath.url}
                    component={NoteProfilesEditBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleNotesPath.url}
                    component={NotesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleOrientationsPath.url}
                    component={OrientationsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleParameterProfilesPath.url}
                    component={ParameterProfilesBody}
                  />
                  <Route
                    exact
                    path={ParameterProfilesEditPath.url}
                    component={ParameterProfilesEditBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleParametersPath.url}
                    component={ParametersBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleSectionsPath.url}
                    component={SectionsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleSimplifiedRepsPath.url}
                    component={SimplifiedRepsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleModelTemplatesPath.url}
                    component={ModelTemplatesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleExplodeStatesPath.url}
                    component={ExplodeStatesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleCombinedStatesPath.url}
                    component={CombinedStateBody}
                  />
                  <Route
                  exact
                  path={StandardizeModuleLayerStatesPath.url}
                  component={LayerStateBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleAppearanceStatesPath.url}
                    component={AppearanceStateBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleStyleStatesPath.url}
                    component={StyleStatesBody}
                  />
                  <Redirect
                    exact
                    from={StandardizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </StandardizeModuleBody>
              )}
            />

            <Route
              path={FrameworkModulePath.url}
              render={ () => (
                <FrameworkModuleSwitch>
                  <Route
                    exact
                    path={FrameworkModuleProfileLibrariesPath.url}
                    component={ProfileLibrariesBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleProfilePath.url}
                    component={ProfileBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleProfileTablesPath.url}
                    component={ProfileTablesBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleTablePath.url}
                    component={TableBody}
                  />
                  <Redirect
                    exact
                    from={FrameworkModuleProfileLibrariesBasePath.url}
                    to={FrameworkModuleProfileLibrariesPath.url}
                  />
                  <Redirect
                    exact
                    from={FrameworkModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </FrameworkModuleSwitch>
              )}
            />
            <PageNotFoundRedirect />
          </Switch>

          <Switch>
            <Route
              exact
              path={PipingModuleInsulationsPath.url}
              component={InsulationsNotices}
            />
            <Route
              exact
              path={PipingModuleFittingsPath.url}
              component={FittingsNotices}
            />
            <Route
              exact
              path={PipingModulePipesPath.url}
              component={PipesNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPath.url}
              component={SpecsNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditNotices}
            />
            <Route
              exact
              path={PipingModuleProjectsPath.url}
              component={ProjectsNotices}
            />
            <Route
              exact
              path={StandardizeModuleNoteProfilesPath.url}
              component={NoteProfilesNotices}
            />
            <Route
              exact
              path={NoteProfilesEditPath.url}
              component={NoteProfilesEditNotices}
            />
            <Route
              exact
              path={StandardizeModuleParameterProfilesPath.url}
              component={ParameterProfilesNotices}
            />
            <Route
              exact
              path={StandardizeModuleSimplifiedRepsPath.url}
              component={SimplifiedRepsNotices}
            />
          </Switch>
        </DashboardBody>
      </DashboardPage>
    </Flex>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isLoading: selectIsLoadingInitialData(),
  isMinimized: selectSidebarIsMinimized(),
  isFiltering: selectSidebarIsFiltering(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnits() {
    dispatch(processFetchAllUnits());
  },
  fetchUnitSystems() {
    dispatch(processFetchAllUnitSystems());
  },
  fetchAppearances() {
    dispatch(processFetchAllAppearances());
  },
  fetchSettings() {
    dispatch(processFetchAllSettings());
  },
  showCurrentUserModal() {
    dispatch(showModal("CURRENT_USER_MODAL"));
  },
  showPrivacyPolicyModal() {
    dispatch(
      showModal("PRIVACY_POLICY_MODAL", { modalSize: MODAL_SIZES.XLARGE })
    );
  },
  showLegalTermsUpdatedModal() {
    dispatch(
      showModal("LEGAL_TERMS_UPDATED_MODAL", { modalSize: MODAL_SIZES.SMALL })
    );
  },
  logout() {
    dispatch(handleLogout());
  },
});

const enhance = compose(
  withRouter,
  injectReducer({ key: "dashboard", reducer: dashboardReducer }),
  connect(mapStateToProps, mapDispatchToProps),
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending
);

export default enhance(Dashboard);
