import baseSchema from '../../../modules/entities/utils/schema';

import keys from './keys';

import EndTypeSchema from '../EndTypes/schema';
import ScheduleSchema from '../Schedules/schema';

const endTypeCompatibilitySchema = baseSchema(keys.state, {
  firstEndType: { schema: EndTypeSchema, isId: true },
  firstSchedule: { schema: ScheduleSchema, isId: true },
  secondEndType: { schema: EndTypeSchema, isId: true },
  secondSchedule: { schema: ScheduleSchema, isId: true },
});

export default endTypeCompatibilitySchema;
