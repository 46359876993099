import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import ModelTemplateForm from '../../../form/templates/ModelTemplateForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import ModelTemplateModel from '../../../../entities/Standardize/ModelTemplates/model';
import {
  processCreateModelTemplate,
  processCopyModelTemplate,
  processEditModelTemplate,
  processDeleteModelTemplate,
} from '../../../../entities/Standardize/ModelTemplates/actions';

import { selectCurrentModelTemplate } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyModelTemplate = new ModelTemplateModel();

const ModelTemplateModal = ({
  modelTemplate,
  copyEntity,
  isPerforming,
  onCreate,
  onCopy,
  onUpdate,
  onDelete,
}) => {
  const onSave = useCallback(
    (data) => {
      if (modelTemplate) {
        if (copyEntity) {
          onCopy({ ...data, id: modelTemplate.id });
        } else {
          onUpdate(modelTemplate.id, data);
        }
      } else {
        onCreate(data);
      }
    },
    [modelTemplate, copyEntity, onCreate, onCopy, onUpdate]
  );

  const onConfirmDelete = useCallback(
    () => onDelete(modelTemplate.id),
    [modelTemplate, onDelete]
  );

  return (
    <Box>
      <CreateOrEditEntityTitle
        edit={modelTemplate && modelTemplate.name}
        copy={copyEntity}
        create="Model Template"
      />

      <ModelTemplateForm
        initialValues={
          modelTemplate || emptyModelTemplate
        }
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {modelTemplate && !copyEntity && (
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm
            deletePhrase={modelTemplate.name}
            isPerforming={isPerforming}
            onRDXSubmit={onConfirmDelete}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  modelTemplate: selectCurrentModelTemplate(),
});

const enhance = compose(
  injectSaga({ key: `${EK.MODEL_TEMPLATES.state}Modal`, saga }),
  connect(mapStateToProps, {
    onCreate: processCreateModelTemplate,
    onCopy: processCopyModelTemplate,
    onUpdate: processEditModelTemplate,
    onDelete: processDeleteModelTemplate,
  })
);

export default enhance(ModelTemplateModal);
