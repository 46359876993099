import axios from 'axios';
import humps from 'humps';

import { isTokenPresent, getToken } from '../auth/utils/token';

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
    common: {
      ...(isTokenPresent() ? { Authorization: `JWT ${getToken()}` } : {}),
    },
  },
  transformRequest: [(data) => JSON.stringify(humps.decamelizeKeys(data))],
  transformResponse: [(data) => humps.camelizeKeys(JSON.parse(data))],
});

export const authenticateClient = (authenticationToken) => {
  if (authenticationToken) {
    client.defaults.headers.common[
      'Authorization'
    ] = `JWT ${authenticationToken}`; // eslint-disable-line dot-notation
  }
};

export const deauthenticateClient = () => {
  delete client.defaults.headers.common['Authorization'];
};

export default client;
