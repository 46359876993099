import React from 'react';
import BaseGraphicSVG, { fillTransitionClass } from '../BaseGraphicSVG';
import theme from '../../../../assets/themes/theme';

const styles = {
  st0: 'rgb(77, 171, 247)',
  st1: 'rgb(179, 184, 188)',
  st2: 'rgb(240, 242, 255)',
  st3: 'rgb(206, 212, 218)',
};

const FrameworkGraphic = ({ id = 'framework-graphic', backgroundColor = theme.colors.primary[4], disabledBackgroundColor = theme.colors.primary[1], disabled = false }) => {
  return (
    <BaseGraphicSVG
      id={id}
      data-name={`data-${id}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      style={{opacity: disabled ? '40%': '100%'}}
    >
      <path className={fillTransitionClass} fill={styles['st0']} d="M128,64c0,35.3-28.7,64-64,64S0,99.3,0,64S28.7,0,64,0c6.6,0,13,1,19,2.9C83.5,3,84,3.2,84.4,3.3 c0.4,0.1,0.8,0.3,1.3,0.4c7,2.5,13.4,6.2,19.1,10.9c0.9,0.7,1.7,1.4,2.5,2.2c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.2,0.2,0.3,0.3 c0.1,0.1,0.2,0.2,0.3,0.3c0.8,0.8,1.6,1.6,2.4,2.5c0,0,0,0,0.1,0.1c0.2,0.3,0.5,0.5,0.7,0.8c0,0,0.1,0.1,0.1,0.1 c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.3,0.5,0.6,0.8,0.9c2.2,2.6,4.2,5.5,5.9,8.5c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0.4,0.5,0.9,0.7,1.3 c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.4,0.3,0.6c1.5,3,2.8,6.2,3.9,9.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.4,0.3,0.8,0.4,1.2 c0,0.1,0,0.1,0.1,0.2c0.3,1.1,0.6,2.3,0.9,3.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.2,0.7,0.2,1.1c0.3,1.2,0.5,2.4,0.7,3.7 C127.8,57.6,128,60.8,128,64z" />
      <polygon className={fillTransitionClass} fill={styles['st1']} points="40,88.1 56,84.1 56,76 " />
      <path className={fillTransitionClass} fill={styles['st1']} d="M 125.831 47.658 L 79.5 88.1 L 63.5 84.1 L 120.7 34.4 C 120.8 34.6 120.9 34.8 121 35 C 122.5 38 123.8 41.2 124.9 44.4 C 124.9 44.5 125 44.6 125 44.6 C 125.2 45.1 125.731 47.258 125.831 47.658 Z" />
      <path className={fillTransitionClass} fill={styles['st2']} d="M120.7,34.4L63.5,84.1V52.6l10.3-9.5l30.9-28.5c0.9,0.7,1.7,1.4,2.5,2.2c0.2,0.2,0.4,0.4,0.6,0.6 c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.8,0.8,1.6,1.6,2.4,2.5c0,0,0,0,0.1,0.1c0.2,0.3,0.5,0.5,0.7,0.8 c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.3,0.5,0.6,0.8,0.9c2.2,2.6,4.2,5.5,5.9,8.5c0.1,0.2,0.2,0.3,0.3,0.5 c0.3,0.4,0.5,0.9,0.7,1.3C120.5,33.8,120.6,34.1,120.7,34.4z" />
      <polygon className={fillTransitionClass} fill={styles['st3']} points="40,43.1 40,48.6 56,52.6 56,84.1 40,88.1 40,93.6 79.5,93.6 79.5,88.1 63.5,84.1 63.5,52.6 79.5,48.6 79.5,43.1 " />
      <path className={fillTransitionClass} fill={styles['st1']} d="M 108.393 17.921 L 79.557 43.199 L 40 43.199 L 85.753 3.701 C 93.567 6.75 95.404 8.139 101.25 11.861 C 102.272 12.512 106.09 15.503 107.423 16.964 C 107.611 17.113 108.193 17.721 108.393 17.921 Z" />
      <path className={fillTransitionClass} fill={styles['st3']} d="M 126.698 50.875 L 79.477 93.61 L 79.461 88.097 L 125.691 47.025 C 126.061 47.778 125.915 47.853 126.016 47.952 C 126.317 49.042 126.199 48.763 126.5 49.853 C 126.586 50.123 126.674 50.504 126.698 50.875 Z" />
      <path className={fillTransitionClass} fill={styles['st3']} d="M 111.284 20.979 L 79.5 48.6 L 79.5 43.205 L 108.382 17.914 C 108.483 18.01 108.59 18.115 108.691 18.213 C 108.792 18.313 108.888 18.407 108.988 18.508 C 109.799 19.323 110.615 20.157 111.284 20.979 Z" />
    </BaseGraphicSVG>
  );
};

export default FrameworkGraphic;