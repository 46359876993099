import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllTableItemTypes,
  createTableItemTypes,
  copyTableItemTypes,
  editTableItemTypes,
  deleteTableItemTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_TABLE_ITEMS);

// # GET /api/framework/profile_tables/:profile_table_id/profile_table_items
export const processFetchTableItems = (TableId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllTableItemTypes,
    `/framework/profile_tables/${TableId}/profile_table_items`,
    {
      mutateResponse: ({ profileTableItems = [] }) => ({
        profileTableItems: profileTableItems.map(item => ({ ...item, table: TableId, column: item.profileTableColumnId, row: item.profileTableRowId, profileSubtable: item.profileSubtableId })),
      }),
    }
  );

// # PATCH /api/framework/profile_tables/:profile_table_id/profile_table_items/collection
// tableItems must be an array of objects with:
// : id, (item Id)
// : _destroy,
// : profile_table_column_id,
// : profile_table_row_id,
// : value,
// : profile_subtable_id

export const tableItemCollection = (tableId, tableItems) =>
  client(
    REQUEST_TYPES.PATCH,
    'plural',
    createTableItemTypes,
    `/framework/profile_tables/${tableId}/profile_table_items/collection`,
    {
      data: {
        profile_table_items_attributes: tableItems,
      },
      mutateResponse: ({ profileTableItems = [] }) => ({
        profileTableItems: profileTableItems.map(item => ({ ...item, table: tableId, column: item.profileTableColumnId, row: item.profileTableRowId, profileSubtable: item.profileSubtableId })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Items successfully edited',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to edit Items',
      },
    }
  );

// # POST /api/framework/profile_tables/:profile_table_id/profile_table_items
export const processCreateTableItems = (tableId, newTableItems) =>
  /// newTableItems must be an array of table objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createTableItemTypes,
    `/framework/profile_tables/${tableId}/profile_table_items`,
    {
      data: {
        profile_table_items: newTableItems,
      },
      mutateResponse: ({ profileTableItems = [] }) => ({
        profileTableItems: profileTableItems.map(item => ({ ...item, table: tableId, column: item.profileTableColumnId, row: item.profileTableRowId, profileSubtable: item.profileSubtableId })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Items successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Item',
      },
    }
  );

// # DELETE /api/framework/profile_tables/:profile_table_id/profile_table_items/:id
export const processDeleteTableItem = (tableId, itemId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteTableItemTypes,
    `/framework/profile_tables/${tableId}/profile_table_items/${itemId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_TABLE_ITEMS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Item deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Item',
      },
    }
  );


//not implemented, not as useable for the modla version of creating items, but
export const processCopyTable = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyTableItemTypes,
    `/framework/profiles/${ProfileId}/profile_tables/copy`,
    {
      data: {
        tables: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Table successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Table',
      },
    }
  );