import React from 'react';
import { compose } from 'redux';
import { Map } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Button from '../../../../components/common/Button';
import Label from '../../../../components/common/Label';
import Icon from '../../../../components/common/Icon';
import Text from '../../../../components/common/Text';

import RDXTextInput from '../../RDXTextInput';
import RDXCheckboxInput from '../../RDXCheckboxInput';

const FlexibleInputContainer = styled(Box)`
  flex-grow: 1;
  height: 100%;
  height: 4rem;
  min-width: 20rem;
`;

const BoxForm = styled(Box)`
  flex-grow: 1;
`;

const InProfileToggle = ({ checked }) => (
  <Label primary disabled={!checked}>In Profile</Label>
);

const EditedToggle = ({ checked }) => (
  <Label success disabled={!checked}>Edited</Label>
);

const UntouchedToggle = ({ checked }) => (
  <Label secondary disabled={!checked}>Untouched</Label>
);

const FilterForm = ({ toggleExpandForm, formExpanded, handleSubmit }) => (
  <BoxForm as='form' onSubmit={handleSubmit}>
    <Flex flexDirection='row' alignItems='center' height='4rem' pr={5}>
      <Button
        transparent
        subtle
        type="button"
        icon='filter'
        large
        onClick={toggleExpandForm}
      />
      {
        formExpanded && (
          <React.Fragment>
            <FlexibleInputContainer ml={2}>
              <Field
                component={RDXTextInput}
                name='filterTerm'
                type='text'
                height='100%'
                placeholder='Filter for...'
                wait={250}
              />
            </FlexibleInputContainer>
            <Flex flexDirection='row' alignItems='center'>
              <Text fontSize={2} color='gray.5' fontWeight='400'>Status:</Text>
              <Field
                component={RDXCheckboxInput}
                toggleComponent={InProfileToggle}
                name='showSaved'
                height='100%'
              />
              <Field
                component={RDXCheckboxInput}
                toggleComponent={EditedToggle}
                name='showEdited'
                height='100%'
              />
              <Field
                component={RDXCheckboxInput}
                toggleComponent={UntouchedToggle}
                name='showUntouched'
                height='100%'
              />
            </Flex>
          </React.Fragment>
        ) || null
      }
    </Flex>
  </BoxForm>
);

const enhance = compose(
  reduxForm({
    form: `filters.${EK.CONFIG_SETTINGS.state}`,
    initialValues: Map({
      showSaved: true,
      showEdited: true,
      showUntouched: true,
    }),
  })
);

export default enhance(FilterForm);
