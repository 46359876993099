import React, { useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';

const FixedItemSizeList = ({ scrollToIndex, scrollAlign = 'auto', ...props }) => {
  const listRef = useRef();

  useEffect(() => {
    scrollToIndex && listRef && listRef.current && listRef.current.scrollToItem(scrollToIndex, scrollAlign);
  }, [scrollToIndex]);

  return <FixedSizeList ref={listRef} { ...props } />;
};

export default FixedItemSizeList;