import React from 'react';

import BaseRadioButton from '../../form/BaseRadioButton';

// import UserStatusIndicator from '../../../modules/permissions/components/UserStatusIndicator';
// <UserStatusIndicator role={role} inactive={disabled} />

const UserRoleRadioButton = React.forwardRef(
  ({ role, label, disabled, ...props }, ref) => (
    <BaseRadioButton
      ref={ref}
      disabled={disabled}
      style={{height: 'calc(100% - 4px)'}}
      { ...props }
    >
      {label}
    </BaseRadioButton>
  )
);

export default UserRoleRadioButton;
