export const BlankCompat: EndTypeCompatibility = {
  id: '',
  firstEndTypeId: '',
  firstEndTypeCode: '',
  secondEndTypeId: '',
  secondEndTypeCode: '',
  firstScheduleId: '',
  secondScheduleId: '',
  isEdited: false,
  isCreated: false,
  isDeleted: false,
};

export type EndTypeCompatibility = {
  readonly id: string,
  firstEndTypeId: string,
  firstEndTypeCode: string,
  secondEndTypeId: string,
  secondEndTypeCode: string,
  firstScheduleId: string,
  secondScheduleId: string,
  isEdited: boolean,
  isCreated: boolean,
  isDeleted: boolean,
}

export function createEndTypeCompatibility (compat: EndTypeCompatibility) {
  return compat;
}