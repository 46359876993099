import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { List, set } from 'immutable';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import borders from '../../../assets/themes/base/borders';
import transitions from '../../../assets/themes/base/transitions';
import space from '../../../assets/themes/base/space';
import './BaseListCreator.css';

import times from 'lodash/times';
import trim from 'lodash/trim';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import Text from '../../common/Text';

import BaseInput from '../BaseInput';
import BaseAppend from '../BaseAppend';
import renderCreatedValueForList from '../BaseOption/utils/renderCreatedValueForList';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import { pluralize } from '../../common/Header';

const Selectable = styled(Flex)`
  margin-bottom: ${space[2]};
  padding: ${space[2]};
  border: ${borders[1]};
  border-radius: ${radii[2]};

  transition: transform ${transitions.speed.fast} ${transitions.type.out};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $listValue }) =>
    !$listValue &&
    css`
      transform: scale(0.9);
      background-color: ${colors.gray[0]};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      background-color: ${colors.white};
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${colors.primary[4]};
      background-color: ${colors.primary[0]};
    `}
`;

const SECTION_LIMIT = 25;
const COLUMN_LIMIT = 5;
const calculateRequiredSectionCount = (listValuesSize) => {
  const requiredSections = Math.ceil(listValuesSize / SECTION_LIMIT) || 1;
  return requiredSections > SECTION_LIMIT ? SECTION_LIMIT : requiredSections;
};
const calculateRequiredColumnCount = (listValuesSize) => {
  if (listValuesSize < 25) {
    const requiredColumns = Math.ceil(listValuesSize / COLUMN_LIMIT) || 1;
    return requiredColumns > COLUMN_LIMIT ? COLUMN_LIMIT : requiredColumns;
  } else {
    return COLUMN_LIMIT;
  }
};
const noop = () => null;

const CreatedListValue = ({
  listValue,
  onDeleteListValue,
  onToggleDefaultListValue,
  itemHeight,
}) => {
  const onClick = useCallback(() => onToggleDefaultListValue(listValue.value), [
    listValue,
    onToggleDefaultListValue,
  ]);
  const onDelete = useCallback(() => onDeleteListValue(listValue.value), [
    listValue,
    onDeleteListValue,
  ]);

  return (
    <Selectable
      $listValue={listValue}
      onClick={(listValue && onClick) || undefined}
      $isSelected={listValue && listValue.isSelected}
      isSelected={listValue && listValue.isSelected}
      height={itemHeight}
      flexDirection="column"
      justifyContent="center"
    >
      {(listValue &&
        renderCreatedValueForList({
          item: listValue,
          isSelected: listValue.isSelected,
          onDelete,
        })) ||
        undefined}
    </Selectable>
  );
};

const CreateListInstructions = styled(Text)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const CreateListCount = styled(Text)`
  flex-grow: 0;
  flex-shrink: 0;
`;

const CreatedListSections = styled(Flex)`
  max-height: calc(
    ${({ maxInnerHeight }) => maxInnerHeight}px + (2 * ${space[2]})
  );
  overflow-y: auto;
`;

const CreatedListColumn = styled(Flex)`
  flex-direction: column;

  ${({ isLastColumn }) =>
    (isLastColumn &&
      css`
        width: calc(
          ${({ minBaseWidth }) =>
        !isNaN(minBaseWidth - parseFloat(minBaseWidth))
          ? `${minBaseWidth}px`
          : minBaseWidth} - ${space[2]}
        );
      `) ||
    css`
      width: ${({ minBaseWidth }) =>
    !isNaN(minBaseWidth - parseFloat(minBaseWidth))
      ? `${minBaseWidth}px`
      : minBaseWidth};
      margin-right: ${space[2]};
    `}
`;

const CreatedListValues = ({
  listValues,
  onDeleteListValue,
  onToggleDefaultListValue,
  baseWidth,
  itemHeight,
  onOuterClick = noop,
  minListSize,
  maxListSize,
}) => {
  const containerRef = useRef();
  const scrollableRef = useRef();
  const sectionCount = useMemo(
    () => calculateRequiredSectionCount(listValues.size),
    [listValues.size]
  );
  const columnCount = useMemo(
    () => calculateRequiredColumnCount(listValues.size),
    [listValues.size]
  );
  const minBaseWidth = useMemo(
    () =>
      (baseWidth > 200 &&
        baseWidth / columnCount > 200 &&
        baseWidth / columnCount) ||
      200,
    [baseWidth, columnCount]
  );
  const isListSizeWarning =
    listValues.size >= minListSize && listValues.size < maxListSize;

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        behavior: 'smooth',
        top: scrollableRef.current.scrollHeight,
      });
    }
  }, [listValues.size]);

  useOnClickOutside(containerRef, onOuterClick);

  return (
    <Box
      ref={containerRef}
      width={minBaseWidth * columnCount}
      bg="white"
      border={1}
      borderRadius={2}
      boxShadow={2}
      className='BaseListContainer'
    >
      <Flex flexDirection="row" alignItems="center" p={2} borderBottom={1}>
        <CreateListInstructions color="gray.6" fontSize={0}>
          Press <strong>Enter</strong> to add options
        </CreateListInstructions>
        <CreateListCount color="gray..6" fontSize={0}>
          <Text
            as="span"
            color={isListSizeWarning ? 'gray.6' : 'error.4'}
            fontWeight={isListSizeWarning ? '500' : '600'}
          >
            {listValues.size}
          </Text>{' '}
          /{' '}
          <Text
            as="span"
            color={listValues.size < maxListSize ? 'gray.6' : 'error.4'}
            fontWeight={listValues.size < maxListSize ? '500' : '600'}
          >
            {maxListSize}
          </Text>
        </CreateListCount>
      </Flex>
      <CreatedListSections
        ref={scrollableRef}
        flexDirection="column"
        p={2}
        maxInnerHeight={itemHeight * COLUMN_LIMIT}
      >
        {times(sectionCount, (sidx) => (
          <Flex
            flexDirection="row"
            pb={sectionCount !== sidx + 1 ? 2 : undefined}
          >
            {times(columnCount, (idx) => (
              <CreatedListColumn
                key={sidx * SECTION_LIMIT + idx}
                minBaseWidth={minBaseWidth}
                height={itemHeight * COLUMN_LIMIT}
                isLastColumn={columnCount === idx + 1}
              >
                {times(COLUMN_LIMIT, (ridx) => {
                  const itemIndex =
                    sidx * SECTION_LIMIT + idx * COLUMN_LIMIT + ridx;
                  return (
                    <CreatedListValue
                      key={itemIndex}
                      listValue={listValues.get(itemIndex)}
                      onDeleteListValue={onDeleteListValue}
                      onToggleDefaultListValue={onToggleDefaultListValue}
                      itemHeight={itemHeight}
                    />
                  );
                })}
              </CreatedListColumn>
            ))}
          </Flex>
        ))}
      </CreatedListSections>
    </Box>
  );
};

export const ForwardedBaseListCreator = ({
  forwardRef=null,
  type,
  value,
  onChange,
  onDefaultValueChange,
  width = '100%',
  itemHeight,
  tabIndex,
  minListSize,
  maxListSize,
  allow,
  onOuterClick,
  defaultValue,
}) => {
  const inputRef = useRef(forwardRef);

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(value || List());
  const [currentValue, setCurrentValue] = useState('');
  const [listDefaultValue, setDefaultValue] = useState(defaultValue);
  const [placeholder, setPlaceholder] = useState(values.size > 0 ? `${listDefaultValue} (${values.size} ${pluralize('value', values.size)} total) Add List Values Here` : 'Add List Values Here');

  const onListValuesChange = useCallback(
    ({ values }) => {
      onChange && onChange(values);
      inputRef.current && inputRef.current.focus();
    },
    [onChange, inputRef.current]
  );

  const handleAppendClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, [isOpen]);

  const onDeleteListValue = useCallback(
    (removeValue) => {
      setValues((values) => values.filter(({ value }) => value !== removeValue));
    },
    [values]
  );

  const handleToggleDefaultListValue = (value, isSelected) => {
    const newdefval = isSelected ? undefined : value;
    onDefaultValueChange && onDefaultValueChange(newdefval);
    setDefaultValue(newdefval);
    return !isSelected;
  };

  const onToggleDefaultListValue = useCallback(
    (toggleValue) => {
      setIsOpen(true);
      setValues((values) => values.map(({ value, isSelected, ...vrest }) => {
        if (value === toggleValue && isSelected) {
          onDefaultValueChange && onDefaultValueChange(undefined);
        }
        return {
          ...vrest,
          value,
          isSelected: value === toggleValue ? handleToggleDefaultListValue(value, isSelected) : undefined,
        };
      }));
    },
    [values]
  );

  const onListValueCreatorFocus = () => {
    setIsOpen(true);
  };

  const onListValueCreatorUnfocus = () => {
    setIsOpen(false);
    onOuterClick && onOuterClick();
  };

  const onCurrentValueChange = useCallback(
    (e) => {
      e.persist();
      setCurrentValue(e.target.value);
    },
    [currentValue]
  );

  const onListValueSubmit = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (trim(currentValue).length > 0) {
          const foundIndex = values.findIndex(
            ({ value }) => value === currentValue
          );
          setCurrentValue('');
          setValues((values) => foundIndex === -1 && values.size < maxListSize
            ? values.push({
              id: currentValue,
              value: currentValue,
              primary: currentValue,
              isSelected: values.size === 0 || undefined,
            })
            : values);
          if (values.size === 0) {
            onDefaultValueChange && onDefaultValueChange(currentValue);
            setDefaultValue(currentValue);
          }
          return;
        }
      }
    },
    [values, currentValue]
  );

  useEffect(() => {
    if (defaultValue) {
      setValues((values) => values.map(({ value, isSelected, ...vrest }) => ({
        ...vrest,
        value,
        isSelected: value === defaultValue ? !isSelected : undefined,
      })));
    }
  }, []);

  useEffect(() => {
    setPlaceholder(values.size > 0 ? `${listDefaultValue} (${values.size} ${pluralize('value', values.size)} total) Add List Values Here` : 'Add List Values Here');
    onChange && onChange(values);
  }, [values]);

  return (
    <div>
      <div
        className='BaseInputContainer'
        onFocus={onListValueCreatorFocus}
        onBlur={onListValueCreatorUnfocus}
      >
        <input
          ref={inputRef}
          value={currentValue || ''}
          onChange={onCurrentValueChange}
          type={type}
          tabIndex={tabIndex}
          allow={allow}
          onKeyDown={onListValueSubmit}
          placeholder={placeholder}
          className='BaseInput'
        />
        <BaseAppend onClick={handleAppendClick}>
          <Icon name='chevron-down' iconSize='1.75rem' />
        </BaseAppend>
      </div>
      <div className='ListValuesContainer' style={{ maxHeight: isOpen ? '400px' : '0px' }} >
        <CreatedListValues
          listValues={values}
          onDeleteListValue={onDeleteListValue}
          onToggleDefaultListValue={onToggleDefaultListValue}
          baseWidth={width}
          itemHeight={itemHeight || 56}
          onOuterClick={onOuterClick}
          minListSize={minListSize}
          maxListSize={maxListSize}
        />
      </div>
    </div>
  );
};

const BaseListCreator = React.forwardRef((props, ref) => (
  <ForwardedBaseListCreator forwardRef={ref} {...props} />
));

export default BaseListCreator;