import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import EK from '../../keys';
import { formValueSelector } from 'redux-form/immutable';
import { newSchedulesRows } from './constants';

export const selectNormalizedSchedules = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SCHEDULES.state)
);

export const selectNormalizedSchedulesList = () => createSelector(
  selectNormalizedSchedules(),
  schedules => schedules.toList()
);

export const selectAllSchedulesOptions = () => createSelector(
  selectNormalizedSchedules(),
  schedules => schedules.map(schedule => schedule.toOption()).toList()
);

const scheduleFilterForm = formValueSelector(`filters.${EK.SCHEDULES.state}`);

export const selectCurrentFilteredSchedules = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedSchedules(),
  state => scheduleFilterForm(state, 'value', 'description'),
  (isFiltering, schedules, query) =>
    isFiltering ?
      schedules.filter(schedule => schedule.doesMatchQuery(query)).toList().toArray() :
      schedules.toList().toArray().concat(newSchedulesRows)
);

export const selectUnfilteredSchedulesCount = () => createSelector(
  selectNormalizedSchedules(),
  (schedules) => schedules.toList().size
);