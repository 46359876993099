import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import UserSchema from './schema';

export const selectNormalizedUsers = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.USERS.state)
);

export const selectUsers = () => createSelector(
  selectEntities(),
  (entities) => denormalize(entities.get(EK.USERS.state), [UserSchema], entities)
);
export const selectUsersList = () => createSelector(
  selectUsers(),
  (users) => users.toList()
);
