import { createAction } from 'redux-actions';

import {
  MOVE_DRAFTS,
  ADD_DRAFTS,
  MERGE_DRAFTS,
  CLEAR_DRAFTS,
} from './constants';

export const moveDrafts = createAction(MOVE_DRAFTS, (entities, { sortBy = {} } = {}) => ({ drafts: entities, sortBy }));
export const addDrafts = createAction(ADD_DRAFTS, (entities, { insertAt } = {}) => ({ drafts: entities, insertAt }));
export const mergeDrafts = createAction(MERGE_DRAFTS, (entities, { sortBy = {} } = {}) => ({ drafts: entities, sortBy }));
export const clearDrafts = createAction(CLEAR_DRAFTS, entities => ({ drafts: entities }));

export const generateCreateDraftsAction = actionType => createAction(actionType, (entities, { insertAt, ignoreValidate, creationProperties, entityKey } = {}) => ({ creations: entities, insertAt, ignoreValidate, creationProperties, entityKey }));
export const generateEditDraftsAction = actionType => createAction(actionType, (entities, { entityKey } = {}) => ({ edits: entities, entityKey }));
