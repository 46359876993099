import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import Button from '../../../components/common/Button';

// Header
import Flex from '../../../components/common/Flex';
import {
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsPipeInstancesEditPath
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// dropdown constants and validation
import {
  BlankSpec,
  ALLOWANCES,
  VALIDATION_FIELDS,
} from '../../../entities/Piping/Specs/model';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { areFieldsValid, doRequiredFieldsExist } from '../Specs/components';

// Actions
import { processEditPipeFamily, processFetchIndividualPipeFamily, processFetchPipeFamiliesForSpec } from '../../../entities/Piping/PipeFamilies/actions';
import { saveCurrentPipingSpecPipeinstanceChanges } from './actions';
import { convertToSpecPipeInstanceType } from '../SpecsPipesEdit/actions';
import { processEditSpec, processFetchIndividualSpec } from '../../../entities/Piping/Specs/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
} from '../../Dashboard/selectors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { selectCurrentFilteredSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/selectors';
import { selectCurrentSpec } from '../../../entities/Piping/Specs/selectors';
import { selectPipeInstanceCount } from '../../../entities/Piping/PipeInstances/selectors';
import { selectCurrentPipe } from '../../PipesEditSwitch/selectors';
import { processSaveSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/actions';

const mapStateToProps = (specId, PipeId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(specId),
  selectCurrentPipe(PipeId),
  selectCurrentFilteredSpecPipeInstances(specId, PipeId),
  selectPipeInstanceCount(PipeId),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    pipe,
    data,
    instanceCount,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && !pipe?.archivedFlag && !spec?.archivedFlag,
      canCollaborate,
      hasValidLicense,
      instanceCount,
      spec,
      pipe,
    };
  },
);

function PipesEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id, match.params.familyId));
  const dispatch = useDispatch();

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToSpecPipeInstanceType, reduxProps.editable, true);

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5] }} >{placeholder}</Text>;
  };

  const summaryBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);
    return curRow?.pipeInstance ?
      <ComplexCellRenderer value={{
        primary: `${curRow.pipeInstance?.sizeProperty?.name || 'No Size'} // ${curRow.pipeInstance?.schedule?.value || 'No Schedule'}`,
        secondary: `${curRow.pipeInstance?.endTypes?.reduce((endTypes, endType) => endTypes ? `${endTypes}, ${endType.code}` : endType.code, '') || 'No End Types'}`
      }} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>Summary Not Available</Text>;
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);

    return curRow && curRow.pipeInstance ?
      <ComplexCellRenderer value={curRow.pipeInstance[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  // Cell Editors
  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} />;

  const instanceSelector = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);
    return <Button icon={curRow.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'} iconFillColor={curRow.isSelectedForSpec ? 'primary.4' : 'gray.6'} onClick={() => onDropdownComplete.current(!curRow.isSelectedForSpec, 'isSelectedForSpec', rowdata)} />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchPipeFamiliesForSpec());
    dispatch(processFetchIndividualPipeFamily(match.params.familyId));
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={reduxProps.spec && reduxProps.pipe && `${reduxProps.spec.specName} // ${reduxProps.pipe.name}` || PipingModuleSpecsPipeInstancesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Pipe Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Pipe Instance', reduxProps.instanceCount)} total (${reduxProps.spec?.specPipeInstances?.filter(inst => inst?.pipeFamilyId?.id == match.params.familyId).size || 0} selected)`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          updateEntities={() => saveCurrentPipingSpecPipeinstanceChanges(editedRows, (rows) =>{ dispatch(processEditSpec(match.params.id, { specPipeInstancesAttributes: rows })); resetEditedRows(); })}
          pendingValidChanges={editedRows.length > 0}
          isEditing={isEditing}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column header='' style={{ width: '2%' }} body={(rowdata) => instanceSelector(rowdata)} ></Column>
          <Column field='id' header='Summary' body={(rowdata) => summaryBody(rowdata)} style={{ width: '19%' }} ></Column>
          <Column field='size' header='Size' body={(rowdata) => sBody(rowdata, 'sizeProperty', 'No Size')} style={{ width: '19%' }} ></Column>
          <Column field='schedule' header='Schedule' body={(rowdata) => sBody(rowdata, 'schedule', 'No Schedule')} style={{ width: '19%' }} ></Column>
          <Column field='stockno' style={{ width: '19%' }} header='Stock No' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('stockno')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'stockno', 'Stockno')} ></Column>
          <Column field='mccsCode' style={{ width: '19%' }} header='MCCS Code' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('mccsCode')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'mccsCode', 'MCCS Code')} ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(PipesEditGrid);