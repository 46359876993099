import validateRules from '../../../utils/validation/validateRules';

import { ALLOWANCES } from './model';

export const rules = {
  name: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.NAME.test(value),
    },
  },
};

export const validations = {
  name: validateRules(rules.name),
};
