import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModulePipesBendMachinesEditPath } from '../../../paths';
import { selectCurrentBendMachine } from '../../../entities/Piping/BendMachineFamilies/selectors';

function BendEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModulePipesBendMachinesEditPath.url });
  const bendMachineId = (match && match.params.familyId) || null;
  const bendMachineFamily = useSelector(selectCurrentBendMachine(bendMachineId));

  return <BreadcrumbTemplate title={(bendMachineFamily && bendMachineFamily.name) || PipingModulePipesBendMachinesEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);