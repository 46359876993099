import React from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';

import Box from '../../components/common/Box';
import Alert from '../../components/common/Alert';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import CadActiveTextLogoGraphic from '../../components/common/graphics/CadActiveTextLogoGraphic';
import AccessMissingGraphic from '../../components/common/graphics/AccessMissing';

import PasswordResetForm from '../../containers/form/templates/PasswordResetForm';

import ExternalPage from '../templates/ExternalPage';

import { UserNotAuthenticatedOrHasRequiredPasswordResetPending } from '../../modules/auth/utils/routes';

import { selectIsPerforming } from '../../modules/utility/selectors';

import { selectIsPendingForcedPasswordReset } from '../../entities/CurrentUser/selectors';

import {
  selectPasswordResetError,
} from '../../modules/auth/selectors';

import {
  processPasswordReset,
} from '../../modules/auth/actions';

import injectSaga from '../../utils/sagas/injectSaga';

import passwordResetSaga from './sagas';

import theme from '../../assets/themes/theme';

import {
  LoginPath,
  ResetPasswordPath,
} from '../../paths';

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerforming(),
  isPasswordResetError: selectPasswordResetError(),
  isForcedPasswordReset: selectIsPendingForcedPasswordReset(),
});

const enhance = compose(
  withRouter,
  injectSaga({ key: 'passwordReset', saga: passwordResetSaga }),
  UserNotAuthenticatedOrHasRequiredPasswordResetPending,
);

function PasswordReset(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const resetPassword = (formItems) => dispatch(processPasswordReset({ passwordResetToken: props.match.params.token, ...formItems }));
  return (
    <ExternalPage title={ResetPasswordPath.title}>
      <Box width="20rem" ml="auto" mr="auto" mb={4}>
        <CadActiveTextLogoGraphic />
      </Box>

      <Box width='14rem' ml='auto' mr='auto' mb={7}>
        <AccessMissingGraphic
          backgroundColor={theme.colors.gray[5]}
          backgroundAccentColor={theme.colors.gray[4]}
          iconColor={reduxProps.isPasswordResetError ? theme.colors.error[4] : theme.colors.primary[4]}
          iconAccentColor={reduxProps.isPasswordResetError ? theme.colors.error[6] : theme.colors.primary[6]}
        />
      </Box>

      <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={7}>
        <Text as='span' fontSize={5} color='gray.7'><strong>Reset</strong> your Password</Text>
      </Flex>

      {
        reduxProps.isForcedPasswordReset &&
        <Alert primary ml='auto' mr='auto' mb={7}>
          <Text fontSize={2}>It looks like an organization admin has required you to reset your password. Just enter a new password below and you&apos;ll be good to go.</Text>
        </Alert>
      }

      <Box ml='auto' mr='auto' mb={5}>
        <PasswordResetForm isPerforming={reduxProps.isPerforming} onRDXSubmit={resetPassword} />
      </Box>

      {
        reduxProps.isPasswordResetError &&
        <Alert error ml='auto' mr='auto' mb={7}>
          <Text fontSize={1} as='span'>We weren&apos;t able to reset your password. Please double check that the reset confirmation link is exactly the one we sent you in your email.</Text>
        </Alert>
      }

      <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={8}>
        <Text as={reduxProps.isPerforming ? 'span' : Link} link to={LoginPath.url}>
          Back to Log In
        </Text>
      </Flex>

      <Box ml="auto" mr="auto">
        <Text as="p" color="gray.7" fontSize={2} textAlign="center" m={0} p={0}>
          CadActive Technologies &copy; {new Date().getFullYear()}
        </Text>
      </Box>
    </ExternalPage>
  );
}

export default enhance(PasswordReset);
