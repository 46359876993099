import React from 'react';

import Flex from '../../../common/Flex';
import Text from '../../../common/Text';

const ModalTitle = ({ children }) =>(
  <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={6}>
    <Text as='span' fontSize={5} color='gray.0'>
      { children }
    </Text>
  </Flex>
);

export default ModalTitle;