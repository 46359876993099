import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllLayersTypes,
  createLayerTypes,
  saveLayersTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.LAYERS);

export const processFetchAllLayers = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllLayersTypes,
    '/standardize/layers'
  );

export const processCreateLayer = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createLayerTypes,
    '/standardize/layers',
    {
      data: {
        layers: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Layer successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create layer',
      },
    }
  );

export const processSaveLayers = (data) =>
  client(
    REQUEST_TYPES.PATCH,
    'plural',
    saveLayersTypes,
    '/standardize/layers/collection?diff_result=true',
    {
      data: { layersAttributes: data },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );
