import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import PipingCatalogSchema from './schema';

export const selectMasterCatalogUnitSystemId = () => createSelector(
  selectEntities(),
  entities => {
    if (entities.has(EK.PIPING_CATALOGS.state)) {
      const currentMasterCatalog = entities.get(EK.PIPING_CATALOGS.state).first();
      return (currentMasterCatalog || {}).unitSystem;
    } else {
      return null;
    }
  }
);
export const selectMasterCatalogUnitSystem = () => createSelector(
  selectEntities(),
  entities => {
    if (entities && entities.get(EK.PIPING_CATALOGS.state)) {
      const pipingCatalog = denormalize(entities.get(EK.PIPING_CATALOGS.state).first(), PipingCatalogSchema, entities);
      return pipingCatalog && pipingCatalog.unitSystem;
    }
  }
);
export const selectMasterCatalogUnitSystemOption = () => createSelector(
  selectMasterCatalogUnitSystem(),
  masterCatalogUnitSystem => masterCatalogUnitSystem && masterCatalogUnitSystem.toOption() || {}
);
