import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import borders from '../../../../assets/themes/base/borders';
import space from '../../../../assets/themes/base/space';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Button from '../../../../components/common/Button';
import Text from '../../../../components/common/Text';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';
import RDXSearchSelect from '../../RDXSearchSelect';

import { selectGlobalCadSearchRulesAndCriteria } from '../../../../entities/GlobalCadSearchRules/selectors';

import SimplifiedRepRulesetRule from '../../../../entities/Standardize/SimplifiedRepRulesetRules/model';
import SimplifiedRepRulesetRuleCriterionValueModel from '../../../../entities/Standardize/SimplifiedRepRulesetRuleCriterionValues/model';

const RulesEmpty = styled(Flex)`
  background-color: ${colors.white};

  border-radius: ${radii[2]};
  border: ${borders[1]};

  margin-bottom: ${space[3]};

  height: 15rem;
`;

const RuleSummary = styled(Box)`
  border-radius: ${radii[2]};
  border: ${borders[1]};
`;

const SimplifiedRepRulesetRuleForm = ({
  handleSubmit,
  isPerforming,
  valid,
  doesInitialRuleExist,
  expectedRule,
  expectedRuleCriteria,
}) => (
  <Flex as="form" onSubmit={handleSubmit} flexDirection="column" width="100%">
    {(!doesInitialRuleExist && (
      <React.Fragment>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="gray.7" fontSize={4} mb={4}>
            <strong>Creating</strong> New Rule
          </Text>
        </Flex>

        <BaseFormSection>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name="globalCadSearchRule"
              label="Rule"
              ek={EK.GLOBAL_CAD_SEARCH_RULES}
              required
              clearable
            />
          </BaseFormRow>
        </BaseFormSection>
      </React.Fragment>
    )) || (
      <Flex flexDirection="column">
        <RuleSummary p={3} bg="white" mb={2}>
          <Text color="gray.7" fontSize={1} fontWeight="600" mb={1}>
            {expectedRule.name}
          </Text>
          <Text color="gray.6" fontSize={1}>
            {expectedRule.description}
          </Text>
        </RuleSummary>
      </Flex>
    )}

    {expectedRuleCriteria.size > 0 ? (
      <BaseFormSection style={{ flexGrow: 1, overflow: 'auto' }}>
        {expectedRuleCriteria.map((criterion, index) => (
          <BaseFormRow key={index}>
            <Field
              name={`simplifiedRepRulesetRuleCriterionValues[${index}].value`}
              label={criterion.name}
              description={criterion.description}
              required
              defaultValue={criterion.defaultValue}
              options={criterion.listValues}
              component={
                criterion.valueType === 'list'
                  ? criterion.listValues.size <= 10
                    ? RDXRadioSelect
                    : RDXSearchSelect
                  : RDXTextInput
              }
              clearable
            />
          </BaseFormRow>
        ))}
      </BaseFormSection>
    ) : (
      <RulesEmpty
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={3} color="gray.6" mb={4}>
          No rule template selected
        </Text>
        <Text fontSize={2} color="gray.6">
          Select a rule template from the dropdown above to get started
        </Text>
      </RulesEmpty>
    )}

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Flex>
);

const simplifiedRepRulesetRuleFormSelector = formValueSelector(
  EK.SIMPLIFIED_REP_RULESET_RULES.state
);

const mapStateToProps = createSelector(
  (state, props) => props.initialValues,
  (state) =>
    simplifiedRepRulesetRuleFormSelector(
      state,
      'globalCadSearchRule'
    ),
  selectGlobalCadSearchRulesAndCriteria(),
  (initialValues, globalRuleId, rules) => {
    const globalCadSearchRule = initialValues.getIn(
      ['globalCadSearchRule', 'id'],
      globalRuleId
    );

    const expectedRuleCriteria = rules
      .getIn([globalCadSearchRule, 'globalCadSearchRuleCriteria'], List())
      .sortBy((criterion) => criterion.sortOrder);

    return {
      doesInitialRuleExist: !!initialValues.id,
      expectedRule: rules.get(globalCadSearchRule),
      expectedRuleCriteria,
      initialValues: initialValues
        .set('globalCadSearchRule', globalCadSearchRule)
        .set(
          'simplifiedRepRulesetRuleCriterionValues',
          initialValues.simplifiedRepRulesetRuleCriterionValues
            .size > 0
            ? initialValues
              .get('simplifiedRepRulesetRuleCriterionValues')
              .sortBy((value) =>
                value.getIn(['globalCadSearchRuleCriterion', 'sortOrder'])
              )
              .map((value) => {
                return value
                  .set(
                    'globalCadSearchRuleCriterion',
                    value.getIn(
                      ['globalCadSearchRuleCriterion', 'id'],
                      value.get('globalCadSearchRuleCriterion')
                    )
                  )
                  .set(
                    'value',
                    value.get(
                      'value',
                      value.getIn([
                        'globalCadSearchRuleCriterion',
                        'defaultValue',
                      ])
                    )
                  );
              })
            : expectedRuleCriteria.map(
              (criterion) =>
                new SimplifiedRepRulesetRuleCriterionValueModel({
                  globalCadSearchRuleCriterion: criterion.id,
                  value: criterion.defaultValue,
                })
            )
        ),
    };
  }
);

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: EK.SIMPLIFIED_REP_RULESET_RULES.state,
    enableReinitialize: true, // every time initialValues changes, form resets
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
    onSubmitSuccess(_, dispatch, props) {
      props.reset(EK.SIMPLIFIED_REP_RULESET_RULES.state);
    },
  })
);

export default enhance(SimplifiedRepRulesetRuleForm);
