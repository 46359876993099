import EntityRecord from '../../../modules/entities/model';

class SimplifiedRepRulesetRuleCriterionValue extends EntityRecord({
  globalCadSearchRuleCriterion: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'globalCadSearchRuleCriterionId',
  },
  simplifiedRepRulesetRule: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    serializeOutputField: 'simplifiedRepRulesetRuleId',
  },
  value: {
    value: undefined,
    handleCompare: true,
    handleSerialize: true,
  },
  valueType: {
    value: undefined,
    handleCopy: true,
  },
}) {
  get primary() {
    return this.value;
  }
  get primaryPlaceholder() {
    return 'No Value';
  }
  get secondary() {
    return (
      this.globalCadSearchRuleCriterion &&
      this.globalCadSearchRuleCriterion.name
    );
  }
  get secondaryPlaceholder() {
    return 'No Criterion Information Present';
  }
}

export default SimplifiedRepRulesetRuleCriterionValue;
