import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import styled, { css } from 'styled-components';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Loading from '../../../../components/common/Loading';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import SimplifiedRepRulesetForm from '../../../form/templates/SimplifiedRepRulesetForm';
import SimplifiedRepRulesetRuleForm from '../../../form/templates/SimplifiedRepRulesetRuleForm';

import MinimizableList from './components/MinimizableList';
import ConnectedList from './components/ConnectedList';

import {
  selectIsFetchingInModal,
  selectIsPerformingInModal,
} from '../../../../modules/utility/selectors';

import { processFetchAllSimplifiedRepRulesetsForSimplifiedRep } from '../../../../entities/Standardize/SimplifiedRepRulesets/actions';

import {
  processCreateSimplifiedRepRuleset,
  processEditSimplifiedRepRuleset,
  processDeleteSimplifiedRepRuleset,
} from '../../../../entities/Standardize/SimplifiedRepRulesets/actions';

import {
  processCreateSimplifiedRepRulesetRule,
  processEditSimplifiedRepRulesetRule,
  processDeleteSimplifiedRepRulesetRule,
} from '../../../../entities/Standardize/SimplifiedRepRulesetRules/actions';

import SimplifiedRepRulesetModel from '../../../../entities/Standardize/SimplifiedRepRulesets/model';
import SimplifiedRepRulesetRuleModel from '../../../../entities/Standardize/SimplifiedRepRulesetRules/model';

import {
  selectCurrentSimplifiedRep,
  selectCurrentSimplifiedRepRulesets,
} from './selectors';

const emptyRuleset = new SimplifiedRepRulesetModel();
const emptyRule = new SimplifiedRepRulesetRuleModel();

const MiniOverlay = styled(Flex)`
    background-color: rgba(248,249,250, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 501;

    opacity: 0;
    transition: opacity 100ms ease-in-out;

    ${({ show }) =>
    show &&
    css`
        opacity: 1;
        right: 0;
        bottom: 0;
      `}
  }
`;

const SimplifiedRepRulesetModal = ({
  simplifiedRep,
  simplifiedRepRulesets,
  isFetching,
  isPerforming,
  onLoad,
  onCreateRuleset,
  onUpdateRuleset,
  onDeleteRuleset,
  onCreateRulesetRule,
  onUpdateRulesetRule,
  onDeleteRulesetRule,
  closeModal,
}) => {
  useEffect(() => onLoad(simplifiedRep.id), []);

  const [modalState, setModalState] = useState({
    currentSelectedRulesetId: null,
    currentSelectedRulesetRuleId: null,
    showRulesetForm: false,
    currentEditingRuleset: null,
    currentEditingRulesetRule: null,
  });

  useEffect(() => {
    setModalState((prev) => {
      const currentEditingRuleset = prev.currentEditingRuleset
        ? simplifiedRepRulesets.find(
          (ruleset) => ruleset.id === prev.currentEditingRuleset.id
        )
        : null;
      const currentSelectedRuleset = prev.currentSelectedRulesetId
        ? simplifiedRepRulesets.find(
          (ruleset) => ruleset.id === prev.currentSelectedRulesetId
        )
        : null;
      return {
        ...prev,
        currentEditingRuleset,
        currentEditingRulesetRule:
          prev.currentEditingRulesetRule && currentSelectedRuleset
            ? currentSelectedRuleset.simplifiedRepRulesetRules.find(
              (rule) => rule.id === prev.currentEditingRulesetRule.id
            )
            : null,
      };
    });
  }, [setModalState, simplifiedRepRulesets]);

  const onToggleRulesetForm = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      showRulesetForm: !prev.showRulesetForm,
      currentEditingRuleset: prev.showRulesetForm
        ? null
        : prev.currentEditingRuleset,
    }));
  }, [setModalState]);

  const onSaveRuleset = useCallback(
    (data) => {
      if (modalState.currentEditingRuleset) {
        onUpdateRuleset(
          simplifiedRep.id,
          modalState.currentEditingRuleset.id,
          data
        );
      } else {
        onCreateRuleset(simplifiedRep.id, data);
      }
    },
    [
      simplifiedRep.id,
      modalState.currentEditingRuleset,
      onCreateRuleset,
      onUpdateRuleset,
    ]
  );

  const onSaveRulesetRule = useCallback(
    (data) => {
      if (modalState.currentEditingRulesetRule) {
        onUpdateRulesetRule(
          simplifiedRep.id,
          modalState.currentSelectedRulesetId,
          modalState.currentEditingRulesetRule.id,
          data
        );
      } else {
        onCreateRulesetRule(
          simplifiedRep.id,
          modalState.currentSelectedRulesetId,
          data
        );
      }
    },
    [
      simplifiedRep.id,
      modalState.currentSelectedRulesetId,
      modalState.currentEditingRulesetRule,
      onCreateRulesetRule,
      onUpdateRulesetRule,
    ]
  );

  const onRemoveRuleset = useCallback(
    (data) => onDeleteRuleset(simplifiedRep.id, data.id),
    [simplifiedRep.id, onDeleteRuleset]
  );

  const onRemoveRulesetRule = useCallback(
    (data) =>
      onDeleteRulesetRule(
        simplifiedRep.id,
        modalState.currentSelectedRulesetId,
        data.id
      ),
    [
      simplifiedRep.id,
      modalState.currentSelectedRulesetId,
      onDeleteRulesetRule,
    ]
  );

  const onShowEditRulesetForm = useCallback(
    (data) => {
      setModalState((prev) => ({
        ...prev,
        showRulesetForm: true,
        currentEditingRuleset: simplifiedRepRulesets.find(
          (ruleset) => ruleset.id === data.id
        ),
      }));
    },
    [setModalState, simplifiedRepRulesets]
  );

  const onToggleRuleset = useCallback(
    (ruleset) => {
      setModalState((prev) => ({
        ...prev,
        currentSelectedRulesetId:
          prev.currentSelectedRulesetId === ruleset.id ? null : ruleset.id,
        currentEditingRulesetRule: null,
      }));
    },
    [setModalState]
  );

  const onToggleRulesetRule = useCallback(
    (rulesetRule) => {
      setModalState((prev) => ({
        ...prev,
        currentEditingRulesetRule:
          !prev.currentEditingRulesetRule ||
            rulesetRule.id !== prev.currentEditingRulesetRule.id
            ? rulesetRule
            : null,
      }));
    },
    [setModalState]
  );

  const onToggleRulesetRuleIsOrLogic = useCallback((rulesetRule) => {
    onUpdateRulesetRule(
      simplifiedRep.id,
      modalState.currentSelectedRulesetId,
      rulesetRule.id,
      { isOrLogic: !rulesetRule.isOrLogic }
    );
  });

  const onSortRulesetRule = useCallback((rulesetRule, newSortOrder) => {
    onUpdateRulesetRule(
      simplifiedRep.id,
      modalState.currentSelectedRulesetId,
      rulesetRule.id,
      { sortOrder: newSortOrder }
    );
  });

  const onClearRulesetRule = useCallback(
    () =>
      setModalState((prev) => ({
        ...prev,
        currentEditingRulesetRule: null,
      })),
    [setModalState]
  );

  const currentRules = useMemo(() => {
    return simplifiedRepRulesets
      .getIn(
        [
          simplifiedRepRulesets.findIndex(
            (ruleset) => ruleset.id === modalState.currentSelectedRulesetId
          ),
          'simplifiedRepRulesetRules',
        ],
        new List()
      )
      .filter((rule) => !!rule)
      .sortBy((rule) => rule.sortOrder);
  }, [modalState.currentSelectedRulesetId, simplifiedRepRulesets]);

  if (isFetching) {
    return (
      <Box style={{ height: '25rem' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <ModalTitle>
        <strong>Manage</strong> {simplifiedRep.name}{' '}
        <strong>Rulesets</strong>
      </ModalTitle>

      <Flex
        flexDirection="row"
        justifyContent="space-evenly"
        width="100%"
        mb={4}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          width={modalState.currentSelectedRulesetId ? '40%' : '50%'}
        >
          <Box style={{ flexGrow: 1, position: 'relative' }}>
            <MiniOverlay show={modalState.showRulesetForm} p={3}>
              {modalState.showRulesetForm && (
                <SimplifiedRepRulesetForm
                  initialValues={
                    modalState.currentEditingRuleset || emptyRuleset
                  }
                  isPerforming={isPerforming}
                  onCancel={onToggleRulesetForm}
                  onRDXSubmit={onSaveRuleset}
                />
              )}
            </MiniOverlay>

            <MinimizableList
              items={simplifiedRepRulesets}
              minimizable
              noItemsText="No Rulesets created"
              noItemsSubtext="Create Rulesets with the button below"
              currentSelectedItemId={modalState.currentSelectedRulesetId}
              onToggleSelect={onToggleRuleset}
              onToggleShowForm={onToggleRulesetForm}
              onEdit={onShowEditRulesetForm}
              onDelete={onRemoveRuleset}
            />

            {modalState.currentSelectedRulesetId && (
              <ConnectedList
                items={currentRules}
                noItemsText="No Rules created"
                noItemsSubtext="Create Rules using the form to the right"
                currentSelectedItemId={
                  modalState.currentEditingRulesetRule &&
                  modalState.currentEditingRulesetRule.id
                }
                onSort={onSortRulesetRule}
                onToggleSelect={onToggleRulesetRule}
                onToggleConnector={onToggleRulesetRuleIsOrLogic}
                onDelete={onRemoveRulesetRule}
              />
            )}
          </Box>
        </Flex>
        {modalState.currentSelectedRulesetId && (
          <Flex flexDirection="column" justifyContent="center" width="40%">
            <SimplifiedRepRulesetRuleForm
              initialValues={modalState.currentEditingRulesetRule || emptyRule}
              isPerforming={isPerforming}
              onRDXSubmit={onSaveRulesetRule}
            />

            {modalState.currentEditingRulesetRule && (
              <Button
                secondary
                large
                full
                disabled={isPerforming}
                onClick={onClearRulesetRule}
                mt={2}
              >
                Cancel (and keep changes)
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetchingInModal(),
  isPerforming: selectIsPerformingInModal(),
  simplifiedRep: selectCurrentSimplifiedRep(),
  simplifiedRepRulesets: selectCurrentSimplifiedRepRulesets(),
});

const enhance = compose(
  connect(mapStateToProps, {
    onLoad: processFetchAllSimplifiedRepRulesetsForSimplifiedRep,
    onCreateRuleset: processCreateSimplifiedRepRuleset,
    onUpdateRuleset: processEditSimplifiedRepRuleset,
    onDeleteRuleset: processDeleteSimplifiedRepRuleset,
    onCreateRulesetRule: processCreateSimplifiedRepRulesetRule,
    onUpdateRulesetRule: processEditSimplifiedRepRulesetRule,
    onDeleteRulesetRule: processDeleteSimplifiedRepRulesetRule,
  })
);

export default enhance(SimplifiedRepRulesetModal);
