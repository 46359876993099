import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.MODEL_TEMPLATES.state;

export const fetchAllModelTemplatesTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const fetchIndividualModelTemplateTypes = generateAPHTypes(
  resource,
  'FETCH'
);

export const createModelTemplateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyModelTemplateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editModelTemplateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteModelTemplateTypes = generateAPHTypes(
  resource,
  'DELETE'
);

export const archiveModelTemplatesTypes = generateAPHTypes(
  resource,
  'ARCHIVE'
);

export const unarchiveModelTemplatesTypes = generateAPHTypes(
  resource,
  'UNARCHIVE'
);
