import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.CONFIG_PROFILES.state;

export const fetchAllConfigProfilesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualConfigProfileTypes = generateAPHTypes(resource, 'FETCH');

export const createConfigProfileTypes = generateAPHTypes(resource, 'CREATE');

export const copyConfigProfileTypes = generateAPHTypes(resource, 'COPY');

export const editConfigProfileTypes = generateAPHTypes(resource, 'EDIT');

export const deleteConfigProfileTypes = generateAPHTypes(resource, 'DELETE');

export const archiveConfigProfilesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveConfigProfilesTypes = generateAPHTypes(resource, 'UNARCHIVE');
