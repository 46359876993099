import React from 'react';
import styled from 'styled-components';
import radii from '../../../assets/themes/base/radii';
import borders from '../../../assets/themes/base/borders';

import Flex from '../../common/Flex';

import { Cell } from '../BaseFormCell';

const Row = styled(Flex)`
  flex-direction: row;
  border-bottom: ${borders[1]};

  &:last-child {
    border-bottom: none;
  }

  & ${Cell} {
    border-right: ${borders[1]};
  }

  & ${Cell}:last-child {
    border-right: none;
  }

  &:first-child ${Cell}:first-child {
    border-top-left-radius: ${radii[1]};
  }
  &:first-child ${Cell}:last-child {
    border-top-right-radius: ${radii[1]};
  }
  &:last-child ${Cell}:first-child {
    border-bottom-left-radius: ${radii[1]};
  }
  &:last-child ${Cell}:last-child {
    border-bottom-right-radius: ${radii[1]};
  }
`;

const BaseFormRow = React.forwardRef((props, ref) => <Row ref={ref} { ...props } />);

export default BaseFormRow;