import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Downshift from 'downshift';

import Flex from '../../common/Flex';

import useFloatingState from '../../../hooks/useFloatingState';

import Tooltip, { LAYER_PRIORITY, CONTAINERS } from '../../tooltip/Tooltip';

import BaseOption from '../BaseOption';
import BaseOptions from '../BaseOptions';
import renderOptionForRadio from '../BaseOption/utils/renderOptionForRadio';

import CurrentHiddenValue from './CurrentHiddenValue';

const findSelectedItem = (value, options) => options.find(option => option.value === value) || null;
const itemToString = option => (!!option && (option.primary || option.value)) || '';

const BaseRadioSelect = ({ forwardRef, isLoading, name, placeholder, value, defaultValue, options, defaultIsOpen, onOuterClick : onOuterClickHandler, onChange : onChangeHandler, currentItemHeight, itemHeight = 56, width, renderValue, renderOption = renderOptionForRadio, clearable }) => {
  const currentSelectedItem = useMemo(() => {
    if ((value === '' || value === null) && defaultValue !== undefined) {
      return findSelectedItem(defaultValue, options);
    } else {
      return findSelectedItem(value, options);
    }
  }, [value, defaultValue, options]);
  const initialInputValue = useMemo(() => itemToString(currentSelectedItem), []);

  const [selectState, setSelectState] = useState({ isOpen: !!defaultIsOpen, inputValue: initialInputValue, initialInputValue });

  const onChange = useCallback(
    item => onChangeHandler && onChangeHandler(!!item && item.value || '') || null,
    [onChangeHandler],
  );

  const onOuterClick = useCallback(
    _ => {
      setSelectState(prev => ({ ...prev, isOpen: false }));
      onOuterClickHandler && onOuterClickHandler();
    },
    [onOuterClickHandler, setSelectState],
  );

  const onStateChange = useCallback(
    ({ type, inputValue, isOpen, selectedItem }) => {
      if (selectedItem) {
        // there is a selected item, so we set it as the new value
        const v = itemToString(selectedItem);
        setSelectState(prev => ({
          ...prev,
          isOpen: false,
          inputValue: v,
          initialInputValue: v,
        }));
        return;
      } else {
        // there is no selected value
        if (type === Downshift.stateChangeTypes.unknown) {
          // specifically using this for the "clearSelection" method which doesn't appear to be working
          // as expected from the docs - WE NEED TO MAKE SURE THERE ARE NO OTHER WEIRD UNKNOWN CLICKS HAPPENING
          setSelectState(prev => ({
            ...prev,
            isOpen: true,
            inputValue: '',
            initialInputValue: clearable ? '' : prev.initialInputValue,
          }));

          if (clearable) {
            onChange();
          }
          return;
        } else if (
          type === Downshift.stateChangeTypes.clickItem ||
          type === Downshift.stateChangeTypes.keyDownEnter ||
          isOpen === false
        ) {
          // this case only triggers if the user basically enters the same matching value
          // as the current value, which is why selectedItem comes back empty
          // this triggers onChange on its own
          // this ALSO triggers whenever the dropdown is closed
          setSelectState(prev => {
            return {
              ...prev,
              isOpen: false,
              inputValue: prev.initialInputValue,
            };});
          return;
        }
      }

      // when the input is just being typed in so the value is changing as normal,
      // we are only allowing numbers as hotkeys to select the options
      // so we need to check for the input, see if it's a number, and then
      // select the matching option if it is a number that matches an input

      // the hotkey pressed - this is NOT zero-based index, so keep that in mind
      if (typeof inputValue === 'string') {
        const inputHotkey = parseInt(inputValue.slice(-1));
        if (inputValue !== undefined && !isNaN(inputHotkey) && inputHotkey >= 1 && inputHotkey <= options.size) {
          // adjust for non-zero index
          const v = itemToString(options.get(inputHotkey - 1));
          setSelectState(prev => ({
            ...prev,
            isOpen: false,
            inputValue: v,
            initialInputValue: v,
          }));
          onChange(options.get(inputHotkey - 1));
        }
      }
    },
    [options, onChange],
  );

  const listHeight = itemHeight * options.size;
  const menuWidth = width <= 240 ? 240 : width;

  const [reference, floating, floatingStyle] = useFloatingState({placement:'bottom'});

  // if the value coming into this component is ever set to null or '',
  // we are going to reset the value
  useEffect(() => {
    if ((value === '' || value === null) && clearable) {
      // effectively, we are calling a pseudo "clearSelection", except we are going
      // to close the dropdown here instead of keeping it open because it is an
      // external "clearSelection"
      setSelectState(prev => ({
        ...prev,
        isOpen: false,
        inputValue: '',
        initialInputValue: clearable ? '' : prev.initialInputValue,
      }));
    } else {
      const incomingSelectedItem = itemToString(currentSelectedItem);
      // if the value ever changes externally for any reason
      setSelectState(prev => ({
        ...prev,
        isOpen: false,
        inputValue: incomingSelectedItem,
      }));
    }
  }, [value]);

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%'>
      <Downshift
        isOpen={selectState.isOpen}
        inputValue={selectState.inputValue}
        initialInputValue={selectState.initialInputValue}
        defaultIsOpen={defaultIsOpen}
        itemToString={itemToString}
        onChange={onChange}
        onOuterClick={onOuterClick}
        defaultHighlightedIndex={0}
        itemCount={options.size}
        onStateChange={onStateChange}
      >
        {
          ({ isOpen, highlightedIndex, inputValue, selectItem, clearSelection, getRootProps, getInputProps, getMenuProps, getItemProps }) => (
            <Flex flexDirection='column' justifyContent='center' height='100%' { ...getRootProps({ ref: reference }) }>
              <CurrentHiddenValue
                ref={forwardRef}
                getInputProps={getInputProps}
                isLoading={isLoading}
                inputValue={inputValue}
                name={name}
                placeholder={placeholder}
                currentHighlightedItem={!isOpen ? currentSelectedItem : highlightedIndex > -1 && options.get(highlightedIndex)}
                renderValue={renderValue}
                isOpen={isOpen}
                setSelectState={setSelectState}
                clearSelection={clearSelection}
                clearable={clearable}
                itemHeight={currentItemHeight || itemHeight}
              />
              {
                !isLoading && isOpen && ReactDOM.createPortal(
                  <Tooltip { ...getMenuProps({ ref: floating, style: floatingStyle, size: 'fluid', priority: LAYER_PRIORITY.MODAL_DROPDOWN }) }>
                    <BaseOptions style={{ width: menuWidth, height: listHeight }}>
                      {
                        options.map((item, index) => (
                          <BaseOption
                            key={index}
                            item={item}
                            index={index}
                            style={{ height: itemHeight }}
                            hotkey={index+1}
                            isHighlighted={index === highlightedIndex}
                            isSelected={currentSelectedItem && item.value === currentSelectedItem.value}
                            getItemProps={getItemProps}
                            renderItem={renderOption}
                          />)
                        )
                      }
                    </BaseOptions>
                  </Tooltip>,
                  document.querySelector(CONTAINERS.DROPDOWN)
                )
              }
            </Flex>
          )
        }
      </Downshift>
    </Flex>
  );
};

const RadioSelect = React.forwardRef((props, ref) => <BaseRadioSelect forwardRef={ref} { ...props } />);

export default RadioSelect;
