import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import NoteBlockForm from '../../../form/templates/NoteBlockForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import NoteBlockModel from '../../../../entities/Standardize/NoteBlocks/model';
import {
  processCreateNoteBlock,
  processEditNoteBlock,
} from '../../../../entities/Standardize/NoteBlocks/actions';

import { selectCurrentNoteBlock } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyNoteBlock = new NoteBlockModel();

const NoteBlockModal = ({ noteProfileId, noteBlock, isPerforming, onCreate, onUpdate }) => {
  const onSave = useCallback(data => {
    if (noteBlock) { onUpdate(noteProfileId, noteBlock.id, data); }
    else { onCreate(noteProfileId, data); }
  }, [noteBlock, onCreate, onUpdate]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={noteBlock && noteBlock.name} create='Note Block' />

      <NoteBlockForm
        initialValues={noteBlock || emptyNoteBlock}
        isPerforming={isPerforming}
        onRDXSubmit={onSave}
      />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  noteBlock: selectCurrentNoteBlock(),
});

const enhance = compose(
  injectSaga({ key: `${EK.NOTE_BLOCKS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateNoteBlock,
      onUpdate: processEditNoteBlock,
    }
  ),
);

export default enhance(NoteBlockModal);
