import React from 'react';

export default function FitHoseElbIcon({ size = 16, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <path d="M10.063,15.75h-5.25V4.625h5.25V15.75z M5.813,14.75h3.25V5.625h-3.25V14.75z" />
      <path d="M24.063,23.625H12.938v-7.75h11.125V23.625z M13.938,22.625h9.125v-5.75h-9.125V22.625z" />
      <rect x="3.98" y="4.625" width="1.332" height="1" />
      <rect x="3.98" y="14.75" width="1.332" height="1" />
      <rect x="9.563" y="12.75" width="5.438" height="1" />
      <rect x="15.063" y="13.665" width="1" height="2.71" />
      <rect x="9.563" y="6.813" width="9.813" height="1" />
      <rect x="21" y="9.813" width="1" height="6.563" />
      <path d="M22,9.813h-1c0-1.103-0.729-2-1.625-2v-1C20.822,6.813,22,8.158,22,9.813z" />
      <path d="M15.102,13.774C15.094,13.768,15.059,13.75,15,13.75v-1c0.596,0,1.063,0.402,1.063,0.915h-1C15.063,13.723,15.089,13.764,15.102,13.774z" />
    </svg>
  );
}
