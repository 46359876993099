import EK from '../../keys';

import EntityRecord, { UTILS, VALIDATORS } from '../../../modules/entities/model';

class ConfigProfileValue extends EntityRecord({
  [EK.CONFIG_PROFILES.single]: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    // handleSerialize: true,
    // handleDeepSerialize: true,
    // serializeOutputField: `${EK.CONFIG_PROFILES.single}Id`,
  },
  [EK.CONFIG_SETTINGS.single]: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: `${EK.CONFIG_SETTINGS.single}Id`,
  },
  value: {
    value: undefined,
    handleCompare: true,
    handleSerialize: (entity) => {
      const value = entity.value;
      if (value === null || value === undefined) return null;

      switch (entity.valueType) {
      case 'string':
      case 'list':
      case 'integer':
      case 'double': {
        return entity.value;
      }

      case 'bool': {
        return `${entity.value}`;
      }

      case 'color': {
        return UTILS.STRINGIFY_COLOR(entity.value);
      }
      }
    },
    validate: (entity) => {
      const value = entity.value;
      // because all settings are optional, null and undefined are acceptable values across the board
      // either of these values means that we will default to the setting's defaultValue
      if (value === null || value === undefined) return true;

      switch (entity.valueType) {
      case 'string': {
        return VALIDATORS.IS_REQUIRED_STRING(value);
      }
      case 'list': {
        return VALIDATORS.IS_REQUIRED_STRING(value) &&
            entity.listValues.find((lv) => lv.value === value);
      }
      case 'integer': {
        return VALIDATORS.IS_INTEGER(value);
      }
      case 'double': {
        return VALIDATORS.IS_NUMERIC(value);
      }
      case 'bool': {
        return value === true || value === false;
      }
      case 'color': {
        try {
          return value && VALIDATORS.IS_RGB_COLOR_OBJECT(value);
        } catch (e) {
          return false;
        }
      }
      }
    },
    validateError: (entity) => {
      switch (entity.valueType) {
      case 'string': {
        return 'A valid non-empty string must be present';
      }
      case 'list': {
        return 'Only one of the options from the list is allowed';
      }
      case 'integer': {
        return 'A non-negative integer must be present';
      }
      case 'double': {
        return 'A valid number must be present';
      }
      case 'bool': {
        return 'This value may only be either true or false';
      }
      case 'color': {
        return 'A valid color must be present';
      }
      }
    },
  },
  valueType: {
    value: undefined,
    handleCopy: true,
  },
  multipleAllowed: {
    value: false,
    handleCopy: true,
  },
}) {
  constructor({ valueType, value, ...other } = {}) {
    super({
      ...other,
      valueType,
      value: valueType === 'bool'
        ? (
          value === 'true'
        )
        : (valueType === 'color' ? UTILS.OBJECTIZE_COLOR(value) : value),
    });
  }
}

export default ConfigProfileValue;
