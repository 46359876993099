import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Text from '../../../../components/common/Text';

const Container = styled(Box)`
  flex: 1;
  min-width: 0;
`;

const Header = React.memo(({ isLoading, title, subtitle }) => (
  <Container>
    <Text $ellipsis fontSize={6} color='gray.7'>{ !isLoading ? title : <Skeleton /> }</Text>
    <Text $ellipsis fontSize={2} color='gray.6'>{ !isLoading ? subtitle : <Skeleton /> }</Text>
  </Container>
));

export default Header;