import React from 'react';

import Text from '../../../common/Text';

import TooltipBody from '../../TooltipBody';
import TooltipSection from '../../TooltipSection';

const InvalidRoleTooltip = React.memo(({ role, requiredRole }) => (
  <TooltipBody>
    <TooltipSection large error>
      <Text fontSize={3} fontWeight={500}>Invalid Permissions</Text>
    </TooltipSection>
    <TooltipSection large>
      <Text color='error.6' fontSize={1}>
        You are currently only a/an <strong>{ role || 'User' }</strong> for this organization and are not allowed to make any changes unless you are a/an <strong>{ requiredRole || 'Administrator/Collaborator' }</strong>. One of your organization&apos;s administrators can change your role in the organization.
      </Text>
    </TooltipSection>
  </TooltipBody>
));

export default InvalidRoleTooltip;