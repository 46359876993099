import { Map } from 'immutable';

import { 
  TOGGLE_SHOW_ARCHIVED, 
} from './constants';

const initialGridState = Map({
  isShowingArchived: false,
});

const queryReducer = (state = initialGridState, { type }) => {
  if (type === TOGGLE_SHOW_ARCHIVED) {
    return state.set('isShowingArchived', !state.get('isShowingArchived'));
  } else {
    return state;
  }
};

export default queryReducer;