import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Button from '../../../../components/common/Button';

import { selectSidebarIsFiltering } from '../../../Dashboard/selectors';
import { toggleSidebarFiltering } from '../../../Dashboard/actions';

const BaseFilterToggleButton = ({ isFiltering, toggleFiltering, ...rest }) => (
  <Button success={isFiltering} secondary={!isFiltering} icon='filter' onClick={toggleFiltering} mr={2} { ...rest } />
);

const mapStateToProps = createSelector(
  selectSidebarIsFiltering(),
  isFiltering => ({ isFiltering })
);

const enhance = compose(
  connect(mapStateToProps, { toggleFiltering: toggleSidebarFiltering })
);

export default enhance(BaseFilterToggleButton);