import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import EK from '../../keys';
import { newInsulationSpecItems } from './constants';

import InsulationSpecSchema from './schema';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { formValueSelector } from 'redux-form/immutable';

export const selectNormalizedInsulationSpecs = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.INSULATION_SPECS.state)
);

const insulationSpecFilterForm = formValueSelector(`filters.${EK.INSULATION_SPECS.state}`);

export const selectFilteredInsulationSpecs = () => createSelector(
  selectNormalizedInsulationSpecs(),
  selectSidebarIsFiltering(),
  state => insulationSpecFilterForm(state, 'insulationCode', 'appearance', 'insulationFamily' ),
  selectEntities(),
  (fittings, isFiltering, query, entities) => {
    const instances = !isFiltering ? fittings : fittings.toList().filter(instance => instance.doesMatchQuery(query));

    return isFiltering ?
      denormalize(instances, [InsulationSpecSchema], entities).toList().toArray() :
      denormalize(instances, [InsulationSpecSchema], entities).toList().toArray().concat(newInsulationSpecItems);
  }
);

export const selectInsulationSpecCount = () => createSelector(
  selectNormalizedInsulationSpecs(),
  specs => specs.toList().count()
);